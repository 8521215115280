'use client';

import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

function usePageVisibility_() {
	const [isVisible, setIsVisible] = useState(!document.hidden);

	useEffect(() => {
		const handleVisibilityChange = () => {
			setIsVisible(!document.hidden);
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return isVisible;
}

export const usePageVisibility = singletonHook(true, usePageVisibility_);
