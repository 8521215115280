import { SettingsTrade as SettingsTradeIcon } from '@drift-labs/icons';

const SettingsTrade = (props: { active: boolean }) => {
	return (
		<SettingsTradeIcon
			color={props.active ? 'url(#icon-gradient)' : '#6683A7'}
			size={18}
		/>
	);
};

export default SettingsTrade;
