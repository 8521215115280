'use client';

import { useCallback, useEffect } from 'react';
import useDriftStore, {
	DEFAULT_AVOID_HISTORY_SERVER_LOAD,
	DriftStore,
} from 'src/stores/DriftStore/useDriftStore';
import useInterval from './useInterval';
import ExchangeHistoryClient from '../utils/exchangeHistoryClient';
import useDevSwitchIsOn from './useDevSwitchIsOn';
import { singletonHook } from 'react-singleton-hook';
import useS3Params from './useS3Params';

const DEFAULT_STATUS = {
	driftStatus: {
		status: 'green',
		message: '',
	} as DriftStore['driftStatus']['mainStatus'],
	driftFeatureAlert: {
		alertType: '',
		alertData: '',
	} as DriftStore['driftFeatureAlert'],
	avoidHistoryServerLoad: DEFAULT_AVOID_HISTORY_SERVER_LOAD,
};

/**
 * Get the drift status from aws s3 bucket. Should be in the form `{status} | {message}` where status can be green|amber|red|'' and message can be any string.
 *
 * e.g:
 * - 'green | Things are back to normal'
 * - 'amber | The solana network is experiencing congestion'
 * - 'false'|'none'|'' (no status to display)
 * @returns
 */
const getStatus = (params: {
	status: string;
	message: string;
	alert: any;
	avoidHistoryServerLoad?: string;
}) => {
	try {
		const driftStatus = {
			status: params?.status ?? '',
			message: params?.message ?? '',
		} as DriftStore['driftStatus']['mainStatus'];

		const driftFeatureAlert = {
			alertType: params?.alert?.alertType || '',
			alertData: params?.alert?.alertData || '',
		} as DriftStore['driftFeatureAlert'];

		const avoidHistoryServerLoad =
			DEFAULT_AVOID_HISTORY_SERVER_LOAD ||
			(params?.avoidHistoryServerLoad &&
				params?.avoidHistoryServerLoad === 'true');

		return { driftStatus, driftFeatureAlert, avoidHistoryServerLoad };
	} catch (e) {
		return DEFAULT_STATUS;
	}
};

const useDriftStatus = () => {
	const isDevMode = useDevSwitchIsOn();
	const pollingMultiplier = useDriftStore((s) => s.pollingMultiplier);
	const s3Params = useS3Params();

	const setState = useDriftStore((s) => s.set);

	useEffect(() => {
		if (!s3Params) return;

		// Check status from s3
		const { driftStatus, driftFeatureAlert, avoidHistoryServerLoad } =
			getStatus(s3Params);

		if (avoidHistoryServerLoad && isDevMode) {
			console.log(`avoidHistoryServerLoad = true`);
		}

		if (driftStatus.status || driftFeatureAlert.alertType) {
			setState((s) => {
				s.driftStatus.mainStatus = {
					...s.driftStatus.mainStatus,
					...driftStatus,
				};
				s.driftFeatureAlert = driftFeatureAlert;
				s.avoidHistoryServerLoad = avoidHistoryServerLoad;
			});
			return;
		}

		setState((s) => {
			s.driftStatus.mainStatus = {
				...s.driftStatus.mainStatus,
				...DEFAULT_STATUS.driftStatus,
			};
			s.driftFeatureAlert = DEFAULT_STATUS.driftFeatureAlert;
			s.avoidHistoryServerLoad = avoidHistoryServerLoad;
		});
	}, [s3Params, getStatus]);

	const refreshHistoryServerStatus = useCallback(async () => {
		const status = await ExchangeHistoryClient.getServerStatus();
		setState((s) => {
			s.driftStatus.historyServerStatus.maintenanceMode =
				status?.body?.data?.maintenanceMode ?? false;
			s.driftStatus.historyServerStatus.maintenanceModeEstimateTs =
				status?.body?.data?.maintenanceModeEstimateTs ?? undefined;
		});
	}, []);

	useInterval(refreshHistoryServerStatus, 30 * 1000 * pollingMultiplier);
};

export default singletonHook(() => {}, useDriftStatus);
