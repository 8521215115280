import Link from 'next/link';
import Modal from './Modal';
import GradientText from 'src/components/Utils/GradientText';
import Twitter from 'src/components/Socials/Twitter';
import Discord from 'src/components/Socials/Discord';

const ExchangePausedModal = () => {
	return (
		<Modal className="sm:max-w-md" onClose={() => {}}>
			<Modal.Body>
				<Modal.Header onClose={() => {}} showX={false} className="">
					<div className="text-lg text-neutrals-30">
						Drift Protocol v1 has been sunsetted
					</div>
				</Modal.Header>
				<Modal.Content className="px-4 font-light md:px-6 text-md text-neutrals-30">
					<div className="mb-4">
						The team is working on a new and improved Protocol v2. Trading is
						expected to resume when v2 is live.
					</div>
					<div className="mb-6">
						If you had an account with Drift, you can access and manage your
						collateral{' '}
						<Link href="/redeem">
							<a>
								<GradientText className="hover:underline">here</GradientText>
							</a>
						</Link>
						.
					</div>
					<div className="flex gap-4">
						<Twitter className="w-6 h-4 hover:opacity-80" color="#577294" />
						<Discord className="w-6 h-4 hover:opacity-80" color="#577294" />
					</div>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default ExchangePausedModal;
