'use client';

import { useEffect } from 'react';
import { allColors } from '../constants/figmaGenerated';
import { gradients } from '../constants/designSystemColors';

const useDriftColours = () => {
	useEffect(() => {
		const styleMap: Record<string, string> = {};

		Object.entries(allColors).forEach(([colourName, weights]) => {
			Object.entries(weights).forEach(([weightValue, hexCode]) => {
				styleMap[`--${colourName}-${weightValue}`] = hexCode;
			});
		});

		Object.entries(gradients).forEach(([gradientName, value]) => {
			styleMap[`--${gradientName}`] = value;
		});

		const root = document.documentElement;

		Object.entries(styleMap).forEach(([label, value]) => {
			root.style.setProperty(label, value);
		});
	}, []);
};

export default useDriftColours;
