import { PriorityFee } from '@drift-labs/icons';

const SettingsInterfaceIcon = (props: { active: boolean }) => {
	return (
		<PriorityFee
			color={props.active ? 'url(#icon-gradient)' : '#6683A7'}
			size={18}
		/>
	);
};

export default SettingsInterfaceIcon;
