'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { ClosePositionPopupInner } from '../Popups/ClosePositionPopup';
import Modal from './Modal';
import React from 'react';

const ClosePositionModal = () => {
	const set = useDriftStore((s) => s.set);

	const handleClose = () => {
		set((s) => {
			s.modals.showClosePositionModal = false;
		});
	};

	const closePositionPopupOptions = useDriftStore(
		(s) => s.popups.closePositionPopupOptions
	);

	return (
		<Modal onClose={handleClose}>
			<Modal.Body>
				<Modal.Header onClose={handleClose} showX>
					Close Position
				</Modal.Header>
				<ClosePositionPopupInner
					{...closePositionPopupOptions}
					onClose={handleClose}
					setFloating={null}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(ClosePositionModal);
