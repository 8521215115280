/**
 * This class stores how many times a function has been called successfully and how many times it has failed.
 *
 * It can be constructed with a buffer size, and a predicate function that determines whether its overall state is a success or failure.
 */
class SuccessAndFailureBuffer {
	private bufferSize: number;
	private successStatePredicate: (
		bufferSize: number,
		successCount: number,
		failureCount: number
	) => boolean;
	private successFailureBuffer: boolean[];

	constructor(
		bufferSize: number,
		successStatePredicate: (
			bufferSize: number,
			successCount: number,
			failureCount: number
		) => boolean
	) {
		this.bufferSize = bufferSize;
		this.successStatePredicate = successStatePredicate;
		this.successFailureBuffer = [];
	}

	public addResult(result: 'success' | 'failure') {
		this.successFailureBuffer.push(result === 'success');
		if (this.successFailureBuffer.length > this.bufferSize) {
			this.successFailureBuffer.shift();
		}
	}

	private get successCount() {
		return this.successFailureBuffer.filter((result) => result).length;
	}

	private get failureCount() {
		return this.successFailureBuffer.filter((result) => !result).length;
	}

	public get isSuccess() {
		return this.successStatePredicate(
			this.bufferSize,
			this.successCount,
			this.failureCount
		);
	}

	public print() {
		return `isSuccess=${this.isSuccess}, state=${this.successFailureBuffer.join(
			', '
		)}`;
	}

	public reset() {
		this.successFailureBuffer = [];
	}
}

export default SuccessAndFailureBuffer;
