'use client';

import { useCallback, useEffect, useState } from 'react';
import SettingsForm from 'src/components/Settings/SettingsForm';
import useDriftActions from '../../hooks/useDriftActions';
import useInterval from '../../hooks/useInterval';
import useIsMobileScreenSize from '../../hooks/useIsMobileScreenSize';
import Modal from './Modal';
import DevMobileForm from './DevMobileForm';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';

const SettingsModal = (props: { settingGroupIndex?: number }) => {
	const { captureEvent } = usePostHogCapture();
	const actions = useDriftActions();
	const isMobile = useIsMobileScreenSize();
	const [devTaps, setDevTaps] = useState<number>(0);
	const [showMobileDevForm, setShowMobileDevForm] = useState(false);

	useInterval(() => {
		if (devTaps > 0) {
			setDevTaps(0);
		}
	}, 3000);

	useEffect(() => {
		if (devTaps >= 5) {
			setShowMobileDevForm(true);
		}
	}, [devTaps]);

	const hideModal = () => {
		actions.showModal('showMarginModal', false);
		actions.showModal('showSettingsModal', false);
		actions.showModal('showNetworkModal', false);
		actions.showModal('showOtherSettingsModal', false);
	};

	const closeDevForm = useCallback(() => {
		setShowMobileDevForm(false);
	}, []);

	const handleSettingsClick = useCallback(() => {
		setDevTaps(devTaps + 1);
	}, [isMobile, devTaps]);

	useEffect(() => {
		if (isMobile) {
			captureEvent('settings_menu_opened_mobile');
		} else {
			captureEvent('settings_menu_opened_desktop');
		}
	}, [isMobile]);

	return (
		<Modal
			onClose={hideModal}
			sizeConfig={{ xs: 12, sm: 10, md: 8, lg: 8, xl: 6 }}
		>
			<Modal.Body className="overflow-visible">
				<Modal.Header onClose={hideModal} showX>
					<div onClick={handleSettingsClick}>Settings</div>
				</Modal.Header>
				{showMobileDevForm ? (
					<DevMobileForm onClose={closeDevForm} />
				) : (
					<SettingsForm
						settingGroupIndex={props.settingGroupIndex}
						onClose={hideModal}
					/>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default SettingsModal;
