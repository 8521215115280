'use client';

import { BankBalanceUI, COMMON_UI_UTILS } from '@drift/common';
import { useEffect, useState } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import useLazySubAccounts from './useLazySubAccounts';
import useTargetAccountData from './useTargetAccountData';

const balancesAreEqual = (balanceA: BankBalanceUI, balanceB: BankBalanceUI) => {
	if (
		balanceA.accountId !== balanceB.accountId ||
		balanceA.accountName !== balanceB.accountName ||
		!balanceA.accountAuthority.equals(balanceB.accountAuthority) ||
		JSON.stringify(balanceA.balanceType) !==
			JSON.stringify(balanceB.balanceType) ||
		!balanceA.cumulativeDeposits.eq(balanceB.cumulativeDeposits) ||
		balanceA.marketIndex !== balanceB.marketIndex ||
		!balanceA.openAsks.eq(balanceB.openAsks) ||
		!balanceA.openBids.eq(balanceB.openBids) ||
		balanceA.openOrders !== balanceB.openOrders ||
		!balanceA.scaledBalance.eq(balanceB.scaledBalance)
	) {
		return false;
	}
	return true;
};

const useSubaccountCollateralBalances = (
	accountKey?: string,
	aggregate?: boolean
) => {
	const [balances, setBalances] = useState<BankBalanceUI[]>([]);

	const clientIsReady = useDriftClientIsReady();
	const userAccountData = useTargetAccountData(accountKey);
	const userAccountClient = userAccountData?.client;
	const subAccounts = useLazySubAccounts();
	const set = useDriftAccountStore((s) => s.set);

	useEffect(() => {
		if (
			!userAccountData ||
			!userAccountClient ||
			!clientIsReady ||
			!userAccountClient?.isSubscribed
		) {
			if (balances?.length) {
				setBalances([]);
			}
			return;
		}

		const subscribedSubaccounts = subAccounts.filter(
			(acct) =>
				acct?.client?.isSubscribed &&
				acct?.client?.accountSubscriber?.isSubscribed
		);

		const updateHandler = () => {
			// TODO 2: Can we also reduce the frequency at which this fires? Can it be reduced to let's say once per second?
			// I guess it's controlled by the user client account but we are still wasting CPU mapping these balances over and over every second too, it's just not AS wasteful as actually changing the state.
			let bankBalances: BankBalanceUI[] = [];

			if (
				subscribedSubaccounts.some(
					(acc) =>
						!acc ||
						!acc?.isSubscribed ||
						!acc?.client?.isSubscribed ||
						!acc?.client?.accountSubscriber?.isSubscribed
				)
			)
				return;

			if (aggregate) {
				subscribedSubaccounts.forEach((acct) => {
					bankBalances.push(
						...(acct?.client?.getUserAccount().spotPositions.map((bal) => {
							return {
								...bal,
								accountId: acct.userId,
								accountName: acct.name,
								accountAuthority: acct.authority,
							};
						}) ?? [])
					);
				});
			} else {
				bankBalances = userAccountClient
					.getUserAccount()
					.spotPositions.map((bal) => {
						return {
							...bal,
							accountId: userAccountData.userId,
							accountName: userAccountData.name,
							accountAuthority: userAccountData.authority,
						};
					});
			}

			const newBalances = bankBalances.filter(
				(bankBalance) => !bankBalance.scaledBalance.isZero()
			);

			const balancesChanged =
				newBalances.length !== balances.length ||
				newBalances.some(
					(newBalance, i) => !balancesAreEqual(newBalance, balances[i])
				);

			// Only set new state if balances changed
			if (balancesChanged) {
				setBalances(newBalances);
				set((s) => {
					subscribedSubaccounts.forEach((acct) => {
						const userKey = COMMON_UI_UTILS.getUserKey(
							acct.userId,
							acct.authority
						);
						if (s.accounts[userKey]) {
							s.accounts[userKey].balanceCount = bankBalances.filter(
								(bankBalance) =>
									!bankBalance.scaledBalance.isZero() &&
									bankBalance.accountId == acct.userId &&
									bankBalance.accountAuthority.equals(acct.authority)
							).length;
						}
					});
				});
			}
		};

		updateHandler();

		userAccountClient.eventEmitter.on('userAccountUpdate', updateHandler);

		return () => {
			userAccountClient.eventEmitter.removeListener(
				'userAccountUpdate',
				updateHandler
			);
		};
	}, [userAccountData, aggregate, subAccounts, balances, clientIsReady]);

	return balances;
};

export default useSubaccountCollateralBalances;
