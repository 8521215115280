'use client';

import { useEffect, useRef } from 'react';
import useDriftActions from './useDriftActions';
import { DevEventEmitter, DevEvents } from '../@types';
import { SPOT_MARKETS_LOOKUP } from '../environmentVariables/EnvironmentVariables';
import { BigNum } from '@drift-labs/sdk';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { DriftWindow } from 'src/window/driftWindow';

const ALREADY_SETUP_REF = { current: false };

// # Type for event handlers for DevEvents interface .. [key, handler]
type EventHandlerConfig = [keyof DevEvents, DevEvents[keyof DevEvents]];

const useDevActions = () => {
	const actions_ = useDriftActions();
	const actionsRef = useRef(actions_);

	const getState = useDriftStore((s) => s.get);
	const getDriftClient = () => getState().driftClient.client;

	useEffect(() => {
		actionsRef.current = actions_;
	}, [actions_]);

	// # Logic for each event handler
	const handleWithdrawAllDust: DevEventEmitter[' _emitType']['withdraw_all_dust'] =
		() => {
			actionsRef.current.withdrawDustPositions();
		};

	const handleSettleAllValidOracleMarkets: DevEventEmitter[' _emitType']['settle_all_valid_oracle_markets'] =
		() => {};

	const handleWithdraw5Usdc: DevEventEmitter[' _emitType']['withdraw_5_usdc'] =
		() => {
			const USDCMarket = SPOT_MARKETS_LOOKUP[0];

			actionsRef.current.withdrawCollateral({
				amount: BigNum.fromPrint('5', USDCMarket.precisionExp),
				spotMarketConfig: USDCMarket,
			});
		};

	const createAnchorErrorTx: DevEventEmitter[' _emitType']['force_anchor_error_tx'] =
		() => {
			actionsRef.current.renameSubaccount(1_000, 'bler');
		};

	const createDriftErrorTx: DevEventEmitter[' _emitType']['force_drift_error_tx'] =
		() => {
			const driftClient = getDriftClient();
			actionsRef.current.removePerpLpShares({
				userAccount: driftClient.getUserAccount(driftClient.activeSubAccountId),
				amount: SPOT_MARKETS_LOOKUP[0].precision.muln(100_000),
				marketIndex: 0,
			});
		};

	const createWalletbalanceError: DevEventEmitter[' _emitType']['force_insufficient_wallet_balance_error'] =
		() => {
			const spotMarket = SPOT_MARKETS_LOOKUP[0];

			actionsRef.current.depositCollateralForExistingAccount(
				BigNum.fromPrint((50_000_000).toString(), spotMarket.precisionExp),
				spotMarket
			);
		};

	const reclaimRent: DevEventEmitter[' _emitType']['reclaim_rent'] = () => {
		actionsRef.current.reclaimRent(getDriftClient().activeSubAccountId);
	};

	// # Config for event handlers
	const eventHandlerConfigs: EventHandlerConfig[] = [
		['withdraw_all_dust', handleWithdrawAllDust],
		['settle_all_valid_oracle_markets', handleSettleAllValidOracleMarkets],
		['withdraw_5_usdc', handleWithdraw5Usdc],
		['force_anchor_error_tx', createAnchorErrorTx],
		['reclaim_rent', reclaimRent],
		['force_drift_error_tx', createDriftErrorTx],
		['force_insufficient_wallet_balance_error', createWalletbalanceError],
	];

	// # Connect event handlers to the event emitter
	useEffect(() => {
		if (ALREADY_SETUP_REF.current) return;

		ALREADY_SETUP_REF.current = true;

		const devEventEmitter = DriftWindow.devEventEmitter;

		eventHandlerConfigs.forEach(([key, handler]) => {
			devEventEmitter.on(key, handler);
		});
	}, []);
};

export default useDevActions;
