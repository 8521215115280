'use client';

import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { useEffect, useState } from 'react';
import { AccountData } from 'src/stores/useDriftAccountsStore';
import { ACCOUNT_HEALTH_PERCENT_CRITICAL } from 'src/constants/constants';
import useTickedAllSubAccounts from '../useTickedAllSubAccounts';

const DEV_MODE = false;

const EMPTY_ACCOUNTS_AT_RISK: AccountData[] = [];

const useAccountsAtRisk = () => {
	const userAccounts = useTickedAllSubAccounts();

	const [accountsAtRisk, setAccountsAtRisk] = useState<AccountData[]>(
		EMPTY_ACCOUNTS_AT_RISK
	);

	const isConnected = useWalletIsConnected();

	const handleSetAccountsAtRisks = () => {
		if (!isConnected) {
			setAccountsAtRisk(EMPTY_ACCOUNTS_AT_RISK);
		} else {
			const accountsAtRisk = userAccounts.filter((account, index) => {
				if (DEV_MODE && index === 0) {
					return true;
				}

				const accountHealth = account.client?.getHealth();

				const isAtRisk = accountHealth < ACCOUNT_HEALTH_PERCENT_CRITICAL;

				return isAtRisk;
			});

			setAccountsAtRisk(
				accountsAtRisk.length > 0 ? accountsAtRisk : EMPTY_ACCOUNTS_AT_RISK
			);
		}
	};

	useEffect(() => {
		handleSetAccountsAtRisks();
	}, [isConnected, userAccounts]);

	return accountsAtRisk;
};

export default useAccountsAtRisk;
