'use client';

import React, { useEffect, useState } from 'react';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import { PnLTimeSeriesDataPoint } from 'src/@types/types';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';
import { ButtonGroup } from './Button';
import NumberDisplayV2 from './Utils/NumberDisplayV2';
import { BigNum, BN } from '@drift-labs/sdk';
import Text from './Text/Text';
import SkeletonValuePlaceholder from './SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { ZERO_BIGNUM } from 'src/constants/math';
import { LoadingChart } from './Charts/LoadingChart';

const PnlChart = dynamic(() => import('./PnlChart'));

type DateRangeOption = '1' | '7' | '30' | '90';

const dateRangeOptions = [
	// { label: '24h', value: '1' },
	{ label: '7d', value: '7' },
	{ label: '30d', value: '30' },
	{ label: '90d', value: '90' },
];

export const RateHistoryChart = (props: {
	//timeframeIndex: number;
	marketIndex: number;
	type: 'deposit' | 'borrow';
	showAverage?: boolean;
	averageLabelText?: 'string';
	className?: string;
	chartClassName?: string;
	loadingClassName?: string;
}) => {
	const displayDecimals = 5;
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [chartData, setChartData] = useState<PnLTimeSeriesDataPoint[]>();
	const [dateRange, setDateRate] = useState<DateRangeOption>('90');
	const [averageRate, setAverageRate] = useState<number>(0);

	const handleChangeDateRange = (range: DateRangeOption) => {
		if (isLoading) return;
		setDateRate(range);
	};

	const getAndSetData = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const result = await ExchangeHistoryClient.getRateHistory(
				props.marketIndex,
				props.type,
				parseInt(dateRange)
			);

			if (result.success) {
				let sum = 0;

				const points: PnLTimeSeriesDataPoint[] = result.body.data.map(
					(point) => {
						const totalPnl = Number(point[1]) * 100;
						sum += totalPnl;
						return {
							date: new Date(point[0] * 1000),
							totalPnl,
						};
					}
				);

				setAverageRate(points?.length > 0 ? sum / points.length : 0);
				setChartData(points);
				setIsLoading(false);
			}
		} catch (e) {
			setError(`Error fetching rate history: ${e}`);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getAndSetData();
	}, [props.marketIndex, dateRange, props.type]);

	const capitalizedType = props.type === 'deposit' ? 'Deposit' : 'Borrow';
	const chartLabel = `${capitalizedType} Rate`;

	return (
		<div>
			<div className="flex flex-row items-center justify-between">
				<ButtonGroup.Segmented
					options={dateRangeOptions}
					selected={dateRange}
					size="MEDIUM"
					selectAction={handleChangeDateRange}
					className={isLoading && 'opacity-50'}
				/>
				<div className="flex flex-col items-end gap-0.5 text-text-label">
					<Text.MICRO1 className="text-text-label">
						{props.averageLabelText || 'Avg APR'}
					</Text.MICRO1>
					{props.showAverage &&
						(isLoading ? (
							<SkeletonValuePlaceholder className="w-12 h-3 mt-1" loading />
						) : (
							<NumberDisplayV2
								value={
									(averageRate &&
										!isNaN(averageRate) &&
										BigNum.fromPrint(
											`${Math.round(averageRate * 10000) / 10000}`,
											new BN(4)
										)) ||
									ZERO_BIGNUM
								}
								displayType="percentage"
								colourCoded
							/>
						))}
				</div>
			</div>
			<div
				className={twMerge(
					'items-center justify-center flex-grow min-h-[200px] w-full mt-4 mb-4',
					props.className
				)}
			>
				{isLoading ? (
					<div
						className={twMerge(
							`flex flex-col justify-center items-center h-[200px] w-full text-center rounded-md`,
							props.loadingClassName
						)}
					>
						<LoadingChart />
					</div>
				) : error ? (
					<div
						className={twMerge(
							`flex flex-col justify-center items-center h-[200px] w-full text-center rounded-md p-6`,
							props.loadingClassName
						)}
					>
						<Text.BODY3 className="text-text-label">
							Unable to fetch {props.type} rate history
						</Text.BODY3>
					</div>
				) : (
					<PnlChart
						id={`rate-history-${props.type}`}
						data={chartData}
						field={'totalPnl'}
						label={chartLabel}
						positive={false}
						decimalsToShow={displayDecimals}
						isInModal={true}
						overrideNoBeginAtZero={true}
						isPercent={true}
						nonNotional={true}
						isHourly={false}
						className={twMerge('min-h-[200px]', props.chartClassName)}
					/>
				)}
			</div>
		</div>
	);
};
