import { useTheme } from 'next-themes';
import { useEffect } from 'react';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';

export const ThemeSetter = (props: {
	setTheme: (value: DriftTheme) => void;
}) => {
	const { theme, setTheme } = useTheme();

	const handleThemeChange = (theme: DriftTheme) => {
		setTheme(theme);
		props.setTheme(theme);
	};

	useEffect(() => {
		if (theme === DriftTheme.dark || theme === DriftTheme.light) {
			handleThemeChange(theme);
		}
	}, [theme]);

	useEffect(() => {
		// Set the theme based on the system preference if no theme is set
		if (theme !== DriftTheme.light && theme !== DriftTheme.dark) {
			if (
				window.matchMedia &&
				window.matchMedia('(prefers-color-scheme: dark)').matches
			) {
				handleThemeChange(DriftTheme.dark);
			} else {
				handleThemeChange(DriftTheme.light);
			}
		}
	}, [theme]);

	return null;
};
