import { Network } from '@drift-labs/icons';

const SettingsNetwork = (props: { active: boolean }) => {
	return (
		<Network
			color={props.active ? 'url(#icon-gradient)' : '#6683A7'}
			size={18}
		/>
	);
};

export default SettingsNetwork;
