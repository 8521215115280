import { BigNum } from '@drift-labs/sdk';
import { IconProps } from '@drift-labs/icons';
import { JSX } from 'react';

export type ApyReturnsLookup = Record<
	string,
	{
		apy: number;
		returns: number;
		maxDrawdownPct: number;
	}
>;

export interface Smoothing {
	reset: () => void;
	sample: (newSample: number, currentTimeMs: number) => number;
	setLastSample: (lastSample: number, lastSampleTimeMs: number) => void;
}

export type VaultManagerConfig = {
	name: string;
	imgSrc: string;
};

export const FEES_PLACEHOLDER = 'fees_placeholder';
export const PERIOD_PLACEHOLDER = 'period_placeholder';

export type Placeholders = typeof FEES_PLACEHOLDER | typeof PERIOD_PLACEHOLDER;

export type OverviewSection = {
	title: string;
	paragraphs: {
		link?: string;
		className?: string;
		/**
		 * The reason why this can be a string[], is to allow for placeholders to be
		 * replaced with dynamic values.
		 *
		 * E.g. text: ['Deposited funds are subject to a ', FEES_PLACEHOLDER, ' redemption period.'],
		 */
		text?: string[] | string;
		contacts?: {
			name: string;
			link: string;
			icon: (allProps: IconProps) => JSX.Element;
		}[];
	}[];
};

export type UiVaultConfig = {
	name: string;
	vaultManager: VaultManagerConfig;
	vaultPubkeyString: string;
	vaultManagerPubkeyString: string;
	driftUserPubkeyString: string;
	description: string;
	depositAsset: number;
	assetsOperatedOn: number[];
	feesFraction: number;
	smoothing?: Smoothing;
	featured?: boolean;
	overviewSection?: OverviewSection[];
	externalUiLink?: string;
	temporaryApy?: number;

	/**
	 * Displays a new tag on the UI.
	 */
	new?: boolean;

	/**
	 * Hides the vault from the vaults page.
	 */
	hidden?: boolean;
};

/**
 * Stats that are derived from on-chain data.
 */
export interface OnChainVaultStats {
	capacityPct: number;
	isUncappedCapacity: boolean;
	totalBasePnl: BigNum;
	totalQuotePnl: BigNum;
	tvlBase: BigNum;
	tvlQuote: BigNum;
	volume30Days: BigNum;
	isOnChainStatsLoaded: boolean;
}

/**
 * Stats that are derived from off-chain data.
 */
export interface OffChainVaultStats {
	apyPct: number;
	cumulativeReturnPct: number;
	maxDrawdownPct: number;
}

export type VaultStats = OnChainVaultStats & OffChainVaultStats;
