'use client';

import { Download } from '@drift-labs/icons';
import confetti from 'canvas-confetti';
import Tooltip from 'src/components/Tooltip/Tooltip';
import useAccountData from 'src/hooks/useAccountData';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { useEffect, useRef, useState } from 'react';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import NumLib from 'src/utils/NumLib';
import Modal from './Modal';
import MarketIcon from '../Utils/MarketIcon';
import {
	BigNum,
	BN,
	PERCENTAGE_PRECISION_EXP,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import { COMMON_UI_UTILS, UIMarket } from '@drift/common';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import Text from '../Text/Text';
import DriftTextLogo from '../Icons/DriftTextLogo';
import { twMerge } from 'tailwind-merge';
import useReferrerNameAccounts from 'src/hooks/useReferrerNameAccounts';
import UI_UTILS from 'src/utils/uiUtils';
import Button from '../Button';
import Switch from '../Switch';
import Checkbox from '../Checkbox';
import { QRCodeSVG } from 'qrcode.react';
import useDriftTheme from 'src/hooks/useDriftTheme';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';
import {
	MAX_PREDICTION_PRICE_BIGNUM,
	ZERO_PRICE_BIGNUM,
} from 'src/constants/math';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { PREDICTION_MARKET_CATEGORY } from 'src/@types/predictionMarkets';
import useGetTickSizeForMarket, {
	getTickSizeDecimalPlaces,
} from 'src/hooks/useGetTickSizeForMarket';
import { notify } from 'src/utils/notifications';
import * as htmlToImage from 'html-to-image';

interface PnlModalProps {
	onClose: () => void;
	modalPosition: DriftStore['pnlModalPosition'];
}

const PnlModal = ({ onClose, modalPosition }: PnlModalProps) => {
	// # Constants
	const currentAccountKey = useDriftAccountStore((s) => s.currentUserKey);
	const setAccountStore = useDriftAccountStore((s) => s.set);
	const setDriftStore = useDriftStore((s) => s.set);
	const driftTheme = useDriftTheme();

	const { leverageBeforeClose, collateralBeforeClose } =
		useAccountData().pnlInfo;
	const bannerRef = useRef<HTMLDivElement>(null);
	const userAccountData = useAccountData();
	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const isMobileSize = useIsMobileScreenSize();
	const { referrerNameAccounts } = useReferrerNameAccounts();

	const isDarkMode = driftTheme === DriftTheme.dark;
	const referralLink = UI_UTILS.getReferralLink(
		referrerNameAccounts.accounts[0]?.decodedName
	);

	// dont allow % pnl to show for lp position
	const isLpPosition = modalPosition.isLpPosition;

	// # State
	const [isPercentagePnl, setIsPercentagePnl] = useState(!isLpPosition);

	const position = modalPosition.position;
	const { baseSize, marketSymbol, entryPrice } = position;
	const exitPrice = modalPosition.actualExitPrice;
	const uiMarket = UIMarket.createPerpMarket(position.marketIndex);
	const predictionMarketConfig =
		PredictionMarketConfigs.getConfigFromMarketSymbol(uiMarket.symbol);
	const pnl = modalPosition.pnl;
	const direction = baseSize.isNeg() ? 'short' : 'long';

	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: direction === 'short',
		})
	);
	const getTickSizeForMarket = useGetTickSizeForMarket();
	const tickSize = getTickSizeForMarket(uiMarket.marketId);
	const decimalPlaces = getTickSizeDecimalPlaces(tickSize);

	const marketStateStore = useMarketStateStore();
	const markPrice =
		marketStateStore.getMarkPriceForMarket(uiMarket.marketId) ??
		ZERO_PRICE_BIGNUM;
	const markPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(markPrice)
		: markPrice;

	let leverage: number;
	if (leverageBeforeClose && collateralBeforeClose) {
		leverage = leverageBeforeClose;
	} else {
		leverage = userAccountData.marginInfo.leverage;
	}

	const leverageString = `${leverage.toFixed(2)}x`;

	const netPnl = BigNum.fromPrint(pnl.toString(), QUOTE_PRECISION_EXP).val;
	// const leverageAdjustedEntryAmount = quoteEntryAmount.div(
	// 	new BN(Math.max(1, leverage))
	// );

	const leveragedPnlPercentageNum = COMMON_UI_UTILS.calculatePnlPctFromPosition(
		netPnl,
		position.quoteEntryAmount,
		leverage
	);

	const pnlPercentageNum = uiMarket.isPredictionMarket
		? modalPosition.isClosed
			? BigNum.from(
					new BN(modalPosition.actualExitPrice * PRICE_PRECISION.toNumber())
						.sub(entryPrice)
						.mul(PRICE_PRECISION)
						.div(entryPrice),
					PERCENTAGE_PRECISION_EXP
			  ).toNum() * 100
			: markPriceToUse
					.sub(BigNum.from(entryPrice, PRICE_PRECISION_EXP))
					.mul(PRICE_PRECISION)
					.div(entryPrice)
					.toNum() * 100
		: COMMON_UI_UTILS.calculatePnlPctFromPosition(
				netPnl,
				position.quoteEntryAmount
		  );

	const isPositivePnl = isPercentagePnl ? pnlPercentageNum >= 0 : pnl >= 0;

	const dollarPnl = `${NumLib.formatNum.toNotionalDisplay(pnl)}`;
	const pnlPercentage = (
		savedSettings.includeLeverageInPnl && !uiMarket.isPredictionMarket
			? leveragedPnlPercentageNum
			: pnlPercentageNum
	).toFixed(2);

	const pnlSign = isPositivePnl ? '+' : '';

	const entryExit = [
		{
			label: 'Entry Price',
			value: BigNum.from(entryPrice, PRICE_PRECISION_EXP).toNum(),
		},
		{
			label: modalPosition.isClosed ? 'Exit Price' : 'Mark Price',
			value: modalPosition.isClosed ? exitPrice : markPriceToUse.toNum(),
		},
	];

	// # State-Dependent constants
	const pnlDisplay = isPercentagePnl
		? `${pnlSign}${pnlPercentage}%`
		: dollarPnl;

	// # Methods
	const handleDownload = async () => {
		if (bannerRef.current) {
			htmlToImage
				.toPng(bannerRef.current)
				.then(function (dataUrl) {
					const link = document.createElement('a');
					link.download = `drift-pnl-${marketSymbol}.png`;
					link.href = dataUrl;
					link.click();
				})
				.catch(function (error) {
					console.error('oops, something went wrong!', error);
					notify({
						type: 'error',
						message: 'Error generating image',
					});
				});
		}
	};

	const handleClose = () => {
		onClose();

		setAccountStore((state) => {
			state.accounts[currentAccountKey].pnlInfo.leverageBeforeClose = null;
			state.accounts[currentAccountKey].pnlInfo.collateralBeforeClose = null;
		});

		setDriftStore((state) => {
			state.pnlModalPosition = null;
		});
	};

	// # Effect Hooks
	useEffect(() => {
		if (isPositivePnl) {
			confetti({
				colors: [
					'#e8a2a0',
					'#c187c5',
					'#b47ed2',
					'#aa77db',
					'#a070e5',
					'#9468f1',
					'#8d7cef',
					'#8690ee',
					'#7fa4ec',
					'#78b8eb',
					'#71cce9',
				],
				origin: {
					y: 0.7,
				},
				particleCount: 100,
				spread: 75,
			});
		}
	}, []);

	return (
		<Modal
			className={isMobileSize ? 'overflow-y-scroll' : ''}
			onClose={handleClose}
			sizeConfig={{
				xs: 12,
				sm: 8,
				md: 6,
			}}
			grid={false}
		>
			<Modal.Body className="xs:h-auto xs:w-full max-w-[578px] pb-0 !rounded-2xl bg-container-border">
				<Modal.Content className="p-0 overflow-hidden max-w-[578px]">
					{/** Pnl Card */}
					<div
						ref={bannerRef}
						className="relative z-10 flex flex-col w-full gap-6 p-6 !pb-0 border-b md:p-8 bg-container-bg border-container-border"
					>
						<img
							className={twMerge(
								'absolute left-0 right-0 items-center bottom-0 rounded-t-2xl -z-10 object-contain',
								uiMarket.isPredictionMarket && 'left-auto md:h-full'
							)}
							src={
								uiMarket.isPredictionMarket
									? predictionMarketConfig?.category ===
									  PREDICTION_MARKET_CATEGORY.US_ELECTIONS
										? '/assets/images/prediction-markets/elections-pnl-card-backdrop.webp'
										: '/assets/images/prediction-markets/pnl-card-backdrop.webp'
									: isDarkMode
									? '/assets/images/pnl-car-dark.webp'
									: '/assets/images/pnl-car-light.webp'
							}
						/>

						<div className="flex items-center gap-4 h-[28px]">
							<DriftTextLogo className="w-[84px]" />
							<Text.BODY3 className="text-text-secondary-button">
								app.drift.trade
							</Text.BODY3>
						</div>

						<div className="flex items-center gap-4">
							{uiMarket.isPredictionMarket ? (
								<>
									<Text.H2 className="flex items-center gap-2">
										<MarketIcon
											marketSymbol={marketSymbol}
											sizeClass="w-10 h-10"
										/>
										<span className="mt-0.5">
											{UI_UTILS.getPerpMarketDisplayName(position.marketIndex)}
										</span>
									</Text.H2>
								</>
							) : (
								<Text.H1 className="flex items-center gap-2">
									<MarketIcon marketSymbol={marketSymbol} sizeClass="w-7 h-7" />
									<span className="mt-0.5">
										{UI_UTILS.getPerpMarketDisplayName(position.marketIndex)}
									</span>
								</Text.H1>
							)}
							{uiMarket.isPredictionMarket && (
								<Text.H3
									className={twMerge(
										'mt-0.5 py-1 px-2 text-text-primary-button rounded font-medium capitalize',
										direction === 'long'
											? 'bg-positive-green'
											: 'bg-negative-red'
									)}
								>
									{direction === 'long' ? 'Yes' : 'No'}
								</Text.H3>
							)}
							{!uiMarket.isPredictionMarket && (
								<Text.H2
									className={twMerge(
										'mt-0.5 capitalize',
										direction === 'long'
											? 'text-positive-green'
											: 'text-negative-red'
									)}
								>
									{direction} {leverageString}
								</Text.H2>
							)}
						</div>

						<div className="flex flex-col">
							<Text.BODY2 className="text-text-secondary">P&amp;L</Text.BODY2>
							<span
								className={twMerge(
									'font-bold text-[60px] leading-tight',
									isPositivePnl ? 'text-positive-green' : 'text-negative-red'
								)}
							>
								{pnlDisplay}
							</span>
						</div>

						<div className="flex flex-col gap-2 mb-8">
							{entryExit.map(({ label, value }) => (
								<Text.BODY2 key={label} className="flex items-center gap-2">
									<span className="text-text-secondary">{label}</span>
									<span className="font-medium">
										${NumLib.formatNum.toDecimalPlaces(value, decimalPlaces)}
									</span>
								</Text.BODY2>
							))}
						</div>

						<div className="px-8 -mx-8 -mt-3 pb-[30px] text-text-secondary-button flex flex-col">
							<div className="z-10 flex items-center w-full gap-2 ">
								{referralLink && (
									<div className="p-[2px] bg-white shrink-0">
										<QRCodeSVG
											value={`https://${referralLink}`}
											className="max-h-[62px] max-w-[62px]"
										/>
									</div>
								)}
								<div className="flex flex-col w-full gap-2">
									{referralLink && (
										<Text.BODY2 className="text-text-secondary">
											Referral Link
										</Text.BODY2>
									)}
									<div className="flex items-center justify-between w-full gap-2">
										{referralLink && (
											<Text.BODY1 className="font-medium">
												{referralLink}
											</Text.BODY1>
										)}
										{!isMobileSize && (
											<Text.BODY2 className="text-right text-text-secondary">
												Timestamp: {new Date().toLocaleString()}
											</Text.BODY2>
										)}
									</div>
								</div>
							</div>

							{isMobileSize && (
								<div className="relative z-10 flex w-full mt-4">
									<Text.BODY2 className="text-text-secondary">
										Timestamp: {new Date().toLocaleString()}
									</Text.BODY2>
								</div>
							)}
						</div>

						{/** Bottom Gradient */}
						<div className="absolute bottom-0 left-0 right-0 h-[118px] dark:bg-[linear-gradient(0deg,rgba(16,26,39,1)_0%,rgba(16,26,39,0.00)_100%)] bg-[linear-gradient(0deg,rgba(255,255,255,1)_0%,rgba(233,241,249,0.00)_100%)]"></div>
					</div>

					{/** Actions Bottom Bar */}
					<div className="flex flex-col w-full gap-6 px-6 pt-6 pb-4 sm:px-8">
						<div className="flex flex-wrap items-center justify-between w-full text-text-secondary-button md:flex-no-wrap gap-y-4">
							<div className="flex items-center gap-6">
								{!uiMarket.isPredictionMarket && (
									<div className="flex items-center gap-2">
										<Text.BODY2>Show Leverage</Text.BODY2>
										<Switch
											checked={savedSettings.includeLeverageInPnl}
											onChange={() => {
												setSavedSettings({
													includeLeverageInPnl:
														!savedSettings.includeLeverageInPnl,
												});
											}}
										/>
									</div>
								)}
								<div className="flex items-center gap-2">
									<Text.BODY2>Switch P&amp;L Type</Text.BODY2>
									<Switch
										checked={!isPercentagePnl}
										onChange={() => {
											setIsPercentagePnl(!isPercentagePnl);
										}}
									/>
								</div>
							</div>
							<Tooltip content="Show/hide automatically when closing positions">
								<div
									className="flex items-center gap-2 cursor-pointer"
									onClick={() =>
										setSavedSettings({
											...savedSettings,
											showPnlOnClose: !savedSettings.showPnlOnClose,
										})
									}
								>
									<Checkbox checked={!savedSettings.showPnlOnClose} />
									<Text.BODY2>Don&apos;t show again</Text.BODY2>
								</div>
							</Tooltip>
						</div>

						<div className="flex items-center gap-2">
							<Button.Secondary
								size={'MEDIUM'}
								onClick={handleClose}
								className="text-[16px] w-full py-3 h-auto leading-[20px]"
							>
								Close
							</Button.Secondary>
							<Button.Secondary
								size={'MEDIUM'}
								onClick={handleDownload}
								className="text-[16px] w-full py-3 h-auto leading-[20px] sm:flex hidden"
							>
								<span>Download</span>
								<Download size={20} />
							</Button.Secondary>
						</div>
					</div>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default PnlModal;
