import Env from '../../environmentVariables/EnvironmentVariables';

export const LOGGING_ENABLED_OVERRIDE =
	process.env.NEXT_PUBLIC_METRICS_ENABLED_OVERRIDE === 'true';

export const METRICS_SAMPLING_ENABLED_RANDOMNESS =
	Math.random() <= Env.metricsLoggingSampleRatio;

export const METRICS_SAMPLING_LOGGING_ENABLED =
	(Env.metricsLoggingEnabled && METRICS_SAMPLING_ENABLED_RANDOMNESS) ||
	LOGGING_ENABLED_OVERRIDE;
