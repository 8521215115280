'use client';

import {
	BigNum,
	SpotMarketConfig,
	SPOT_MARKET_BALANCE_PRECISION_EXP,
} from '@drift-labs/sdk';
import useAccountTargetSpotBalance from './useAccountTargetSpotBalance';

const useSubaccountCollateralBalanceForBank = (props: {
	accountKey: string;
	market: SpotMarketConfig;
	useTokenBalance?: boolean;
}) => {
	const bankBalance = useAccountTargetSpotBalance(
		props.market,
		props.accountKey
	);

	if (!bankBalance)
		return BigNum.zero(
			props.useTokenBalance
				? bankBalance.asset.precisionExp
				: SPOT_MARKET_BALANCE_PRECISION_EXP
		);

	return bankBalance.assetBaseBalance;
};

export default useSubaccountCollateralBalanceForBank;
