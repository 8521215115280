'use client';

import { MarketType, PhoenixSubscriber, PublicKey } from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId } from '@drift/common';
import { useEffect, useRef } from 'react';
import Env, {
	CurrentSpotMarkets,
	syncGetCurrentSettings,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useMarketStateStore from '../../stores/useMarketStateStore';
import useDriftClientIsReady from '../useDriftClientIsReady';
import {
	MarketDlobLiquidityCategorisation,
	MarketStatePriority,
} from '../useMarketsForDlobToTrack';

const DLOB_LEVELS_TO_TRACK: (keyof MarketDlobLiquidityCategorisation)[] = [
	MarketStatePriority.BackgroundDeep,
	MarketStatePriority.SelectedMarket,
	MarketStatePriority.BackgroundShallow,
];

/**
 * Given a list of markets to track (categorised into DLOB levels), this hook will make sure that the appropriate phoenix subscribers are in the store and subscribed or unsubscribed.
 * @param marketsToTrack
 */
const useSyncPhoenixSubscriberList = (
	marketsToTrack: MarketDlobLiquidityCategorisation
) => {
	const connection = useDriftStore((s) => s.connection);
	const set = useMarketStateStore((s) => s.set);
	const driftClientIsReady = useDriftClientIsReady();
	const previouslySubscribedMarkets = useRef<MarketId[]>([]);
	const getPhoenixSubscriberFromStore = useMarketStateStore(
		(s) => s.getPhoenixSubscriberForMarket
	);
	const phoenixEnabled = useMarketStateStore((s) => s.phoenixEnabled);

	const generatePhoenixSubscriber = async (marketIndex: number) => {
		const market = CurrentSpotMarkets.find(
			(spotMkt) => spotMkt.marketIndex === marketIndex
		);

		if (!market.phoenixMarket) return undefined;

		const settings = syncGetCurrentSettings();

		try {
			const phoenixSubscriber = new PhoenixSubscriber({
				connection: connection.current,
				programId: new PublicKey(Env.phoenixAddress),
				marketAddress: market.phoenixMarket,
				accountSubscription: {
					type: settings.accountSubscriberType,
					accountLoader: connection.accountLoader,
				},
			});
			return phoenixSubscriber;
		} catch (e) {
			console.log('Error setting phoenixSubscriber ', e);
			return undefined;
		}
	};

	const handleSubscribingNewMarket = async (marketId: MarketId) => {
		// Create the phoenix subscriber
		const subscriber = await generatePhoenixSubscriber(marketId.marketIndex);

		if (!subscriber) return;

		// Do the subscription
		await subscriber.subscribe();

		// Add it to the subscriber store
		set((s) => {
			s.phoenixSubscribers[marketId.key] = subscriber;
		});

		previouslySubscribedMarkets.current.push(marketId);
	};

	const handleUnsubscribingNewMarket = (marketId: MarketId) => {
		const previousSubscriber = getPhoenixSubscriberFromStore(marketId);
		if (!previousSubscriber) return;

		// Unsubscribe the previous subscriber
		previousSubscriber.unsubscribe();

		// Remove it from the store
		set((s) => {
			delete s.phoenixSubscribers[marketId.key];
		});
	};

	const isPreviouslySubscribed = (marketId: MarketId) => {
		return (
			previouslySubscribedMarkets.current.find((mktId) =>
				mktId.equals(marketId)
			) ?? false
		);
	};

	useEffect(() => {
		if (!driftClientIsReady) return;

		(async () => {
			const marketsWhichShouldBeSubscribed = phoenixEnabled
				? Object.entries(marketsToTrack)
						.filter(([key]) => {
							//@ts-ignore
							return DLOB_LEVELS_TO_TRACK.includes(key);
						})
						.map(([_key, val]) => val)
						.flat()
						.filter((mkt) => ENUM_UTILS.match(mkt.marketType, MarketType.SPOT))
				: [];

			// Want to find markets to unsubscrbe from .. these are the markets which ARE previously subscribed but not in the list of expected subscribed markets
			const marketsToUnsubscribe = previouslySubscribedMarkets.current.filter(
				(mkt) =>
					!marketsWhichShouldBeSubscribed.find((mktToBeSubscribed) =>
						mktToBeSubscribed.equals(mkt)
					)
			);

			// Want to find markets to newly subscribe to
			const marketsToNewlySubscribe = marketsWhichShouldBeSubscribed.filter(
				(mkt) => !isPreviouslySubscribed(mkt)
			);

			marketsToUnsubscribe.forEach((marketId) =>
				handleUnsubscribingNewMarket(marketId)
			);
			marketsToNewlySubscribe.forEach((marketId) =>
				handleSubscribingNewMarket(marketId)
			);
		})();
	}, [driftClientIsReady, marketsToTrack]);
};

export default useSyncPhoenixSubscriberList;
