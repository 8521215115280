'use client';

import { BigNum, PRICE_PRECISION_EXP, SpotMarketConfig } from '@drift-labs/sdk';
import NumLib from 'src/utils/NumLib';
import useCollateralPrice from './useCollateralPrice';

const useDisplayPrecisionForCollateral = (collateralType: SpotMarketConfig) => {
	const currentCollateralPrice =
		useCollateralPrice(collateralType) ?? BigNum.zero(PRICE_PRECISION_EXP);

	const collateralDisplayPrecision = NumLib.getDisplayPrecision(
		currentCollateralPrice
	);

	return collateralDisplayPrecision;
};

export default useDisplayPrecisionForCollateral;
