'use client';

import React from 'react';
import InfoMessage from './TradeForm/InfoMessage';
import useAccountData from 'src/hooks/useAccountData';
import useDriftActions from 'src/hooks/useDriftActions';

const CustomMaxLeverageCta = () => {
	const currentAccount = useAccountData();
	const actions = useDriftActions();
	const hasCustomMaxMarginRatio =
		!!currentAccount?.client?.getUserAccount()?.maxMarginRatio;

	const goToMarginSettings = () => {
		actions.showModal('showMarginModal');
	};

	if (!hasCustomMaxMarginRatio) return;

	return (
		<div className="py-2">
			<InfoMessage
				type="warn"
				messageTitle="Your custom max leverage settings may affect these amounts"
				message={
					<div
						className="hover:cursor-pointer"
						onClick={goToMarginSettings}
					>{`Click here to update`}</div>
				}
			/>
		</div>
	);
};

export default CustomMaxLeverageCta;
