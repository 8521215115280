'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	ContractType,
	MarketType,
	PerpMarketAccount,
	PerpMarketConfig,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotMarketAccount,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { useCallback } from 'react';
import Env, {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
} from '../environmentVariables/EnvironmentVariables';
import { GenericMarketInfo } from '../stores/types';
import useMarketsInfoStore, {
	MarketsInfoStore,
} from '../stores/useMarketsInfoStore';
import UI_UTILS from '../utils/uiUtils';
import useDriftClient from './useDriftClient';
import { ENUM_UTILS, MarketId } from '@drift/common';
import useMarketStateStore from '../stores/useMarketStateStore';
import useUiUpdateInterval from './useUiUpdateInterval';
import useDriftClientIsReady from './useDriftClientIsReady';

const getDecimalsFromSize = (size: BN, precisionExp: BN) => {
	const formattedSize = BigNum.from(size, precisionExp).prettyPrint();
	if (formattedSize.includes('.')) {
		return formattedSize.split('.')[1].length;
	}
	return 0;
};

const generateEmptySpotLookupList = () => {
	const allSpotMarkets = Env.sdkConfig.SPOT_MARKETS;
	const spotMarketLookupList = new Array(allSpotMarkets.length);

	return spotMarketLookupList;
};

const generateEmptyPerpLookupList = () => {
	const allPerpMarkets = Env.sdkConfig.PERP_MARKETS;
	const perpMarketLookupList = new Array(allPerpMarkets.length);

	return perpMarketLookupList;
};

const getGenericInfoFromPerpMarketInfo = (
	config: PerpMarketConfig,
	account: PerpMarketAccount,
	markPrice?: BigNum
): GenericMarketInfo => {
	const stepSize = account.amm.orderStepSize;
	const tickSize = account.amm.orderTickSize;

	const baseDecimals = getDecimalsFromSize(stepSize, BASE_PRECISION_EXP);
	const priceDecimals = getDecimalsFromSize(tickSize, QUOTE_PRECISION_EXP);

	return {
		markPrice: markPrice ? markPrice.toNum() : 0,
		markPriceBigNum: markPrice ? markPrice : BigNum.zero(PRICE_PRECISION_EXP),
		marketIndex: config.marketIndex,
		marketType: MarketType.PERP,
		baseAssetSymbol: config.baseAssetSymbol,
		baseDisplayDecimals: baseDecimals,
		priceDisplayDecimals: priceDecimals,
		symbol: config.symbol,
		marketSymbol: config.symbol,
		tickSizeExponent: priceDecimals,
		tickSizeMultiplier: 1,
	};
};

const getGenericInfoFromSpotMarketInfo = (
	config: SpotMarketConfig,
	account: SpotMarketAccount,
	markPrice?: BigNum
): GenericMarketInfo => {
	const stepSize = account.orderStepSize;
	const tickSize = account.orderTickSize;

	const baseDecimals = getDecimalsFromSize(stepSize, config.precisionExp);
	const priceDecimals = getDecimalsFromSize(tickSize, QUOTE_PRECISION_EXP);

	return {
		markPrice: markPrice ? markPrice.toNum() : 0,
		markPriceBigNum: markPrice ? markPrice : BigNum.zero(),
		marketIndex: config.marketIndex,
		marketType: MarketType.PERP,
		baseDisplayDecimals: baseDecimals,
		priceDisplayDecimals: priceDecimals,
		baseAssetSymbol: config.symbol,
		symbol: config.symbol,
		marketSymbol: `${config.symbol}/USDC`,
		tickSizeExponent: priceDecimals,
		tickSizeMultiplier: 1,
	};
};

/**
 * This hook keeps the full info (config, account state) for all of the perp and spot markets up to date in the store
 */
const useSyncWithAllMarketsInfo = () => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const setState = useMarketsInfoStore((s) => s.set);
	const getMarkPriceForMarket = useMarketStateStore(
		(s) => s.getMarkPriceForMarket
	);

	const getAndSetMarketsInfo = useCallback(() => {
		if (!driftClientIsReady || !driftClient) return;

		const newAllMarketsList: MarketsInfoStore['allMarketsInfo']['allMarketsList'] =
			[];

		const allMarketsLookup: MarketsInfoStore['allMarketsInfo']['lookup'] = {};

		const newPerpMarketInfoLookup: MarketsInfoStore['allMarketsInfo']['perpLookup'] =
			generateEmptyPerpLookupList();

		const newPerpMarketInfoList: MarketsInfoStore['allMarketsInfo']['perpList'] =
			[];

		const newSpotMarketInfoLookup: MarketsInfoStore['allMarketsInfo']['spotLookup'] =
			generateEmptySpotLookupList();

		const newSpotMarketInfoList: MarketsInfoStore['allMarketsInfo']['spotList'] =
			[];

		const newPredictionMarketInfoList: MarketsInfoStore['allMarketsInfo']['predictionList'] =
			[];

		for (const marketConfig of CurrentPerpMarkets) {
			if (!marketConfig) continue;

			const marketIndex = marketConfig.marketIndex;
			const marketAccount = driftClient.getPerpMarketAccount(marketIndex);

			if (!marketAccount) continue;

			const markPrice = getMarkPriceForMarket(
				UI_UTILS.getPerpMarketId(marketConfig)
			);

			const perpMarketInfo = {
				config: marketConfig,
				account: marketAccount,
				genericInfo: getGenericInfoFromPerpMarketInfo(
					marketConfig,
					marketAccount,
					markPrice
				),
				extendedInfo: driftClient.getPerpMarketExtendedInfo(marketIndex),
				marketId: new MarketId(marketConfig.marketIndex, MarketType.PERP),
			};

			if (
				ENUM_UTILS.match(marketAccount.contractType, ContractType.PREDICTION)
			) {
				newPredictionMarketInfoList.push(perpMarketInfo);
			}

			newAllMarketsList.push(perpMarketInfo);
			newPerpMarketInfoList.push(perpMarketInfo);
			newPerpMarketInfoLookup[perpMarketInfo.genericInfo.marketIndex] =
				perpMarketInfo;
			allMarketsLookup[perpMarketInfo.genericInfo.symbol] = perpMarketInfo;
		}

		for (const marketConfig of CurrentSpotMarkets.filter(
			(market) => market.symbol !== 'USDC'
		)) {
			if (!marketConfig) continue;

			const marketIndex = marketConfig.marketIndex;
			let marketAccount: SpotMarketAccount | undefined;
			try {
				marketAccount = driftClient.getSpotMarketAccount(marketIndex);
			} catch (e) {
				console.error(e);
			}

			if (!marketAccount) continue;

			const spotMarketInfo = {
				config: marketConfig,
				account: marketAccount,
				genericInfo: getGenericInfoFromSpotMarketInfo(
					marketConfig,
					marketAccount,
					getMarkPriceForMarket(UI_UTILS.getSpotMarketId(marketConfig))
				),
				marketId: new MarketId(marketConfig.marketIndex, MarketType.SPOT),
			};

			newAllMarketsList.push(spotMarketInfo);
			newSpotMarketInfoList.push(spotMarketInfo);
			newSpotMarketInfoLookup[spotMarketInfo.genericInfo.marketIndex] =
				spotMarketInfo;
			allMarketsLookup[spotMarketInfo.genericInfo.symbol] = spotMarketInfo;
		}

		setState((s) => {
			s.allMarketsInfo.allMarketsList = newAllMarketsList;
			s.allMarketsInfo.perpLookup = newPerpMarketInfoLookup;
			s.allMarketsInfo.spotLookup = newSpotMarketInfoLookup;
			s.allMarketsInfo.perpList = newPerpMarketInfoList;
			s.allMarketsInfo.spotList = newSpotMarketInfoList;
			s.allMarketsInfo.lookup = allMarketsLookup;
			s.allMarketsInfo.predictionList = newPredictionMarketInfoList;
			s.initialized = true;
		});
	}, [driftClient, driftClientIsReady, getMarkPriceForMarket]);

	useUiUpdateInterval(getAndSetMarketsInfo, true, true);
};

export default useSyncWithAllMarketsInfo;
