'use client';

import Button from 'src/components/Button';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import useDriftActions from 'src/hooks/useDriftActions';
import { useEffect, useMemo, useState } from 'react';
import Modal from './Modal';
import useAccountData from 'src/hooks/useAccountData';
import Text from 'src/components/Text/Text';
import { Open } from '@drift-labs/icons';
import { DEFAULT_PUBLIC_KEY } from 'src/@types/types';
import { PublicKey } from '@drift-labs/sdk';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { Connection } from '@solana/web3.js';

const DelegateModal = () => {
	const connection = useDriftStore((s) => s.connection?.current) as Connection;
	const actions = useDriftActions();
	const currentAccount = useAccountData();

	const selectedSubAccountId = currentAccount?.userId;
	const selectedSubAccountName = currentAccount?.name;
	const currentDelegate =
		currentAccount?.client?.getUserAccount()?.delegate ?? undefined;
	const currentDelegateStr = useMemo(() => {
		return currentDelegate && !currentDelegate.equals(DEFAULT_PUBLIC_KEY)
			? currentDelegate.toString()
			: '';
	}, [currentDelegate]);

	const showModal = actions.showModal;

	const hideModal = () => showModal('showDelegateAccountModal', false);

	const [delegateToAddress, setDelegateToAddress] =
		useState(currentDelegateStr);
	const [errorMessage, setErrorMessage] = useState('');
	const [warningMessage, setWarningMessage] = useState('');

	const buttonDisabled = errorMessage !== '';

	const checkValidAddress = (inputAddress: string) => {
		if (!inputAddress) return false;
		try {
			return PublicKey.isOnCurve(inputAddress);
		} catch (_e) {
			return false;
		}
	};

	const checkNotSameAuthority = (inputAddress: string) => {
		return inputAddress !== currentAccount?.authority.toString();
	};

	const checkWalletHasSol = async (inputAddress: string) => {
		const accountInfo = await connection.getAccountInfo(
			new PublicKey(inputAddress)
		);

		if (!accountInfo || !accountInfo.lamports || accountInfo.lamports === 0) {
			return false;
		}
		return true;
	};

	const handleDelegateAccount = async () => {
		if (!checkValidAddress(delegateToAddress)) {
			setErrorMessage('Invalid address');
			return;
		}

		if (!checkNotSameAuthority(delegateToAddress)) {
			setErrorMessage('Cannot delegate to same authority');
			return;
		}

		// stop from continuing the first time, if they make no changes and click it again, let it happen
		if (!warningMessage) {
			if (!(await checkWalletHasSol(delegateToAddress))) {
				setWarningMessage(
					'Address has no SOL. Please verify before continuing.'
				);
				return;
			}
		}

		const result = await actions.updateSubaccountDelegate(
			selectedSubAccountId,
			delegateToAddress
		);

		if (result === true) {
			hideModal();
		}
	};

	const removeDelegateAccount = async () => {
		const result = currentDelegateStr
			? await actions.updateSubaccountDelegate(
					selectedSubAccountId,
					DEFAULT_PUBLIC_KEY.toString()
			  )
			: true;

		if (result === true) {
			hideModal();
		}
	};

	useEffect(() => {
		// remove error message if they edit the box after an invalid entry
		if (errorMessage !== '') {
			setErrorMessage('');
		}
		if (warningMessage !== '') {
			setWarningMessage('');
		}
	}, [delegateToAddress]);

	return (
		currentAccount && (
			<Modal onClose={hideModal}>
				<Modal.Body>
					<Modal.Header onClose={hideModal} showX>
						<Modal.Title>Delegate Your Account</Modal.Title>
					</Modal.Header>
					<Modal.Content>
						<div className="items-center block mb-4">
							<Text.P1 className="mr-1 text-text-emphasis">
								{`Delegate your subaccount (${selectedSubAccountName}) to another wallet address. Delegates can `}
								<p className="inline-flex underline">only</p>
								{` deposit, swap, place orders, and cancel orders. They cannot withdraw funds from your account.`}
							</Text.P1>
							<Text.P1 className="inline-flex items-center my-1 text-text-emphasis hover:cursor-pointer">
								<a
									href="https://docs.drift.trade/getting-started/delegated-accounts"
									target="_blank"
									rel="noreferrer"
									className="inline-flex items-center gap-1 text-purple-40"
								>
									<span> Learn more about Delegate Permissions</span>
									<Open />
								</a>
							</Text.P1>
						</div>
						<div className="flex flex-col items-end">
							<LabelledInput label="Wallet Address">
								<TextField.Default
									type="text"
									value={delegateToAddress}
									onChange={setDelegateToAddress}
								/>
							</LabelledInput>
							<Utility.VERTSPACERXS />
						</div>
						{errorMessage !== '' && (
							<InfoMessage
								className="mt-1"
								type="error"
								messageTitle="Error"
								message={<>{errorMessage}</>}
							/>
						)}
						{warningMessage !== '' && (
							<InfoMessage
								className="mt-1"
								type="warn"
								messageTitle="Warning"
								message={<>{warningMessage}</>}
							/>
						)}
						<Modal.ButtonBar>
							<Button.Secondary
								textClass="text-negative-red bg-negative-red-secondary-bg hover:bg-negative-red-secondary-bg"
								className="w-[50%] flex-grow sm:grow-0"
								onClick={removeDelegateAccount}
								size="MEDIUM"
							>
								{currentDelegateStr ? `Remove` : `Cancel`}
							</Button.Secondary>
							<Button.Secondary
								// textClass="bg-positive-green hover:bg-positive-green-secondary-bg"
								className="w-[50%] flex-grow sm:grow-0"
								disabled={buttonDisabled}
								onClick={handleDelegateAccount}
								size="MEDIUM"
								positiveGreen
							>
								{currentDelegateStr ? `Update` : `Confirm`}
							</Button.Secondary>
						</Modal.ButtonBar>
					</Modal.Content>
				</Modal.Body>
			</Modal>
		)
	);
};

export default DelegateModal;
