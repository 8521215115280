'use client';

import Text from 'src/components/Text/Text';
import React, { useCallback, useState } from 'react';
import Modal from './Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import AddLiquidityModalContent from '../LP/AddLiquidityModalContent';
import RemoveLiquidityModalContent from '../LP/RemoveLiquidityModalContent';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useAccountData from 'src/hooks/useAccountData';
import Button from '../Button';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import UI_UTILS from 'src/utils/uiUtils';

type ProvideSide = 'add' | 'remove';

const LPModal = ({
	id,
	initialSide = 'add',
}: {
	id?: string;
	initialSide?: ProvideSide;
}) => {
	const setState = useDriftStore((s) => s.set);
	const setAccountState = useDriftAccountStore((s) => s.set);
	const availableSubaccounts = useLazySubAccounts();
	const { name: currentAccountName, openLpOrders } = useAccountData();
	const driftClient = useDriftClient();

	const [currentSelection, setCurrentSelection] = useState(initialSide);

	const userLpSubaccount = availableSubaccounts.find((acct) =>
		UI_UTILS.accountNameIsLp(acct.name)
	);

	const accountHasLp = openLpOrders?.length > 0;

	const [promptToSwitchAccounts, setPromptToSwitchAccounts] = useState(
		!accountHasLp &&
			userLpSubaccount &&
			!UI_UTILS.accountNameIsLp(currentAccountName)
	);

	const onClose = useCallback(() => {
		setState((s) => {
			s.modals.showAddLiquidityModal = false;
			s.modals.showRemoveLiquidityModal = false;
		});
	}, []);

	const switchToDlpSubaccount = useCallback(() => {
		UI_UTILS.updateCurrentSubAccount(
			userLpSubaccount.userId,
			userLpSubaccount.authority,
			driftClient,
			setAccountState
		);
		setPromptToSwitchAccounts(false);
	}, [userLpSubaccount?.userKey]);

	const continueWithoutSwitch = useCallback(() => {
		setPromptToSwitchAccounts(false);
	}, []);

	if (currentSelection === 'add' && promptToSwitchAccounts) {
		return (
			<Modal onClose={onClose} id={id}>
				<Modal.Body>
					<Modal.Header
						showX
						onClose={onClose}
						showBack={false}
						borderBottom={true}
					>
						<Text.H2>Switch to BAL Subaccount</Text.H2>
					</Modal.Header>

					<Modal.Content>
						<div className="space-y-4">
							<Text.BODY2 className="text-text-default">
								We recommend keep trading activity and BAL in separate
								subaccounts.
							</Text.BODY2>
							<Text.BODY2 className="block text-text-default">
								Switch to your BAL subaccount?
							</Text.BODY2>
							<div className="w-full pt-4 space-y-2">
								<Button.Primary
									className="w-full"
									size="MEDIUM"
									onClick={switchToDlpSubaccount}
								>
									Switch
								</Button.Primary>
								<Button.Secondary
									size="MEDIUM"
									className="w-full"
									textClass="text-text-default text-xs"
									onClick={continueWithoutSwitch}
								>
									{`No thanks, continue with ${currentAccountName}`}
								</Button.Secondary>
							</div>
						</div>
					</Modal.Content>
				</Modal.Body>
			</Modal>
		);
	}

	return (
		<Modal onClose={onClose} id={id}>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>Provide Liquidity</Text.H2>
				</Modal.Header>

				<Modal.Navigation
					className="justify-start"
					currentSelection={currentSelection}
					onChange={(selection) =>
						setCurrentSelection(selection as ProvideSide)
					}
					options={[
						{
							value: 'add',
							label: 'Add Liquidity',
						},
						{
							value: 'remove',
							label: 'Remove Liquidity',
						},
					]}
				/>

				{currentSelection === 'add' ? (
					<AddLiquidityModalContent onClose={onClose} />
				) : (
					<RemoveLiquidityModalContent onClose={onClose} />
				)}
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(LPModal);
