import { Subject, interval } from 'rxjs';
import { MetricsEventSink } from './MetricsEventSink';
import { NetworkMetricsEvent } from './metricsTypes';

const getAvgPerMinute = (value: number, startTimeMs: number) =>
	value / ((Date.now() - startTimeMs) / (1000 * 60));

/**
 * A class which subscribes to a metrics event sink, tracks network metrics, and triggers alarms if conditions are met.
 */
export class NetworkMetricsTracker {
	private networkEventCountMetrics = {
		startTimeMs: Date.now(),
		count: 0,
	};

	constructor(private sink: MetricsEventSink) {}

	public subscribe() {
		const metricsSinkObservable = new Subject<NetworkMetricsEvent>();

		// # Subscribe to the metrics sink
		this.resetMetrics();
		this.sink.subscribe((event) => {
			if (event.type === 'network_request') {
				metricsSinkObservable.next(event);
			}
		});
		metricsSinkObservable.subscribe((event) => {
			this.updateLocalMetrics(event);
		});

		// Create handling for per-minute metrics
		interval(1000 * 60).subscribe(() => {
			this.updateRemoteMetrics();
			this.resetMetrics();
		});
	}

	private resetNetworkEventCountMetrics() {
		this.networkEventCountMetrics.startTimeMs = Date.now();
		this.networkEventCountMetrics.count = 0;
	}

	private resetMetrics() {
		this.resetNetworkEventCountMetrics();
	}

	private updateLocalMetrics(_event: NetworkMetricsEvent) {
		this.networkEventCountMetrics.count++;
	}

	private updateRemoteMetrics() {
		const networkEventsAvg = +getAvgPerMinute(
			this.networkEventCountMetrics.count,
			this.networkEventCountMetrics.startTimeMs
		).toFixed(0);

		window.UNCONSUMED_GRAFANA_METRICS.AVG_NETWORK_REQUESTS_PER_MINUTE =
			networkEventsAvg;
	}
}
