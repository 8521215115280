'use client';

import { singletonHook } from 'react-singleton-hook';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { LoggingServerPacket } from '@drift-labs/logging-server';
import { useContext, useEffect } from 'react';
import { MetricsContext } from '../providers/metrics/MetricsProvider';
import {
	LOGGING_ENABLED_OVERRIDE,
	METRICS_SAMPLING_LOGGING_ENABLED,
} from '../providers/metrics/metrics';
import { dlog } from '../dev';
import { usePageVisibility } from './utils/usePageVisibility';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useMarketStateStore from '../stores/useMarketStateStore';
import { MetricsServerClient } from '../utils/metricsServerClient';
import useFeatureFlagStore from 'src/stores/useFeatureFlagStore';

const LOGGING_SERVER_URL = Env.metricsLoggingServerUrl;
const METRICS_LOGGING_FREQUENCY = 60_000;

const hasBeenInvisibleRef = { current: false };
const isVisibleRef = { current: true };

const METRICS_CLIENT_UNIQUENESS_ID_STORAGE_KEY = `metrics_id`;

const metricsServerClient = new MetricsServerClient(LOGGING_SERVER_URL);

type MetricKey = keyof LoggingServerPacket['metrics'];

// These are metrics that shouldn't be affected by the browser going idle - so we allow them to be sent even after the browser has gone idle
const METRICS_TO_ALLOW_AFTER_IDLE: MetricKey[] = [
	'txTimeoutCount',
	'txConfirmationTimes',
	'txErrorClass',
	'txErrorId',
	'cuBudgetExceededData',
];

const sendMetrics = async (metricsBody: LoggingServerPacket) => {
	dlog(`ui_metrics`, `logging_metrics`, metricsBody);

	try {
		let metricsToSend: LoggingServerPacket = metricsBody;

		if (hasBeenInvisibleRef.current) {
			// The browser going idle can screw with metrics, so we want to ignore tracking some metrics once the browser has been idle in the current session. Therefore we filter based on the METRICS_TO_ALLOW_AFTER_IDLE array here
			const filteredMetricsBody = Object.keys(metricsBody.metrics).reduce(
				(acc, key) => {
					if (!METRICS_TO_ALLOW_AFTER_IDLE.includes(key as MetricKey)) {
						return acc;
					}

					acc[key] = metricsBody.metrics[key];
					return acc;
				},
				{} as LoggingServerPacket['metrics']
			);

			metricsToSend = { metrics: filteredMetricsBody };

			if (Object.keys(metricsToSend.metrics).length === 0) {
				dlog(`ui_metrics`, `skipping_because_idle`);
				return;
			}
		}

		await metricsServerClient.sendMetrics(metricsToSend);
	} catch (e) {
		console.log('Failed to report metrics: ', e);
	}
};

/**
 * Message bus events should go through as long as the app is running on the drift domain or the override is enabled
 * @returns
 */
const messageBusMetricsEnabled = () => {
	return (
		LOGGING_ENABLED_OVERRIDE ||
		window?.location?.hostname?.includes('app.drift.trade')
	);
};

const useLogMetricsToGrafana = () => {
	const metricsMessageBus = useContext(MetricsContext).eventBus;

	const isVisible = usePageVisibility();

	const getDriftStore = useDriftStore((s) => s.get);
	const getMarketStateStore = useMarketStateStore((s) => s.get);
	const getFeatureFlagsStore = useFeatureFlagStore((s) => s.get);

	const getPrimarySubscriptionMethod = () => {
		const currentlySelectedMarketId = getDriftStore().selectedMarket.marketId;
		const selectedMarketListeningState =
			getMarketStateStore().getSubscriptionStateForMarket(
				currentlySelectedMarketId
			);
		return selectedMarketListeningState.listeningSelection;
	};

	useEffect(() => {
		isVisibleRef.current = isVisible;

		if (!isVisible && !hasBeenInvisibleRef.current) {
			dlog(
				`ui_metrics`,
				`disabling_metrics_logging_after_page_going_invisible`
			);
		}

		hasBeenInvisibleRef.current = !isVisible || hasBeenInvisibleRef.current;
	}, [isVisible]);

	const getMetricsId = () => {
		try {
			let metricsId = localStorage.getItem(
				METRICS_CLIENT_UNIQUENESS_ID_STORAGE_KEY
			);

			if (!metricsId) {
				metricsId = window.crypto.randomUUID();
				localStorage.setItem(
					METRICS_CLIENT_UNIQUENESS_ID_STORAGE_KEY,
					metricsId
				);
			}

			return metricsId;
		} catch (e) {
			if (process.env.NEXT_PUBLIC_ISLOCAL) {
				return 'localdev';
			}

			// If this errors then we're not in a secure context (https) which Crypto.randomUUID requires .. not a big deal though so we can ignore this -- it actually helps us to better ignore metrics logs that we don't care about.
			return undefined;
		}
	};

	/**
	 * This hook handles messages which should be sent regardless of whether the session is a sample session
	 */
	useEffect(() => {
		if (!metricsMessageBus) return;
		if (!messageBusMetricsEnabled()) return;

		const subscription = metricsMessageBus.subscribe((message) => {
			switch (message.type) {
				case 'transaction_error_metric': {
					switch (message.value.class) {
						case 'transaction_timeout': {
							sendMetrics({
								metrics: {
									txTimeoutCount: {
										count: 1,
										preFlightEnabled:
											!getFeatureFlagsStore()?.flags
												?.SKIP_TRANSACTION_PREFLIGHTS?.enabled,
									},
								},
							});
							break;
						}
						case 'transaction_cu_budget_exceeded':
						case 'transaction_other_error':
							sendMetrics({
								metrics: {
									txErrorClass: message?.value?.class,
									txErrorId: message?.value?.id,
								},
							});
							break;
					}
					break;
				}
				case 'transaction_confirmation_time': {
					sendMetrics({
						metrics: {
							txConfirmationTimes: [
								{
									confirmationTimeMs: message.value,
									preFlightEnabled:
										!getFeatureFlagsStore()?.flags?.SKIP_TRANSACTION_PREFLIGHTS
											?.enabled,
								},
							],
						},
					});
					break;
				}
				case 'transaction_cu_budget_exceeded_metadata':
					sendMetrics({
						metrics: {
							cuBudgetExceededData: message.value,
						},
					});
					break;
				case 'pre_prepped_transaction':
					sendMetrics({
						metrics: {
							prePreppedTransaction: message.value,
						},
					});
					break;
				case 'passthrough_metric':
					if (METRICS_SAMPLING_LOGGING_ENABLED) {
						sendMetrics({
							metrics: message.value,
						});
					}
					break;
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [metricsMessageBus]);

	/**
	 * This hook handles sending the background metrics, only for sample sessions
	 */
	useEffect(() => {
		if (!METRICS_SAMPLING_LOGGING_ENABLED) return;

		const interval = setInterval(() => {
			// Pull out unconsumed data
			const metricsBody: LoggingServerPacket = {
				metrics: {
					requestsPerMinute:
						window.UNCONSUMED_GRAFANA_METRICS.AVG_NETWORK_REQUESTS_PER_MINUTE,
					maxSlotGapMs: window.UNCONSUMED_GRAFANA_METRICS.MAX_SLOT_GAP_MS,
					maxSlotGapCount: window.UNCONSUMED_GRAFANA_METRICS.MAX_SLOT_GAP_COUNT,
					// @ts-ignore
					totalJSHeapSize: window.performance?.memory?.totalJSHeapSize,
					// @ts-ignore
					usedJSHeapSize: window.performance?.memory?.usedJSHeapSize,
					eventLoopLag: window.UNCONSUMED_GRAFANA_METRICS.EVENT_LOOP_LAG,
					dlobSource:
						window.UNCONSUMED_GRAFANA_METRICS?.SELECTED_MARKET_DLOB_SOURCE &&
						window.UNCONSUMED_GRAFANA_METRICS?.SELECTED_MARKET_ID
							? {
									source:
										window.UNCONSUMED_GRAFANA_METRICS
											.SELECTED_MARKET_DLOB_SOURCE,
									marketId:
										window.UNCONSUMED_GRAFANA_METRICS.SELECTED_MARKET_ID,
							  }
							: undefined,
					primarySubscriptionMethod: getPrimarySubscriptionMethod(),
					metricsId: getMetricsId(),
					historyServerRequestsTarget:
						window.UNCONSUMED_GRAFANA_METRICS.HISTORY_SERVER_REQUESTS_TARGET,
					historyServerRequestsSource:
						window.UNCONSUMED_GRAFANA_METRICS.HISTORY_SERVER_REQUESTS_SOURCE,
				},
				miscData: {},
			};

			// Reset unconsumed data in window object
			window.UNCONSUMED_GRAFANA_METRICS = {};

			sendMetrics(metricsBody);

			// Reset the has been invisble flag for the next interval
			if (isVisibleRef.current) {
				hasBeenInvisibleRef.current = false;
			}
		}, METRICS_LOGGING_FREQUENCY);

		return () => {
			clearInterval(interval);
		};
	}, []);
};

export default singletonHook(undefined, useLogMetricsToGrafana);
