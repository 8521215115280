'use client';

import { useEffect, useRef } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { notify } from '../utils/notifications';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import useWalletIsConnected from './useWalletIsConnected';
import { useSettingsContext } from '../providers/settingsProvider';
import { DRIFT_CUSTOM_WALLET_OPTIONS } from 'src/constants/wallets';

/* TODO: Remove this once the brave browser wallet fix makes it in : https://github.com/brave/wallet-standard-brave/issues/36
 * Update 27th Aug 2024 : the issue above is "gone" but tested and confirmed that it still doesn't support versioned transactions
 */
// This is a list of wallets that we're aware currently don't support versioned transactions. We override the transaction version back to legacy (instead of default versioned transactions) when we detect a user connecting with one of thse.
const WALLETS_TO_OVERRIDE_TO_LEGACY_TX_VERSION = [
	'brave',
	DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME,
	// 'walletconnect',
];

const WALLETS_TO_GIVE_VERSIONED_TX_WARNINGS_FOR = [];

const walletIsInList = (walletToMatch: string, walletList: string[]) => {
	for (const walletInList of walletList) {
		if (walletToMatch.toLowerCase().includes(walletInList.toLowerCase())) {
			return true;
		}
	}
	return false;
};

const useWalletHooks = () => {
	const currentWallet = useCurrentWalletAdapter();
	const walletIsConnected = useWalletIsConnected();
	const setState = useDriftStore((s) => s.set);
	const settingsClient = useSettingsContext();
	const enableVersionedTransactions =
		settingsClient.currentSettings.enableVersionedTx;

	// Handler for tx version overriding
	useEffect(() => {
		if (!currentWallet?.name || !walletIsConnected) {
			setState((s) => {
				s.wallet.overrideTxVersion = undefined;
			});
			return;
		}

		const walletName = currentWallet.name;

		if (walletIsInList(walletName, WALLETS_TO_OVERRIDE_TO_LEGACY_TX_VERSION)) {
			console.log(`OVERRIDING TX VERSION FOR ${walletName} wallet`);
			setState((s) => {
				s.wallet.overrideTxVersion = 'legacy';
			});

			notify({
				type: 'info',
				message: 'Overriding Transaction Version',
				description: `Your wallet does not support Solana Versioned Transactions, affecting your ability to use certain features. You can clink the link below to learn more.`,
				action: {
					type: 'link',
					href: 'https://docs.drift.trade/trading/versioned-transactions',
				},
				offerIgnoreToast: 'walletVersionedTxCompatibility',
				showUntilCancelled: true,
			});
		}
	}, [currentWallet?.name, walletIsConnected]);

	// Handler for tx version warnings
	const haveShownVersionedTxWarningForWallet = useRef('');
	useEffect(() => {
		if (!currentWallet?.name || !walletIsConnected) {
			haveShownVersionedTxWarningForWallet.current = '';
			return;
		}

		const walletName = currentWallet.name;

		if (haveShownVersionedTxWarningForWallet.current === walletName) {
			return;
		}

		if (
			walletIsInList(walletName, WALLETS_TO_GIVE_VERSIONED_TX_WARNINGS_FOR) &&
			enableVersionedTransactions === true
		) {
			notify({
				type: 'info',
				message: 'Wallet Compatibility Warning',
				description: `You may encounter some compatibility problems with this wallet and Solana Versioned Transactions. You can clink the link below to learn more.`,
				action: {
					type: 'link',
					href: 'https://docs.drift.trade/trading/versioned-transactions',
				},
				offerIgnoreToast: 'walletVersionedTxCompatibility',
				showUntilCancelled: true,
			});
			haveShownVersionedTxWarningForWallet.current = walletName;
		}
	}, [currentWallet?.name, walletIsConnected, enableVersionedTransactions]);
};

export default useWalletHooks;
