import { MarketId } from '@drift/common';
import {
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from '../../environmentVariables/EnvironmentVariables';

type DlobServerChannel = 'trades' | 'orderbook';

type BaseSubscriptionOutputProps<T extends DlobServerChannel> = {
	type: 'subscribe';
	channel: T;
};

type OrderbookSubscriptionOutputProps =
	BaseSubscriptionOutputProps<'orderbook'> & {
		marketType: 'perp' | 'spot';
		market: string;
	};

type TradeSubscriptionOutputProps = BaseSubscriptionOutputProps<'trades'> & {
	marketType: 'perp' | 'spot';
	market: string;
};

type WebsocketSubscriptionOutputProps =
	| TradeSubscriptionOutputProps
	| OrderbookSubscriptionOutputProps;

type BaseUnsubscriptionOutputProps<T extends DlobServerChannel> = {
	type: 'unsubscribe';
	channel: T;
};

type OrderbookUnsubscriptionOutputProps =
	BaseUnsubscriptionOutputProps<'orderbook'> & {
		marketType: 'perp' | 'spot';
		market: string;
	};

type TradeUnsubscriptionOutputProps =
	BaseUnsubscriptionOutputProps<'trades'> & {
		marketType: 'perp' | 'spot';
		market: string;
	};

type WebsocketUnsubscriptionOutputProps =
	| OrderbookUnsubscriptionOutputProps
	| TradeUnsubscriptionOutputProps;

type BaseSubscriptionProps<T extends DlobServerChannel> = {
	type: T;
};

type TargetMarketProps = { market: MarketId };

type OrderbookSubscriptionProps = BaseSubscriptionProps<'orderbook'> &
	TargetMarketProps;

type TradesSubscriptionProps = BaseSubscriptionProps<'trades'> &
	TargetMarketProps;

type WebsocketSubscriptionProps =
	| TradesSubscriptionProps
	| OrderbookSubscriptionProps;

export type WebsocketServerResponse = {
	channel: string;
	data: string;
	error?: any;
};

const getMarketSymbolFromId = (marketId: MarketId) => {
	const isPerp = marketId.isPerp;

	if (isPerp) {
		return PERP_MARKETS_LOOKUP[marketId.marketIndex].symbol;
	} else {
		return SPOT_MARKETS_LOOKUP[marketId.marketIndex].symbol;
	}
};

const getSubscriptionProps = (
	props: WebsocketSubscriptionProps
): WebsocketSubscriptionOutputProps => {
	const type = props.type;

	switch (type) {
		case 'orderbook': {
			return {
				type: 'subscribe',
				channel: 'orderbook',
				market: getMarketSymbolFromId(props.market),
				marketType: props.market.isPerp ? 'perp' : 'spot',
			};
		}
		case 'trades': {
			return {
				type: 'subscribe',
				channel: 'trades',
				market: getMarketSymbolFromId(props.market),
				marketType: props.market.isPerp ? 'perp' : 'spot',
			};
		}
		default: {
			const exhaustiveCheck: never = type;
			throw new Error(`Unhandled case: ${exhaustiveCheck}`);
		}
	}
};

const getUnsubscriptionProps = (
	props: WebsocketSubscriptionProps
): WebsocketUnsubscriptionOutputProps => {
	const type = props.type;

	switch (type) {
		case 'orderbook': {
			return {
				type: 'unsubscribe',
				channel: 'orderbook',
				market: getMarketSymbolFromId(props.market),
				marketType: props.market.isPerp ? 'perp' : 'spot',
			};
		}
		case 'trades': {
			return {
				type: 'unsubscribe',
				channel: 'trades',
				market: getMarketSymbolFromId(props.market),
				marketType: props.market.isPerp ? 'perp' : 'spot',
			};
		}
		default: {
			const exhaustiveCheck: never = type;
			throw new Error(`Unhandled case: ${exhaustiveCheck}`);
		}
	}
};

const getChannelKey = (props: WebsocketSubscriptionProps) => {
	const type = props.type;

	switch (type) {
		case 'orderbook': {
			return `orderbook_${props.market.isPerp ? 'perp' : 'spot'}_${
				props.market.marketIndex
			}`;
		}
		case 'trades': {
			return `trades_${props.market.isPerp ? 'perp' : 'spot'}_${
				props.market.marketIndex
			}`;
		}
		default: {
			const exhaustiveCheck: never = type;
			throw new Error(`Unhandled case: ${exhaustiveCheck}`);
		}
	}
};

const getMessageFilter = (
	props: WebsocketSubscriptionProps
): ((message: { channel: string; data: any }) => boolean) => {
	const type = props.type;

	switch (type) {
		case 'orderbook': {
			return (message: { channel: string; data: any }) => {
				return (
					message.channel === getChannelKey(props) ||
					// This is a special extra message which comes through once, immediately after subscribing, to let the subscriber know the initial state of the orderbook
					message.channel ===
						`last_update_orderbook_${props.market.marketTypeStr}_${props.market.marketIndex}`
				);
			};
		}
		case 'trades': {
			return (message: { channel: string; data: any }) =>
				message.channel === getChannelKey(props);
		}
		default: {
			const exhaustiveCheck: never = type;
			throw new Error(`Unhandled case: ${exhaustiveCheck}`);
		}
	}
};

const DLOB_SERVER_WEBSOCKET_UTILS = {
	getSubscriptionProps,
	getUnsubscriptionProps,
	getMessageFilter,
};

export default DLOB_SERVER_WEBSOCKET_UTILS;
