'use client';

import Drawer from 'src/components/Drawer';
import MarketIcon from 'src/components/Utils/MarketIcon';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import Text from 'src/components/Text/Text';
import Utility from 'src/components/Inputs/Utility';
import {
	BigNum,
	BN,
	calculateAssetWeight,
	calculateLiabilityWeight,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SPOT_MARKET_WEIGHT_PRECISION,
	SpotBalanceType,
	SpotMarketConfig,
	ZERO,
} from '@drift-labs/sdk';
import { memo, useState } from 'react';
import UnderlinedTabs from 'src/components/TabbedNavigation/UnderlinedTabs';
import useSafePush from 'src/hooks/useSafePush';
import { ArrowLeft, Info, Open } from '@drift-labs/icons';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useDriftClient from 'src/hooks/useDriftClient';
import NumberDisplay from 'src/components/Utils/NumberDisplay';
import useAprBreakdowns from 'src/hooks/useAprBreakdowns';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import NumberDisplayV2 from 'src/components/Utils/NumberDisplayV2';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { ZERO_BIGNUM } from 'src/constants/math';
import useOraclePriceStore from 'src/stores/useOraclePriceStore';
import UI_UTILS from 'src/utils/uiUtils';
import LoweredAssetWeightTooltip from 'src/components/Tooltip/LoweredAssetWeightTooltip';
import { ENUM_UTILS } from '@drift/common';
import { RateHistoryChart } from 'src/components/RateHistoryChart';
import dynamic from 'next/dynamic';
import Button from 'src/components/Button';
import useDriftActions from 'src/hooks/useDriftActions';
import CloseBorrowSwapForm from 'src/components/Swap/CloseBorrowSwapForm';
import {
	CloseBorrowOption,
	CloseBorrowSpotTrade,
} from 'src/components/Popups/CloseBorrowPopup';
import CloseBorrowDepositForm from 'src/components/CloseBorrowDepositForm';
import ButtonTabs from 'src/components/TabbedNavigation/ButtonTabs';

const BorrowLendRateToUtilizationChart = dynamic(
	() => import('../../BorrowLendRateToUtilizationChart')
);

type BorrowLendTabValue = 'deposit' | 'borrow';

const ONE_HUNDRED = BigNum.from(100);

const TabOptions = [
	{ value: 'deposit', label: 'Deposit (Lend)' },
	{ value: 'borrow', label: 'Borrow' },
];

const CloseBorrowTabOptions = [
	{ value: CloseBorrowOption.Swaps, label: 'Swaps' },
	// { value: CloseBorrowOption.SpotTrade, label: 'Spot Trade' },
	{ value: CloseBorrowOption.Deposit, label: 'Deposit' },
];

const Section = ({
	sectionLabel,
	topRightNode,
	children,
}: {
	sectionLabel: string;
	topRightNode?: React.ReactNode;
	children: React.ReactNode;
}) => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between w-full">
				<Text.H5 className="text-text-default">{sectionLabel}</Text.H5>
				{!!topRightNode && (
					<Text.BODY2 className="text-text-label">{topRightNode}</Text.BODY2>
				)}
			</div>
			<div className="flex flex-col gap-2">{children}</div>
		</div>
	);
};

const DetailRow = ({
	label,
	value,
	marketSymbol,
	className,
}: {
	label: React.ReactNode;
	value: React.ReactNode;
	marketSymbol?: string;
	className?: string;
}) => (
	<div className={twMerge('flex justify-between w-full h-[16px]', className)}>
		<Text.BODY3 className="text-text-label">{label}</Text.BODY3>
		<div className="flex items-center gap-1">
			{marketSymbol && (
				<MarketIcon marketSymbol={marketSymbol} sizeClass="w-4 h-4" />
			)}
			<Text.BODY3 className="text-text-default">{value}</Text.BODY3>
		</div>
	</div>
);

const DepositMarketContent = ({
	market: spotMarketConfig,
}: {
	market: SpotMarketConfig;
	//onClose?: () => void;
}) => {
	const actions = useDriftActions();
	const setStore = useDriftStore((s) => s.set);
	const getOraclePrice = useOraclePriceStore((s) => s.getMarketPriceData);
	const safePush = useSafePush();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const borrowLendData = useDriftStore((s) =>
		s.borrowLendData.find(
			(data) => data.bankIndex === spotMarketConfig.marketIndex
		)
	);
	const currentUserClient = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.client
	);
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const spotBalance = useDriftAccountStore(
		(s) =>
			s.accounts[s.currentUserKey]?.spotBalances?.find(
				(spotBalance) =>
					spotBalance.asset.marketIndex === spotMarketConfig.marketIndex
			) || null
	);

	const userDeposits = ENUM_UTILS.match(
		spotBalance?.balanceType,
		SpotBalanceType.DEPOSIT
	)
		? spotBalance?.balance
		: ZERO_BIGNUM;

	const liqPrice = BigNum.from(
		currentUserClient?.spotLiquidationPrice(spotMarketConfig.marketIndex),
		PRICE_PRECISION_EXP
	);

	const aprBreakdowns = useAprBreakdowns();
	const marketAprBreakdowns = aprBreakdowns[spotMarketConfig.marketIndex] ?? [];

	const depositApr = borrowLendData.depositApr.mul(ONE_HUNDRED);

	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(spotMarketConfig?.marketIndex)) ||
		undefined;

	const maxDeposits = BigNum.from(
		spotMarketAccount?.maxTokenDeposits || new BN(0),
		spotMarketConfig?.precisionExp
	);

	const oraclePrice =
		getOraclePrice(UI_UTILS.getSpotMarketId(spotMarketConfig))?.rawPriceData
			?.price ?? ZERO;

	const initialAssetWeight = calculateAssetWeight(
		spotBalance?.balance?.val ?? ZERO,
		oraclePrice,
		spotMarketAccount,
		'Initial'
	);

	const maintenanceAssetWeight = calculateAssetWeight(
		spotBalance?.balance?.val ?? ZERO,
		oraclePrice,
		spotMarketAccount,
		'Maintenance'
	);

	const scaleInitialAssetWeightStartBigNum = BigNum.from(
		spotMarketAccount?.scaleInitialAssetWeightStart || ZERO,
		QUOTE_PRECISION_EXP
	);
	const isLoweredAssetWeight = !scaleInitialAssetWeightStartBigNum.eqZero();

	const handleDepositHistory = () => {
		safePush('/overview/history/deposits');
	};

	const handleShowDepositModal = () => {
		actions.showDepositModal(
			spotMarketConfig.marketIndex,
			currentUserKey,
			false
		);
	};

	const handleShowWithdrawModal = () => {
		setStore((s) => {
			s.modalTargetAccountKey = currentUserKey;
			s.modalCollateralType = spotMarketAccount.marketIndex;
			s.modals.showWithdrawModal = true;
		});
	};

	return (
		<>
			<Drawer.Body>
				<div className={'flex flex-col gap-4 p-4 pb-0'}>
					{
						<>
							<Section sectionLabel="Overview">
								<DetailRow
									label="Lending APR"
									value={
										<div className="flex flex-row items-center gap-1 text-positive-green">
											<NumberDisplayV2
												value={depositApr}
												displayType="percentage"
												toFixed={4}
												trimZeroes={1}
											/>
										</div>
									}
								/>
								{marketAprBreakdowns.length > 0 && (
									<>
										{marketAprBreakdowns.map((aprDetails) => (
											<DetailRow
												key={`${aprDetails.source}_${aprDetails.icon}`}
												label={
													aprDetails.helperText ? (
														<Tooltip
															content={
																<Text.BODY2>{aprDetails.helperText}</Text.BODY2>
															}
															allowHover
														>
															<div className="flex flex-row items-center gap-1">
																{aprDetails.source} <Info />
															</div>
														</Tooltip>
													) : (
														aprDetails.source
													)
												}
												value={
													aprDetails.apr ? (
														<div className="text-positive-green">
															<NumberDisplayV2
																value={BigNum.fromPrint(
																	`${aprDetails.apr}`,
																	spotMarketConfig.precisionExp
																)}
																displayType="percentage"
																toFixed={4}
																trimZeroes={1}
															/>
														</div>
													) : null
												}
											/>
										))}
									</>
								)}
								{/* <DetailRow label={'30d avg APR'} value={`0`} /> */}
								<DetailRow
									label={'Global deposits'}
									value={
										<div className="flex flex-row items-center gap-1">
											<MarketIcon marketSymbol={spotMarketConfig.symbol} />{' '}
											<NumberDisplay
												displayType="tradePrecision"
												value={borrowLendData.totalDepositsBase.toNum()}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Max deposits'}
									value={
										<div className="flex flex-row items-center gap-1">
											<MarketIcon marketSymbol={spotMarketConfig.symbol} />{' '}
											<NumberDisplay
												displayType="tradePrecision"
												value={maxDeposits.toNum()}
											/>
										</div>
									}
								/>
								<DetailRow
									label={
										<div className="flex flex-row items-center">
											Initial asset weight{' '}
											{isLoweredAssetWeight && (
												<LoweredAssetWeightTooltip
													marginLimitDisplayValue={scaleInitialAssetWeightStartBigNum.toNotional()}
												/>
											)}
										</div>
									}
									value={
										<div className="flex flex-row items-center gap-1">
											<NumberDisplayV2
												value={BigNum.from(
													initialAssetWeight
														.mul(new BN(100))
														.div(SPOT_MARKET_WEIGHT_PRECISION)
												)}
												displayType="percentage"
												toFixed={0}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Maintenance asset weight'}
									value={
										<NumberDisplayV2
											value={BigNum.from(
												maintenanceAssetWeight
													.mul(new BN(100))
													.div(SPOT_MARKET_WEIGHT_PRECISION)
											)}
											displayType="percentage"
											toFixed={0}
										/>
									}
								/>
							</Section>
							<Utility.VERTDIVIDER />
							<Section
								sectionLabel="Your Deposits"
								topRightNode={
									<button
										onClick={handleDepositHistory}
										className="text-text-secondary flex flex-row items-center gap-1"
									>
										<Text.BODY2>History</Text.BODY2> <Open />
									</button>
								}
							>
								<DetailRow
									label="Your deposits"
									value={
										<NumberDisplayV2
											displayType="asset"
											toTradePrecision
											value={userDeposits}
											assetSymbol={spotMarketConfig.symbol}
										/>
									}
								/>
								<DetailRow
									label={
										<div className="flex flex-row items-center gap-1">
											Liquidation price{' '}
											<Tooltip
												content={
													'Liquidation Price is the Oracle Price of the asset where your account can be liquidated, assuming the prices of your other assets/positions are constant.'
												}
											/>
										</div>
									}
									value={
										liqPrice.gtZero() ? (
											<NumberDisplayV2
												displayType="notional"
												toTradePrecision
												value={liqPrice}
											/>
										) : (
											'None'
										)
									}
								/>
							</Section>
							<Utility.VERTDIVIDER />
							<Section sectionLabel="Deposit Rate History	">
								<RateHistoryChart
									marketIndex={spotMarketConfig.marketIndex}
									type={'deposit'}
									showAverage
								/>
							</Section>
							<Utility.VERTDIVIDER />
							<Section sectionLabel="Interest Rate and Utilization">
								<BorrowLendRateToUtilizationChart
									marketIndex={spotMarketConfig.marketIndex}
									className="h-[200px]"
								/>
							</Section>
						</>
					}
				</div>
			</Drawer.Body>
			<Drawer.Footer>
				<div className="flex flex-row items-center justify-between gap-2">
					<Button.BigSemantic
						className="py-2"
						positive
						onClick={handleShowDepositModal}
					>
						<Text.H3>Deposit</Text.H3>
					</Button.BigSemantic>
					{userDeposits.gtZero() && (
						<Button.BigSemantic
							className="py-2"
							neutral
							onClick={handleShowWithdrawModal}
						>
							<Text.H3>Withdraw</Text.H3>
						</Button.BigSemantic>
					)}
				</div>
			</Drawer.Footer>
		</>
	);
};

const BorrowMarketContent = ({
	market: spotMarketConfig,
}: {
	market: SpotMarketConfig;
	//onClose?: () => void;
}) => {
	const [closeBorrowOption, setCloseBorrowOption] = useState<string>(
		CloseBorrowOption.Swaps
	);
	const [showCloseBorrowForm, setShowCloseBorrowForm] = useState(false);
	const setStore = useDriftStore((s) => s.set);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const borrowLendData = useDriftStore((s) =>
		s.borrowLendData.find(
			(data) => data.bankIndex === spotMarketConfig.marketIndex
		)
	);
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const currentUserClient = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.client
	);
	const spotBalance = useDriftAccountStore(
		(s) =>
			s.accounts[s.currentUserKey]?.spotBalances?.find(
				(spotBalance) =>
					spotBalance.asset.marketIndex === spotMarketConfig.marketIndex
			) || null
	);

	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(spotMarketConfig?.marketIndex)) ||
		undefined;

	const borrowApr = borrowLendData
		? borrowLendData.borrowApr.mul(ONE_HUNDRED)
		: ZERO_BIGNUM;
	const globalBorrows = borrowLendData?.totalBorrowsBase || ZERO_BIGNUM;
	const maxBorrows = borrowLendData?.borrowLimitBase || ZERO_BIGNUM;
	const borrowUtilization =
		borrowLendData?.borrowUtilization.mul(ONE_HUNDRED) || ZERO_BIGNUM;

	const userLiabilityWeight = calculateLiabilityWeight(
		spotBalance?.balance?.val ?? ZERO,
		spotMarketAccount,
		'Initial'
	);
	const userMaintenanceLiabilityWeight = calculateLiabilityWeight(
		spotBalance?.balance?.val ?? ZERO,
		spotMarketAccount,
		'Maintenance'
	);

	const userBorrows = ENUM_UTILS.match(
		spotBalance?.balanceType,
		SpotBalanceType.BORROW
	)
		? spotBalance?.balance
		: ZERO_BIGNUM;

	const liqPrice = BigNum.from(
		currentUserClient?.spotLiquidationPrice(spotMarketConfig.marketIndex),
		PRICE_PRECISION_EXP
	);

	const handleShowBorrowModal = () => {
		setStore((s) => {
			s.modalTargetAccountKey = currentUserKey;
			s.modalCollateralType = spotMarketAccount.marketIndex;
			s.modals.showBorrowModal = true;
		});
	};

	const handleShowCloseBorrowForm = () => {
		setShowCloseBorrowForm(true);
	};

	const handleHideCloseBorrow = () => {
		setShowCloseBorrowForm(false);
	};

	const renderCloseBorrowOption = () => {
		switch (closeBorrowOption) {
			case CloseBorrowOption.SpotTrade:
				return (
					<CloseBorrowSpotTrade
						spotMarketIndex={spotMarketConfig.marketIndex}
						onClose={handleHideCloseBorrow}
					/>
				);
			case CloseBorrowOption.Swaps:
				return (
					<CloseBorrowSwapForm
						swapToSpotMarketIndex={spotMarketConfig.marketIndex}
						onClose={handleHideCloseBorrow}
						fullHeight
					/>
				);
			case CloseBorrowOption.Deposit:
				return (
					<CloseBorrowDepositForm
						spotMarketIndex={spotMarketConfig.marketIndex}
						onClose={handleHideCloseBorrow}
					/>
				);
			default:
				return (
					<CloseBorrowSpotTrade
						spotMarketIndex={spotMarketConfig.marketIndex}
						onClose={handleHideCloseBorrow}
					/>
				);
		}
	};

	return showCloseBorrowForm ? (
		<Drawer.Body>
			<button
				className="flex flex-row items-cente gap-2 p-3 text-text-default my-2"
				onClick={handleHideCloseBorrow}
			>
				<ArrowLeft />
				<Text.BODY1>Close Borrow</Text.BODY1>
			</button>
			<div className="p-2">
				<ButtonTabs
					className="gap-2"
					tabs={CloseBorrowTabOptions}
					activeTab={closeBorrowOption}
					onSelect={(newSelection: BorrowLendTabValue) => {
						setCloseBorrowOption(newSelection);
					}}
					tabClassName={
						'grow text-center text-[14px] leading-[16px] items-center justify-center py-2 w-[50%] text-text-label'
					}
				/>
			</div>
			<div className="p-3 pr-4 py-4">{renderCloseBorrowOption()}</div>
		</Drawer.Body>
	) : (
		<>
			<Drawer.Body>
				<div className={'flex flex-col gap-4 p-4 pb-0'}>
					{
						<>
							<Section sectionLabel="Overview">
								<DetailRow
									label="Borrow APR"
									value={
										<div className="flex flex-row items-center gap-1 text-warn-yellow">
											<NumberDisplayV2
												value={borrowApr}
												displayType="percentage"
												toFixed={4}
												trimZeroes={1}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Global borrows'}
									value={
										<div className="flex flex-row items-center gap-1">
											<MarketIcon marketSymbol={spotMarketConfig.symbol} />{' '}
											<NumberDisplay
												displayType="tradePrecision"
												value={globalBorrows.toNum()}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Borrow availability'}
									value={
										<div className="flex flex-row items-center gap-1">
											<MarketIcon marketSymbol={spotMarketConfig.symbol} />{' '}
											<NumberDisplay
												displayType="tradePrecision"
												value={maxBorrows.toNum()}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Borrow utilization'}
									value={
										<div className="flex flex-row items-center gap-1">
											<NumberDisplayV2
												displayType="percentage"
												value={borrowUtilization}
												toFixed={2}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Initial liability weight'}
									value={
										<div className="flex flex-row items-center gap-1">
											<NumberDisplayV2
												displayType="percentage"
												value={BigNum.from(
													userLiabilityWeight
														.mul(new BN(100))
														.div(SPOT_MARKET_WEIGHT_PRECISION)
												)}
												toFixed={2}
											/>
										</div>
									}
								/>
								<DetailRow
									label={'Maintenance liability weight'}
									value={
										<div className="flex flex-row items-center gap-1">
											<NumberDisplayV2
												displayType="percentage"
												value={BigNum.from(
													userMaintenanceLiabilityWeight
														.mul(new BN(100))
														.div(SPOT_MARKET_WEIGHT_PRECISION)
												)}
												toFixed={2}
											/>
										</div>
									}
								/>
							</Section>
							<Utility.VERTDIVIDER />
							<Section sectionLabel="Your Borrows">
								<DetailRow
									label="Your borrows"
									value={
										<NumberDisplayV2
											displayType="asset"
											toTradePrecision
											value={userBorrows}
											assetSymbol={spotMarketConfig.symbol}
										/>
									}
								/>
								<DetailRow
									label={
										<div className="flex flex-row items-center gap-1">
											Liquidation price{' '}
											<Tooltip
												content={
													'Liquidation Price is the Oracle Price of the asset where your account can be liquidated, assuming the prices of your other assets/positions are constant.'
												}
											/>
										</div>
									}
									value={
										liqPrice.gtZero() ? (
											<NumberDisplayV2
												displayType="notional"
												toTradePrecision
												value={liqPrice}
											/>
										) : (
											'None'
										)
									}
								/>
							</Section>
							<Utility.VERTDIVIDER />
							<Section sectionLabel="Borrow Rate History">
								<RateHistoryChart
									marketIndex={spotMarketConfig.marketIndex}
									type={'borrow'}
									showAverage
								/>
							</Section>
							<Utility.VERTDIVIDER />
							<Section sectionLabel="Interest Rate and Utilization">
								<BorrowLendRateToUtilizationChart
									marketIndex={spotMarketConfig.marketIndex}
									className="h-[200px]"
								/>
							</Section>
						</>
					}
				</div>
			</Drawer.Body>
			<Drawer.Footer>
				<div className="flex flex-row items-center justify-between gap-2">
					<Button.BigSemantic
						className="py-2"
						positive
						onClick={handleShowBorrowModal}
					>
						<Text.H3>Borrow</Text.H3>
					</Button.BigSemantic>
					{userBorrows.gtZero() && (
						<Button.BigSemantic
							className="py-2"
							neutral
							onClick={handleShowCloseBorrowForm}
						>
							<Text.H3>Close Borrow</Text.H3>
						</Button.BigSemantic>
					)}
				</div>
			</Drawer.Footer>
		</>
	);
};

const BorrowLendDetailsDrawer = () => {
	const handleClose = useDrawersStore((s) => s.handleCloseDrawer);
	const set = useDrawersStore((s) => s.set);
	const { market, displayTab } = useDrawersStore((s) => s.borrowLendDetails);

	const symbol = market.symbol;

	const onClose = () => {
		handleClose('borrowLendDetails');
	};

	const setSelectedTab = (value: BorrowLendTabValue) => {
		set((s) => {
			s.borrowLendDetails.displayTab = value;
		});
	};

	return (
		<Drawer
			drawerName="borrowLendDetails"
			className="w-full sm:max-w-[400px] sm:w-[400px] thin-scroll overflow-y-auto flex flex-col"
		>
			<div className="bg-container-bg">
				<Drawer.Header
					titleContent={
						<div className="flex items-center gap-1">
							<MarketIcon marketSymbol={symbol} />
							<span className="ml-1">{symbol}</span>
						</div>
					}
					className="p-4"
					onClose={onClose}
				/>

				<UnderlinedTabs
					options={TabOptions}
					currentSelection={displayTab}
					onChange={(newSelection: BorrowLendTabValue) => {
						setSelectedTab(newSelection);
					}}
					tabClassName={
						'grow text-center text-[14px] leading-[16px] items-center justify-center w-[50%]'
					}
					gradient
				/>
			</div>
			{displayTab === 'deposit' && <DepositMarketContent market={market} />}
			{displayTab === 'borrow' && <BorrowMarketContent market={market} />}
		</Drawer>
	);
};

export default memo(BorrowLendDetailsDrawer);
