'use client';

import { Draft } from 'immer';
import posthog from 'posthog-js';
import { useEffect, useMemo } from 'react';
import { dlog } from 'src/dev';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useFeatureFlagStore, {
	FeatureFlagKey,
	FeatureFlagStore,
} from 'src/stores/useFeatureFlagStore';

/**
 * ⭐️⭐️⭐️⭐️ SEE readmes/feature-flags.md FOR MORE INFO ⭐️⭐️⭐️⭐️
 */

const RELOAD_REMOTE_FLAGS_EVERY_SESSION = false; // Whether to reload the feature flags for each session, otherwise it may use cached flags

/**
 * Base state update handler to prevent duplicate code. This just sets the enabled and rawPayload values for a feature flag in the store.
 * @param stateDraft
 * @param key
 * @param enabled
 * @param rawPayload
 */
const baseFlagStateUpdateHandler = (
	stateDraft: Draft<FeatureFlagStore>,
	key: FeatureFlagKey,
	enabled: boolean,
	rawPayload?: any
) => {
	stateDraft.flags[key].enabled = enabled;
	stateDraft.flags[key].rawPayload = rawPayload;
};

/**
 * Keeps the feature flag state in the store in sync asynchonously loaded flags. These handlers only determine if the flags are enabled and and dumps their rawPayload into the flag state. It is onto the more specific handlers (useSyncFeatureFlagStateWithHandlers) to catch changes to this state and do further changes.
 */
const useSyncFeatureFlagStateInStore = () => {
	const setFeatureFlagStore = useFeatureFlagStore((s) => s.set);
	const getFeatureFlagStore = useFeatureFlagStore((s) => s.get);
	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);
	const actions = useDriftActions();

	const FEATURE_FLAG_HANDLERS: Record<
		FeatureFlagKey,
		(enabled: boolean, rawPayload?: any) => void
	> = useMemo(
		() => ({
			SKIP_TRANSACTION_PREFLIGHTS: (enabled: boolean, rawPayload?: any) => {
				dlog(`feature_flags`, `handler::SKIP_TRANSACTION_PREFLIGHTS`, enabled);

				setFeatureFlagStore((state) => {
					baseFlagStateUpdateHandler(
						state,
						'SKIP_TRANSACTION_PREFLIGHTS',
						enabled,
						rawPayload
					);
				});

				actions.handleUpdateTransactionPreflights(enabled);
			},
			HIGHLIGHTED_ASSETS: (enabled: boolean, rawPayload?: any) => {
				// Validate that the payload value is an array of strings otherwise we exit early
				if (
					!rawPayload ||
					!Array.isArray(rawPayload) ||
					rawPayload.some((asset) => typeof asset !== 'string')
				) {
					dlog(
						`feature_flags`,
						`handler::HIGHLIGHTED_ASSETS::invalid_payload`,
						rawPayload
					);
					setFeatureFlagStore((state) => {
						state.flags.HIGHLIGHTED_ASSETS.enabled = false;
						state.flags.HIGHLIGHTED_ASSETS.assets = [];
						state.flags.HIGHLIGHTED_ASSETS.rawPayload = undefined;
					});
					return;
				}

				setFeatureFlagStore((state) => {
					baseFlagStateUpdateHandler(
						state,
						'HIGHLIGHTED_ASSETS',
						enabled,
						rawPayload
					);
					state.flags.HIGHLIGHTED_ASSETS.assets = rawPayload ?? [];
				});
			},
		}),
		[]
	);

	// Sync the enabled state of the feature flags in the store
	useEffect(() => {
		const flagsLoadedHandler = (flags: any[]) => {
			const state = getFeatureFlagStore();

			for (const flag of flags) {
				if (state.flags[flag]) {
					const matchedFlagPayload = posthog.getFeatureFlagPayload(flag);

					const isEnabled =
						matchedFlagPayload === true ||
						matchedFlagPayload === 'true' ||
						(!!matchedFlagPayload && matchedFlagPayload !== 'false');

					dlog(
						`feature_flags`,
						`flag_payload:: ${flag},isEnabled=${isEnabled}`,
						{
							payload: matchedFlagPayload,
						}
					);

					FEATURE_FLAG_HANDLERS[flag as FeatureFlagKey](
						isEnabled,
						matchedFlagPayload
					);
				} else {
					dlog(`feature_flags`, `flag_not_in_store::${flag}`, flags);
				}
			}
		};

		appEventEmitter.on('featureFlagsLoaded', flagsLoadedHandler);

		return () => {
			appEventEmitter.off('featureFlagsLoaded', flagsLoadedHandler);
		};
	}, [appEventEmitter]);
};

const useSyncFeatureFlags = () => {
	useEffect(() => {
		if (!RELOAD_REMOTE_FLAGS_EVERY_SESSION) return;
		// Reload the feature flags for each session
		posthog.reloadFeatureFlags();
	}, []);

	useSyncFeatureFlagStateInStore();
};

export default useSyncFeatureFlags;
