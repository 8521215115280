import { ENUM_UTILS, MarketId, Opaque } from '@drift/common';
import {
	KnownMarketOrderId,
	MarketOrderToastId,
	UnknownMarketOrderId,
} from './MarketOrderToastStateTypes';
import { BN, PositionDirection, PublicKey } from '@drift-labs/sdk';

export type OrderWithUnknownIdProps = {
	idNonce: number;
	user: PublicKey;
	marketId: MarketId;
	baseSize: BN;
	direction: PositionDirection;
};

export type OrderWithKnownIdProps = Omit<OrderWithUnknownIdProps, 'idNonce'> & {
	orderId: number;
};

export type BridgingQueueId = Opaque<'BridgingQueueId', string>;

export const getBridgingQueueId = (
	user,
	marketId,
	baseSize,
	direction
): BridgingQueueId => {
	return `user:${user.toString()}_market:${
		marketId.key
	}_base:${baseSize.toString()}_${ENUM_UTILS.toStr(
		direction
	)}` as BridgingQueueId;
};

export const getKnownMarketOrderId = (
	user: PublicKey,
	marketId: MarketId,
	orderId: number
): KnownMarketOrderId => {
	return `user:${user.toString()}_market:${
		marketId.key
	}_order:${orderId}` as KnownMarketOrderId;
};

const getUnknownMarketOrderId = ({
	idNonce,
	user,
	marketId,
	baseSize,
	direction,
}: OrderWithUnknownIdProps) => {
	return `nonce:${idNonce}_user:${user.toString()}_${
		marketId.key
	}_base:${baseSize.toString()}_${ENUM_UTILS.toStr(
		direction
	)}` as UnknownMarketOrderId;
};

const generateMarketOrderToastId = () => {
	return window.crypto.randomUUID() as MarketOrderToastId;
};

/**
 * This is a cache to keep track of the abstracted market order IDs that are generated for each market order.
 *
 * The abstract market order IDs are required because we cannot deterministically know the order ID of a market order before the order is confirmed and we receive a confirmation.
 *
 * Instead we generate an abstracted ID, and link to it using the identifying (not necessarily unique) parameters of the order, which we do know deterministically.
 *
 * While we're waiting for the confirmation to come in, more equal orders can be placed so we store the abstracted ID in a list.
 *
 * When we finally receive the confirmation, we can link pull the first abstracted ID from the unlinked(no order ID) list and add it to the linked(known order id) list.
 */
export class BaseMarketOrderToastIdHandler {
	protected static knownIdToastLinkCache: Map<
		KnownMarketOrderId,
		MarketOrderToastId
	>;
	protected static unknownIdToastLinkCache: Map<
		UnknownMarketOrderId,
		MarketOrderToastId
	>;
	protected static unlinkedIdQueue: Map<BridgingQueueId, MarketOrderToastId[]>;

	static handleNewMarketOrderWithUnknownOrderId(
		props: OrderWithUnknownIdProps
	) {
		const unknownMarketOrderId = getUnknownMarketOrderId(props);
		const marketOrderToastId = generateMarketOrderToastId();
		const bridgingQueueId = getBridgingQueueId(
			props.user,
			props.marketId,
			props.baseSize,
			props.direction
		);

		if (!this.unknownIdToastLinkCache.has(unknownMarketOrderId)) {
			this.unknownIdToastLinkCache.set(
				unknownMarketOrderId,
				marketOrderToastId
			);
		} else {
			throw new Error(`Market order id already exists for unknown Id props`);
		}

		// Add new abstracted market order ID to the unlinked queue
		if (!this.unlinkedIdQueue.has(bridgingQueueId)) {
			this.unlinkedIdQueue.set(bridgingQueueId, [marketOrderToastId]);
		} else {
			this.unlinkedIdQueue.get(bridgingQueueId)?.push(marketOrderToastId);
		}

		return marketOrderToastId;
	}

	static getToastIdWithUnknownOrderId(props: OrderWithUnknownIdProps) {
		const unknownMarketOrderId = getUnknownMarketOrderId(props);

		if (this.unknownIdToastLinkCache.has(unknownMarketOrderId)) {
			const marketOrderToastId =
				this.unknownIdToastLinkCache.get(unknownMarketOrderId);

			if (!marketOrderToastId || marketOrderToastId.length === 0) {
				throw new Error(
					`Couldn't find abstracted market order ID for unknown ID ${unknownMarketOrderId}`
				);
			}

			const id = marketOrderToastId;

			return id;
		} else {
			throw new Error(
				`Couldn't find abstracted market order ID for unknown ID ${unknownMarketOrderId}`
			);
		}
	}

	static getToastIdWithKnownOrderId(props: OrderWithKnownIdProps) {
		const knownMarketOrderId = getKnownMarketOrderId(
			props.user,
			props.marketId,
			props.orderId
		);

		// If we already have the abstracted market order id, return it
		if (this.knownIdToastLinkCache.has(knownMarketOrderId)) {
			const id = this.knownIdToastLinkCache.get(knownMarketOrderId);
			return id;
		}

		// Else we can pull the unlinked id off the queue, and create the link
		const bridgingQueueId = getBridgingQueueId(
			props.user,
			props.marketId,
			props.baseSize,
			props.direction
		);

		const nextUnlinkedId = this.unlinkedIdQueue.get(bridgingQueueId)?.shift();

		if (!nextUnlinkedId) {
			return;
		}

		this.knownIdToastLinkCache.set(knownMarketOrderId, nextUnlinkedId);

		return nextUnlinkedId;
	}

	static clearStateForUnknownOrderId(props: OrderWithUnknownIdProps) {
		const bridgingQueueId = getBridgingQueueId(
			props.user,
			props.marketId,
			props.baseSize,
			props.direction
		);

		const unknownMarketOrderId = getUnknownMarketOrderId(props);

		this.unknownIdToastLinkCache.delete(unknownMarketOrderId);
		this.unlinkedIdQueue.delete(bridgingQueueId);
	}
}

// Used by our toast handling logic
export class MarketOrderToastIdHandler extends BaseMarketOrderToastIdHandler {
	protected static knownIdToastLinkCache = new Map<
		KnownMarketOrderId,
		MarketOrderToastId
	>();
	protected static unknownIdToastLinkCache = new Map<
		UnknownMarketOrderId,
		MarketOrderToastId
	>();
	protected static unlinkedIdQueue = new Map<
		BridgingQueueId,
		MarketOrderToastId[]
	>();
}

// Used by our self-filling logic
export class SelfFillMarketOrderIdHandler extends BaseMarketOrderToastIdHandler {
	protected static knownIdToastLinkCache = new Map<
		KnownMarketOrderId,
		MarketOrderToastId
	>();
	protected static unknownIdToastLinkCache = new Map<
		UnknownMarketOrderId,
		MarketOrderToastId
	>();
	protected static unlinkedIdQueue = new Map<
		BridgingQueueId,
		MarketOrderToastId[]
	>();
}
