'use client';

import { MarketId } from '@drift/common';
import { useEffect, useRef } from 'react';
import useMarketStateStore, {
	PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION,
	PREFERRED_SELECTED_MARKET_LISTENING_SELECTION,
} from '../../stores/useMarketStateStore';
import useMarketsForDlobToTrack, {
	MarketStatePriority,
} from '../useMarketsForDlobToTrack';
import { dlog } from '../../dev';

const useSyncMarketSubscriptions = () => {
	const marketsToSubscribe = useMarketsForDlobToTrack();
	const marketSubscriptionCategories = marketsToSubscribe.categories;

	const doneInitialSync = useRef(false);

	const setMarketStateStore = useMarketStateStore((s) => s.set);
	const previouslySelectedMarketId = useRef<MarketId | null>(null);

	/**
	 * This hook ensures that the appropriate changes are made to the subscription parameters when the user changes the selected market.
	 *
	 * It does this by updating the newly selected, and previous selected, market's subscription states.
	 */
	useEffect(() => {
		const currentMarketSubscriptionMarketId =
			marketSubscriptionCategories[MarketStatePriority.SelectedMarket][0];

		if (!doneInitialSync.current) {
			previouslySelectedMarketId.current = currentMarketSubscriptionMarketId;
			return;
		}

		if (
			currentMarketSubscriptionMarketId?.key ===
			previouslySelectedMarketId.current?.key
		) {
			return;
		}

		setMarketStateStore((state) => {
			if (
				previouslySelectedMarketId.current &&
				state.marketSubscriptionState[previouslySelectedMarketId.current.key]
			) {
				dlog(
					`market_subscriptions`,
					`updating_previous_market_listening_state`
				);

				state.marketSubscriptionState[
					previouslySelectedMarketId.current.key
				].preferredListeningSelection =
					PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION;

				state.marketSubscriptionState[
					previouslySelectedMarketId.current.key
				].listeningSelection = PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION;
			}

			// currentMarketSubscriptionMarketId can be undefined. so first check if its set before trying to update the relevant state
			if (currentMarketSubscriptionMarketId) {
				if (
					state.marketSubscriptionState[currentMarketSubscriptionMarketId.key]
				) {
					dlog(
						`market_subscriptions`,
						`updating_newly_selected_market_listening_state`
					);

					state.marketSubscriptionState[
						currentMarketSubscriptionMarketId.key
					].preferredListeningSelection =
						PREFERRED_SELECTED_MARKET_LISTENING_SELECTION;

					state.marketSubscriptionState[
						currentMarketSubscriptionMarketId.key
					].listeningSelection = PREFERRED_SELECTED_MARKET_LISTENING_SELECTION;
				}
			}
		});

		previouslySelectedMarketId.current = currentMarketSubscriptionMarketId;
	}, [marketSubscriptionCategories[MarketStatePriority.SelectedMarket]]);

	/**
	 * This hook initialises the subscription state for all markets.
	 */
	useEffect(() => {
		if (doneInitialSync.current) {
			return;
		}

		setMarketStateStore((state) => {
			for (const market of marketSubscriptionCategories[
				MarketStatePriority.SelectedMarket
			]) {
				state.marketSubscriptionState[market.key] = {
					preferredListeningSelection:
						PREFERRED_SELECTED_MARKET_LISTENING_SELECTION,
					listeningSelection: PREFERRED_SELECTED_MARKET_LISTENING_SELECTION,
					lastSubscriptionChangeTime: Date.now(),
					upgradeAttemptCount: 0,
				};
			}

			for (const market of marketSubscriptionCategories[
				MarketStatePriority.BackgroundDeep
			]) {
				state.marketSubscriptionState[market.key] = {
					preferredListeningSelection:
						PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION,
					listeningSelection: PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION,
					lastSubscriptionChangeTime: Date.now(),
					upgradeAttemptCount: 0,
				};
			}

			for (const market of marketSubscriptionCategories[
				MarketStatePriority.BackgroundShallow
			]) {
				state.marketSubscriptionState[market.key] = {
					preferredListeningSelection:
						PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION,
					listeningSelection: PREFERRED_BACKGROUND_MARKET_LISTENING_SELECTION,
					lastSubscriptionChangeTime: Date.now(),
					upgradeAttemptCount: 0,
				};
			}
		});

		doneInitialSync.current = true;
	}, [marketSubscriptionCategories]);
};

export default useSyncMarketSubscriptions;
