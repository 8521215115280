'use client';

import { useState } from 'react';
import useDriftAccountStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import useUiUpdateInterval from './useUiUpdateInterval';

/**
 * CAREFUL: This "actively" fetches sub accounts -> it will return a new value at an interval of `Env.uiEssentialUpdateIntervalMs`
 * @returns
 */
const useTickedAllSubAccounts = (): AccountData[] => {
	const getAccountsStore = useDriftAccountStore((s) => s.get);
	const [accounts, setAccounts] = useState<AccountData[]>([]);

	useUiUpdateInterval(
		() => {
			setAccounts(Object.values(getAccountsStore().accounts));
		},
		true,
		false
	);

	return accounts;
};

export default useTickedAllSubAccounts;
