'use client';

import { ZERO } from '@drift-labs/sdk';
import { useMemo } from 'react';
import useAccountData from './useAccountData';

const useCurrentAccountOpenPerpPositions = () => {
	const currentAccount = useAccountData();

	const openPerpPositions = useMemo(() => {
		if (!currentAccount || !currentAccount?.positionsLoaded) return [];

		return currentAccount.openPerpPositions
			.filter((pos) => !pos.baseSize.eq(ZERO))
			.map((pos) => {
				return {
					...pos,
					accountName: currentAccount.name,
					accountKey: currentAccount.userKey,
				};
			});
	}, [
		!!currentAccount,
		currentAccount?.positionsLoaded,
		currentAccount?.openPerpPositions,
		currentAccount?.name,
		currentAccount?.userKey,
	]);

	return openPerpPositions;
};

export default useCurrentAccountOpenPerpPositions;
