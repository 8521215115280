'use client';

import XButton from 'src/components/Buttons/XButton';
import ModalPortal from 'src/components/ModalPortal/ModalPortal';
import React, { useEffect, useRef, useState } from 'react';
import ModalBackground from './ModalBackground';
import { twMerge } from 'tailwind-merge';
import { SheetName, useSheetsStore } from 'src/stores/useSheetsStore';

const Header = ({
	children,
	sheetName,
	className,
}: {
	children: React.ReactNode;
	sheetName: SheetName;
	className?: string;
}) => {
	const handleCloseSheet = useSheetsStore((s) => s.handleCloseSheet);

	const handleClose = () => {
		handleCloseSheet(sheetName);
	};

	return (
		<div
			className={twMerge(
				'flex flex-row items-center p-4 pl-6 py-2 justify-between border-t border-b border-container-border',
				className
			)}
		>
			{children}
			<XButton onClick={handleClose} className="relative top-1" />
		</div>
	);
};

// Sheets for mobile display
// If rendering on desktop for some reason, it should behave the same as a normal modal
const Sheet = ({
	children,
	sheetName,
	className,
}: {
	children: React.ReactNode;
	sheetName: SheetName;
	className?: string;
}) => {
	const contentRef = useRef(null);
	const isClosing = useSheetsStore((s) => s.sheetIsTransitionClosing);
	const handleCloseSheet = useSheetsStore((s) => s.handleCloseSheet);
	const [isMounted, setIsMounted] = useState(false); // used for transitions

	useEffect(() => {
		if (!isClosing) {
			setTimeout(() => {
				setIsMounted(true);
			}, 50);
		} else {
			setIsMounted(false);
		}

		return () => {
			setIsMounted(false);
		};
	}, [isClosing]);

	const handleClose = () => {
		handleCloseSheet(sheetName);
	};

	return (
		<ModalPortal customZIndex={110}>
			<ModalBackground
				onClose={handleClose}
				contentRef={contentRef}
				className={twMerge(
					'overflow-hidden',
					isMounted ? 'bg-opacity-30' : 'bg-opacity-0'
				)}
				blurClassName={isMounted ? 'backdrop-blur' : 'backdrop-blur-none'}
			>
				<div
					ref={contentRef}
					className={twMerge(
						'absolute top-[100%] left-0 w-full bg-container-bg-hover transition-transform max-h-[100vh] thin-scroll overflow-y-auto',
						isMounted ? 'translate-y-[-100%]' : '',
						className
					)}
				>
					{children}
				</div>
			</ModalBackground>
		</ModalPortal>
	);
};

Sheet.Header = Header;

export default Sheet;
