'use client';

import { UserAccount } from '@drift-labs/sdk';
import { PublicKey } from '@solana/web3.js';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { useEffect, useRef, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { NonTradeRoutes, PageRoute, PageRoutes } from '../constants/constants';
import useDriftActions from './useDriftActions';
import UI_UTILS from 'src/utils/uiUtils';
import usePostHogCapture from './posthog/usePostHogCapture';
import useDriftClientIsReady from './useDriftClientIsReady';
import useSafePush from './useSafePush';
import useAccountExists from './useAccountExists';
import { DriftWindow } from '../window/driftWindow';
import {
	ReadonlyURLSearchParams,
	usePathname,
	useSearchParams,
} from 'next/navigation';

const AUTHORITY_OVERRIDE = '';
const USER_OVERRIDE = '';

const useRouterConfig = () => {
	const [hasHandledReferrerParam, setHasHandledReferrerParam] = useState(false);
	const set = useDriftStore((s) => s.set);
	const get = useDriftStore((s) => s.get);
	const actions = useDriftActions();
	const { captureEvent } = usePostHogCapture();
	const driftClientIsReady = useDriftClientIsReady();
	const safePush = useSafePush();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const accountExists = useAccountExists();

	/**
	 * We use this flag to denote "If the wallet was going to auto-connect, it would have by now".
	 */
	const [autoconnectTimeoutElapsed, setAutoconnectTimeoutElapsed] =
		useState(false);

	const { switchMarket } = actions;

	// Logic to set initial market selection when navigating to a particular market route

	const alreadyUsedRouteParams = useRef(false);

	const handleAuthorityEmulation = async (
		authorityParam: string | string[]
	) => {
		//// Sometimes users accidentally type random things into the address bar which aren't a public key - avoid sending error about this and do nothing
		try {
			if (authorityParam.includes(',')) {
				set((s) => {
					s.authorityParam = `authority=${authorityParam}`;
				});
			} else {
				const authority = new PublicKey(authorityParam);
				set((s) => {
					s.authorityParam = `authority=${authority.toString()}`;
				});
				actions.emulateAccount({ authority });
			}
		} catch (e) {
			console.log('no authority: ' + authorityParam);
		}
	};

	const handleUserAccountEmulation = async (userAccountParam: string) => {
		try {
			const driftClient = get().driftClient.client;

			const userAccountPubKey = new PublicKey(userAccountParam);

			const userAccount = (await driftClient.program.account.user.fetch(
				userAccountPubKey
			)) as UserAccount;

			const authority = userAccount.authority;

			set((s) => {
				s.authorityParam = `authority=${authority.toString()}`;
			});

			actions.emulateAccount({
				authority,
				subAccountPubKey: userAccountPubKey,
			});
		} catch (e) {
			console.log(
				'Error emulating account using user account key: ' + userAccountParam,
				e
			);
		}
	};

	const handleReferralPageLoad = async (
		referrerParam: string,
		fromLocalStorage?: boolean
	) => {
		const driftClient = get().driftClient.client;

		let referrerNameExists: boolean;
		try {
			const referrerNameAccount = await driftClient.fetchReferrerNameAccount(
				referrerParam
			);
			referrerNameExists = !!referrerNameAccount;
		} catch (err) {
			referrerNameExists = false;
		}

		if (referrerNameExists && !accountExists) {
			if (!fromLocalStorage) {
				safePush(`/onboarding/connect`);

				window?.localStorage?.setItem('referrerParam', referrerParam);
				window?.localStorage?.setItem(
					'referrerAppliedTimestamp',
					Date.now().toString()
				);
			}

			set((s) => {
				s.referrerParam = referrerParam;
			});
		} else {
			set((s) => {
				s.referrerParamFailed = true;
			});
		}
	};

	const handleGoogleAnalyticsStore = (query: ReadonlyURLSearchParams) => {
		if (
			query.get('utm_source') ||
			query.get('utm_medium') ||
			query.get('utm_campaign')
		) {
			set((s) => {
				s.utmParams = `utm_source=${
					(query.get('utm_source') as string) ?? ''
				}&utm_medium=${
					(query.get('utm_medium') as string) ?? ''
				}&utm_campaign=${(query.get('utm_campaign') as string) ?? ''}`;
			});
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setAutoconnectTimeoutElapsed(true);
		}, 5000);
	}, []);

	// Handle pushing posthog pageview event on route change
	useEffect(() => {
		if (pathname) {
			captureEvent('$pageview', {
				$current_url: window.location.href,
			});
		}
	}, [pathname]);

	useEffect(() => {
		const isNotTradePage = NonTradeRoutes.find((nonTradeRoute) =>
			pathname.includes(nonTradeRoute)
		);

		const isTradePage = !isNotTradePage;

		// shouldTrackTradePageStartupTimeMetrics is a oneWayValueJar so it will only let us set the value ONCE. This prevents us changing the value multiple times as this router instance is re-mounted. We only want to set this value based on the initial page route.
		DriftWindow.shouldTrackTradePageStartupTimeMetrics.set(isTradePage);
	}, [pathname]);

	// Handle route from router
	useEffect(() => {
		// try hide the feedback widget on page change
		// try {
		// 	//@ts-ignore
		// 	window?.FreshworksWidget('hide');
		// } catch (e) {
		// 	// widget not mounted yet, do nothing
		// }

		const pageRoute = PageRoutes.find((pageRoute) =>
			pathname.includes(pageRoute)
		);

		if (pageRoute !== undefined) {
			set((s) => {
				const routeChanged = s.currentPageRoute !== pageRoute;

				if (routeChanged) {
					s.currentPageRoute = pageRoute;
					// if somehow get in a state where we change page with a modal open, close the modal
					s.modals = {};
				}
			});
		}
	}, [pathname]);

	// Handle query params from router
	useEffect(() => {
		const storedReferrer = UI_UTILS.getStoredReferrerParam();

		const referralMatch = pathname.match(/ref\/(.+)($|\/)/);
		if (referralMatch?.[1]) {
			if (
				driftClientIsReady &&
				autoconnectTimeoutElapsed &&
				!hasHandledReferrerParam
			) {
				handleReferralPageLoad(referralMatch[1] as string);
				setHasHandledReferrerParam(true);
			}
		} else if (
			storedReferrer &&
			driftClientIsReady &&
			autoconnectTimeoutElapsed &&
			!hasHandledReferrerParam &&
			!searchParams.get('ref')
		) {
			handleReferralPageLoad(storedReferrer, true);
			setHasHandledReferrerParam(true);
		}

		if (searchParams || pathname) {
			// don't execute rest of logic if user onboarding
			if (pathname?.includes('/connect')) {
				set((s) => {
					s.authorityParam = '';
				});
				return;
			}

			const firstSubPath = pathname.split('/')[1];
			let possibleMarketSymbol = firstSubPath;

			if (firstSubPath === PageRoute.bet) {
				possibleMarketSymbol = pathname.split('/')[2];
			}

			if (
				(possibleMarketSymbol &&
					!PageRoutes.includes(possibleMarketSymbol as PageRoute)) ||
				searchParams.get('market')
			) {
				const query =
					possibleMarketSymbol ?? (searchParams.get('market') as string);

				const targetUiMarket = UI_UTILS.findMarketBySymbol(query, false, false);

				if (targetUiMarket) {
					switchMarket({
						marketIndex: targetUiMarket.market.marketIndex,
						marketType: targetUiMarket.marketType,
					});
				} else {
					const targetUiMarket2 = UI_UTILS.findMarketBySymbol(
						searchParams.get('market') as string,
						false,
						true
					);
					switchMarket({
						marketIndex: targetUiMarket2.market.marketIndex,
						marketType: targetUiMarket2.marketType,
					});
				}
			}

			if (searchParams.get('asset')) {
				const assetSymbol = searchParams.get('asset') as string;
				const targetAsset = CurrentSpotMarkets.slice(1).find(
					(bank) => bank.symbol.toLowerCase() == assetSymbol.toLowerCase()
				);
				if (targetAsset) {
					set((s) => {
						s.selectedAsset = targetAsset;
					});
				}
			}

			// Handle google analytics
			handleGoogleAnalyticsStore(searchParams);

			// # Handle referrer param
			if (searchParams.get('ref')) {
				if (
					driftClientIsReady &&
					autoconnectTimeoutElapsed &&
					!hasHandledReferrerParam
				) {
					handleReferralPageLoad(searchParams.get('ref') as string);
					setHasHandledReferrerParam(true);
				}
			}

			if (alreadyUsedRouteParams.current) return;

			if (AUTHORITY_OVERRIDE || searchParams.get('authority')) {
				// need driftclient ready to emulate
				if (driftClientIsReady) {
					alreadyUsedRouteParams.current = true;

					const authorityParam =
						AUTHORITY_OVERRIDE || searchParams.get('authority');
					// Must be trying to emulate an account
					handleAuthorityEmulation(authorityParam);
				}
			} else if (USER_OVERRIDE || searchParams.get('userAccount')) {
				// need driftclient ready to emulate
				if (driftClientIsReady) {
					alreadyUsedRouteParams.current = true;

					const userAccountParam =
						USER_OVERRIDE || searchParams.get('userAccount');

					handleUserAccountEmulation(userAccountParam as string);
				}
			} else {
				set((s) => {
					s.authorityParam = '';
				});
			}
		} else {
			set((s) => {
				s.authorityParam = '';
			});
		}
	}, [
		searchParams,
		driftClientIsReady,
		autoconnectTimeoutElapsed,
		hasHandledReferrerParam,
	]);

	return null;
};

export default useRouterConfig;
