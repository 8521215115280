'use client';

import { PublicKey } from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	MarketId,
	UIMarket,
	UISerializableOrderActionRecord,
	getSortScoreForOrderActionRecords,
	sortUIOrderActionRecords,
} from '@drift/common';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { dlog } from 'src/dev';
import { OrderMatcherContext } from 'src/providers/orderRecordMatcherProvider';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useDriftAccountsStore from '../stores/useDriftAccountsStore';
import useDriftActions from './useDriftActions';
import useDriftClientIsReady from './useDriftClientIsReady';
import useHandleReturnFromIdle from './useHandleReturnFromIdle';
import useLazySubAccounts from './useLazySubAccounts';
import useAppEventEmitter from './useAppEventEmitter';

const useMarketHistory = () => {
	const setDriftState = useDriftStore((s) => s.set);
	const getDriftState = useDriftStore((s) => s.get);
	const getAccountsState = useDriftAccountsStore((s) => s.get);
	const setAccountsState = useDriftAccountsStore((s) => s.set);
	const userAccounts = useLazySubAccounts();
	const driftClientIsReady = useDriftClientIsReady();
	const currentMarket = useDriftStore((s) => s.selectedMarket.current);
	const actions = useDriftActions();
	const orderMatcher = useContext(OrderMatcherContext);
	const appEventEmitter = useAppEventEmitter();

	const finishedInitialFetchForCurrentMarket = useRef(false);
	const isFetchingInitialFetchForCurrentMarket = useRef<UIMarket | null>(null);
	const currentMarketTrades = getDriftState().marketTradeHistory.trades;

	const tradeMatchesACurrentUserAccount = (
		pubKey = PublicKey.default
	): string => {
		if (!pubKey) return undefined;
		if (pubKey.equals(PublicKey.default)) return undefined;

		const targetAccount = userAccounts.find((account) =>
			account.pubKey.equals(pubKey)
		);

		if (targetAccount) {
			return targetAccount.userKey;
		}

		return undefined;
	};

	/**
	 * If the new trade for user is newer than any that we currently have in the history or is not in the history at all then we'll add it.
	 * @param trade
	 * @param userKey
	 */
	const addTradesToUserTradehistory = (
		trades: UISerializableOrderActionRecord[],
		userKey: string
	) => {
		const isPredictionMarket =
			trades[0] &&
			new UIMarket(trades[0].marketIndex, trades[0].marketType)
				.isPredictionMarket;

		const historyStoreKey = isPredictionMarket
			? 'predictionsTradeHistory'
			: 'tradeHistory';

		const userTrades = getAccountsState().accounts[userKey][historyStoreKey];

		const lastSeenUserTrade = userTrades.loadedUserTradeHistory[0];

		const validNewTrades = trades.filter((trade) => {
			const tradeIsNewer =
				!lastSeenUserTrade ||
				getSortScoreForOrderActionRecords(trade, lastSeenUserTrade) === 1;
			const tradeIsInHistory = userTrades.loadedUserTradeHistory.find(
				(tradeInHistory) =>
					orderMatcher.ordersAreIdentical(tradeInHistory, trade)
			);
			return tradeIsNewer || !tradeIsInHistory;
		});

		// If there are new trades, add em
		if (validNewTrades?.length > 1) {
			setAccountsState((s) => {
				const newTrades = sortUIOrderActionRecords([
					...userTrades.loadedUserTradeHistory,
					...validNewTrades,
				]) as UISerializableOrderActionRecord[];

				s.accounts[userKey][historyStoreKey].loadedUserTradeHistory = newTrades;
				s.accounts[userKey][historyStoreKey].userTradeHistoryTotalCount++;
			});
		} else {
			dlog('initial_load', `No new trades to add`);
		}
	};

	const handleTradesForUserHistory = (
		trades: UISerializableOrderActionRecord[]
	) => {
		// Find trades which belong to the current user
		const tradesAndMatchingUser = trades.map(
			(trade) =>
				[
					trade,
					tradeMatchesACurrentUserAccount(trade.taker) ||
						tradeMatchesACurrentUserAccount(trade.maker),
				] as [UISerializableOrderActionRecord, string]
		);

		const matchingUserKeys = [];

		const tradesToAdd = tradesAndMatchingUser.filter(
			([trade, matchingUserKey]) => {
				if (!matchingUserKey) return false;

				// Add trades to order history
				orderMatcher.addActionRecord(trade);

				if (!matchingUserKeys.includes(matchingUserKey)) {
					matchingUserKeys.push(matchingUserKey);
				}

				return true;
			}
		);

		matchingUserKeys.forEach((userKey) => {
			const userTradesToAdd = tradesToAdd
				.filter(([_trade, matchingUserKey]) => matchingUserKey === userKey)
				.map(([trade, _matchingUserKey]) => trade);

			addTradesToUserTradehistory(userTradesToAdd, userKey);
		});
	};

	const handleNewTradesResult = useCallback(
		({
			trades,
			mostRecentTradeScore,
		}: {
			trades: UISerializableOrderActionRecord[];
			mostRecentTradeScore: number;
		}) => {
			if (!trades || trades.length === 0 || !orderMatcher) {
				return;
			}

			setDriftState((s) => {
				// # Handle if any of the trades affect the current selected market history
				// Skip adding records to market history if they don't match the currently selected market at the time of adding it to the store
				if (
					s.selectedMarket.current.market.marketIndex !==
						currentMarket.market.marketIndex ||
					!ENUM_UTILS.match(
						s.selectedMarket.current.marketType,
						currentMarket.marketType
					)
				) {
					return;
				}

				const currentTrades = s.marketTradeHistory?.trades ?? [];
				const tradesToClean = [...trades, ...currentTrades];
				const newTradesState = orderMatcher.getSortedDedupedTrimmedRecords(
					tradesToClean.filter((trade) =>
						currentMarket.marketId.equals(
							new MarketId(trade.marketIndex, trade.marketType)
						)
					),
					50
				);

				s.marketTradeHistory = {
					market: currentMarket,
					trades: newTradesState,
					mostRecentTradeScore,
				};
			});

			// # Handle if any of the trades affect the user's order history
			handleTradesForUserHistory(trades);

			appEventEmitter.emit('recentTradesLoaded', currentMarket.key);
		},
		[currentMarket?.market?.symbol, currentMarketTrades, orderMatcher]
	);

	// reset state on market change
	useEffect(() => {
		finishedInitialFetchForCurrentMarket.current = false;
		actions.addLoadingItemToQueue({ key: 'recentTrades' });
		setDriftState((s) => {
			s.marketTradeHistory.market = currentMarket;
			s.marketTradeHistory.trades = [];
			s.marketTradeHistory.mostRecentTradeScore = 0;
		});
	}, [currentMarket?.market?.symbol]);

	// Whenever the driftClient or the current market changes, fetch the initial market history from the history server
	useEffect(() => {
		if (!driftClientIsReady) return;
		if (!orderMatcher) return;

		// when market index or clearing house changes, check if we need to clear previous trades and get new ones from history server
		if (
			!isFetchingInitialFetchForCurrentMarket.current ||
			!isFetchingInitialFetchForCurrentMarket.current.equals(currentMarket)
		) {
			fetchInitialMarketHistory();
		}
	}, [driftClientIsReady, currentMarket, orderMatcher]);

	const fetchInitialMarketHistory = useCallback(async () => {
		if (!orderMatcher) return;

		isFetchingInitialFetchForCurrentMarket.current = currentMarket;

		const newTradesResult = currentMarket
			? (await ExchangeHistoryClient.getRecentTrades(currentMarket.marketId))
					?.body?.data ?? { trades: [], mostRecentTradeScore: 0 }
			: { trades: [], mostRecentTradeScore: 0 };

		if (!currentMarket.equals(isFetchingInitialFetchForCurrentMarket.current)) {
			return;
		}

		if (!newTradesResult) {
			actions.removeLoadingItemFromQueue({ key: 'recentTrades' });
			return;
		}

		const trades = sortUIOrderActionRecords(
			newTradesResult.trades
		) as UISerializableOrderActionRecord[];
		const mostRecentTradeScore = newTradesResult.mostRecentTradeScore;

		handleNewTradesResult({ trades, mostRecentTradeScore });

		actions.removeLoadingItemFromQueue({ key: 'recentTrades' });

		finishedInitialFetchForCurrentMarket.current = true;
	}, [currentMarket?.market?.symbol, actions, orderMatcher]);

	const refetchInitialMarketHistory = useCallback(() => {
		setDriftState((s) => {
			s.marketTradeHistory.market = currentMarket;
			s.marketTradeHistory.trades = [];
			s.marketTradeHistory.mostRecentTradeScore = 0;
			s.loadingElements.recentTrades.isLoading = true;
		});

		fetchInitialMarketHistory();
	}, [fetchInitialMarketHistory]);

	useHandleReturnFromIdle(refetchInitialMarketHistory);
};

export default useMarketHistory;
