'use client';

import { BigNum, QUOTE_PRECISION_EXP, User } from '@drift-labs/sdk';
import useLazySubAccounts from './useLazySubAccounts';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

export const getTotalAllTimePnlForUserAccount = (user: User) => {
	const netBankValue = user.getNetSpotMarketValue();
	const unrealizedPnl = user.getUnrealizedPNL(true, undefined, undefined);

	const netUsdValue = netBankValue.add(unrealizedPnl);
	const totalDeposits = user.getUserAccount().totalDeposits;
	const totalWithdraws = user.getUserAccount().totalWithdraws;

	const totalPnl = netUsdValue.add(totalWithdraws).sub(totalDeposits);

	return BigNum.from(totalPnl, QUOTE_PRECISION_EXP);
};

const useTotalPnl = (aggregate = false) => {
	const currentUserKey = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.userKey
	);

	const accountsToUse = useLazySubAccounts()
		.filter((acct) => (aggregate ? true : acct.userKey === currentUserKey))
		.map((account) => account.client);

	const totalPnl = accountsToUse.reduce((prevTotal, user) => {
		return (
			prevTotal +
			(user.isSubscribed ? getTotalAllTimePnlForUserAccount(user).toNum() : 0)
		);
	}, 0);

	return totalPnl;
};

export default useTotalPnl;
