'use client';

import {
	BigNum,
	QUOTE_PRECISION_EXP,
	AMM_RESERVE_PRECISION_EXP,
	MarketType,
} from '@drift-labs/sdk';
import useAccountExists from 'src/hooks/useAccountExists';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import React, { useEffect, useState } from 'react';
import { RemoveLpInput } from '../Inputs/CollateralInput';
import LabelledInput from '../Inputs/LabelledInput';
import Select from '../Inputs/Select';
import Utility from '../Inputs/Utility';
import Modal from '../Modals/Modal';
import Text from '../Text/Text';
import ValueDisplay from '../ValueDisplay';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useTargetAccountData from '../../hooks/useTargetAccountData';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { LiquidityPool } from 'src/hooks/useLiquidityPools';
import Tooltip from '../Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';

const RemoveLiquidityModalContent = (props: { onClose: () => void }) => {
	// # Constants
	const availableSubaccounts = useLazySubAccounts();
	const actions = useDriftActions();
	const accountExists = useAccountExists();
	const selectedTargetAccountKey = useDriftAccountStore(
		(s) => s.currentUserKey
	);
	const targetAccount = useTargetAccountData(selectedTargetAccountKey);
	const setAccountState = useDriftAccountStore((s) => s.set);
	const driftClientIsReady = useDriftClientIsReady();
	const lpData = useDriftStore((s) => s.liquidityPoolInfo);
	const allLiqPools = lpData?.pools ?? [];
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const { captureEvent } = usePostHogCapture();
	const isMobile = useIsMobileScreenSize();

	// # State
	const [targetAccountKey, setTargetAccountKey] = useState(
		selectedTargetAccountKey
	);

	const [selectedPool, setSelectedPool] = useState<LiquidityPool>(
		allLiqPools?.find(
			(pool) =>
				pool.marketConfig.marketIndex === selectedMarket?.market.marketIndex
		)
	);

	const [sharesToRemove, setSharesToRemove] = useState('');

	const [submitting, setSubmitting] = useState(false);

	const [maxButtonTransition, setMaxButtonTransition] = useState(false);

	const [maxSelected, setMaxSelected] = useState(false);

	const [lpSharesAmountToRemoveBigNum, setLpSharesAmountToRemoveBigNum] =
		useState(BigNum.zero(AMM_RESERVE_PRECISION_EXP));

	// # State-Dependent constants
	const currentPoolSharesBalance =
		selectedPool?.userLpShares ?? BigNum.zero(AMM_RESERVE_PRECISION_EXP);
	const currentPoolQuoteValue =
		selectedPool?.userQuoteValue ?? BigNum.zero(QUOTE_PRECISION_EXP);

	const afterSharesAmount = maxSelected
		? BigNum.zero(AMM_RESERVE_PRECISION_EXP)
		: BigNum.max(
				currentPoolSharesBalance.sub(lpSharesAmountToRemoveBigNum),
				BigNum.zero(AMM_RESERVE_PRECISION_EXP)
		  );

	const exceededMax = lpSharesAmountToRemoveBigNum.gt(currentPoolSharesBalance);

	const allowConfirmation =
		!exceededMax &&
		accountExists &&
		!submitting &&
		currentPoolQuoteValue &&
		currentPoolQuoteValue.gtZero() &&
		sharesToRemove &&
		lpSharesAmountToRemoveBigNum.gtZero();

	// # Effect Hooks
	useEffect(() => {
		captureEvent('opened_remove_lp_modal');
	}, []);

	useEffect(() => {
		const newSharesmountToRemoveBigNum = BigNum.fromPrint(
			sharesToRemove,
			AMM_RESERVE_PRECISION_EXP
		);
		setLpSharesAmountToRemoveBigNum(newSharesmountToRemoveBigNum);
	}, [
		sharesToRemove,
		driftClientIsReady,
		selectedPool?.marketConfig?.marketIndex,
	]);

	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setSharesToRemove('');
	}, [selectedPool?.marketConfig?.marketIndex]);

	useEffect(() => {
		if (exceededMax) {
			setSharesToRemove(currentPoolSharesBalance.printShort());
		}
	}, [exceededMax]);

	useEffect(() => {
		setSelectedPool(
			allLiqPools?.find(
				(pool) =>
					pool.marketConfig.marketIndex === selectedMarket?.market.marketIndex
			)
		);
	}, [lpData?.currentUserKey]);

	// # Methods
	const updateCurrentPool = (newPool: LiquidityPool) => {
		setSelectedPool(newPool);
		actions.switchMarket({
			marketIndex: newPool?.marketConfig.marketIndex,
			marketType: MarketType.PERP,
		});
	};

	const handleRemoveLiq = async () => {
		setSubmitting(true);
		actions.addLoadingItemToQueue({ key: 'modal' });

		const lpSharesAmount = maxSelected
			? currentPoolSharesBalance
			: lpSharesAmountToRemoveBigNum;

		captureEvent('submitted_remove_lp', {
			lp_symbol: selectedPool.marketConfig.baseAssetSymbol,
		});

		const userAccountToRemoveLiq = targetAccount.client.getUserAccount();

		await actions.removePerpLpShares({
			userAccount: userAccountToRemoveLiq,
			amount: lpSharesAmount.val,
			marketIndex: selectedPool.marketConfig.marketIndex,
		});

		setSubmitting(false);
		actions.removeLoadingItemFromQueue({ key: 'modal' });
		props.onClose();
	};

	const handleValueChange = (newValue: string) => {
		setMaxSelected(false);
		setSharesToRemove(newValue);
	};

	const handleMaxSelected = () => {
		setSharesToRemove(currentPoolSharesBalance.printShort());
		setMaxSelected(true);
	};

	const handleTargetAccountChange = (newAccountKey: string) => {
		setTargetAccountKey(newAccountKey);
		setAccountState((s) => {
			s.currentUserKey = newAccountKey;
		});
	};

	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					<div className="items-center block">
						<Text.P1 className="mr-1 text-text-emphasis">
							Removing your liquidity will burn your BAL shares and
							proportionally reduce any open BAL positions.
						</Text.P1>
					</div>
					<Utility.VERTSPACERXL />

					<>
						<LabelledInput label="Remove Liquidity From">
							<Select.Subaccount
								id="lpAccountSelection"
								onChange={handleTargetAccountChange}
								options={availableSubaccounts}
								initialSelection={targetAccountKey}
								includeDelegates
							/>
						</LabelledInput>
						<Utility.VERTSPACERXL />
					</>

					{selectedPool && (
						<RemoveLpInput
							direction="remove"
							allLiqPools={allLiqPools}
							selectedPool={selectedPool}
							maxAmount={currentPoolSharesBalance}
							onChangePool={updateCurrentPool}
							label="Market & Liquidity to Remove"
							value={sharesToRemove}
							onChangeValue={handleValueChange}
							amountLabel={'Shares Available'}
							onMax={handleMaxSelected}
							exceededMax={exceededMax}
							disabled={currentPoolSharesBalance.eqZero()}
							showMaxLabel
							isMobile={isMobile}
						/>
					)}

					<Utility.VERTSPACERL />

					<Utility.VERTSPACERM />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />
					<Text.BODY3 className="text-text-default">
						<ValueDisplay.ValueChange
							label={
								<Text.BODY3 className="text-text-default">
									BAL Shares
								</Text.BODY3>
							}
							previousValue={currentPoolSharesBalance}
							afterValue={afterSharesAmount}
							previousValuePrint={currentPoolSharesBalance.prettyPrint()}
							afterValuePrint={afterSharesAmount.prettyPrint()}
						/>
					</Text.BODY3>
					<Utility.VERTSPACERXS />
					<ValueDisplay.Default
						label={
							<div className="flex items-center gap-1 text-text-default">
								<Text.BODY3>Removal Burn Fee</Text.BODY3>
								{selectedPool?.removalBurnCost.gtZero() && (
									<Tooltip
										content={
											<span>{`Your Residual Base Asset Amount (${selectedPool?.remainderBaseAmount
												.abs()
												.printShort()}) is below the market's Order Step Size and is burned to ensured solvency`}</span>
										}
									>
										<Info />
									</Tooltip>
								)}
							</div>
						}
						value={
							<Text.BODY3 className="text-text-default">
								{selectedPool?.removalBurnCost.toNotional(true)}
							</Text.BODY3>
						}
					/>

					<Utility.VERTSPACERL />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleRemoveLiq}
						customLabel={'Remove Liquidity'}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(RemoveLiquidityModalContent);
