'use client';

import { ComponentType } from 'react';
import { twMerge } from 'tailwind-merge';
import useShowAccountValues from '../hooks/useShowAccountValues';
import ShowHideBalanceButton from './ShowHideBalancesButton';

const ToggleableAccountValue = ({
	value,
	Component,
	className,
}: {
	value: string;
	Component?: ComponentType<{ children: string }>;
	className?: string;
}) => {
	const showAccountValues = useShowAccountValues();

	return (
		<span className={twMerge('flex items-center', className)}>
			{Component && (
				<Component>{showAccountValues ? value : '*****'}</Component>
			)}
			{!Component && <span>{showAccountValues ? value : '*****'}</span>}
			<ShowHideBalanceButton className="ml-1" />
		</span>
	);
};

export default ToggleableAccountValue;
