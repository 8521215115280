import React, { PropsWithChildren } from 'react';

/**
 * Shortcut to apply an svg to a div's background. Just give the path to the SVG.
 * @returns
 */
const SvgBgDiv = ({
	svgPath,
	children,
	...rest
}: PropsWithChildren<
	{
		svgPath: string;
	} & React.HTMLProps<HTMLDivElement>
>) => {
	return (
		<div
			style={{
				backgroundImage: `url(${svgPath})`,
				backgroundSize: 'cover',
			}}
			{...rest}
		>
			{children}
		</div>
	);
};

export default SvgBgDiv;
