import React from 'react';
import ModalBackground from 'src/components/Modals/ModalBackground';

/**
 * To prevent jankiness we use a "shared" modal background for all modals. This prevents the graphical jankiness from sometimes quickly displaying the bright background when switching between two active modals. We use the customZIndex of 50 here because by default it uses 30 but there are some UI elements which briefly use a higher z-index than 30, which also causes jankiness.
 * @returns
 */
const SharedModalBackground = () => {
	return (
		<ModalBackground
			key={'modal_background'}
			onClose={() => {}}
			contentRef={null}
			customZIndex="z-50"
			isSharedBackground
		/>
	);
};

export default SharedModalBackground;
