import React from 'react';
// import useDriftStore from '../stores/DriftStore/useDriftStore';
import Modal from './Modals/Modal';
import WalletConnectModalContent from './WalletConnectModalContent';

const WalletConnectModal = () => {
	return (
		<Modal onClose={() => {}}>
			<Modal.Body>
				<WalletConnectModalContent />
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(WalletConnectModal);
