'use client';

import { useState, useEffect, useMemo } from 'react';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from '../Text/Text';
import { QRCodeSVG } from 'qrcode.react';
import Modal from './Modal';
import UI_UTILS from 'src/utils/uiUtils';
import { Copy } from '@drift-labs/icons';

const ReceiveToWalletModal = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	const wallet = useCurrentWalletAdapter();
	const shortAddress = UI_UTILS.abbreviateAddress(wallet?.publicKey);
	const [copied, setCopied] = useState(false);
	// const backModal = useDriftStore(
	// 	(s) => s.modalsProps.showSendFromWalletModal?.backModal
	// );

	const copyWalletAddress = () => {
		if (!copied && wallet?.publicKey) {
			UI_UTILS.copyToClipboard(wallet.publicKey.toString());
			setCopied(true);
		}
	};

	useEffect(() => {
		let resetCopiedTimeout: ReturnType<typeof setTimeout>;
		if (copied) {
			resetCopiedTimeout = setTimeout(() => {
				setCopied(false);
				resetCopiedTimeout = null;
			}, 3000);
		}
		return () => clearTimeout(resetCopiedTimeout);
	}, [copied]);

	const onClose = () => {
		setDriftStore((s) => {
			s.modals.showReceiveToWalletModal = false;
		});
	};

	const walletString = useMemo(() => {
		return wallet?.publicKey?.toString();
	}, [wallet?.publicKey]);

	return (
		<Modal onClose={onClose}>
			<Modal.Body className="bg-container-bg">
				<Modal.Header onClose={onClose}>
					<Text.H3>Receive Assets</Text.H3>
				</Modal.Header>
				<div className="flex flex-col items-center justify-center p-6">
					<div className="rounded bg-[#fff] p-2 my-2">
						<QRCodeSVG value={walletString} />
					</div>
					<div
						className="mt-6 mb-2 bg-input-bg-hover border-solid border-1 rounded-sm border-input-border-hover cursor-pointer px-4 pt-1.5 pb-2"
						onClick={copyWalletAddress}
					>
						<Text.BODY2 className="text-text-default">
							<div className="inline-block mr-4">{shortAddress}</div>
							<span className="text-text-label">
								<div className="relative inline-block w-4 h-4 top-1">
									<Copy />
								</div>
								<div className="inline-block ml-1">
									{copied ? 'Copied!' : 'Copy'}
								</div>
							</span>
						</Text.BODY2>
					</div>
					<div className="px-6 pt-4 pb-6 text-center text-text-default">
						<Text.BODY3>
							This address can only be used to receive assets via the Solana
							network.
						</Text.BODY3>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ReceiveToWalletModal;
