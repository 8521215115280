// wagmi is a library used to connect to evm wallets e.g. Metamask, docs: https://wagmi.sh/react

import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { publicProvider } from '@wagmi/core/providers/public';
import { CCTP_SUPPORTED_CHAINS } from 'src/constants/cctp';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

const { chains, publicClient, webSocketPublicClient } = configureChains(
	CCTP_SUPPORTED_CHAINS,
	[publicProvider()]
);

const wagmiConfig = createConfig({
	autoConnect: false,
	publicClient,
	webSocketPublicClient,
	connectors: [
		new MetaMaskConnector({ chains }),
		new CoinbaseWalletConnector({
			chains,
			options: {
				appName: 'Drift',
			},
		}),
	],
});

const WagmiProvider = ({ children }: { children: React.ReactNode }) => {
	return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
};

export default WagmiProvider;
