'use client';

import React, { useEffect, useState } from 'react';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import { MagicAuthAdapter } from 'src/utils/magicAuthAdapter';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import DriftGradientIcon from './Icons/DriftGradientIcon';
import SolanaTextLogo from './Icons/SolanaTextLogo';
import LoadingDots from './LoadingDots';
import Modal from './Modals/Modal';
import Text from './Text/Text';
import useHandleSelectWalletProvider from '../hooks/useHandleSelectWalletProvider';
import {
	DRIFT_CUSTOM_WALLET_ICONS,
	DRIFT_CUSTOM_WALLET_OPTIONS,
} from 'src/constants/wallets';
import useCurrentWalletIconSrc from 'src/hooks/useCurrentWalletIconSrc';

/**
 * Now this is only used as a "Waiting for wallet connection" modal, not to select which wallet provider to use. Selecting wallet provider to use is now done in the ConnectWalletPanel component inside of OnboardingPageContent.
 */
const WalletConnectModalContent = () => {
	const isMagicAuth = useDriftStore((s) => s.wallet?.isMagicAuth);
	const isMetamask = useDriftStore((s) => s.wallet?.isMetamask);
	const isAppKit = useDriftStore((s) => s.wallet?.isAppKit);
	const wallet = useCurrentWalletAdapter();
	const { handleDeselectProvider } = useHandleSelectWalletProvider();
	const [showX, setShowX] = useState(false);

	const selectedProvider = useDriftStore((s) => s.wallet?.current?.adapter);

	const currentWalletIconSrc = useCurrentWalletIconSrc();

	let connectingStatusText = `Please connect your ${selectedProvider?.name} wallet.`;

	if (isMagicAuth) {
		const magicWallet = wallet as MagicAuthAdapter;
		if (magicWallet.checkingLoginStatus) {
			connectingStatusText = 'Retrieving account information';
		} else if (magicWallet.isLoggedIn) {
			connectingStatusText = 'Signing in';
		} else {
			connectingStatusText = 'Signing in with email code';
		}
	}

	const nonBgProviders = ['SolFlare', 'Ledger', 'Math Wallet', 'Solong'];

	const handleClose = () => {
		handleDeselectProvider();
	};

	// Show the X / close button after a delay -- don't really want ppl to click this unless their wallet is timing out
	useEffect(() => {
		const showXTimer = setTimeout(() => {
			setShowX(true);
		}, 1000);

		return () => {
			clearTimeout(showXTimer);
		};
	}, []);

	return (
		<>
			<Modal.Header
				center
				onClose={showX ? handleClose : () => {}}
				showX={showX}
			>
				<div>
					{isMetamask ? (
						<Text.H2>Connecting with MetaMask</Text.H2>
					) : (
						<Text.H2>
							Connect your
							<div className="inline-block px-2">
								<SolanaTextLogo className="text-text-emphasis" />
							</div>
							Wallet
						</Text.H2>
					)}
				</div>
			</Modal.Header>
			<Modal.Content>
				{!isMetamask && (
					<div className="mt-4 mb-2 text-2xl text-center">
						{isMagicAuth ? 'Connecting to Magic Link' : 'Connecting...'}
					</div>
				)}
				{isMetamask ? (
					<div className="p-6 text-center text-text-emphasis">
						<Text.BODY1>
							Before connecting, you&apos;ll be asked to install the Drift
							Connect Snap if you haven’t already.
						</Text.BODY1>
					</div>
				) : isAppKit ? (
					<div className="px-6 mb-8 text-center text-l text-text-emphasis">
						Connecting to{' '}
						{DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME}
					</div>
				) : (
					<div className="px-6 mb-8 text-center text-l text-text-emphasis">
						{connectingStatusText}
					</div>
				)}
				<div className="flex items-center justify-center w-full mb-4 space-x-8">
					<div className="relative overflow-hidden border rounded-full border-neutrals-60">
						<div
							className={`w-12 h-12 flex items-center justify-center ${
								nonBgProviders.includes(selectedProvider?.name) && 'p-3'
							}`}
						>
							{isMagicAuth ? (
								<img
									src={DRIFT_CUSTOM_WALLET_ICONS.MAGIC_AUTH_WALLET_ADAPTER}
									alt={'Magic Wallet'}
									className="w-full h-full"
								/>
							) : (
								currentWalletIconSrc && (
									<img src={currentWalletIconSrc} className="w-8 h-8" />
								)
							)}
						</div>
					</div>
					<LoadingDots />
					<div className="relative w-12 h-12 p-3 border rounded-full border-neutrals-60">
						<DriftGradientIcon className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 w-7 h-7" />
					</div>
				</div>
			</Modal.Content>
			{isMetamask && (
				<div className="w-full px-12 py-6 mt-20 text-center bg-container-bg-hover text-text-default">
					<Text.BODY3>
						Snaps extends the power of the MetaMask platform, enabling users to
						interact with dApps on any blockchain.{' '}
						<a
							href="https://metamask.io/snaps/"
							target="_blank"
							rel="noreferrer"
						>
							Learn more
						</a>
					</Text.BODY3>
				</div>
			)}
		</>
	);
};

export default React.memo(WalletConnectModalContent);
