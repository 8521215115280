'use client';

import { useState } from 'react';
import { useInterval } from 'react-use';
import useDriftStore from '../../stores/DriftStore/useDriftStore';

const useRecentTimeouts = (basePollingRate: number) => {
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const [timeoutCount, setTimeoutCount] = useState(0);

	// keep timeout count from last 10 polls
	useInterval(() => {
		if (driftClient?.txSender) {
			setTimeoutCount(
				Math.max(0, driftClient.txSender.getTimeoutCount() - timeoutCount)
			);
		}
	}, basePollingRate * 10);

	return timeoutCount;
};

export default useRecentTimeouts;
