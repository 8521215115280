'use client';

import { useEffect } from 'react';
import Button from 'src/components/Button';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import { notify } from 'src/utils/notifications';
import { useImmer } from 'use-immer';
import { useAccountsBeingLiquidatedWithoutBankruptcies } from './useAccountsBeingLiquidated';

// Limit alerts per user to once every 30 seconds
const ALERT_RATE_LIMIT_MS = 30 * 1000;

const useLiquidationAlerts = () => {
	const actions = useDriftActions();
	const userAccounts = useLazySubAccounts();
	const accountsBeingLiquidated =
		useAccountsBeingLiquidatedWithoutBankruptcies();

	const hasMultipleAccounts = userAccounts.length > 0;

	const [lastShownLiqAlertTs, setLastShownLiqAlertTs] = useImmer<
		Record<number, number>
	>({});

	useEffect(() => {
		const now = Date.now();

		// Look for new alerts which need to be displayed
		const liqAlertsToAdd = accountsBeingLiquidated.filter(
			(account) =>
				!lastShownLiqAlertTs[account.userKey] ||
				lastShownLiqAlertTs[account.userKey] + ALERT_RATE_LIMIT_MS < now
		);

		liqAlertsToAdd.forEach((alertToAdd) => {
			const alertId = `alertOpen_${alertToAdd.userKey}`;

			// Skip adding this alert if it breaks the rate limit
			const shouldSkipAlert =
				lastShownLiqAlertTs[alertToAdd.userKey] &&
				lastShownLiqAlertTs[alertToAdd.userKey] + ALERT_RATE_LIMIT_MS > now;

			if (shouldSkipAlert) return;

			// Otherwise we're good to add it, with a 5 second timer before it disappears
			setLastShownLiqAlertTs((s) => {
				s[alertToAdd.userKey] = now;
			});

			notify({
				type: 'awaiting',
				bgType: 'error',
				id: alertId,
				message: hasMultipleAccounts
					? `Your account is being liquidated`
					: `your account '${alertToAdd.name}' is being liquidated`,
				description: `Any assets and liabilities can be transferred to bring your account health back in good standing. To prevent further liquidation, deposit more assets.`,
				showUntilCancelled: true,
				updatePrevious: true,
				action: {
					type: 'custom',
					content: (
						<div className="flex items-center space-x-2">
							<Button.Secondary
								size="SMALL"
								onClick={() => {
									actions.showDepositModal(0, alertToAdd.userKey, true);
								}}
							>
								Deposit
							</Button.Secondary>
							<a
								href="https://docs.drift.trade/liquidations/liquidations"
								className="text-darkBlue-30 hover:text-th-white"
								target="_blank"
								rel="noreferrer"
							>
								Learn More
							</a>
						</div>
					),
				},
			});
		});

		// Handle hiding alerts for these accounts
	}, [accountsBeingLiquidated, hasMultipleAccounts]);
};

export default useLiquidationAlerts;
