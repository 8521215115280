import { UiVaultConfig } from 'src/@types/vaults';
import MarketIcon from '../Utils/MarketIcon';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';

export const VaultManagerIcon = ({
	uiVaultConfig,
	size = 24,
	assetSize,
}: {
	uiVaultConfig: UiVaultConfig;
	size?: number;
	assetSize?: number;
}) => {
	const assetsOperatedOn = uiVaultConfig.assetsOperatedOn;

	const assetSizeToUse = assetSize ?? size / 2;

	return (
		<div className="relative">
			<img
				src={uiVaultConfig.vaultManager.imgSrc}
				alt={uiVaultConfig.name}
				style={{
					width: size,
					height: size,
					minWidth: size,
					minHeight: size,
				}}
			/>
			{assetsOperatedOn.map((marketIndex, index) => (
				<MarketIcon
					key={marketIndex}
					marketSymbol={CurrentSpotMarkets[marketIndex].symbol}
					style={{
						width: assetSizeToUse,
						height: assetSizeToUse,
						right: index * (assetSizeToUse / 2) - 4,
						bottom: -4,
						zIndex: assetsOperatedOn.length - index,
					}}
					className="absolute bottom-0"
				/>
			))}
		</div>
	);
};
