'use client';

import Text from 'src/components/Text/Text';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Modal from './Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import InlineLoadingBar from '../InlineLoadingBar/InlineLoadingBar';
import { PERP_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useInfoForCurrentlySelectedMarket from 'src/hooks/useInfoForCurrentlySelectedMarket';
import { ButtonGroup } from '../Button';
import ColourCodedValue from '../Utils/ColourCodedValue';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import UI_UTILS from 'src/utils/uiUtils';
import {
	PerformanceDataSet,
	DEFAULT_PERFORMANCE_DATA,
	PnLTimeSeriesDataPoint,
	PERFORMANCE_TIMEFRAMES,
	PnlTimePeriodOption,
	PERFORMANCE_DAYS_AGO,
	PERFORMANCE_VIEW_OPTIONS,
} from 'src/@types/types';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import dynamic from 'next/dynamic';
import { Open } from '@drift-labs/icons';

const PnlChart = dynamic(() => import('../PnlChart'));

const DEFAULT_INDEX = 2;

const timeFrameToGrafanaMapping = {
	'24h': '24h',
	'7d': '7d',
	'30d': '30d',
	'6m': '90d',
	all: '90d',
};

const LpPerformanceModal = (props: { timeframeIndex: number }) => {
	const setState = useDriftStore((s) => s.set);
	const marketInfo = useInfoForCurrentlySelectedMarket();
	const isMobile = useIsMobileScreenSize();
	const { marketIndex, baseDisplayDecimals } = marketInfo.info.genericInfo;

	const allMarketLpPerformancePct = useRef<PerformanceDataSet>(
		DEFAULT_PERFORMANCE_DATA
	);
	const allMarketLpPerformanceNotional = useRef<PerformanceDataSet>(
		DEFAULT_PERFORMANCE_DATA
	);

	const [chartDataPct, setChartDataPct] = useState<PnLTimeSeriesDataPoint[]>();
	const [chartDataNotional, setChartDataNotional] =
		useState<PnLTimeSeriesDataPoint[]>();

	const [selectedView, setSelectedView] = useState('pct');
	const [selectedTimeFrame, setSelectedTimeFrame] = useState(
		PERFORMANCE_TIMEFRAMES[props?.timeframeIndex ?? DEFAULT_INDEX]
	);

	const onClose = useCallback(() => {
		setState((s) => {
			s.modals.showLpPerformanceModal7d = false;
			s.modals.showLpPerformanceModal30d = false;
		});
	}, []);

	const getAndSetPctPoints = (timeFrame: PnlTimePeriodOption) => {
		ExchangeHistoryClient.getLpPerformanceData(
			marketIndex,
			'pct',
			PERFORMANCE_DAYS_AGO[timeFrame]
		).then((result) => {
			if (result.success) {
				const lpDataPct = result?.body.data.map((dataPoint) => {
					return {
						date: new Date(dataPoint[0] * 1000),
						totalPnl: dataPoint[1].includes('e')
							? 0
							: BigNum.fromPrint(dataPoint[1], QUOTE_PRECISION_EXP).toNum(),
					};
				});

				allMarketLpPerformancePct.current[timeFrame] = lpDataPct;
				setChartDataPct(lpDataPct);
			}
		});
	};

	const getAndSetNotionalPoints = (timeFrame: PnlTimePeriodOption) => {
		ExchangeHistoryClient.getLpPerformanceData(
			marketIndex,
			'raw',
			PERFORMANCE_DAYS_AGO[timeFrame]
		).then((result) => {
			if (result.success) {
				const lpDataNotional = result?.body.data.map((dataPoint) => {
					return {
						date: new Date(dataPoint[0] * 1000),
						totalPnl: dataPoint[1].includes('e')
							? 0
							: BigNum.fromPrint(dataPoint[1], QUOTE_PRECISION_EXP).toNum(),
					};
				});

				allMarketLpPerformanceNotional.current[timeFrame] = lpDataNotional;
				setChartDataNotional(lpDataNotional);
			}
		});
	};

	useEffect(() => {
		allMarketLpPerformanceNotional.current = DEFAULT_PERFORMANCE_DATA;
		allMarketLpPerformancePct.current = DEFAULT_PERFORMANCE_DATA;
		setChartDataNotional(undefined);
		setChartDataPct(undefined);
		setSelectedTimeFrame(
			PERFORMANCE_TIMEFRAMES[props?.timeframeIndex ?? DEFAULT_INDEX]
		);
		setSelectedView('pct');

		getAndSetPctPoints(
			PERFORMANCE_TIMEFRAMES[props?.timeframeIndex ?? DEFAULT_INDEX]
		);
	}, [marketIndex]);

	useEffect(() => {
		// only call history server again if we havent already fetched this data type + timeframe
		if (selectedView === 'raw') {
			if (allMarketLpPerformanceNotional[selectedTimeFrame]) {
				setChartDataNotional(allMarketLpPerformanceNotional[selectedTimeFrame]);
			} else {
				setChartDataNotional(undefined);
				getAndSetNotionalPoints(selectedTimeFrame);
			}
		} else {
			if (allMarketLpPerformancePct[selectedTimeFrame]) {
				setChartDataPct(allMarketLpPerformanceNotional[selectedTimeFrame]);
			} else {
				setChartDataPct(undefined);
				getAndSetPctPoints(selectedTimeFrame);
			}
		}
	}, [selectedView, selectedTimeFrame]);

	const isLoading =
		(selectedView === 'pct' && !chartDataPct) ||
		(selectedView === 'raw' && !chartDataNotional);
	const chartLabel = selectedView === 'pct' ? 'ROI' : 'Profit Per Share';
	const chartData = selectedView === 'pct' ? chartDataPct : chartDataNotional;

	return (
		<Modal
			onClose={onClose}
			id={`lp_performance_modal_${marketIndex}`}
			sizeConfig={{ xs: 12, sm: 6, md: 8, xl: 6 }}
			className="p-4"
		>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>
						{`${PERP_MARKETS_LOOKUP[marketIndex].symbol} LP: Historical Performance`}
					</Text.H2>
				</Modal.Header>

				<div className="items-center justify-start flex-grow w-full px-4 pb-2">
					<Text.H1>
						<span className="text-2xl whitespace-nowrap">
							{chartData?.length ? (
								<ColourCodedValue
									value={chartData[chartData.length - 1].totalPnl}
								>
									{selectedView === 'raw'
										? UI_UTILS.toNotional(
												chartData[chartData.length - 1].totalPnl
										  )
										: `${chartData[chartData.length - 1].totalPnl?.toFixed(
												2
										  )}%`}
								</ColourCodedValue>
							) : (
								<SkeletonValuePlaceholder
									className="w-12 h-6"
									loading={false}
								/>
							)}
						</span>
					</Text.H1>
					<Text.BODY3 className="py-1">
						<a
							href={`https://metrics.drift.trade/d/b56aa920-c714-4760-a0de-e69e9db17cf1/vamm-lp-performance?orgId=1&var-market=${PERP_MARKETS_LOOKUP[marketIndex].symbol}&from=now-${timeFrameToGrafanaMapping[selectedTimeFrame]}&to=now`}
							target="_blank"
							rel="noreferrer"
							className="flex flex-row items-center text-purple-40"
						>
							<span>Detailed Performance</span>
							<Open size={16} className="ml-1" />
						</a>
					</Text.BODY3>
				</div>

				<div className="items-center justify-center flex-grow min-h-[430px] max-h-[430px] w-full px-4 pb-4 pt-1">
					{isLoading ? (
						<div
							className={`flex flex-col justify-center items-center h-[430px] w-full text-center text-th-fgd-3 rounded-md`}
						>
							<InlineLoadingBar />
						</div>
					) : (
						<PnlChart
							id="lp-performance"
							data={chartData}
							field={'totalPnl'}
							label={chartLabel}
							positive={false}
							decimalsToShow={baseDisplayDecimals}
							isInModal={true}
							nonNotional={selectedView === 'pct'}
							isPercent={selectedView === 'pct'}
							className="min-h-[430px]"
							isHourly={selectedTimeFrame === '24h'}
						/>
					)}
				</div>
				<div
					className={
						isMobile
							? 'block justify-start'
							: 'inline-flex items-center justify-between w-full'
					}
				>
					<div>
						<ButtonGroup.Segmented
							className={isMobile ? 'm-0.5' : 'm-4'}
							options={PERFORMANCE_TIMEFRAMES.map((label) => {
								return {
									label: label === 'all' ? '90d' : label,
									value: label,
								};
							})}
							selected={selectedTimeFrame}
							size="MEDIUM"
							selectAction={(val) =>
								setSelectedTimeFrame(val as PnlTimePeriodOption)
							}
						/>
					</div>
					<div>
						<ButtonGroup.Segmented
							className={isMobile ? 'm-0.5' : 'm-4'}
							options={PERFORMANCE_VIEW_OPTIONS}
							selected={selectedView}
							size="MEDIUM"
							selectAction={(val: string) => setSelectedView(val)}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LpPerformanceModal;
