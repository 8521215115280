'use client';

import { BigNum, BN, FOUR, TWO, UserStatus, ZERO } from '@drift-labs/sdk';
import { useEffect } from 'react';
import useDriftAccountStore, {
	checkIfMarginInfosAreEqual,
} from 'src/stores/useDriftAccountsStore';
import useTickedAllSubAccounts from './useTickedAllSubAccounts';
import useInterval from './useInterval';
import useWalletIsConnected from './useWalletIsConnected';

const useMarginInfo = () => {
	const set = useDriftAccountStore((s) => s.set);
	const accounts = useTickedAllSubAccounts();
	const connected = useWalletIsConnected();

	const setMarginInfo = () => {
		if (connected) {
			set((s) => {
				accounts.forEach((acct) => {
					if (acct.client && acct.client.isSubscribed) {
						let userMarginRatio = acct.client.getMarginRatio() ?? ZERO;
						// weird to display massive fraction when really it just means that their position is zero
						if (userMarginRatio.eq(new BN(Number.MAX_SAFE_INTEGER)))
							userMarginRatio = ZERO;

						const netBankValue = acct.client.getNetSpotMarketValue();
						const unrealizedPnl = acct.client.getUnrealizedPNL(
							true,
							undefined,
							undefined
						);

						const netUsdValue = netBankValue.add(unrealizedPnl);
						const freeCollateral = acct.client.getFreeCollateral();
						const maintenanceFreeCollateral =
							acct.client.getFreeCollateral('Maintenance');
						const advancedLp = acct.client.hasStatus(UserStatus.ADVANCED_LP);

						const newMarginInfo = {
							collateral: netBankValue,
							totalCollateral: acct.client.getTotalCollateral(),
							totalMaintenanceCollateral:
								acct.client.getTotalCollateral('Maintenance'),
							freeCollateral,
							maintenanceFreeCollateral,
							leverage: BigNum.from(acct.client.getLeverage(), FOUR).toNum(),
							marginRatioPct: BigNum.from(userMarginRatio, TWO).toNum(),
							unrealisedPnL: unrealizedPnl,
							netUsdValue,
							maintenanceReq: acct.client.getMaintenanceMarginRequirement(),
							initialReq: acct.client.getInitialMarginRequirement(),

							// use previous state
							unrealisedFundingPnL:
								s.accounts[acct.userKey].marginInfo.unrealisedFundingPnL,
							totalUnsettledPnl:
								s.accounts[acct.userKey].marginInfo.totalUnsettledPnl,
							quoteInOpenOrders:
								s.accounts[acct.userKey].marginInfo.quoteInOpenOrders,
							quoteInLpOrders:
								s.accounts[acct.userKey].marginInfo.quoteInLpOrders,
							advancedLp,
						};

						const isSameAsPrevMarginInfo = checkIfMarginInfosAreEqual(
							s.accounts[acct.userKey].marginInfo,
							newMarginInfo
						);

						if (!isSameAsPrevMarginInfo) {
							s.accounts[acct.userKey].marginInfo.collateral = netBankValue;
							s.accounts[acct.userKey].marginInfo.totalCollateral =
								acct.client.getTotalCollateral();
							s.accounts[acct.userKey].marginInfo.totalMaintenanceCollateral =
								acct.client.getTotalCollateral('Maintenance');
							s.accounts[acct.userKey].marginInfo.freeCollateral =
								freeCollateral;
							s.accounts[acct.userKey].marginInfo.maintenanceFreeCollateral =
								maintenanceFreeCollateral;
							s.accounts[acct.userKey].marginInfo.leverage = BigNum.from(
								acct.client.getLeverage(),
								FOUR
							).toNum();
							s.accounts[acct.userKey].marginInfo.marginRatioPct = BigNum.from(
								userMarginRatio,
								TWO
							).toNum();
							s.accounts[acct.userKey].marginInfo.unrealisedPnL = unrealizedPnl;
							s.accounts[acct.userKey].marginInfo.netUsdValue = netUsdValue;
							s.accounts[acct.userKey].marginInfo.maintenanceReq =
								acct.client.getMaintenanceMarginRequirement();
							s.accounts[acct.userKey].marginInfo.initialReq =
								acct.client.getInitialMarginRequirement();
							s.accounts[acct.userKey].marginInfo.advancedLp = advancedLp;
						}
					}
				});
			});
		}
	};

	useEffect(() => {
		setMarginInfo();
	}, [connected]);

	useInterval(() => {
		setMarginInfo();
	}, 1000);
};

export default useMarginInfo;
