'use client';

import { BN, OrderType, PositionDirection, PublicKey } from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId, UISerializableOrder } from '@drift/common';
import { Transaction } from '@solana/web3.js';
import { useEffect, useMemo, useRef } from 'react';
import useDriftAccountStore from '../stores/useDriftAccountsStore';
import { AuctionOrderSignedTxHandler } from '../utils/DriftAppEvents';
import useAppEventEmitter from './useAppEventEmitter';
import useUiUpdateInterval from './useUiUpdateInterval';
import useDriftClient from './useDriftClient';
import { dlog } from '../dev';
import { MarketOrderToastId } from '../components/MarketOrderToasts/MarketOrderToastStateTypes';
import { DriftWindow } from '../window/driftWindow';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import {
	getKnownMarketOrderId,
	SelfFillMarketOrderIdHandler,
} from '../components/MarketOrderToasts/toastIdHandlingUtils';

const getSelfFillSlot = (order: UISerializableOrder) => {
	const orderSlot = order.slot.toNumber();
	const auctionDuration = order.auctionDuration;
	return Math.round(orderSlot + auctionDuration);
};

const SELF_FILLING_DISABLED = false;

/**
 * This hook should do all of the work necessary to self-fill market orders when the auction expires. It listens for events containing the signed transactions, and the current order state for the user. It will try to send the signed self-fill transaction when it detects that an order's auction state is newly expired.
 * @returns
 */
const useSelfFilling = () => {
	if (SELF_FILLING_DISABLED) {
		return;
	}

	const appEventEmitter = useAppEventEmitter();

	const selfFillTxCache = useRef<Record<MarketOrderToastId, Transaction>>({});
	const knownAuctionSelfFillSlots = useRef<Record<MarketOrderToastId, number>>(
		{}
	);
	const alreadySelfFilledOrders = useRef<Set<MarketOrderToastId>>(new Set());
	const driftClient = useDriftClient();

	const addToSignedTxCache = (
		idNonce: number,
		user: PublicKey,
		marketId: MarketId,
		baseSize: BN,
		direction: PositionDirection,
		tx: Transaction
	) => {
		const key =
			SelfFillMarketOrderIdHandler.handleNewMarketOrderWithUnknownOrderId({
				idNonce,
				user,
				marketId,
				baseSize,
				direction,
			});

		selfFillTxCache.current[key] = tx;
	};

	// # Handler to listen for signed transactions and add them to the cache
	useEffect(() => {
		const handler: AuctionOrderSignedTxHandler = ({
			idNonce,
			user,
			tx,
			direction,
			baseSize,
			marketId,
		}) => {
			dlog('self_filling', `Received signed tx for order`);
			addToSignedTxCache(idNonce, user, marketId, baseSize, direction, tx);
		};

		appEventEmitter.on('fallbackAuctionOrderSignedTx', handler);

		return () => {
			appEventEmitter.removeListener('fallbackAuctionOrderSignedTx', handler);
		};
	}, [appEventEmitter]);

	// # Logic to keep track of auction expiry slots for all open orders
	const openOrders = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.openOrders
	);
	const currentUser = useDriftAccountStore(
		(s) => s.accounts?.[s?.currentUserKey]?.pubKey
	);

	const marketOrders = useMemo(() => {
		if (!openOrders) return [];

		return openOrders.filter(
			(order) =>
				ENUM_UTILS.match(order.orderType, OrderType.MARKET) ||
				ENUM_UTILS.match(order.orderType, OrderType.ORACLE)
		);
	}, [openOrders]);

	const marketOrderIdsUniqueness = useMemo(() => {
		return (marketOrders ?? [])
			.map((order) =>
				getKnownMarketOrderId(
					currentUser,
					new MarketId(order.marketIndex, order.marketType),
					order.orderId
				)
			)
			.join(',');
	}, [marketOrders]);

	const clearInactiveOrdersFromTxCache = (orders: UISerializableOrder[]) => {
		const toastIds = orders.map((order) =>
			SelfFillMarketOrderIdHandler.getToastIdWithKnownOrderId({
				orderId: order.orderId,
				user: currentUser,
				marketId: new MarketId(order.marketIndex, order.marketType),
				baseSize: order.baseAssetAmount.val,
				direction: order.direction,
			})
		);

		// Clear the cache of any orders that are no longer in the user's open orders
		Object.keys(selfFillTxCache.current).forEach((key) => {
			const typedKey = key as MarketOrderToastId;
			if (!toastIds.includes(typedKey)) {
				dlog('self_filling', `Removing order ${typedKey} from cache`);
				delete selfFillTxCache.current[key];
			}
		});
	};

	const clearInactiveOrdersFromKnownSelfFillSlots = (
		orders: UISerializableOrder[]
	) => {
		const toastIds = orders.map((order) =>
			SelfFillMarketOrderIdHandler.getToastIdWithKnownOrderId({
				orderId: order.orderId,
				user: currentUser,
				marketId: new MarketId(order.marketIndex, order.marketType),
				baseSize: order.baseAssetAmount.val,
				direction: order.direction,
			})
		);

		// Clear the cache of any orders that are no longer in the user's open orders
		Object.keys(knownAuctionSelfFillSlots.current).forEach((key) => {
			const typedKey = key as MarketOrderToastId;
			if (!toastIds.includes(typedKey)) {
				dlog('self_filling', `Removing known self fill slot from cache`);
				delete knownAuctionSelfFillSlots.current[key];
			}
		});
	};

	useEffect(() => {
		/**
		 * Every time the current market orders change .. we should:
		 * - Remove any orders from the cache that are no longer in the user's open orders
		 * - Update the known auction expiry times
		 */
		const marketOrderKeys = marketOrderIdsUniqueness.split(
			','
		) as MarketOrderToastId[];

		dlog(
			'self_filling',
			`Syncing market order keys: ${marketOrderKeys.join(', ')}`
		);

		clearInactiveOrdersFromTxCache(marketOrders);
		clearInactiveOrdersFromKnownSelfFillSlots(marketOrders);

		// Update the known auction expiry times for the user's open orders
		marketOrders.forEach((order) => {
			const selfFillSlot = getSelfFillSlot(order);

			if (
				selfFillSlot <=
				DriftWindow?.chainClock?.getState?.(DEFAULT_COMMITMENT_LEVEL)?.slot
			) {
				dlog(
					'self_filling',
					`Order ${order.orderId} self-fill slot already expired. Skipping`
				);
				return;
			}

			dlog('self_filling', `Updating expiry time for order ${order.orderId}`);
			const key = SelfFillMarketOrderIdHandler.getToastIdWithKnownOrderId({
				orderId: order.orderId,
				user: currentUser,
				marketId: new MarketId(order.marketIndex, order.marketType),
				baseSize: order.baseAssetAmount.val,
				direction: order.direction,
			});
			knownAuctionSelfFillSlots.current[key] = selfFillSlot;
		});
	}, [marketOrderIdsUniqueness]);

	// # Logic to trigger self-fills when we detect a newly expired order
	useUiUpdateInterval(
		() => {
			const currentSlot = DriftWindow?.chainClock?.getState?.(
				DEFAULT_COMMITMENT_LEVEL
			)?.slot;

			if (!currentSlot) return;
			if (Object.keys(knownAuctionSelfFillSlots.current).length === 0) return;

			dlog(
				'self_filling',
				`Checking for self-fills .. currentSlot:${currentSlot}, knownAuctionExpiryTimes:${Object.entries(
					knownAuctionSelfFillSlots.current
				)
					.map(([key, val]) => `${key}:${val}`)
					.join(', ')}`
			);

			Object.entries(knownAuctionSelfFillSlots.current).forEach(
				([key, selfFillSlot]) => {
					const typedKey =
						key as keyof typeof knownAuctionSelfFillSlots.current;
					const typedSelfFillSlot = selfFillSlot as number;

					if (
						typedSelfFillSlot <= currentSlot &&
						!alreadySelfFilledOrders.current.has(typedKey) &&
						selfFillTxCache.current[typedKey]
					) {
						const tx = selfFillTxCache.current[typedKey];

						if (tx) {
							dlog(
								'self_filling',
								`Sending self-fill transaction for orderkey ${typedKey}`
							);
							alreadySelfFilledOrders.current.add(typedKey);
							driftClient.sendSignedTx(tx, {
								skipPreflight: true,
							});
							// Clear the tx cache for this order
							delete selfFillTxCache.current[typedKey];
						}
					}
				}
			);
		},
		true,
		false
	);
};

export default useSelfFilling;
