import { Subject } from 'rxjs';
import { pairwise, timeInterval } from 'rxjs/operators';

/**
 * A class which subscribes to a metrics event sink, tracks network metrics, and triggers alarms if conditions are met.
 */
export class SlotSubscriberMetricsTracker {
	constructor(private slotSubject: Subject<number>) {}

	public subscribe() {
		// We want to catch if there is no slot update for longer than the bad gap
		this.slotSubject.pipe(timeInterval()).subscribe((event) => {
			const gapMs = event.interval;
			const currentMaxSlotGapMs =
				window.UNCONSUMED_GRAFANA_METRICS.MAX_SLOT_GAP_MS ?? 0;
			const newMaxGap = Math.max(currentMaxSlotGapMs, gapMs);
			if (newMaxGap === 0) return;
			window.UNCONSUMED_GRAFANA_METRICS.MAX_SLOT_GAP_MS = newMaxGap;
		});
		this.slotSubject.pipe(pairwise()).subscribe((event) => {
			const gapCount = event[1] - event[0];
			const currentMaxSlotGapMs =
				window.UNCONSUMED_GRAFANA_METRICS.MAX_SLOT_GAP_COUNT ?? 0;
			const newMaxGap = Math.max(currentMaxSlotGapMs, gapCount);
			if (newMaxGap === 0) return;
			window.UNCONSUMED_GRAFANA_METRICS.MAX_SLOT_GAP_COUNT = newMaxGap;
		});
	}
}
