'use client';

import { useEffect, useRef } from 'react';
import { useInterval } from 'react-use';
import Env from '../../environmentVariables/EnvironmentVariables';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { SANITY_CHECK_ABS_MAX_CU_PRICE } from '../../utils/PriorityFeeCalculator';
import { FEE_STRATEGY_TARGET_PERCENTILE } from './consts';
import useMarketsForDlobToTrack, {
	MarketStatePriority,
} from '../../hooks/useMarketsForDlobToTrack';
import { MarketId, MarketKey } from '@drift/common';
import UI_UTILS from '../../utils/uiUtils';
import { dlog } from '../../dev';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';

const FALLBACK_TYPE: 'max' | 'min' = 'max';

const FEE_ENDPOINT = 'https://dlob.drift.trade/batchPriorityFees';

const EXTRA_POLLING_MULTIPLIER = Env.feeSubscriptionPollingMultiplier;

const FEE_ESTIMATE_ENDPOINT_IS_AVAILABLE = { current: true };

const getHeliusPriorityFeeEstimate = async (
	marketIds: MarketId[]
): Promise<number[]> => {
	try {
		const queryParamsMap = {
			marketType: marketIds.map((market) => market.marketTypeStr).join(','),
			marketIndex: marketIds.map((market) => market.marketIndex).join(','),
		};

		const encodedQueryParams = UI_UTILS.encodeQueryParams(queryParamsMap);

		const response = await fetch(`${FEE_ENDPOINT}?${encodedQueryParams}`, {
			headers: { 'Content-Type': 'application/json' },
		});

		const result = (await response.json()) as {
			min: number;
			low: number;
			medium: number;
			high: number;
			veryHigh: number;
			unsafeMax: number;
		}[];

		if (!response.ok) {
			return undefined;
		}

		/**
		 * Helius fee buckets are as follows:
		 * 
		 * enum PriorityLevel {
			NONE, // 0th percentile
			LOW, // 25th percentile
			MEDIUM, // 50th percentile
			HIGH, // 75th percentile
			VERY_HIGH, // 95th percentile
			// labelled unsafe to prevent people using and draining their funds by accident
			UNSAFE_MAX, // 100th percentile 
			DEFAULT, // 50th percentile
		}
		 */

		const feeLevelToUse: keyof (typeof result)[0] =
			FEE_STRATEGY_TARGET_PERCENTILE > 75
				? 'veryHigh'
				: FEE_STRATEGY_TARGET_PERCENTILE > 50
				? 'high'
				: FEE_STRATEGY_TARGET_PERCENTILE > 25
				? 'medium'
				: 'low';

		const feeLevelValues = result.map((result) => result?.[feeLevelToUse]);

		dlog(`priority_fee_sampling`, `bulk_dlob_server_results`, {
			encodedQueryParams,
			feeLevelToUse,
			feeLevelValues,
		});

		return feeLevelValues;
	} catch (e) {
		return undefined;
	}
};

const useHeliusSourcedPriorityFees = () => {
	const selectMarket =
		useMarketsForDlobToTrack().categories[MarketStatePriority.SelectedMarket];
	const otherMarkets =
		useMarketsForDlobToTrack().categories[MarketStatePriority.BackgroundDeep];
	const walletIsConnected = useWalletIsConnected();
	const marketsToPoll = [...selectMarket, ...otherMarkets];
	const marketsToPollRef = useRef(marketsToPoll);

	const latestFeeResults = useRef<Record<MarketKey | 'fallback', number>>({
		fallback: 0,
	});

	// Keep Markets To Poll Ref in sync
	useEffect(() => {
		marketsToPollRef.current = marketsToPoll;
	}, [marketsToPoll]);

	const pollingMultiplier = useDriftStore((s) => s.pollingMultiplier);
	const pollMs =
		Env.pollingFrequencyMs * pollingMultiplier * EXTRA_POLLING_MULTIPLIER;

	const refreshHeliusPriorityFeeValue = async () => {
		if (!FEE_ESTIMATE_ENDPOINT_IS_AVAILABLE.current || !walletIsConnected)
			return;

		const marketsToFetch = marketsToPollRef.current;

		const results = await getHeliusPriorityFeeEstimate(marketsToFetch);

		if (!results) {
			return;
		}

		const fallbackResult =
			FALLBACK_TYPE === 'max' ? Math.max(...results) : Math.min(...results);

		latestFeeResults.current.fallback = fallbackResult;

		results.forEach((heliusResult, index) => {
			const marketId = marketsToFetch[index];
			if (!heliusResult) {
				// Use undefined here, because Math.min can turned into NaN otherwise.
				latestFeeResults.current[marketId.key] = undefined;
			} else {
				latestFeeResults.current[marketId.key] = Math.min(
					heliusResult,
					SANITY_CHECK_ABS_MAX_CU_PRICE
				);
			}
		});
	};

	useInterval(refreshHeliusPriorityFeeValue, pollMs);

	return latestFeeResults;
};

export default useHeliusSourcedPriorityFees;
