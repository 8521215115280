'use client';

import React, { useEffect, useMemo } from 'react';
import Drawer from '../../Drawer';
import MarketIcon from '../../Utils/MarketIcon';
import Text from '../../Text/Text';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import Utility from 'src/components/Inputs/Utility';
import { ArrowLeft, Info, Swap } from '@drift-labs/icons';
import { LabelChip } from 'src/components/Chips/LabelChip';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import { COMMON_UI_UTILS, MarketId, UIMarket } from '@drift/common';
import { AccountData } from 'src/stores/useDriftAccountsStore';
import {
	BASE_PRECISION_EXP,
	BigNum,
	MarketType,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import useShowAccountValues from 'src/hooks/useShowAccountValues';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import Tooltip from 'src/components/Tooltip/Tooltip';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { PERP_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { useTickedAccountData } from 'src/hooks/useAccountData';
import { MAX_PREDICTION_PRICE_BIGNUM, ZERO_BIGNUM } from 'src/constants/math';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import NumberDisplay from 'src/components/Utils/NumberDisplay';
import Button from 'src/components/Button';
import { ClosePositionPopupInner } from 'src/components/Popups/ClosePositionPopup';

const Section = ({
	sectionLabel,
	topRightNode,
	children,
}: {
	sectionLabel: string;
	topRightNode?: React.ReactNode;
	children: React.ReactNode;
}) => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between w-full">
				<Text.H5 className="text-text-default">{sectionLabel}</Text.H5>
				{!!topRightNode && (
					<Text.BODY2 className="text-text-label">{topRightNode}</Text.BODY2>
				)}
			</div>
			<div className="flex flex-col gap-2">{children}</div>
		</div>
	);
};

const DetailRow = ({
	label,
	value,
	marketSymbol,
	tooltipText,
}: {
	label: React.ReactNode;
	value: React.ReactNode;
	marketSymbol?: string;
	tooltipText?: string | React.ReactNode;
}) => {
	const isMobile = useIsMobileScreenSize();

	const labelElement = (
		<Text.BODY3 className="text-text-label">
			{label}
			{tooltipText && <Info className="absolute ml-1" />}
		</Text.BODY3>
	);
	return (
		<div className="flex justify-between w-full">
			{tooltipText ? (
				<Tooltip content={tooltipText} placement={isMobile ? 'bottom' : 'left'}>
					{labelElement}
				</Tooltip>
			) : (
				labelElement
			)}

			<div className="flex items-center gap-1">
				{marketSymbol && (
					<MarketIcon marketSymbol={marketSymbol} sizeClass="w-4 h-4" />
				)}
				<Text.BODY3 className="text-text-default">{value}</Text.BODY3>
			</div>
		</div>
	);
};

export const PositionDrawer = () => {
	const [userSettings] = useCurrentSettings();
	const setStore = useDriftStore((s) => s.set);
	const setDrawers = useDrawersStore((s) => s.set);
	const marketIndex = useDrawersStore((s) => s.position.marketIndex);
	const showClosePositionForm = useDrawersStore(
		(s) => s.position.showClosePositionForm
	);
	const closePositionPopupOptions = useDriftStore(
		(s) => s.popups.closePositionPopupOptions
	);
	// const isLpPosition = useDrawersStore((s) => s.position.isLpPosition);
	const showAccountValues = useShowAccountValues();
	const currentAccount = useTickedAccountData() as AccountData;
	const accountLeverage = currentAccount?.marginInfo?.leverage || 1;
	const [
		maintenancePositionWeightedValue,
		setMaintenancePositionWeightedValue,
	] = React.useState(ZERO_BIGNUM);
	const [initialPositionWeightedValue, setInitialPositionWeightedValue] =
		React.useState(ZERO_BIGNUM);

	const position = currentAccount.openPerpPositions
		.filter((pos) => !pos.baseSize.eq(ZERO))
		.find((pos) => pos.marketIndex === marketIndex);

	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const user = driftClient && driftClientIsReady && driftClient.getUser();

	const totalMaintenanceCollateral = BigNum.from(
		user?.getTotalCollateral('Maintenance', true) ?? 0,
		QUOTE_PRECISION_EXP
	);
	const totalInitialCollateral = BigNum.from(
		user?.getTotalCollateral('Initial', true) ?? 0,
		QUOTE_PRECISION_EXP
	);

	const maintenanceMarginRatio =
		maintenancePositionWeightedValue.toNum() /
		totalMaintenanceCollateral.toNum();
	const initialMarginRatio =
		initialPositionWeightedValue.toNum() / totalInitialCollateral.toNum();

	const marketConfig = PERP_MARKETS_LOOKUP[marketIndex];

	const [pnlReference, setPnlReference] = useLocalStorageState<
		'mark' | 'oracle'
	>('pnlReference', 'mark');

	// const isLong = position?.direction === 'long';

	const marketId = useMemo(() => {
		return MarketId.createPerpMarket(position?.marketIndex ?? 0);
	}, [position]);

	const getMarkPriceForMarket = useMarketStateStore(
		(s) => s.getMarkPriceForMarket
	);
	const markPrice = getMarkPriceForMarket(marketId);

	const getOraclePriceForMarket = useMarketStateStore(
		(s) => s.getOraclePriceForMarket
	);
	const oraclePrice = getOraclePriceForMarket(marketId);

	const uiMarket = new UIMarket(position?.marketIndex || 0, MarketType.PERP);
	const direction = position?.baseSize?.isNeg() ? 'short' : 'long';
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: direction === 'short',
		})
	);
	const markPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(markPrice)
		: markPrice;

	const notional = showAccountValues
		? BigNum.from(position?.notional || ZERO, QUOTE_PRECISION_EXP).toNotional()
		: '∗∗∗∗∗∗';

	const pnlToUse = BigNum.from(
		pnlReference === 'mark' || uiMarket.isPredictionMarket
			? position?.pnlVsMark
			: position?.pnlVsOracle,
		QUOTE_PRECISION_EXP
	);

	const quoteEntryAmount = BigNum.from(
		position?.quoteEntryAmount || ZERO,
		QUOTE_PRECISION_EXP
	);

	const costBasis = showAccountValues
		? quoteEntryAmount.toNotional()
		: '∗∗∗∗∗∗';

	const quoteBreakEvenAmount = BigNum.from(
		position?.quoteBreakEvenAmount || ZERO,
		QUOTE_PRECISION_EXP
	);

	const baseSizeBigNum = BigNum.from(
		position?.baseSize?.abs() || ZERO,
		BASE_PRECISION_EXP
	);

	const breakEvenPrice = BigNum.fromPrint(
		baseSizeBigNum.gtZero()
			? `${quoteBreakEvenAmount.abs().toNum() / baseSizeBigNum.toNum()}`
			: '0',
		PRICE_PRECISION_EXP
	);
	const breakEvenPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(breakEvenPrice)
		: breakEvenPrice;

	const liqPrice = BigNum.from(position?.liqPrice || ZERO, PRICE_PRECISION_EXP);
	const liqPriceToUse =
		isSellPredictionMarket && position?.liqPrice.gt(ZERO)
			? MAX_PREDICTION_PRICE_BIGNUM.sub(liqPrice)
			: liqPrice;

	const entryPrice = BigNum.from(
		position?.entryPrice || ZERO,
		PRICE_PRECISION_EXP
	);
	const entryPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(entryPrice)
		: entryPrice;

	const fundingPnl = BigNum.from(
		position.feesAndFundingPnl,
		QUOTE_PRECISION_EXP
	);

	const fundinPnlNotional = fundingPnl.shift(-4);

	const fundingPnlDisplay = showAccountValues
		? fundinPnlNotional.toNotional()
		: '∗∗∗∗∗∗';

	const netPnl = pnlToUse.add(fundingPnl);

	const positionDollarPnl = showAccountValues
		? `${pnlToUse.toNotional()}`
		: '∗∗∗∗∗∗';
	const netDollarPnl = showAccountValues ? `${netPnl.toNotional()}` : '∗∗∗∗∗∗';

	const percentageNum = uiMarket.isPredictionMarket
		? markPriceToUse
				.sub(entryPriceToUse)
				.mul(PRICE_PRECISION)
				.div(entryPriceToUse.val)
				.toNum() * 100
		: COMMON_UI_UTILS.calculatePnlPctFromPosition(
				netPnl.val,
				position.quoteEntryAmount,
				userSettings.includeLeverageInPnl ? accountLeverage : 1
		  );

	const percentagePnl = `${percentageNum.toFixed(2)}%`;

	const handleClose = () => {
		setDrawers((state) => {
			state.position.show = false;
			state.position.marketIndex = -1;
			state.position.isLpPosition = false;
			state.position.showClosePositionForm = false;
		});
	};

	const handleShowClosePositionForm = () => {
		setStore((s) => {
			s.popups.closePositionPopupOptions.marketIndex = marketIndex;
			s.popups.closePositionPopupOptions.uiOrderType = 'market';
			s.popups.closePositionPopupOptions.isStopLossOnly = false;
			s.popups.closePositionPopupOptions.isTakeProfitOnly = false;
		});
		setDrawers((state) => {
			state.position.showClosePositionForm = true;
		});
	};

	const handleHideClosePositionForm = () => {
		setDrawers((state) => {
			state.position.showClosePositionForm = false;
		});
	};

	const togglePnlReference = () => {
		setPnlReference(pnlReference === 'mark' ? 'oracle' : 'mark');
	};

	const estNetPnl = (
		<Text.BODY3
			className={`text-text-default ${
				netPnl.gtZero()
					? `text-positive-green`
					: netPnl.ltZero()
					? `text-negative-red`
					: ``
			}`}
		>
			{netDollarPnl} ({percentagePnl})
		</Text.BODY3>
	);

	const getAndSetPositionHealth = () => {
		if (!user) return;

		const perpPosition = user.getPerpPosition(position.marketIndex);
		if (!perpPosition) return;

		const maintenanceHealth = user.getPerpPositionHealth({
			marginCategory: 'Maintenance',
			perpPosition,
		});
		const initialHealth = user.getPerpPositionHealth({
			marginCategory: 'Initial',
			perpPosition,
		});

		setMaintenancePositionWeightedValue(
			BigNum.from(maintenanceHealth.weightedValue, QUOTE_PRECISION_EXP)
		);
		setInitialPositionWeightedValue(
			BigNum.from(initialHealth.weightedValue, QUOTE_PRECISION_EXP)
		);
	};

	useEffect(() => {
		if (position && driftClient && driftClientIsReady) {
			getAndSetPositionHealth();
		}
	}, [driftClient, driftClientIsReady, position]);

	return (
		<Drawer
			drawerName="position"
			className="w-full md:max-w-[400px] md:w-[400px] max-h-full"
		>
			<Drawer.Header
				titleContent={
					<div className="flex items-center gap-1">Position Details</div>
				}
				infoContent={
					<>
						<div className="flex flex-row items-center justify-between">
							<div className="flex flex-row items-center gap-2">
								<MarketIcon
									marketSymbol={marketConfig.symbol}
									sizeClass="w-6 h-6"
								/>
								<div className="flex flex-col gap-1">
									<Text.BODY3>
										{uiMarket.isPredictionMarket
											? PredictionMarketConfigs.get(position.marketIndex).title
											: marketConfig.symbol}
									</Text.BODY3>
									<div>
										<LabelChip
											label={
												uiMarket.isPredictionMarket
													? position?.direction === 'long'
														? 'Yes'
														: 'No'
													: position?.direction
											}
											positive={position?.direction === 'long'}
											negative={position?.direction === 'short'}
											className={'capitalize w-auto inline-block'}
										/>
									</div>
								</div>
							</div>
							<div className="flex flex-col gap-1 items-end min-w-[120px]">
								<Text.BODY3 className="text-text-label">
									Est. Net P&L
								</Text.BODY3>
								{estNetPnl}
							</div>
						</div>
					</>
				}
				className="p-4"
				onClose={handleClose}
			/>

			{showClosePositionForm ? (
				<Drawer.Body>
					<button
						className="flex flex-row gap-2 p-2 mt-2 items-cente text-text-default"
						onClick={handleHideClosePositionForm}
					>
						<ArrowLeft />
						<Text.BODY1>Close Position</Text.BODY1>
					</button>
					<ClosePositionPopupInner
						{...closePositionPopupOptions}
						showHeader={false}
						onClose={handleClose}
						setFloating={null}
						className="sm:w-full"
					/>
				</Drawer.Body>
			) : (
				<>
					<Drawer.Body>
						<div className="flex flex-col gap-4 p-4">
							<Section sectionLabel="Position Info" topRightNode={<></>}>
								<DetailRow
									label="Entry price"
									value={
										<NumberDisplay
											displayType="tradePrecision"
											value={entryPriceToUse.toNum()}
											subType="$"
										/>
									}
								/>
								<DetailRow
									label={
										pnlReference === 'mark' || uiMarket.isPredictionMarket
											? 'Mark price'
											: 'Oracle price'
									}
									value={
										<NumberDisplay
											displayType="tradePrecision"
											value={
												pnlReference === 'mark' || uiMarket.isPredictionMarket
													? markPriceToUse?.toNum()
													: oraclePrice?.toNum()
											}
											subType="$"
										/>
									}
								/>

								<DetailRow
									label="Break even price"
									value={
										<NumberDisplay
											displayType="tradePrecision"
											value={breakEvenPriceToUse.toNum()}
											subType="$"
										/>
									}
									tooltipText={`Break even price is the price at which your position can be closed to cover all acquisition and holding costs with no net loss.`}
								/>
								<DetailRow
									label="Liquidation price"
									value={
										<NumberDisplay
											displayType="tradePrecision"
											value={liqPrice.toNum()}
											textOverride={
												liqPriceToUse.gtZero()
													? `${liqPriceToUse.toNotional()}`
													: 'None'
											}
											subType="$"
										/>
									}
								/>

								<div className="mt-1" />
								<Utility.VERTDIVIDER />
								<div className="mb-1" />

								<DetailRow
									label="Maintenance margin used"
									value={`${maintenancePositionWeightedValue.toNotional()} (${(
										maintenanceMarginRatio * 100
									).toFixed(1)}%)`}
								/>
								<DetailRow
									label="Initial margin used"
									value={`${initialPositionWeightedValue.toNotional()} (${(
										initialMarginRatio * 100
									).toFixed(1)}%)`}
								/>
							</Section>

							<Utility.VERTDIVIDER />

							<Section
								sectionLabel="Est. net P&L"
								topRightNode={
									<div className="flex flex-row items-center relative top-[-2px]">
										<button
											onClick={togglePnlReference}
											className="mr-2"
											disabled={uiMarket.isPredictionMarket}
										>
											<Text.BODY3 className="text-text-label">
												{pnlReference === 'mark' || uiMarket.isPredictionMarket
													? 'Mark Price'
													: 'Oracle Price'}
												{!uiMarket.isPredictionMarket && (
													<Swap className="relative top-[3px] ml-0.5" />
												)}
											</Text.BODY3>
										</button>
										{estNetPnl}
									</div>
								}
							>
								<DetailRow label="Notional size" value={notional} />
								<DetailRow
									label="Cost basis"
									value={costBasis}
									tooltipText={
										"Cost basis is the original value of an asset, including the position's notional size and any fees and funding charges paid."
									}
								/>
								<DetailRow
									label="Position P&L"
									value={
										<Text.BODY3
											className={
												pnlToUse.gtZero()
													? 'text-positive-green'
													: pnlToUse.ltZero()
													? 'text-negative-red'
													: 'text-text-default'
											}
										>
											{positionDollarPnl}
										</Text.BODY3>
									}
								/>
								<DetailRow
									label="Fees and funding"
									value={
										<Text.BODY3
											className={
												fundinPnlNotional.gtZero()
													? 'text-positive-green'
													: fundinPnlNotional.ltZero()
													? 'text-negative-red'
													: 'text-text-default'
											}
										>
											{fundingPnlDisplay}
										</Text.BODY3>
									}
								/>
							</Section>
						</div>
					</Drawer.Body>
					<Drawer.Footer>
						<Button.BigSemantic
							onClick={handleShowClosePositionForm}
							className="w-full"
							positive={pnlToUse.gteZero()}
						>
							Close Position
						</Button.BigSemantic>
					</Drawer.Footer>
				</>
			)}
		</Drawer>
	);
};
