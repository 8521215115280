'use client';

import { MouseEvent, useEffect, useState } from 'react';
import Drawer from 'src/components/Drawer';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import Text from '../Text/Text';
import Button from '../Button';
import CheckboxInput from '../CheckboxInput';
import useDriftActions from 'src/hooks/useDriftActions';
import { MarginMode } from '@drift-labs/sdk';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { twMerge } from 'tailwind-merge';
import { matchEnum } from '@drift/common';
import useHighLeverageSpotsOpen from 'src/hooks/useHighLeverageSpotsOpen';
import Tooltip from '../Tooltip/Tooltip';
import UI_UTILS from 'src/utils/uiUtils';
import InfoMessage from '../TradeForm/InfoMessage';
//import useGoToRoute from 'src/hooks/useGoToRoute';

export default function EnableHighLeverageDrawer() {
	const handleClose = useDrawersStore((s) => s.handleCloseDrawer);
	const actions = useDriftActions();
	const subAccountId = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.userId
	);
	const user = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.client
	);
	//const goToRoute = useGoToRoute();

	const [isHighLeverageEnabled, setIsHighLeverageEnabled] = useState(false);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
	const { highLeverageMaxUsers, highLeverageSpotsOpen } =
		useHighLeverageSpotsOpen();
	const [selectedLeverageOption, setSelectedLeverageOption] =
		useState<MarginMode>(MarginMode.HIGH_LEVERAGE);
	const [submitting, setSubmitting] = useState(false);
	const [accountMaxLeverage, setAccountMaxLeverage] = useState(0);

	// If the user is switching to high leverage mode, remove their max leverage setting
	const removeMaxLeverage =
		matchEnum(selectedLeverageOption, MarginMode.HIGH_LEVERAGE) &&
		accountMaxLeverage
			? true
			: false;

	useEffect(() => {
		if (user) {
			const account = user.getUserAccount();
			const maxLeverage = UI_UTILS.convertMarginRatioToLeverage(
				account.maxMarginRatio
			);
			setAccountMaxLeverage(maxLeverage);
			const isUserHighLeverageMode = user.isHighLeverageMode();
			setIsHighLeverageEnabled(isUserHighLeverageMode);
			setSelectedLeverageOption(MarginMode.HIGH_LEVERAGE);
		}
	}, [user, subAccountId]);

	useEffect(() => {
		if (isHighLeverageEnabled) {
			setSelectedLeverageOption(MarginMode.HIGH_LEVERAGE);
		} else if (highLeverageSpotsOpen === 0) {
			setSelectedLeverageOption(MarginMode.DEFAULT);
		}
	}, [isHighLeverageEnabled, highLeverageSpotsOpen]);

	const handleChangeLeverageMode = async () => {
		setSubmitting(true);
		await actions.updateUserMarginMode([
			{
				subAccountId,
				marginMode: selectedLeverageOption,
				removeMaxLeverage,
			},
		]);
		handleClose('marginLeverageSettings');
	};

	const onClose = () => {
		handleClose('marginLeverageSettings');
	};

	const handleAddNewSubaccount = (event: MouseEvent) => {
		event.preventDefault();
		handleClose('marginLeverageSettings');
		actions.showModal('showNewSubaccountModal', true);
	};

	// const goToFeeTierDocs = () => {
	// 	goToRoute('https://docs.drift.trade/trading/trading-fees');
	// };

	const isSaveButtonDisabled =
		submitting ||
		(!isHighLeverageEnabled && !hasAcceptedTerms) ||
		(isHighLeverageEnabled &&
			matchEnum(selectedLeverageOption, MarginMode.HIGH_LEVERAGE)) ||
		(!isHighLeverageEnabled &&
			matchEnum(selectedLeverageOption, MarginMode.DEFAULT));

	const isHighLeverageButtonDisabled =
		!isHighLeverageEnabled && highLeverageSpotsOpen === 0;

	const highLeverageModeButton = (
		<Button.BigInput
			onClick={() => {
				setSelectedLeverageOption(MarginMode.HIGH_LEVERAGE);
			}}
			className="w-auto px-5 text-center"
			highlighted={matchEnum(selectedLeverageOption, MarginMode.HIGH_LEVERAGE)}
			disabled={isHighLeverageButtonDisabled}
		>
			<Text.BODY2
				className={
					!isHighLeverageButtonDisabled &&
					twMerge(
						matchEnum(selectedLeverageOption, MarginMode.HIGH_LEVERAGE) &&
							'text-text-emphasis'
					)
				}
			>
				High Leverage (up to 50x)
			</Text.BODY2>
		</Button.BigInput>
	);

	return (
		<Drawer
			drawerName="marginLeverageSettings"
			className="sm:w-[420px] sm:max-w-[420px]"
		>
			<Drawer.Header
				titleContent={<Text.H4>Enable High Leverage Mode (Beta)</Text.H4>}
				className="p-4"
				onClose={onClose}
			/>
			<Drawer.Body>
				<>
					<div className="px-4 my-3 text-text-label">
						<Text.BODY3>
							The maximum leverage applies to your subaccount&apos;s total
							leverage, not your position. To open an isolated position, you can
							create a new subaccount instead{' '}
							<a href="" onClick={handleAddNewSubaccount}>
								here.
							</a>
						</Text.BODY3>
					</div>
					<div className="px-4 my-3 text-text-label">
						<Text.BODY3>
							Note that High Leverage Mode comes with increased perp taker fees
							regardless of fee tier. Make sure to preview fees when placing
							orders.
						</Text.BODY3>
					</div>
					<div className="px-4 my-3 text-text-label">
						High leverage mode is available to a limited number of Drift user
						accounts at a time. Spots remaining:{' '}
						<span className="text-warn-yellow">
							{highLeverageSpotsOpen}/{highLeverageMaxUsers}
						</span>
					</div>
					<div className="px-4 my-4 mt-6 text-text-label">
						<div className="mb-2">
							<Text.BODY3>
								Margin Mode (current:{' '}
								{isHighLeverageEnabled ? 'High Leverage' : 'Normal'})
							</Text.BODY3>
						</div>
						<div className="flex flex-row items-center gap-4">
							<Button.BigInput
								onClick={() => setSelectedLeverageOption(MarginMode.DEFAULT)}
								className="w-auto px-5 text-center"
								highlighted={matchEnum(
									selectedLeverageOption,
									MarginMode.DEFAULT
								)}
							>
								<Text.BODY2
									className={twMerge(
										matchEnum(selectedLeverageOption, MarginMode.DEFAULT) &&
											'text-text-emphasis'
									)}
								>
									Normal (up to 20x)
								</Text.BODY2>
							</Button.BigInput>
							{!isHighLeverageButtonDisabled ? (
								highLeverageModeButton
							) : (
								<Tooltip content="All high leverage mode spots are currently taken. Please check again later.">
									{highLeverageModeButton}
								</Tooltip>
							)}
						</div>
					</div>
					<div className="px-4 my-4">
						{removeMaxLeverage && (
							<InfoMessage
								type="warn"
								message={`Your subaccount's max leverage setting will be removed when switching to high leverage mode. (current: ${accountMaxLeverage}x)`}
							/>
						)}
					</div>
					{!isHighLeverageEnabled && (
						<div className="px-4 my-6 text-text-label">
							<CheckboxInput
								secondaryStyle
								label={
									'I understand that enabling higher leverage above 20x increases my risk of liquidation'
								}
								checked={hasAcceptedTerms}
								disabled={isHighLeverageEnabled}
								onChange={() => setHasAcceptedTerms((s) => !s)}
								alignStart
							/>
						</div>
					)}
					<div className="px-4 my-6">
						<Button.BigSemantic
							disabled={isSaveButtonDisabled}
							positive
							onClick={handleChangeLeverageMode}
						>
							Save Changes
						</Button.BigSemantic>
					</div>
				</>
			</Drawer.Body>
		</Drawer>
	);
}
