import { brandName } from '../../constants/constants';
import React from 'react';

const DriftGradientIcon = (
	props: React.ImgHTMLAttributes<HTMLImageElement>
) => {
	return <img src="/assets/icons/driftIcon.svg" alt={brandName} {...props} />;
};

export default DriftGradientIcon;
