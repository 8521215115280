'use client';

import { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDevSwitchIsOn from './useDevSwitchIsOn';
import useIsMainnet from './useIsMainnet';
import { GEOBLOCK_LIST } from '@drift/common';

export const getCountry = async () => {
	const result = await fetch(`https://geolocation.drift-labs.workers.dev/`, {
		cache: 'no-cache',
	});

	if (!result.ok) {
		// handle error
		return;
	}

	const country_code = await result.text();

	return country_code;
};

export const checkIfCountryIsBlocked = (countryCode: string) => {
	if (process.env.NEXT_PUBLIC_IGNORE_GEOBLOCK === 'true') {
		return false;
	}

	const countryIsBlocked =
		GEOBLOCK_LIST.find((country) => country.code === countryCode) && true;

	return countryIsBlocked;
};

const useGeolocation = () => {
	const setState = useDriftStore((s) => s.set);
	const isMainnnet = useIsMainnet();
	const devswitchIsOn = useDevSwitchIsOn();
	const [countryCode, setCountryCode] = useState('');

	const onlyGeoBlockMainnet =
		process.env.NEXT_PUBLIC_ONLY_GEOBLOCK_MAINNET === 'true';

	useEffect(() => {
		getCountry().then((fetchedCountryCode) => {
			setCountryCode(fetchedCountryCode);
		});
	}, []);

	useEffect(() => {
		const ignoreGeoBlock =
			process.env.NEXT_PUBLIC_IGNORE_GEOBLOCK === 'true' || devswitchIsOn;

		if ((onlyGeoBlockMainnet && !isMainnnet) || ignoreGeoBlock) {
			setState((s) => {
				s.isGeoblocked = false;
			});
		} else {
			const countryIsBlocked = checkIfCountryIsBlocked(countryCode);
			setState((s) => {
				s.countryCode = countryCode;
				s.isGeoblocked = countryIsBlocked;
			});
		}
	}, [onlyGeoBlockMainnet, isMainnnet, devswitchIsOn, countryCode]);

	return;
};

export default useGeolocation;
