'use client';

import { ENUM_UTILS, MarketId } from '@drift/common';
import { useContext, useEffect, useRef } from 'react';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useMarketStateStore, {
	MarketState,
	MarketSubscriptionState,
} from '../../stores/useMarketStateStore';
import SuccessAndFailureBuffer from '../../utils/SuccessAndFailureBuffer/SuccessAndFailureBuffer';
import useCurrentSlotRef from '../useCurrentSlotRef';
import Env, {
	ALL_TRADEABLE_MARKET_IDS,
} from '../../environmentVariables/EnvironmentVariables';
import { SlotContext } from '../../providers/currentSlotProvider';
import { dlog } from '../../dev';
import { usePageVisibility } from '../utils/usePageVisibility';
import { DriftWindow } from 'src/window/driftWindow';
import useDriftClient from 'src/hooks/useDriftClient';
import { MarketStatus } from '@drift-labs/sdk';

const APP_WIDE_ALLOWED_INITIAL_DELAY_MS = Env.initialDataSourceMaxDelay; // We allow this much time during setup for the first message to come through
const NEW_SUBSCRIPTION_ALLOWED_INITIAL_DELAY_MS = Env.newDataSourceSubMaxDelay; // This is how much time a message is allowed to come through when the app has already been setup and we're subscribing to a new market

const SLOT_SUBSCRIBER_COMMITMENT_OFFSET = 10; // Slot subscriber can only use processed commitment, UI uses confirmed, there is roughly an extra 10 slot delay.
const STALE_DATA_SLOT_CUTOFF = 50 + SLOT_SUBSCRIBER_COMMITMENT_OFFSET;

const USE_STRICT_FALLBACK = false; // If this is enabled then the fallback will be triggered instantly when staleness is detected, otherwise it uses uses the failure buffer and only falls back after FALLBACK_FAILURES_IN_A_ROW number of failures

const FALLBACK_FAILURES_IN_A_ROW = 3;

const UPGRADE_DELAY_MS = Env.upgradeDataSourceDelay;

const HEALTH_CHECK_FREQUENCY_MS = 2 * 1000; // 2 seconds

const APP_START_TIME = Date.now();

const dataHealthCheck = (
	marketId: MarketId,
	getMarketStateForMarket: (marketId: MarketId) => MarketState,
	getSubscriptionStateForMarket: (
		marketId: MarketId
	) => MarketSubscriptionState,
	handleFallback: (marketId: MarketId) => void,
	getLatestSlot: () => number,
	successAndFailureBuffer: SuccessAndFailureBuffer
) => {
	const currentMarketState = getMarketStateForMarket(marketId);
	const currentSubscriptionState = getSubscriptionStateForMarket(marketId);

	if (Date.now() - APP_START_TIME < APP_WIDE_ALLOWED_INITIAL_DELAY_MS) {
		return;
	}

	if (
		Date.now() - currentSubscriptionState.lastSubscriptionChangeTime <
		NEW_SUBSCRIPTION_ALLOWED_INITIAL_DELAY_MS
	) {
		return;
	}

	// # Check if we haven't fetched any initial data after the allowed timeout
	if (!currentMarketState) {
		dlog(
			`market_subscriptions`,
			`auto_switch_fallback ${marketId.key} => no currentMarketState`
		);
		handleFallback(marketId);
		return;
	}

	const latestOnChainSlot = getLatestSlot();
	const latestUpdateSlot = currentMarketState.derivedState.updateSlot;
	const diff = latestOnChainSlot - latestUpdateSlot;

	const isStale = diff >= STALE_DATA_SLOT_CUTOFF;

	if (isStale) {
		dlog(
			`market_subscriptions`,
			`${marketId.key} STALE => latestOnChainSlot: ${latestOnChainSlot} latestUpdateSlot: ${latestUpdateSlot} diff: ${diff}`
		);

		if (USE_STRICT_FALLBACK) {
			handleFallback(marketId);
			return;
		} else {
			successAndFailureBuffer.addResult('failure');
		}
	} else {
		if (USE_STRICT_FALLBACK) {
			// Do nothing
		} else {
			successAndFailureBuffer.addResult('success');
		}
	}

	if (!successAndFailureBuffer.isSuccess) {
		dlog(
			`market_subscriptions`,
			`auto_switch_fallback ${marketId.key} => TOO MUCH STALENESS`
		);
		handleFallback(marketId);
	}
};

const generateSuccessFailureBuffer = () => {
	return new SuccessAndFailureBuffer(
		FALLBACK_FAILURES_IN_A_ROW,
		(_bufferSize, _successCount, failureCount) => {
			return !(failureCount >= FALLBACK_FAILURES_IN_A_ROW);
		}
	);
};

/**
 * This hook is responsible for detecting when the current dlob data source is stale or not,
 * and switches to the fallback datasource if it is.
 */
const useDlobSourceFallback = () => {
	const fallbackDatasource = useMarketStateStore((s) => s.fallbackDatasource);
	const currentSlot = useContext(SlotContext);
	const currentSlotRef = useCurrentSlotRef(0);

	const pageIsVisible = usePageVisibility();

	const minorHealthCheckFailureBuffer = useRef<
		Map<string, SuccessAndFailureBuffer>
	>(
		ALL_TRADEABLE_MARKET_IDS.reduce((lookup, marketId) => {
			lookup.set(marketId.key, generateSuccessFailureBuffer());

			return lookup;
		}, new Map())
	);

	const getLastUpdateSlot = () => {
		return currentSlotRef.current;
	};

	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);

	const getMarketStateForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);

	const getSubscriptionStateForMarket = useMarketStateStore(
		(s) => s.getSubscriptionStateForMarket
	);

	const handleFallback = (marketId: MarketId) => {
		minorHealthCheckFailureBuffer.current.get(marketId.key).reset();
		fallbackDatasource(marketId);
	};

	useEffect(() => {
		currentSlotRef.current = currentSlot.currentSlot;
	}, [currentSlot.currentSlot]);

	const driftClient = useDriftClient();

	useEffect(() => {
		// # Check if the currently fetched data is stale.
		if (!pageIsVisible) {
			dlog(
				`market_subscriptions`,
				`skipping_auto_switch_fallback => tab_hidden`
			);
			// Data is allowed to be stale when the UI is idle
			return;
		}

		const interval = setInterval(() => {
			if (!driftClient?.isSubscribed) return;
			if (DriftWindow.DISABLE_AUTO_SWITCH_DLOB_SOURCE) return;

			ALL_TRADEABLE_MARKET_IDS.forEach((marketId) => {
				// Check and skip delisted markets
				// 🚨 :: NOTE this is a shitty solution but the _proper_ one I tried didn't work -- fow now just band-aid fixing because the UI is screwing up for me
				if (marketId.isPerp) {
					const perpMarketAccount = driftClient.getPerpMarketAccount(
						marketId.marketIndex
					);
					if (
						ENUM_UTILS.match(perpMarketAccount.status, MarketStatus.DELISTED)
					) {
						return;
					}
				}

				if (marketId.isSpot) {
					const spotMarketAccount = driftClient.getSpotMarketAccount(
						marketId.marketIndex
					);
					if (
						ENUM_UTILS.match(spotMarketAccount.status, MarketStatus.DELISTED)
					) {
						return;
					}
				}

				dataHealthCheck(
					marketId,
					getMarketStateForMarket,
					getSubscriptionStateForMarket,
					handleFallback,
					getLastUpdateSlot,
					minorHealthCheckFailureBuffer.current.get(marketId.key)
				);
			});
		}, HEALTH_CHECK_FREQUENCY_MS);

		return () => {
			clearInterval(interval);
		};
	}, [selectedMarketId, driftClient]);
};

/**
 * This hook is responsible for automatically switching to the best source of dlob.
 * For now it implements a simple logic to switch to the best possible source, and
 * allow it to fallback down by itself (handled by useDlobFallback)
 */
const useDlobSourceUpgrade = () => {
	const handleUpgradeDatasource = useMarketStateStore(
		(s) => s.upgradeDatasource
	);
	const getMarketSubscriptionState = useMarketStateStore(
		(s) => s.getSubscriptionStateForMarket
	);

	useEffect(() => {
		const upgradeAttemptInterval = setInterval(() => {
			if (DriftWindow.DISABLE_AUTO_SWITCH_DLOB_SOURCE) return;

			for (const marketId of ALL_TRADEABLE_MARKET_IDS) {
				const marketSubscriptionState = getMarketSubscriptionState(marketId);
				const _upgradeAttemptCount =
					marketSubscriptionState.upgradeAttemptCount;
				const subscriptionStartTime =
					marketSubscriptionState.lastSubscriptionChangeTime;

				// const upgradeDelay = UPGRADE_DELAY_MS * 2 ** upgradeAttemptCount;
				const upgradeDelay = UPGRADE_DELAY_MS; // Presently looks like fallbacks to the blockchain are happening semi-frequently from dlob going stale. We really want to avoid staying in this state so removing the exponential backoff on connection upgrades for now.

				if (Date.now() - subscriptionStartTime < upgradeDelay) {
					continue;
				}

				handleUpgradeDatasource(marketId);
			}
		}, 1000 * 5);

		return () => {
			clearInterval(upgradeAttemptInterval);
		};
	}, []);
};

const useDlobAutoSwitchSource = () => {
	useDlobSourceFallback();
	useDlobSourceUpgrade();
};

export default useDlobAutoSwitchSource;
