'use client';

import useWalletIsConnected from './useWalletIsConnected';
import { useEffect } from 'react';
import UI_UTILS from '../utils/uiUtils';
import { setLocalStorageItem } from '../utils/localStorageUtils';
import useAccountData from './useAccountData';
import Env from '../environmentVariables/EnvironmentVariables';

const useSyncSavedSubaccount = () => {
	const isConnected = useWalletIsConnected();
	const accountData = useAccountData();
	const env = Env.sdkEnv;

	// update the current subaccount in local storage
	useEffect(() => {
		if (accountData && isConnected) {
			const storageKey = UI_UTILS.getSubAccountStorageKey(
				accountData?.authority,
				env
			);
			setLocalStorageItem(storageKey, accountData?.userId?.toString());
		}
	}, [accountData?.userId, isConnected]);
};

export default useSyncSavedSubaccount;
