'use client';

import useDevStore from '../../stores/useDevStore';
import { notify } from '../../utils/notifications';
import Button from '../Button';

const DevMobileForm = (props: { onClose: () => void }) => {
	const clearCache = () => {
		notify({
			type: 'info',
			message: 'Cache cleared',
			description: 'Please refresh the page',
		});
		window.localStorage.clear();
	};

	const setDevStore = useDevStore((s) => s.set);

	const toggleRefCodeStatus = () => {
		setDevStore((s) => {
			s.showRefCode = !s.showRefCode;
		});
	};

	return (
		<div className="flex flex-col text-text-default">
			<Button size={'LARGE'} onClick={props.onClose}>
				Close
			</Button>
			<Button size={'LARGE'} onClick={toggleRefCodeStatus}>
				Toggle Show Ref Code Status
			</Button>
			<Button size={'LARGE'} onClick={clearCache}>
				Clear Cache
			</Button>
		</div>
	);
};

export default DevMobileForm;
