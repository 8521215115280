'use client';

import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	MAX_PREDICTION_PRICE,
	PerpMarketAccount,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { Typo } from '../Text/Typo';
import {
	PredictionMarketConfig,
	ResolutionOutcome,
} from 'src/@types/predictionMarkets';
import { No, Share, YES } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';
import { useTickedAccountData } from 'src/hooks/useAccountData';
import { COMMON_UI_UTILS, MarketId } from '@drift/common';
import Button from '../Button';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftTheme from 'src/hooks/useDriftTheme';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';
import { useSettledExpiredPositionsPnlHistory } from 'src/hooks/useSettlePnlHistory';
import { PERP_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useGetTickSizeForMarket, {
	getTickSizeDecimalPlaces,
} from 'src/hooks/useGetTickSizeForMarket';
import { useCallback } from 'react';

const YesNoOutcome = (props: { outcome: ResolutionOutcome }) => {
	const isOutcomeYes = props.outcome === ResolutionOutcome.Yes;

	if (props.outcome === ResolutionOutcome.Unresolved) {
		return null;
	}

	return (
		<Typo.H5
			className={twMerge(
				'flex items-center gap-2',
				isOutcomeYes
					? 'dark:text-positive-green text-text-positive-green-button'
					: 'dark:text-negative-red text-text-negative-red-button'
			)}
		>
			{isOutcomeYes ? <YES size={40} /> : <No size={40} />}
			<span>{isOutcomeYes ? 'YES' : 'NO'}</span>
		</Typo.H5>
	);
};

const SettlePositionDetailRow = (props: {
	label: string;
	value: React.ReactNode;
}) => {
	return (
		<div className="flex items-center justify-between w-full">
			<Typo.B4 className="text-text-secondary">{props.label}</Typo.B4>
			<Typo.T4 className="text-text-default">{props.value}</Typo.T4>
		</div>
	);
};

export const PredictionMarketClaimPnlCard = (props: {
	marketAccount: PerpMarketAccount;
	mobile?: boolean;
}) => {
	const currentAccountData = useTickedAccountData();
	const actions = useDriftActions();
	const setDriftStore = useDriftStore((s) => s.set);
	const isDarkTheme = useDriftTheme() === DriftTheme.dark;
	const perpMarketConfig = PERP_MARKETS_LOOKUP[props.marketAccount.marketIndex];
	const getTickSizeForMarket = useGetTickSizeForMarket();
	const marketId = MarketId.createPerpMarket(props.marketAccount.marketIndex);
	const tickSize = getTickSizeForMarket(marketId);
	const decimalPlaces = getTickSizeDecimalPlaces(tickSize);

	const expiredPositionSettlePnlRecords =
		useSettledExpiredPositionsPnlHistory();

	const expiredPositionSettlePnlRecordForCurrentMarket =
		expiredPositionSettlePnlRecords.find(
			(record) => record.marketIndex === props.marketAccount?.marketIndex
		);

	const userPosition = currentAccountData?.openPerpPositions.find(
		(pos) => pos.marketIndex === props.marketAccount?.marketIndex
	);
	const userHasOrHadPositionAfterResolution =
		!!userPosition || !!expiredPositionSettlePnlRecordForCurrentMarket;

	const getDisplayedData = useCallback((): {
		entryPrice: BN;
		direction: 'long' | 'short';
		baseSize: BN;
		pnl: BN;
		quoteEntryAmount: BN;
		resolutionPrice: BN;
	} => {
		if (userPosition && !userPosition?.baseSize?.eq(ZERO)) {
			// use details for current open position
			const direction = userPosition.direction;
			const isSellPredictionMarket = direction === 'short';
			const entryPrice = isSellPredictionMarket
				? MAX_PREDICTION_PRICE.sub(userPosition?.entryPrice ?? ZERO)
				: userPosition?.entryPrice ?? ZERO;

			const resolutionPrice = isSellPredictionMarket
				? MAX_PREDICTION_PRICE.sub(props.marketAccount?.expiryPrice ?? ZERO)
				: props.marketAccount?.expiryPrice ?? ZERO;

			const pnl = userPosition.pnlVsOracle;
			const baseSize = userPosition.baseSize.abs();
			const quoteEntryAmount = isSellPredictionMarket
				? entryPrice.mul(baseSize).div(BASE_PRECISION)
				: userPosition.quoteEntryAmount ?? ZERO;

			return {
				entryPrice,
				direction,
				baseSize,
				pnl,
				quoteEntryAmount,
				resolutionPrice,
			};
		} else if (
			expiredPositionSettlePnlRecordForCurrentMarket &&
			!expiredPositionSettlePnlRecordForCurrentMarket.baseAssetAmount.eqZero() // if a settlePnlRecord exists with a baseAssetAmount of 0, it means the position was closed before resolution but the PnL was not settled yet
		) {
			// use details for last settled P&L record
			// TODO: this seems wrong? showing entry price of 1 for user who still had a position unsettled
			const yesEntryPrice =
				expiredPositionSettlePnlRecordForCurrentMarket.baseAssetAmount.gtZero()
					? expiredPositionSettlePnlRecordForCurrentMarket.quoteEntryAmount
							.shift(BASE_PRECISION_EXP)
							.div(
								expiredPositionSettlePnlRecordForCurrentMarket.baseAssetAmount
							)
							.abs().val
					: ZERO;

			const direction =
				expiredPositionSettlePnlRecordForCurrentMarket.quoteEntryAmount.gte(
					ZERO
				)
					? 'short'
					: 'long';

			const isSellPredictionMarket = direction === 'short';
			const entryPrice = isSellPredictionMarket
				? MAX_PREDICTION_PRICE.sub(yesEntryPrice)
				: yesEntryPrice;
			const resolutionPrice = isSellPredictionMarket
				? MAX_PREDICTION_PRICE.sub(props.marketAccount?.expiryPrice ?? ZERO)
				: props.marketAccount?.expiryPrice ?? ZERO;

			const baseSize =
				expiredPositionSettlePnlRecordForCurrentMarket.baseAssetAmount.abs()
					.val;
			const pnl = resolutionPrice
				.sub(entryPrice)
				.mul(baseSize)
				.div(BASE_PRECISION);

			const quoteEntryAmount = isSellPredictionMarket
				? entryPrice.mul(baseSize).div(BASE_PRECISION)
				: expiredPositionSettlePnlRecordForCurrentMarket.quoteEntryAmount.val ??
				  ZERO;

			return {
				entryPrice,
				direction,
				baseSize,
				pnl,
				quoteEntryAmount,
				resolutionPrice,
			};
		} else {
			return {
				entryPrice: ZERO,
				direction: 'long',
				baseSize: ZERO,
				pnl: ZERO,
				quoteEntryAmount: ZERO,
				resolutionPrice: ZERO,
			};
		}
	}, [userPosition, expiredPositionSettlePnlRecordForCurrentMarket]);

	const {
		entryPrice,
		direction,
		baseSize,
		pnl,
		quoteEntryAmount,
		resolutionPrice,
	} = getDisplayedData();

	const isSellPredictionMarket = direction === 'short';
	const pnlPercentage = COMMON_UI_UTILS.calculatePnlPctFromPosition(
		pnl,
		quoteEntryAmount,
		1
	);
	const isPositivePnl = pnl.gte(ZERO);

	const resolutionOutcome = PredictionMarketConfig.getResolvedOutcome(
		props.marketAccount
	);

	const handleShowPnlModal = () => {
		if (!userHasOrHadPositionAfterResolution) {
			return;
		}

		const position = expiredPositionSettlePnlRecordForCurrentMarket
			? {
					marketSymbol: perpMarketConfig.symbol,
					marketIndex: props.marketAccount.marketIndex,
					direction,
					entryPrice,
					baseSize,
					quoteEntryAmount,
			  }
			: isSellPredictionMarket
			? { ...userPosition, entryPrice }
			: userPosition;

		setDriftStore((s) => {
			s.pnlModalPosition = {
				position,
				actualExitPrice: +BigNum.from(resolutionPrice, PRICE_PRECISION_EXP)
					.toNum()
					.toFixed(decimalPlaces),
				pnl: BigNum.from(pnl, QUOTE_PRECISION_EXP).toNum(),
				isClosed: true,
				isLpPosition: false,
			};
		});
		actions.showModal('showPnlModal');
	};

	const settlePositionPnl = () => {
		actions.settlePnl(props.marketAccount?.marketIndex).then(() => {
			handleShowPnlModal();
		});
	};

	if (resolutionOutcome === ResolutionOutcome.Unresolved) {
		return null;
	}

	return (
		<div
			className={twMerge(
				'flex flex-col gap-6 p-6 bg-container-bg text-text-emphasis',
				props.mobile ? 'grow' : 'border border-container-border rounded-b-lg'
			)}
		>
			<Typo.T1>Final Outcome</Typo.T1>

			<div
				className={twMerge(
					'w-full border border-stroke-secondary relative overflow-hidden flex justify-center items-center p-6',
					props.mobile ? 'grow min-h-[310px]' : 'h-[310px]'
				)}
			>
				{/** Backdrop */}
				<img
					src={`/assets/images/prediction-markets/outcome-backdrop-${
						isDarkTheme ? 'dark' : 'light'
					}.webp`}
					className={twMerge(
						'absolute',
						props.mobile ? 'inset-x-0 bottom-0' : 'inset-0'
					)}
				/>

				{/** Position Details */}
				<div className="z-10 flex flex-col items-center justify-center w-full">
					{userHasOrHadPositionAfterResolution ? (
						<div className="flex flex-col w-full gap-8">
							<div className="flex w-full">
								<YesNoOutcome outcome={resolutionOutcome} />
							</div>
							<div className="flex flex-col gap-4">
								<div className="flex flex-col">
									<Typo.B4>Your BET P&L</Typo.B4>
									<div
										className={twMerge(
											'flex items-center gap-2',
											isPositivePnl
												? 'dark:text-positive-green text-text-positive-green-button'
												: 'dark:text-negative-red text-text-negative-red-button'
										)}
									>
										<Typo.H5>
											{!isPositivePnl && '-'}$
											{BigNum.from(pnl, QUOTE_PRECISION_EXP)
												.abs()
												.prettyPrint(true)}
										</Typo.H5>
										<Typo.T2>
											{isPositivePnl && '+'}
											{pnlPercentage.toFixed(2)}%
										</Typo.T2>
									</div>
								</div>

								<div className="flex flex-col gap-2">
									<SettlePositionDetailRow
										label="Position"
										value={`${
											direction === 'long' ? 'YES' : 'NO'
										} ${BigNum.from(
											baseSize.abs(),
											BASE_PRECISION_EXP
										).prettyPrint()} shares`}
									/>
									<SettlePositionDetailRow
										label="Entry Price"
										value={`$${BigNum.from(
											entryPrice,
											PRICE_PRECISION_EXP
										).toFixed(decimalPlaces)}`}
									/>
									<SettlePositionDetailRow
										label="Resolution Price"
										value={`$${BigNum.from(resolutionPrice, PRICE_PRECISION_EXP)
											.toNum()
											.toFixed(decimalPlaces)}`}
									/>
								</div>
							</div>
						</div>
					) : (
						<YesNoOutcome outcome={resolutionOutcome} />
					)}
				</div>
			</div>

			{/** CTAs */}

			<div className="flex flex-col gap-4">
				{userPosition && (
					<Button.Secondary
						size="LARGE"
						highlight
						disabled={userPosition.unsettledPnl.eq(ZERO)}
						onClick={settlePositionPnl}
						className="capitalize typo-t2"
					>
						Claim{' '}
						{!userPosition || userPosition.unsettledPnl?.gte(ZERO) ? '' : '-'}
						{BigNum.from(
							userPosition?.unsettledPnl ?? ZERO,
							QUOTE_PRECISION_EXP
						)
							.abs()
							.toNotional()}
					</Button.Secondary>
				)}

				{userHasOrHadPositionAfterResolution && (
					<Button.Secondary
						size="LARGE"
						onClick={() => handleShowPnlModal()}
						className="flex items-center justify-center gap-1 capitalize typo-t2"
					>
						Share
						<Share size={20} />
					</Button.Secondary>
				)}
			</div>
		</div>
	);
};
