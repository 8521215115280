import {
	BN,
	PublicKey,
	InsuranceFundStake,
	getTokenAmount,
	SpotBalanceType,
	ZERO,
	unstakeSharesToAmount,
	BigNum,
	unstakeSharesToAmountWithOpenRequest,
	timeRemainingUntilUpdate,
	QUOTE_PRECISION_EXP,
	SpotMarketAccount,
} from '@drift-labs/sdk';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import { COMMON_UI_UTILS } from '@drift/common';

export interface IfData {
	userStake: {
		loaded: boolean;
		account?: InsuranceFundStake;
		costBasisBigNum?: BigNum;
		costBasis?: number;
		currentStakeBigNum?: BigNum;
		currentStake?: number;
		currentShares?: BN;
		unstakeRequest?: UnstakeRequest;
		currentStakeNotionalValue?: BigNum;
	};
	vault: {
		loaded: boolean;
		account?: {
			vault: PublicKey;
			totalShares: BN;
			userShares: BN;
			sharesBase: BN;
			unstakingPeriod: BN;
			lastRevenueSettleTs: BN;
			revenueSettlePeriod: BN;
			totalFactor: number;
			userFactor: number;
		};
		revenuePoolBigNum?: BigNum;
		totalStakeBigNum?: BigNum;
		totalStake?: number;
		totalStakeNotionalValue?: BigNum;
		nextPayoutTotal?: number;
		/**
		 * How much will be paid out to stakers on the next settle if the revenue pool stays the same
		 */
		nextPayoutForStakers?: number;
		/**
		 * The actual ratio of the current revenue pool that will be paid out to stakers (e.g.: 0.05 means 5% of the pool is paid to stakers each settle period)
		 */
		actualRatioForStakers?: number;
		/*
		 * Ratio of each settlement payout that goes to stakers (rest goes to protocol)
		 */
		ratioForStakers?: number;
		/**
		 * Ratio of the revenue pool balance paid out each settle period
		 */
		payoutRatio?: number;
		/*
		 * Revenue pool balance as a number
		 */
		revenuePool?: number;
		/**
		 * Next APR as a percentage (e.g.: 35 means 35% APR)
		 */
		nextApr?: number;
		/*
		 * Max APR of staker and protocol share combined. multiply by ratioForStakers to get stakers' max APR)
		 */
		maxApr?: number;
		/*
		 * Seconds until next revenue settle
		 */
		secondsUntilNextRevenueSettle?: number;
		/**
		 * Timestamp of last revenue settle in ms, can be used to create a js Date
		 */
		lastRevenueSettleMs?: number;
		revenueSettlePeriodMinutes?: number;
	};
	pausedOperations: string[];
	marketIndex: number;
}

export const MAX_APR = 1000;

export interface UnstakeRequest {
	amountBigNum: BigNum;
	amount: number;
	timestamp: number;
}

/**
 * Fetches insurance fund data one time for the given spot market
 *
 * @param spotMarket	Spot market account
 * @param oraclePrice	Current oracle price of the given spot market
 * @param connected		Is the user connected to a wallet?
 */
export const fetchInsuranceFundData = async (
	spotMarket: SpotMarketAccount,
	oraclePrice: number,
	vaultBalanceBigNum: BigNum,
	userIfStakeAccount?: InsuranceFundStake,
	connected?: boolean
) => {
	const { precisionExp } = SPOT_MARKETS_LOOKUP[spotMarket.marketIndex];

	const newIfData: IfData = {
		userStake: {
			loaded: false,
		},
		vault: {
			loaded: false,
		},
		pausedOperations: [],
		marketIndex: undefined,
	};

	let ifStakeAccount: InsuranceFundStake | null = null;
	let hasExistingUnstakeRequest = false;

	if (connected) {
		// Get the user's IF stake account for the market (if it exists)
		try {
			ifStakeAccount = userIfStakeAccount;

			if (
				ifStakeAccount?.lastWithdrawRequestValue &&
				ifStakeAccount.lastWithdrawRequestValue.gt(ZERO)
			) {
				hasExistingUnstakeRequest = true;

				const unstakeRequestAmountBigNum = BigNum.from(
					ifStakeAccount.lastWithdrawRequestValue,
					precisionExp
				);

				newIfData.userStake.unstakeRequest = {
					amountBigNum: unstakeRequestAmountBigNum,
					amount: unstakeRequestAmountBigNum.toNum(),
					timestamp: ifStakeAccount?.lastWithdrawRequestTs.toNumber() * 1000,
				};
			}

			newIfData.userStake.account = ifStakeAccount;
			newIfData.userStake.loaded = true;
		} catch (err) {
			// console.log(err);
			newIfData.userStake.account = undefined;
			newIfData.userStake.loaded = true;
		}
	} else {
		newIfData.userStake.account = undefined;
		newIfData.userStake.loaded = true;
	}

	// Get global stats for the market's IF vault
	try {
		const vaultBalance = vaultBalanceBigNum.toNum();

		// Conmputing the APR:
		const revenuePoolBN = getTokenAmount(
			spotMarket.revenuePool.scaledBalance,
			spotMarket,
			SpotBalanceType.DEPOSIT
		);
		const revenuePoolBigNum = BigNum.from(revenuePoolBN, precisionExp);
		const revenuePool = revenuePoolBigNum.toNum();

		const payoutRatio = 0.1;
		const ratioForStakers =
			spotMarket.insuranceFund.totalFactor > 0 &&
			spotMarket.insuranceFund.userFactor > 0
				? spotMarket.insuranceFund.userFactor /
				  spotMarket.insuranceFund.totalFactor
				: 0;
		const actualRatioForStakers = ratioForStakers * payoutRatio;

		// Settle periods from on-chain data:
		const revSettlePeriod =
			spotMarket.insuranceFund.revenueSettlePeriod.toNumber() * 1000;

		const settlesPerYear = 31536000000 / revSettlePeriod;

		const projectedAnnualRev = revenuePool * settlesPerYear * payoutRatio;

		const uncappedApr =
			vaultBalance === 0 ? 0 : (projectedAnnualRev / vaultBalance) * 100;
		const cappedApr = Math.min(uncappedApr, MAX_APR);

		const nextApr = cappedApr * ratioForStakers;

		const nextPayoutTotal =
			revenuePool * payoutRatio * (cappedApr / uncappedApr);
		const nextPayoutForStakers = nextPayoutTotal * ratioForStakers;

		const lastRevSettle =
			spotMarket.insuranceFund.lastRevenueSettleTs.toNumber() * 1000;

		const currentStakeBN = hasExistingUnstakeRequest
			? unstakeSharesToAmountWithOpenRequest(
					ifStakeAccount?.ifShares || new BN(0),
					ifStakeAccount?.lastWithdrawRequestShares || new BN(0),
					ifStakeAccount?.lastWithdrawRequestValue || new BN(0),
					spotMarket.insuranceFund.totalShares,
					vaultBalanceBigNum.val
			  )
			: unstakeSharesToAmount(
					ifStakeAccount?.ifShares || new BN(0),
					spotMarket.insuranceFund.totalShares,
					vaultBalanceBigNum.val
			  );

		const currentStakeBigNum = BigNum.from(currentStakeBN, precisionExp);
		const currentStake = currentStakeBigNum.toNum();

		const costBasisBigNum = BigNum.from(
			ifStakeAccount?.costBasis || ZERO,
			precisionExp
		);
		const costBasis = costBasisBigNum.toNum();

		const timeUntilNextSettleBn = timeRemainingUntilUpdate(
			new BN(Math.round(Date.now() / 1000)),
			spotMarket.insuranceFund.lastRevenueSettleTs,
			spotMarket.insuranceFund.revenueSettlePeriod
		);

		newIfData.userStake.costBasisBigNum = costBasisBigNum;
		newIfData.userStake.costBasis = costBasis;
		newIfData.userStake.currentStakeBigNum = currentStakeBigNum;
		newIfData.userStake.currentStake = currentStake;
		newIfData.userStake.currentShares = ifStakeAccount?.ifShares;
		newIfData.userStake.currentStakeNotionalValue = BigNum.fromPrint(
			`${currentStakeBigNum.toNum() * oraclePrice}`,
			QUOTE_PRECISION_EXP
		);

		newIfData.vault.nextApr = revSettlePeriod === 0 ? 0 : nextApr;

		newIfData.vault.nextPayoutTotal = nextPayoutTotal;
		newIfData.vault.nextPayoutForStakers = nextPayoutForStakers;
		newIfData.vault.actualRatioForStakers = actualRatioForStakers;
		newIfData.vault.ratioForStakers = ratioForStakers;
		newIfData.vault.revenuePool = revenuePool;
		newIfData.vault.payoutRatio = payoutRatio;
		newIfData.vault.revenuePoolBigNum = revenuePoolBigNum;
		newIfData.vault.totalStakeBigNum = vaultBalanceBigNum;
		newIfData.vault.totalStake = vaultBalance;
		newIfData.vault.account = spotMarket.insuranceFund;
		newIfData.vault.loaded = true;
		newIfData.vault.secondsUntilNextRevenueSettle =
			timeUntilNextSettleBn.toNumber();
		newIfData.vault.lastRevenueSettleMs = lastRevSettle;
		newIfData.vault.maxApr = MAX_APR;
		newIfData.vault.revenueSettlePeriodMinutes = revSettlePeriod / 1000 / 60;

		const quoteBalance = BigNum.fromPrint(
			`${vaultBalanceBigNum.toNum() * oraclePrice}`,
			QUOTE_PRECISION_EXP
		);

		newIfData.vault.totalStakeNotionalValue = quoteBalance;
		newIfData.pausedOperations =
			COMMON_UI_UTILS.getPausedOperations(spotMarket);
		newIfData.marketIndex = spotMarket.marketIndex;
	} catch (err) {
		// console.log(err);
		newIfData.vault.loaded = true;
		newIfData.vault.account = undefined;
	}

	return newIfData;
};
