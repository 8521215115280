'use client';

import useDriftAccountStore from '../stores/useDriftAccountsStore';

const useCurrentUserAccount = () => {
	const userAccount = useDriftAccountStore(
		(s) => s?.accounts?.[s?.currentUserKey]
	);
	return userAccount;
};

export default useCurrentUserAccount;
