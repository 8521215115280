'use client';

import {
	BN,
	BigNum,
	L2OrderBook,
	OraclePriceData,
	PRICE_PRECISION_EXP,
	uncrossL2,
} from '@drift-labs/sdk';
import { useCallback } from 'react';
import useCurrentUserBidsAndAsks from '../useCurrentUserBidsAndAsks';
import useMarketInfoDisplayData from '../useMarketInfoDisplayData';
import useGetTickSizeForMarket from '../useGetTickSizeForMarket';
import { L2WithOracle } from './dlobServerUtils';
import { MarketId } from '@drift/common';

export const useUncrossOrderbook = () => {
	const { getUserBidsAndAsksForMarket } = useCurrentUserBidsAndAsks();
	const marketData = useMarketInfoDisplayData();

	const getTickSizeForMarket = useGetTickSizeForMarket();

	const uncrossL2OrderbookWithOracle = useCallback(
		(l2WithOracle: L2WithOracle, marketId: MarketId) => {
			const userBidsAndAsksForMarket = getUserBidsAndAsksForMarket(
				marketId.key
			);

			return uncrossL2(
				l2WithOracle.bids,
				l2WithOracle.asks,
				l2WithOracle.oracleData.price,
				new BN(+marketData.oracleTwapLive),
				new BN(+marketData.markTwapLive),
				getTickSizeForMarket(marketId),
				new Set(
					userBidsAndAsksForMarket.bids.map((b) =>
						BigNum.fromPrint(b.price.toString(), PRICE_PRECISION_EXP).toString()
					)
				),
				new Set(
					userBidsAndAsksForMarket.asks.map((a) =>
						BigNum.fromPrint(a.price.toString(), PRICE_PRECISION_EXP).toString()
					)
				)
			);
		},
		[getTickSizeForMarket, marketData.markTwapLive, getUserBidsAndAsksForMarket]
	);

	const uncrossL2Orderbook = useCallback(
		(
			l2Orderbook: L2OrderBook,
			oraclePriceData: OraclePriceData,
			marketId: MarketId
		) => {
			const userBidsAndAsksForMarket = getUserBidsAndAsksForMarket(
				marketId.key
			);

			return uncrossL2(
				l2Orderbook.bids,
				l2Orderbook.asks,
				oraclePriceData.price,
				new BN(+marketData.oracleTwapLive),
				new BN(+marketData.markTwapLive),
				getTickSizeForMarket(marketId),
				new Set(
					userBidsAndAsksForMarket.bids.map((b) =>
						BigNum.fromPrint(b.price.toString(), PRICE_PRECISION_EXP).toString()
					)
				),
				new Set(
					userBidsAndAsksForMarket.asks.map((a) =>
						BigNum.fromPrint(a.price.toString(), PRICE_PRECISION_EXP).toString()
					)
				)
			);
		},
		[getTickSizeForMarket, marketData.markTwapLive, getUserBidsAndAsksForMarket]
	);

	return {
		uncrossL2OrderbookWithOracle,
		uncrossL2Orderbook,
	};
};
