'use client';

import { useEffect } from 'react';
import { VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY } from 'src/constants/vaults/misc';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import UI_UTILS from 'src/utils/uiUtils';

// Add local storage keys here, that are to be synced with the DriftStore
const LOCAL_STORAGE_KEYS: {
	key: string;
	storeKey: keyof DriftStore;
	checkFn: (val: string) => any;
}[] = [
	{
		key: 'isGeoblocked',
		storeKey: 'isGeoblocked',
		checkFn: (val: string) => val === 'true',
	},
	{
		key: 'hasAcknowledgedAlphaTicket',
		storeKey: 'hasAcknowledgedAlphaTicket',
		checkFn: (val: string) => val === 'true',
	},
	{
		key: 'hasCompletedOnboarding',
		storeKey: 'hasCompletedOnboarding',
		checkFn: (val: string) => val === 'true',
	},
	{
		key: VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY,
		storeKey: VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY,
		checkFn: (val: string) => val === 'true',
	},
];

export const useSyncLocalStorage = () => {
	const setDriftStore = useDriftStore((s) => s.set);

	useEffect(() => {
		if (UI_UTILS.isWindowDefined() && window?.localStorage?.getItem) {
			LOCAL_STORAGE_KEYS.forEach(({ key, storeKey, checkFn }) => {
				const value = window.localStorage.getItem(key);
				const parsedValue = checkFn(value);

				setDriftStore((s) => {
					// @ts-ignore
					s[storeKey] = parsedValue;
				});
			});
		}
	}, []);
};
