'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	PerpMarketDetailsMemo,
	SpotMarketDetailsMemo,
} from '../PerpPageStats/ViewDetailsForMarket';
import Modal from './Modal';

const MarketDetailsModal = () => {
	const isSpot = useDriftStore((s) => s.selectedMarket?.current?.isSpot);
	const currentMarket = useDriftStore((s) => s.selectedMarket?.current?.market);
	const setState = useDriftStore((s) => s.set);

	const hideMarketDetailsModal = () => {
		setState((s) => {
			s.modals.showMarketDetailsModal = false;
		});
	};

	return (
		<Modal onClose={hideMarketDetailsModal}>
			<Modal.Body>
				<Modal.Header onClose={hideMarketDetailsModal} showX>
					<Modal.Title>
						{currentMarket.symbol}
						{isSpot} Market Details
					</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					{isSpot ? (
						<SpotMarketDetailsMemo
							marketIndex={currentMarket?.marketIndex || 0}
						/>
					) : (
						<PerpMarketDetailsMemo
							marketIndex={currentMarket?.marketIndex || 0}
						/>
					)}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default MarketDetailsModal;
