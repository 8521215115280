'use client';

import { User } from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	UISerializableAccountSnapshot,
	UISnapshotHistory,
} from '@drift/common';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useHistoryFetcher from './useHistoryFetcher';

// TODO-v2 make more granular with funding? for now will roll funding pnl into perp pnl
export interface PnLTimeSeriesDataPoint {
	date: Date;
	totalPnl?: number;
	accountValue?: number;
	referralRewards?: number;
	referralVolume?: number;
	referralCount?: number;
	hideTooltip?: boolean;
}

export const accountPnlSnapshotToPnlSeriesDatapoint = (
	snapshot: UISerializableAccountSnapshot
): PnLTimeSeriesDataPoint => {
	if (!snapshot) return;
	return {
		date: new Date(snapshot.epochTs * 1000),
		totalPnl: snapshot.allTimeTotalPnl.toNum(),
		accountValue: snapshot.totalAccountValue.toNum(),
		referralRewards: snapshot.cumulativeReferralReward?.toNum() ?? 0,
		referralVolume: snapshot.cumulativeReferralVolume?.toNum() ?? 0,
		referralCount: snapshot.cumulativeReferralCount,
	};
};

const usePnlHistory = () => {
	const getState = useDriftStore((s) => s.get);

	const setAccountsStore = useDriftAccountsStore((s) => s.set);

	const setUserPnlHistory = (snapshots: UISnapshotHistory, userKey: string) => {
		setAccountsStore((s) => {
			s.accounts[userKey].pnlHistory = {
				...snapshots,
				initialHistoryLoaded: true,
			};
			s.accounts[userKey].latestSnapshot =
				snapshots.DAY[snapshots.DAY.length - 1];
		});
	};

	// Get the initial trade history, and find out what the total size of the trade history is for the user - to help with pagination
	const getNewSnapshotsFromHistoryServer = async (users: User[]) => {
		const isConnected =
			getState().wallet.current.adapter.connected &&
			getState().connection.current;
		if (!isConnected) return;

		const pubKeys = users.map((user) => user.getUserAccountPublicKey());
		const userKeys = users.map((user) =>
			COMMON_UI_UTILS.getUserKey(
				user.getUserAccount().subAccountId,
				user.getUserAccount().authority
			)
		);

		const userSnapshotsResult =
			await ExchangeHistoryClient.getSnapshotsForUsers(pubKeys);

		if (!userSnapshotsResult.success) return false;

		if (userSnapshotsResult.success) {
			userKeys.forEach((userKey, index) => {
				const snapshotsForUser = userSnapshotsResult?.body?.data?.[index];

				setUserPnlHistory(snapshotsForUser, userKey);
			});
		}

		return true;
	};

	useHistoryFetcher(getNewSnapshotsFromHistoryServer, 'pnlHistory');
};

export default usePnlHistory;
