import { Smoothing } from 'src/@types/vaults';

/**
 * Calculate an adjusted alpha value based on the last time the alpha was calculated,
 * this way we can account for irregularities in sampling period.
 * @param halflifePeriod - The halflife period (must match deltaPeriod units).
 * @param deltaPeriod - The time since the last sample (must match halflifePeriod units), will be clamped to 0.
 * @returns The alpha value.
 */
export function calculateAlpha(
	halflifePeriod: number,
	deltaPeriod: number
): number {
	// Math.log is natural log (base e)
	return (
		1 - Math.exp((Math.log(0.5) / halflifePeriod) * Math.max(deltaPeriod, 0))
	);
}

export function calculateUpdatedEwma(
	alpha: number,
	newSample: number,
	lastSample: number
): number {
	return alpha * newSample + (1 - alpha) * lastSample;
}

/**
 * Exponential Weighted Moving Average (EWMA) class.
 *
 * It supports irregular sampling intervals by adjusting alpha based
 * on the time delta between samples.
 */
export class Ewma implements Smoothing {
	private halfLifePeriodMs: number;
	private currentFilteredValue: number | undefined;
	private lastSampleTime: number | undefined;

	/**
	 * Constructor for the Ewma class.
	 * @param {number} halfLifeMs - The milliseconds when the filtered value is halved.
	 */
	constructor(halfLifeMs: number) {
		this.halfLifePeriodMs = halfLifeMs;
	}

	public reset() {
		this.currentFilteredValue = undefined;
		this.lastSampleTime = undefined;
	}

	/**
	 * Update the current average with a new value.
	 * @param {number} newSample - The new value.
	 * @param {number} currentTimeMs - The current time in ms.
	 * @return {number} - The updated average.
	 */
	public sample(newSample: number, currentTimeMs: number): number {
		if (
			this.currentFilteredValue === undefined ||
			this.lastSampleTime === undefined
		) {
			this.currentFilteredValue = newSample;
			this.lastSampleTime = currentTimeMs;
		} else {
			const deltaTime = currentTimeMs - this.lastSampleTime;
			const alpha = calculateAlpha(this.halfLifePeriodMs, deltaTime);
			this.currentFilteredValue = calculateUpdatedEwma(
				alpha,
				newSample,
				this.currentFilteredValue
			);
			this.lastSampleTime = currentTimeMs;
		}
		return this.currentFilteredValue;
	}

	/**
	 * Set the last sample value and time.
	 * @param {number} lastSample - The last sample value.
	 * @param {number} lastSampleTimeMs - The last sample time in ms.
	 */
	public setLastSample(lastSample: number, lastSampleTimeMs: number): void {
		this.currentFilteredValue = lastSample;
		this.lastSampleTime = lastSampleTimeMs;
	}

	/**
	 * Get the current ewma value.
	 * @return {number | undefined} - The current value.
	 */
	public getFilteredValue(): number | undefined {
		return this.currentFilteredValue;
	}
}
