'use client';

import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useInterval from './useInterval';

const REFRESH_RATE_SECS = 60 * 5;

const use24hPricePoints = () => {
	const setState = useDriftStore((s) => s.set);

	const pull24hPricePoints = async () => {
		const newPricePoints = await ExchangeHistoryClient.get24HourPricePoints();
		set24hPricePoints(newPricePoints?.body?.data ?? []);
	};

	const set24hPricePoints = (newData) => {
		setState((s) => {
			s.pricePoints24H = newData;
		});
	};

	// update every 5 min (no rush for this as of now, dont )
	useInterval(() => {
		pull24hPricePoints();
	}, 1000 * REFRESH_RATE_SECS);
};

export default use24hPricePoints;
