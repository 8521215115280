import React from 'react';
import { Portal } from 'react-portal';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { ZINDEX } from '../../utils/uiUtils';

const ProgressAnimation = keyframes`
  0% {
    width: 30%;
    margin-left: -30%;
  }
  100% {
    width: 0%;
    margin-left: 100%;
  }
`;

const LoadingBarOuter = styled.div`
	display: block;
	width: 100%;
	height: 6px;
	margin: 0;
	background-color: rgba(0, 0, 0, 0.34);
`;

const LoadingBarInner = styled.div`
	width: 50%;
	height: 100%;
	margin-left: 0;
	background: linear-gradient(
			114.67deg,
			rgba(255, 255, 255, 0.2) 16.59%,
			rgba(0, 0, 0, 0) 56.74%
		),
		linear-gradient(137.87deg, #f6f063 5.36%, rgba(224, 119, 116, 0) 62.77%),
		linear-gradient(83.36deg, #ff3873 3.72%, #711eff 46.75%, #3fe5ff 94.51%);

	animation-name: ${ProgressAnimation};
	animation-duration: 1000ms;
	animation-timing-function: ease-in-out;
	animation-play-state: running;
	animation-iteration-count: infinite;
`;

const PageBottomLoadingBar = (props: { visible: boolean }) => {
	return props.visible ? (
		<Portal>
			<LoadingBarOuter
				className={`fixed ${ZINDEX.onTop} bottom-0 w-screen h-5 ${
					props.visible ? `visible` : `invisible`
				}`}
			>
				<LoadingBarInner />
			</LoadingBarOuter>
		</Portal>
	) : null;
};

export default React.memo(PageBottomLoadingBar);
