'use client';

import useDlobAutoSwitchSource from './useDlobAutoSwitchSource';
import useSyncMarketDataWithBlockchain from './useSyncMarketDataWithBlockchain';
import useSyncMarketDataWithDlobServerPolling from './useSyncMarketDataWithDlobServerPolling';
import useSyncBackgroundDlobWithServerWebsocket from './useSyncMarketDataWithServerWebsocket';
import useSyncMarketSubscriptions from './useSyncMarketSubscriptions';
import useSyncRecentTradesWithServerWebsocket from './useSyncRecentTradesWithServerWebsocket';
import { useTrackDlobSourceMetrics } from './useTrackDlobSourceMetrics';

const useSyncMarketDataState = () => {
	useSyncMarketSubscriptions();
	useSyncMarketDataWithDlobServerPolling();
	useSyncMarketDataWithBlockchain();
	useSyncBackgroundDlobWithServerWebsocket();
	useSyncRecentTradesWithServerWebsocket();
	useDlobAutoSwitchSource();
	useTrackDlobSourceMetrics();
};

export default useSyncMarketDataState;
