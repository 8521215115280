import React from 'react';

const Twitter = (props: { className?: string; color?: string }) => {
	return (
		<a
			href="https://twitter.com/DriftProtocol"
			target="_blank"
			rel="noreferrer noopener"
		>
			<div className={`${props.className ?? ''}`}>
				<svg
					className="h-full"
					viewBox="0 0 14 11"
					fill={props.color ?? '#6B6F74'}
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M13.5448 1.27697C13.0485 1.49334 12.5152 1.64002 11.9556 1.70572C12.5267 1.36856 12.9656 0.834942 13.1719 0.199112C12.6373 0.511053 12.0453 0.738041 11.4149 0.860163C10.9105 0.330525 10.1918 0 9.39618 0C7.86834 0 6.62976 1.21923 6.62976 2.72318C6.62976 2.9369 6.65403 3.14464 6.70123 3.34375C4.40206 3.23026 2.36315 2.14642 0.998488 0.498443C0.76048 0.900649 0.623609 1.36856 0.623609 1.86767C0.623609 2.81278 1.11176 3.64574 1.8541 4.13422C1.40034 4.12029 0.974215 3.9975 0.600685 3.79374C0.600685 3.80503 0.600685 3.81631 0.600685 3.82825C0.600685 5.1477 1.55406 6.24813 2.81962 6.49834C2.58768 6.56073 2.34293 6.59392 2.09076 6.59392C1.91276 6.59392 1.73948 6.57666 1.57025 6.5448C1.9222 7.62664 2.94435 8.41446 4.15462 8.43636C3.20798 9.1671 2.01457 9.60249 0.718677 9.60249C0.495503 9.60249 0.275026 9.58988 0.0585938 9.56466C1.28302 10.3372 2.73736 10.7885 4.29958 10.7885C9.38808 10.7885 12.1714 6.63905 12.1714 3.03977C12.1714 2.92163 12.1687 2.80416 12.1633 2.68734C12.7047 2.30306 13.174 1.8232 13.5448 1.27697Z"
						fill={props.color ?? '#6B6F74'}
					/>
				</svg>
			</div>
		</a>
	);
};

export default Twitter;
