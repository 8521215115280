'use client';

import useShowInsuranceWarningForMarket from '../../hooks/useShowInsuranceWarningForMarket';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import Button from '../Button';
import Link from '../Utils/Link';
import Modal from './Modal';

const NoInsuranceFundForMarketWarningModal = () => {
	const currentlySelectedMarketName = useDriftStore(
		(s) => s.selectedMarket.name
	);
	const { setNoInsuranceWarningSeen } = useShowInsuranceWarningForMarket();

	const handleUnderstood = () => {
		setNoInsuranceWarningSeen();
	};

	return (
		<Modal onClose={() => {}}>
			<Modal.Body>
				<Modal.Header onClose={() => {}} showX={false} className="">
					<div className="text-lg">{currentlySelectedMarketName}</div>
				</Modal.Header>
				<Modal.Content className="px-4 font-light md:px-6 text-md">
					<div className="mb-4">
						This market is operating with limited insurance for levered losses.
					</div>
					<div className="flex mb-6">
						<span>Learn more about isolated market insurance funds </span>
						<Link
							className="inline ml-1"
							href="https://docs.drift.trade/insurance-fund/insurance-fund-intro"
						>
							here
						</Link>
						{'.'}
					</div>
					<Button.BigSemantic positive onClick={handleUnderstood}>
						I understand
					</Button.BigSemantic>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default NoInsuranceFundForMarketWarningModal;
