'use client';

import { useEffect } from 'react';
import {
	checkIfPreLaunchMarketWarningRequired,
	getIsPreLaunchMarket,
} from '../components/PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import useDriftActions from './useDriftActions';
import useInfoForCurrentlySelectedMarket from './useInfoForCurrentlySelectedMarket';

const useShowPreLaunchMarketWarningAlert = () => {
	const selectedMarket = useInfoForCurrentlySelectedMarket();
	const actions = useDriftActions();

	const checkAndShowPreLaunchWarningAlert = () => {
		const shouldShow = checkIfPreLaunchMarketWarningRequired();
		if (shouldShow) {
			actions.showModal('showPreLaunchMarketWarningAlert', true);
		}
	};

	useEffect(() => {
		if (
			!getIsPreLaunchMarket(
				selectedMarket?.info?.marketId,
				selectedMarket?.info?.account
			)
		) {
			return;
		}

		checkAndShowPreLaunchWarningAlert();
	}, [selectedMarket?.info?.marketId?.key]);
};

export default useShowPreLaunchMarketWarningAlert;
