'use client';

import Text from 'src/components/Text/Text';
import React, { useState } from 'react';
import Modal from './Modal';
import StakeModalContent from '../StakeModalContent';
import UnstakeModalContent from '../UnstakeModalContent';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

type StakeSide = 'stake' | 'unstake';

const StakeModal = ({
	onClose,
	id,
	initialSide = 'stake',
}: {
	onClose: () => void;
	id?: string;
	initialSide?: StakeSide;
}) => {
	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);
	const [currentSelection, setCurrentSelection] = useState(initialSide);
	const isDriftStaking = useDriftStore(
		(s) => s.modalsProps.showStakeModal?.isDriftStaking
	);

	return (
		<Modal onClose={onClose} id={id}>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>
						{isDriftStaking ? 'DRIFT Staking' : 'Insurance Fund Staking'}
					</Text.H2>
				</Modal.Header>

				<Modal.Navigation
					currentSelection={currentSelection}
					onChange={(selection) => setCurrentSelection(selection as StakeSide)}
					options={[
						{ value: 'stake', label: 'Stake', disabled: isGeoblocked },
						{ value: 'unstake', label: 'Unstake' },
					]}
					className="justify-start"
				/>

				{currentSelection === 'stake' ? (
					<StakeModalContent onClose={onClose} />
				) : currentSelection === 'unstake' ? (
					<UnstakeModalContent onClose={onClose} />
				) : null}
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(StakeModal);
