'use client';

import {
	PriorityFeeStrategy,
	PriorityFeeSubscriber,
	PublicKey,
} from '@drift-labs/sdk';
import { Connection } from '@solana/web3.js';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import Env from '../../environmentVariables/EnvironmentVariables';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { FEE_SUBSCRIPTION_SLOT_LOOKBACK } from './consts';

const EXTRA_POLLING_MULTIPLIER = Env.feeSubscriptionPollingMultiplier;

const PRIORITY_FEE_SUBSCRIPTION_ADDRESSES = [
	new PublicKey('8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6'), // sol openbook market
	new PublicKey('8UJgxaiQx5nTrdDgph5FiahMmzduuLTLf5WmsPegYA6W'), // sol perp
	new PublicKey('6gMq3mRCKf8aP3ttTyYhuijVZ2LGi14oDsBbkgubfLB3'), // usdc
];

const createPriorityFeeSubscriber = (
	connection: Connection,
	frequencyMs: number,
	slotsToCheck: number,
	customStrategy: PriorityFeeStrategy
) => {
	const priorityFeeSubscriber = new PriorityFeeSubscriber({
		connection,
		frequencyMs,
		addresses: PRIORITY_FEE_SUBSCRIPTION_ADDRESSES,
		slotsToCheck,
		customStrategy,
	});

	return priorityFeeSubscriber;
};

const usePriorityFeeSubscriber = (priorityFeeStrategy: PriorityFeeStrategy) => {
	const subscriber = useRef<PriorityFeeSubscriber>(undefined);
	const pollingMultiplier = useDriftStore((s) => s.pollingMultiplier);
	const connection = useDriftStore((s) => s.connection.current);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const [{ priorityFee: userPriorityFeeSetting }] = useCurrentSettings();

	const pollingFrequencyMs =
		Env.pollingFrequencyMs * pollingMultiplier * EXTRA_POLLING_MULTIPLIER;

	// The params that subscribing depend on can change in bursts, but we don't want to subscribe+unsubscribe in quick bursts because it's unnecessary. For this reason we're going to make a ticker to sync with these params, and only update it with a debounced hook.
	const [subscribeUpdateTicker, setSubscribeUpdateTicker] = useState([]);

	// The debounced hook which can only update the ticker at a maximum speed
	useDebounce(
		() => {
			setSubscribeUpdateTicker([]);
		},
		1000,
		[connection, driftClient, pollingMultiplier, userPriorityFeeSetting]
	);

	// HOOK TO MANAGE SUBSCRIPTION -- Debounce this so that we don't subscribe and unsubscribe in crazy bursts when the dependencies change
	useEffect(() => {
		if (!connection) return;
		if (!driftClient) return;

		(async () => {
			const priorityFeeSubscriber = createPriorityFeeSubscriber(
				connection,
				pollingFrequencyMs,
				FEE_SUBSCRIPTION_SLOT_LOOKBACK,
				priorityFeeStrategy
			);

			subscriber.current = priorityFeeSubscriber;

			await priorityFeeSubscriber.subscribe();
		})();

		return () => {
			subscriber.current?.unsubscribe();
		};
	}, [subscribeUpdateTicker]);

	useEffect(() => {
		if (subscriber.current) {
			(async () => {
				await subscriber.current.unsubscribe();
				subscriber.current.frequencyMs =
					Env.pollingFrequencyMs * EXTRA_POLLING_MULTIPLIER * pollingMultiplier;
				await subscriber.current.subscribe();
			})();
		}
	}, [pollingMultiplier]);

	return subscriber.current;
};

export default usePriorityFeeSubscriber;
