'use client';

import React from 'react';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import Button from './Button';
import { Wallet } from '@drift-labs/icons';
import { useWindowSizeIsLessThan } from 'src/hooks/useWindowSize';
import { twMerge } from 'tailwind-merge';

const MarginAccountButton = (props: {
	className?: string;
	highlight?: boolean;
	centerText?: boolean;
	desktopMode?: boolean;
	size?: 'SMALL' | 'MEDIUM' | 'LARGE';
}) => {
	const actions = useDriftActions();
	const currentAccountKey = useDriftAccountStore((s) => s.currentUserKey);
	const set = useDriftStore((s) => s.set);

	const showPopup = () => {
		set((s) => {
			s.modalTargetAccountKey = currentAccountKey ?? '';
			s.modalCollateralType = 0;
		});
		actions.showDepositModal(0, currentAccountKey, true);
	};

	const connected = useWalletIsConnected();

	const isMediumOrSmallerScreenSize = useWindowSizeIsLessThan('lg');

	return (
		<>
			<Button.Secondary
				size={props.size ?? 'MEDIUM'}
				className={twMerge([
					`flex flex-row items-center space-x-0`,
					props.centerText ? 'justify-center' : 'justify-between',
					!connected && 'hover:cursor-not-allowed',
					props.className ?? '',
				])}
				style={{ lineHeight: '12px' }}
				onClick={showPopup}
				disabled={!connected}
				id="depositButton"
				iconOnly
				highlight={props.highlight}
			>
				{/** Using CSS styling of 'display: none' causes an unwanted left margin on the Wallet icon */}
				{isMediumOrSmallerScreenSize && !props.desktopMode ? (
					<span className="block pt-1 lg:hidden">
						<Wallet color="var(--text-emphasis)" size={20} />
					</span>
				) : (
					<div>Deposit/Withdraw</div>
				)}
			</Button.Secondary>
		</>
	);
};

export default React.memo(MarginAccountButton);
