'use client';

import { PublicKey } from '@solana/web3.js';
import { Checkmark, Copy } from '@drift-labs/icons';
import useTickedAllSubAccounts from 'src/hooks/useTickedAllSubAccounts';
import { AccountData } from 'src/stores/useDriftAccountsStore';
import HealthDisplay from 'src/utils/HealthDisplay';
import UI_UTILS from 'src/utils/uiUtils';
import Text from '../../Text/Text';
import Button from 'src/components/Button';
import useSetCollateralModalParams from 'src/hooks/useSetCollateralModalParams';
import useAccountData from 'src/hooks/useAccountData';
import RoundedGradientBorderBox from 'src/components/RoundedGradientBorderBox';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import { twMerge } from 'tailwind-merge';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useSafePush from 'src/hooks/useSafePush';
import { useEffect, useRef, useState } from 'react';
import { ONBOARDING_STEP } from 'src/components/Onboarding/OnboardingPageContent';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import useDriftActions from 'src/hooks/useDriftActions';

const SubaccountOption = ({
	onClick,
	subAccount,
	isActive,
	isDelegate,
}: {
	onClick: () => void;
	subAccount: AccountData;
	isActive: boolean;
	isDelegate?: boolean;
}) => {
	const [isCopied, setIsCopied] = useState(false);
	const blockRef = useRef(null);
	const devswitch = useDevSwitchIsOn();

	useEffect(() => {
		if (isActive) {
			setTimeout(() => {
				blockRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center',
				});
			}, 300);
		}
	}, [isActive]);

	const handleCopyAddress = () => {
		if (!isCopied) {
			UI_UTILS.copyToClipboard(subAccount.pubKey.toString());
			setIsCopied(true);

			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		}
	};

	const health =
		(subAccount.client?.isSubscribed && subAccount.client?.getHealth()) ?? 0;

	return (
		<div className="pt-1" ref={blockRef}>
			<RoundedGradientBorderBox
				onClick={onClick}
				borderRadius="4px"
				borderColour={'transparent'}
			>
				<div className="flex items-center justify-between w-full gap-2 px-2 py-3 overflow-hidden cursor-pointer bg-main-bg hover:bg-container-bg-hover">
					{isDelegate ? (
						<div className="flex flex-col gap-[2px]">
							<Text.BODY1
								className={twMerge(
									'truncate text-text-emphasis',
									isActive && 'text-gradient-1'
								)}
							>
								{UI_UTILS.abbreviateAddress(subAccount.authority)} :{' '}
								{subAccount.userId}
							</Text.BODY1>
							<Text.MICRO1
								className={twMerge(
									'truncate text-text-label',
									isActive && 'text-gradient-1'
								)}
							>
								{subAccount.name}
							</Text.MICRO1>
						</div>
					) : (
						<Text.BODY1
							className={twMerge(
								'truncate text-text-emphasis',
								isActive && 'text-gradient-1'
							)}
						>
							{subAccount.name}
						</Text.BODY1>
					)}
					<div className="flex items-center gap-1">
						<Text.BODY3 className="text-text-emphasis">
							$
							{BigNum.from(
								subAccount.marginInfo.netUsdValue,
								QUOTE_PRECISION_EXP
							).toMillified()}
						</Text.BODY3>
						<HealthDisplay health={health} className="w-[52px]" />
						{devswitch && (
							<span
								className="flex flex-col text-text-label hover:opacity-80"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									handleCopyAddress();
								}}
							>
								{isCopied ? <Checkmark size={16} /> : <Copy size={16} />}
							</span>
						)}
					</div>
				</div>
			</RoundedGradientBorderBox>
		</div>
	);
};

const SubaccountsTab = ({
	onClose,
	isZeroWalletBalances,
}: {
	onClose: () => void;
	isZeroWalletBalances: boolean;
}) => {
	const subAccounts = useTickedAllSubAccounts();
	const setCollateralModalParams = useSetCollateralModalParams();
	const userKey = useAccountData()?.userKey;
	const [setDriftStore, isMagicAuth, isMetamask] = useDriftStore((s) => [
		s.set,
		s.wallet.isMagicAuth,
		s.wallet.isMetamask,
	]);
	const safePush = useSafePush();
	const actions = useDriftActions();

	const isNonSolanaWallet = isMagicAuth || isMetamask;
	const hasSubaccounts =
		subAccounts.filter((subAccount) => !subAccount.isDelegatedTo).length > 0;
	const hasDelegatedAccounts =
		subAccounts.filter((subAccount) => !!subAccount.isDelegatedTo).length > 0;

	const updateCurrentSubAccount = (newValue: number, authority: PublicKey) => {
		actions.updateCurrentSubAccount(newValue, authority);
	};

	const handleOpenDepositModal = () => {
		onClose();
		setCollateralModalParams('showDepositModal', 0, userKey);
	};

	const handleOpenAddAccountModal = () => {
		onClose();
		setDriftStore((s) => {
			s.modals.showNewSubaccountModal = true;
		});
	};

	const handleRouteToManageAccts = () => {
		onClose();
		safePush('/overview/subaccounts');
	};

	const handleRouteToFundYourWallet = () => {
		onClose();
		safePush(
			`${UI_UTILS.getOnboardingPath(
				ONBOARDING_STEP.FUND_YOUR_WALLET
			)}?source=tradeform` // TODO: should refactor this to use drift store
		);
	};
	const handleOnClick = (userId: number, authority: PublicKey) => {
		updateCurrentSubAccount(userId, authority);
		onClose();
	};

	return (
		<div className="flex flex-col w-full -mt-2">
			{/** Zero balance UI state */}
			{subAccounts.length === 0 &&
				(isNonSolanaWallet && isZeroWalletBalances ? (
					<div className="flex flex-col items-center justify-center gap-4 pt-8 text-center text-text-label">
						<Text.BODY3 className="max-w-[244px]">
							Fund your{' '}
							<span
								className="cursor-pointer text-interactive-link"
								onClick={handleRouteToFundYourWallet}
							>
								{isMetamask ? 'MetaMask Snaps' : 'Magic wallet'}
							</span>{' '}
							and deposit collateral to start trading on Drift.
						</Text.BODY3>
						<Button.Secondary
							size="MEDIUM"
							onClick={handleOpenDepositModal}
							className="w-[168px]"
						>
							Get Started
						</Button.Secondary>
					</div>
				) : (
					<div className="flex flex-col items-center justify-center gap-4 pt-8 text-center text-text-label">
						<Text.BODY3>
							Deposit collateral to start trading on Drift.
						</Text.BODY3>
						<Button.Secondary
							size="MEDIUM"
							onClick={handleOpenDepositModal}
							className="w-[168px]"
						>
							Deposit Collateral
						</Button.Secondary>
					</div>
				))}

			{hasSubaccounts && (
				<>
					{/** Subaccount Options */}
					<div className="flex flex-col w-full gap-1 divide-y divide-[var(--container-border)]">
						{subAccounts
							.filter((subAccount) => !subAccount.isDelegatedTo)
							.map((subAccount) => (
								<SubaccountOption
									key={subAccount.userKey}
									subAccount={subAccount}
									onClick={() =>
										handleOnClick(subAccount.userId, subAccount.authority)
									}
									isActive={userKey === subAccount.userKey}
								/>
							))}
					</div>

					{/** Account Actions */}
					<div className="flex w-full gap-2 mt-2">
						<Button.Secondary
							size="MEDIUM"
							className="flex-1"
							onClick={handleOpenAddAccountModal}
						>
							Add
						</Button.Secondary>
						<Button.Secondary
							size="MEDIUM"
							className="flex-1"
							onClick={handleRouteToManageAccts}
						>
							Manage
						</Button.Secondary>
					</div>
				</>
			)}

			{hasDelegatedAccounts && (
				<>
					<Text.BODY2 className="pl-2 mt-10 mb-2 text-text-label">
						Delegate Accounts
					</Text.BODY2>
					<div className="flex flex-col w-full gap-1 divide-y divide-[var(--container-border)]">
						{subAccounts
							.filter((subAccount) => !!subAccount.isDelegatedTo)
							.map((subAccount) => (
								<SubaccountOption
									key={subAccount.userKey}
									subAccount={subAccount}
									onClick={() =>
										handleOnClick(subAccount.userId, subAccount.authority)
									}
									isActive={userKey === subAccount.userKey}
									isDelegate
								/>
							))}
					</div>
				</>
			)}
		</div>
	);
};

export default SubaccountsTab;
