'use client';

import React, { ReactNode, useMemo, useState } from 'react';
import Modal from './Modal';
import useDriftActions from 'src/hooks/useDriftActions';
import Text from 'src/components/Text/Text';
import Button from 'src/components/Button';
import CheckboxInput from '../CheckboxInput';
import { useLocalStorageState } from 'src/utils/localStorageUtils';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import UI_UTILS from 'src/utils/uiUtils';

type StepProps = {
	nextAction: () => void;
	skipAction?: () => void;
};

const StepOne = (props: StepProps) => {
	return (
		<div className="justify-start space-y-4">
			<div>
				<Text.BODY3 className="font-bold text-text-emphasis">
					Getting Started with BAL
				</Text.BODY3>
			</div>
			<div>
				<Text.BODY3 className="text-text-default">
					You’ll take on a share of the vAMM’s positions as a counterparty to
					other Drift traders. For example, if a Drift trader goes short, you’ll
					receive a long position.
				</Text.BODY3>
			</div>
			<div>
				<Text.BODY3 className="text-text-default">
					Your liquidity is represented as open orders (bids/asks) and you’ll
					see your BAL positions in the positions table:
				</Text.BODY3>
			</div>
			<div>
				<img
					src={`/assets/images/dlp-intro-step-one.webp`}
					className="w-full"
				/>
			</div>
			<div className="block">
				<Button.Primary
					onClick={props.nextAction}
					positiveGreen
					className="w-full"
					size="MEDIUM"
					textClass="text-sm"
				>
					Next
				</Button.Primary>
				<Button.Secondary
					onClick={props.skipAction}
					className="w-full mt-1"
					textClass="text-xs"
					size="MEDIUM"
				>
					Skip
				</Button.Secondary>
			</div>
		</div>
	);
};

const StepTwo = (props: StepProps) => {
	return (
		<div className="justify-start space-y-4">
			<div>
				<Text.BODY3 className="font-bold text-text-emphasis">
					Position Updates & Management
				</Text.BODY3>
			</div>
			<div>
				<Text.BODY3 className="text-text-default">
					As new trades are filled, your BAL positions are updated.
				</Text.BODY3>
			</div>
			<div>
				<Text.BODY3 className="text-text-default">
					You can manage your risk manually by reducing the BAL position or
					closing it via the close button in the positions table.
				</Text.BODY3>
			</div>
			<div>
				<img
					src={`/assets/images/dlp-intro-step-two.webp`}
					className="w-full"
				/>
			</div>
			<div className="block">
				<Button.Primary
					onClick={props.nextAction}
					positiveGreen
					className="w-full"
					size="MEDIUM"
					textClass="text-sm"
				>
					Next
				</Button.Primary>
				<Button.Secondary
					onClick={props.skipAction}
					className="w-full mt-1"
					textClass="text-xs"
					size="MEDIUM"
				>
					Skip
				</Button.Secondary>
			</div>
		</div>
	);
};

const StepThree = (props: StepProps) => {
	return (
		<div className="justify-start block space-y-2">
			<div>
				<Text.BODY3 className="font-bold text-text-emphasis">
					Setting max leverage for your subaccount
				</Text.BODY3>
			</div>
			<div>
				<Text.BODY3 className="text-text-default">
					A subaccount max leverage is suggested when you add liquidity but can
					be adjusted in the settings. This setting adjusts your margin
					requirement for increasing BAL positions. If your account falls below
					the collateral requirement shown on BAL page, the next P&L settle will
					trigger de-risking of the position. You can also opt out of this in
					Margin/Leverage Settings.
				</Text.BODY3>
			</div>
			<div>
				<img
					src={`/assets/images/dlp-intro-step-three.webp`}
					className="w-full"
				/>
			</div>
			<div className="block">
				<Button.Primary
					onClick={props.nextAction}
					positiveGreen
					className="w-full"
					size="MEDIUM"
				>
					Next
				</Button.Primary>
			</div>
		</div>
	);
};

const AcceptTerms = (props: StepProps) => {
	const [acceptedReadDocs, setAcceptedReadDocs] = useState(false);
	const [acceptedPotentialLosses, setAcceptedPotentialLosses] = useState(false);

	const canContinue = useMemo(
		() => acceptedReadDocs && acceptedPotentialLosses,
		[acceptedReadDocs, acceptedPotentialLosses]
	);

	return (
		<div className="justify-start block space-y-4">
			<div>
				<Text.BODY3 className="font-normal text-text-default">
					You can learn more about how BAL works by visiting the{' '}
					<a
						className="inline-flex text-purple-40 hover:cursor-pointer"
						href="https://docs.drift.trade/backstop-amm-liquidity/bal-provider-overview"
						target="_blank"
						rel="noreferrer"
					>
						docs page
					</a>
					.
				</Text.BODY3>
			</div>
			<div className="space-y-2">
				<Text.BODY3 className="text-text-default">
					To get started, review and accept the terms below:
				</Text.BODY3>
				<CheckboxInput
					label={
						<Text.BODY3 className="font-normal text-text-default">
							{`I understand the concept and role of Liquidity Providers on Drift and I have read the Liquidity Providers docs page.`}
						</Text.BODY3>
					}
					checked={acceptedReadDocs}
					onChange={() => setAcceptedReadDocs(!acceptedReadDocs)}
					secondaryStyle
					alignStart
				/>
				<CheckboxInput
					label={
						<Text.BODY3 className="font-normal text-text-default">
							By becoming a BAL Provider, I accept all risks associated with
							electing to participate in Liquidity Pools, including the possible
							loss of all funds or unsettled P&L.
						</Text.BODY3>
					}
					checked={acceptedPotentialLosses}
					onChange={() => setAcceptedPotentialLosses(!acceptedPotentialLosses)}
					secondaryStyle
					alignStart
				/>
			</div>
			<div className="block">
				<Button.Primary
					onClick={props.nextAction}
					positiveGreen
					disabled={!canContinue}
					className="w-full"
					size="MEDIUM"
				>
					Agree & Continue
				</Button.Primary>
			</div>
		</div>
	);
};

type DlpOnboardingStep = {
	content: ReactNode;
	title: string;
};

const DlpIntroModal = () => {
	const actions = useDriftActions();
	const availableSubaccounts = useLazySubAccounts();

	const [currentStep, setCurrentStep] = useState(0);
	const setUserHasSeenNewDlpFlow = useLocalStorageState(
		'userHasSeenNewDlpFlow'
	)[1];

	const userHasLpSubaccount = !!availableSubaccounts.find((acct) =>
		UI_UTILS.accountNameIsLp(acct.name)
	);

	const showModal = actions.showModal;
	const hideModal = () => showModal('showDlpIntroModal', false);

	const completeOnboarding = () => {
		console.log('completing onboarding');
		setUserHasSeenNewDlpFlow('true');
		hideModal();

		showModal(
			userHasLpSubaccount ? 'showAddLiquidityModal' : 'showNewLpSubaccountModal'
		);
	};

	const OnboardingSteps: DlpOnboardingStep[] = [
		{
			content: (
				<StepOne
					nextAction={() => setCurrentStep(1)}
					skipAction={() => setCurrentStep(3)}
				/>
			),
			title: 'Intro to BAL (1 of 3)',
		},
		{
			content: (
				<StepTwo
					nextAction={() => setCurrentStep(2)}
					skipAction={() => setCurrentStep(3)}
				/>
			),
			title: 'Intro to BAL (2 of 3)',
		},
		{
			content: <StepThree nextAction={() => setCurrentStep(3)} />,
			title: 'Intro to BAL (3 of 3)',
		},
		{
			content: <AcceptTerms nextAction={completeOnboarding} />,
			title: 'Accept Terms',
		},
	];

	const currentStepToRender = useMemo(
		() => OnboardingSteps[currentStep],
		[currentStep]
	);

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>{currentStepToRender.title}</Modal.Title>
				</Modal.Header>
				<Modal.Content>{currentStepToRender.content}</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(DlpIntroModal);
