'use client';

import { useCallback, useEffect, useRef } from 'react';
// import { SnapWalletAdapter } from '@drift-labs/snap-wallet-adapter';
// import { WalletReadyState } from '@solana/wallet-adapter-base';
import { PublicKey } from '@solana/web3.js';
import { useCustomWalletAutoconnect } from 'src/hooks/useCustomWalletAutoconnect';
import useWalletContext from 'src/hooks/useWalletContext';
import { dlog } from '../dev';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useCurrentWalletConnectionState from './useCurrentWalletConnectionState';
import useDriftActions from './useDriftActions';
import useDriftClient from './useDriftClient';

const MINIMUM_WALLET_CONNECTION_MODAL_DISPLAY_TIME_MS = 500;

/**
 * Seperate hook to abstract away the logic which determines when to show the wallet connection modal. To avoid the jitter / jank on screen we want to make sure the modal is displayed for a minimum amount of time before it is hidden again.
 */
const useShowWalletConnectionModal = () => {
	const actions = useDriftActions();
	const walletConnectionState = useCurrentWalletConnectionState();

	const displayTimeRef = useRef<number>(null);

	const hideWalletConnectionModalAfterDelay = (delayMs: number) => {
		setTimeout(() => {
			actions.showModal('showWalletConnectionModal', false);
		}, delayMs);
	};

	useEffect(() => {
		dlog(
			'wallet_debugging',
			`use_keep_wallets_in_sync::wallet_connection_state_changed :: ${walletConnectionState.instance.printStates()}`
		);

		if (walletConnectionState.instance.is('Connecting')) {
			actions.showModal('showWalletConnectionModal', true);
			displayTimeRef.current = Date.now();
		} else {
			if (
				displayTimeRef.current &&
				Date.now() - displayTimeRef.current <
					MINIMUM_WALLET_CONNECTION_MODAL_DISPLAY_TIME_MS
			) {
				hideWalletConnectionModalAfterDelay(
					MINIMUM_WALLET_CONNECTION_MODAL_DISPLAY_TIME_MS -
						(Date.now() - displayTimeRef.current)
				);
			} else {
				actions.showModal('showWalletConnectionModal', false);
			}
		}
	}, [walletConnectionState]);
};

/**
 * A hook which handles making sure the app correctly reacts to the wallet changing state, keeps the store state in sync with the changing wallet, and attaches the wallet's events to the assosciated methods in the store
 *
 * Note : It's OK that magic wallet isn't included in the useWallet hook because that is handled seperately (in driftStoreActions, on connect)
 */
const useKeepWalletsInSync = () => {
	const actions = useDriftActions();
	const setState = useDriftStore((s) => s.set);
	useCustomWalletAutoconnect();
	const walletContext = useWalletContext();
	const authority = walletContext?.wallet?.adapter?.publicKey;
	const driftClient = useDriftClient();
	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);
	const isEmulatingAccount = useDriftStore((s) => s.isEmulatingAccount);

	const walletConnectionState = useCurrentWalletConnectionState();

	const wallets = walletContext.wallets;

	const currentWalletState = useDriftStore((s) => s.wallet);

	const alreadySyncedAuthority = useRef<PublicKey>(null);

	const linkWalletToClient = useCallback(async () => {
		await actions.linkWalletToDriftClient();

		clearRpcReadyListener();
	}, []);

	const setRpcReadyListener = () => {
		appEventEmitter.addListener('rpcAndClientSubscribed', linkWalletToClient);
	};

	const clearRpcReadyListener = () => {
		appEventEmitter.removeListener(
			'rpcAndClientSubscribed',
			linkWalletToClient
		);
	};

	const handleDisconnect = () => {
		dlog(`wallet_debugging`, `useKeepWalletsInSync::handle_disconnect`);

		alreadySyncedAuthority.current = null;
		actions.handleWalletDisconnect();
		clearRpcReadyListener();
		walletConnectionState.instance.update('NotConnected', true);
	};

	/**
	 * This method is designed to handle the race-condition where the wallet might want to connect before the RPC-Connection or DriftClient are actually ready to go. It first checks if they are ready, otherwise sets up a handler to handle the wallet connection once they are ready to go.
	 */
	const handleConnect = () => {
		dlog(`wallet_debugging`, `useKeepWalletsInSync::handle_connect`);

		const isReadyToLinkToClient = driftClient?.isSubscribed;

		if (!isReadyToLinkToClient) {
			setRpcReadyListener();
		} else {
			linkWalletToClient();
		}
	};

	// Keep wallet adapters in sync
	useEffect(() => {
		setState((s) => {
			s.wallets = wallets;
		});
	}, [wallets]);

	// TODO :: The fact that we need to set these handlers up seperately is weird and annoying. I think the issue is that lots of our wallet code assumes the current wallet is inside of WalletContext when IT IS NOT - ONLY solana/react WALLETS are in here. So I think that metamask/magic walleta are also not behaving correctly until we standardise this.
	// Add Custom Wallet event handlers
	useEffect(() => {
		if (!currentWalletState?.current?.adapter) return;

		if (
			!currentWalletState.isAppKit &&
			!currentWalletState.isMagicAuth &&
			!currentWalletState.isMetamask
		)
			return;

		currentWalletState.current.adapter.on('disconnect', handleDisconnect);
		currentWalletState.current.adapter.on('error', actions.handleWalletError);

		return () => {
			currentWalletState.current.adapter.removeListener(
				'disconnect',
				handleDisconnect
			);
			currentWalletState.current.adapter.removeListener(
				'error',
				actions.handleWalletError
			);
		};
	}, [currentWalletState]);

	// Set up solana/react wallet event handlers
	useEffect(() => {
		const wallet = walletContext?.wallet;

		if (!wallet) return;

		setState((s) => {
			// @ts-expect-error
			s.wallet.current = wallet;
		});

		wallet.adapter.on('disconnect', handleDisconnect);
		wallet.adapter.on('error', actions.handleWalletError);

		return () => {
			wallet.adapter.removeListener('disconnect', handleDisconnect);
			wallet.adapter.removeListener('error', actions.handleWalletError);
		};
	}, [walletContext?.wallet?.adapter]);

	// Ensure that wallet connection logic happens when the adapter connects
	useEffect(() => {
		// Exit if we're using account emulation
		if (isEmulatingAccount) {
			return;
		}

		if (authority) {
			// Skip doing connection logic if we've already connected for this wallet
			if (alreadySyncedAuthority?.current?.equals(authority)) {
				return;
			}

			alreadySyncedAuthority.current = authority;
			handleConnect();
		}
	}, [walletConnectionState, authority, isEmulatingAccount]);

	// Store wallet context on the window for e2e tests
	useEffect(() => {
		// @ts-ignore
		window.walletContext = walletContext;
	}, [walletContext]);

	// Ensure that the wallet connection modal shows when it is "connecting"
	useShowWalletConnectionModal();
};

export default useKeepWalletsInSync;
