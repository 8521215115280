'use client';

import { useContext, useEffect, useRef } from 'react';
import { dlog } from '../../dev';
import { V2AuctionToastData } from '../../utils/notifications';
import { MarketOrderStateHandlerContext } from './MarketOrderToastStateHandlerProvider';
import useNotificationStore from '../../stores/useNotificationStore';
import {
	MarketOrderToastId,
	MarketOrderToastState,
} from './MarketOrderToastStateTypes';

/**
 * This hook is responsible for making sure that the toasts being displayed on screen remain in sync with the market order toast state manager.
 * @returns
 */

const getToastId = (orderId: string) => `market_order_toast_${orderId}`;

const generateV2NotificationData = (
	toastState: MarketOrderToastState
): V2AuctionToastData => {
	if (!toastState?.orderInfo) {
		return undefined;
	}

	return {
		...toastState,
		type: 'v2MarketOrder',
		id: getToastId(toastState.orderInfo.id),
	};
};

const useSyncMarketOrderToastStateAndUI = () => {
	const notificationStore = useNotificationStore();

	const marketOrderToastState = useContext(MarketOrderStateHandlerContext);

	const lastSeenToastStateLookup = useRef(
		new Map<MarketOrderToastId, MarketOrderToastState>()
	);

	useEffect(() => {
		// Loop through toast states currently in the state handler and update any which have changed
		for (const [orderId, toastState] of Object.entries(marketOrderToastState)) {
			const typedOrderId = orderId as keyof typeof marketOrderToastState;
			const typedToastState = toastState as MarketOrderToastState;

			const lastSeenToastState =
				lastSeenToastStateLookup.current.get(typedOrderId);

			if (lastSeenToastState !== toastState) {
				const newToastState = generateV2NotificationData(typedToastState);

				dlog(`v2_auctions`, `updating_toast_state`, newToastState);

				notificationStore.updateOrAddNotificationById(
					getToastId(orderId),
					newToastState
				);

				lastSeenToastStateLookup.current.set(typedOrderId, typedToastState);
			} else {
				dlog(
					`v2_auctions`,
					`skipping_toast_update_matches_last_seen`,
					orderId,
					toastState
				);
			}
		}

		// Loop through the states in the current lookup and handle any which no longer match the state in the state handler.
		for (const [orderId] of lastSeenToastStateLookup.current.entries()) {
			const toastState = marketOrderToastState[orderId];
			// # Remove toasts which should no longer exist
			if (!toastState) {
				dlog(`v2_auctions`, `removing_old_toast`, orderId);
				notificationStore.removeNotificationById(getToastId(orderId));
				lastSeenToastStateLookup.current.delete(orderId);
			}
		}
	}, [marketOrderToastState]);
};

export default useSyncMarketOrderToastStateAndUI;
