'use client';

import { useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import useLazySubAccounts from './useLazySubAccounts';
import useWalletIsConnected from './useWalletIsConnected';
// import useInterval from './useInterval';
// import Env from 'src/environmentVariables/EnvironmentVariables';

const useUserStatsAccount = () => {
	const setState = useDriftStore((s) => s.set);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const connected = useWalletIsConnected();
	const connection = useDriftStore((s) => s.connection.current);
	const subAccounts = useLazySubAccounts();

	const setUserStatsAccount = async () => {
		if (driftClientIsReady && connection && connected) {
			try {
				const userStatsAccount = driftClient.userStats?.getAccount();
				setState((s) => {
					s.userStatsAccount = userStatsAccount;
				});
			} catch (err) {
				// driftClient.userStats?.getAccount() throws an error if user stats account is uninitialized
				console.log('User stats account not found');
			}
		} else {
			setState((s) => {
				s.userStatsAccount = undefined;
			});
		}
	};

	// TODO: it will be better if user stats account subscriber takes in a callback that updates the drift store on account update
	// useInterval(setUserStatsAccount, Env.pollingFrequencyMs);

	useEffect(() => {
		setUserStatsAccount();
	}, [
		driftClientIsReady,
		connection,
		connected,
		subAccounts,
		driftClient?.authority,
	]);
};

export default useUserStatsAccount;
