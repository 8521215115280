'use client';

import Text from 'src/components/Text/Text';
import AccountHealthModalContent from 'src/components/AccountHealthModalContent';
import React from 'react';
import Modal from './Modal';
import useDriftActions from 'src/hooks/useDriftActions';

const AccountHealthModal = ({ id }: { id?: string }) => {
	const actions = useDriftActions();

	const onClose = () => {
		actions.showModal('showAccountHealthModal', false);
	};

	return (
		<Modal
			onClose={onClose}
			id={id}
			sizeConfig={{ xs: 12, sm: 8, md: 6, xl: 4 }}
		>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
					className="border-b border-container-border"
				>
					<Text.H2>Account Health Breakdown</Text.H2>
				</Modal.Header>
				<AccountHealthModalContent onClose={onClose} />
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(AccountHealthModal);
