const RECORDING_INTERVAL_MS = 5000;

export class EventLoopMetricsTracker {
	private interval: ReturnType<typeof setTimeout>;

	constructor(private cb: (lag: number) => void) {}

	private measureLag() {
		const start = Date.now();

		// The reason we use a timeout with zero delay is because that is how you measure how clogged the eventloop is.
		setTimeout(() => {
			const lag = Date.now() - start;
			this.cb(lag);
		});
	}

	subscribe() {
		if (this.interval) {
			this.unsubscribe();
		}

		this.interval = setInterval(() => {
			this.measureLag();
		}, RECORDING_INTERVAL_MS);
	}

	unsubscribe() {
		clearInterval(this.interval);
		this.interval = undefined;
	}
}
