import Env from '../environmentVariables/EnvironmentVariables';

/**
 * Utility class which makes sure that all results with a slot are only accepted if the slot is higher than the previous result
 */
export class ResultSlotIncrementer {
	private resultIncrements = new Map<string | symbol, number>();
	handleResult(key: string | symbol, slot: number) {
		if (slot === undefined) {
			if (Env.localEnv === 'devnet' || Env.localEnv === 'master') {
				throw new Error('Caught undefined slot in ResultSlotIncrementer');
			}
			return true;
		}

		const previous = this.resultIncrements.get(key);

		if (!previous || slot >= previous) {
			this.resultIncrements.set(key, slot);
			return true;
		} else {
			return false;
		}
	}
}
