'use client';

import { useContext, useEffect, useRef } from 'react';
import useMarketStateStore, {
	MarketStateStore,
	MarketState,
	DlobListeningSelection,
} from '../../stores/useMarketStateStore';
import useInterval from '../useInterval';
import { OrderSubscriberContext } from '../../providers/orderSubscriberProvider';
import { SlotContext } from '../../providers/currentSlotProvider';
import useOraclePriceStore from '../../stores/useOraclePriceStore';
import { COMMON_MATH, ENUM_UTILS } from '@drift/common';
import {
	BN,
	BigNum,
	DEFAULT_TOP_OF_BOOK_QUOTE_AMOUNTS,
	L2Level,
	L2OrderBook,
	MarketType,
	OraclePriceData,
	PRICE_PRECISION_EXP,
	PerpMarketAccount,
	getVammL2Generator,
} from '@drift-labs/sdk';
import useDriftClient from '../useDriftClient';
import UI_UTILS from '../../utils/uiUtils';
import useMarketsForDlobToTrack from '../useMarketsForDlobToTrack';
import useSyncPhoenixSubscriberList from './useSyncPhoenixSubscriberList';
import useSyncSerumSubscriberList from './useSyncSerumSubscriberList';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { PageRoute } from '../../constants/constants';
import { UNCROSS_ONLY_FOR_UI } from '../../providers/orderbookDisplayProvider';
import { useUncrossOrderbook } from './useUncrossOrderbook';
import useDevStore from '../../stores/useDevStore';
import { DriftWindow } from 'src/window/driftWindow';

const DLOB_REFRESH_RATE_MS = 1000;
// rate of refresh to use for any pages besides trade and overview (where users can trade from) - can play w this
const LAZY_DLOB_REFRESH_RATE_MS = 20000;

const DEFAULT_L2_STATE: L2OrderBook = { asks: [], bids: [] };
const DEFAULT_ORDERBOOK_ROWS = 20;

const getVammL2ForMarket = (
	marketType: MarketType,
	perpMarketAccount: PerpMarketAccount,
	priceData: OraclePriceData,
	groupingSize: number
): L2OrderBook => {
	if (!ENUM_UTILS.match(marketType, MarketType.PERP)) return DEFAULT_L2_STATE;

	const vammL2Generator = getVammL2Generator({
		marketAccount: perpMarketAccount,
		oraclePriceData: priceData,
		numOrders: groupingSize * DEFAULT_ORDERBOOK_ROWS,
		topOfBookQuoteAmounts: DEFAULT_TOP_OF_BOOK_QUOTE_AMOUNTS,
	});

	const vammAsksGenerator = vammL2Generator.getL2Asks();
	const vammBidsGenerator = vammL2Generator.getL2Bids();

	const vammL2 = {
		asks: Array.from(vammAsksGenerator),
		bids: Array.from(vammBidsGenerator),
	};

	return vammL2;
};

/**
 * Merge seperate sources of l2 orderbook together. During the merge it will sort by price (ascending) and merge sources where they share the same price
 * @param l2Data
 * @returns
 */
const mergeL2Data = (l2Data: L2OrderBook[]): L2OrderBook => {
	// Buckets to put bids and asks into when merging
	const askBuckets = new Map<string, L2Level>();
	const bidBuckets = new Map<string, L2Level>();

	const addOrAppendToBucketStorage = (
		key: string,
		currentL2Level: L2Level,
		bucketStorage: Map<string, L2Level>
	) => {
		const currentBucket = bucketStorage.get(key);

		if (currentBucket) {
			currentBucket.size = currentBucket.size.add(currentL2Level.size);
			currentBucket.sources = {
				...currentBucket.sources,
				...currentL2Level.sources,
			};
		} else {
			bucketStorage.set(key, currentL2Level);
		}
	};

	// Place all the bids and asks into the buckets
	for (const bid of l2Data.map((d) => d.bids).flat()) {
		const bidKey = bid.price.toString();
		addOrAppendToBucketStorage(bidKey, bid, bidBuckets);
	}
	for (const ask of l2Data.map((d) => d.asks).flat()) {
		const askKey = ask.price.toString();
		addOrAppendToBucketStorage(askKey, ask, askBuckets);
	}

	// Map the bids and asks back out into l2 orderbook
	const l2Orderbook = {
		bids: Array.from(bidBuckets.values()).sort((bidA, bidB) =>
			UI_UTILS.sortBnDesc(bidA.price, bidB.price)
		),
		asks: Array.from(askBuckets.values()).sort((askA, askB) =>
			UI_UTILS.sortBnAsc(askA.price, askB.price)
		),
	};

	return l2Orderbook;
};

// Use a const here so that it's the same value between refreshes
const BLANK_MARKETS_TO_TRACK = {};

/**
 * Handles turning on the blockchain order listener and keeping it in sync with the DLOB class when the dlob listening gets enabled.
 * @param enabled
 */
const useSyncMarketDataWithBlockchain = () => {
	const setMarketStateStore = useMarketStateStore((s) => s.set);
	const orderSubscriber = useContext(OrderSubscriberContext)?.orderSubscriber;
	const currentSlot = useContext(SlotContext).currentSlot;
	const getMarketPriceData = useOraclePriceStore((s) => s.getMarketPriceData);
	const driftClient = useDriftClient();
	const marketsToTrack = useMarketsForDlobToTrack(
		DlobListeningSelection.BLOCKCHAIN
	);

	const uncrossDisabled_ = useDevStore((s) => s.dlobSettings.disableUncross);
	const uncrossDisabled = useRef(uncrossDisabled_);
	useEffect(() => {
		uncrossDisabled.current = uncrossDisabled_;
	}, [uncrossDisabled_]);

	const uncross = useUncrossOrderbook();

	const currentPage = useDriftStore((s) => s.currentPageRoute);
	const refreshRateToUse =
		!currentPage ||
		ENUM_UTILS.match(currentPage, PageRoute.overview) ||
		ENUM_UTILS.match(currentPage, PageRoute.trade)
			? DLOB_REFRESH_RATE_MS
			: LAZY_DLOB_REFRESH_RATE_MS;
	const bulkAccountLoader = useDriftStore((s) => s.currentBulkAccountLoader);

	const getPhoenixSubscriber = useMarketStateStore(
		(s) => s.getPhoenixSubscriberForMarket
	);
	const getSerumSubscriber = useMarketStateStore(
		(s) => s.getSerumSubscriberForMarket
	);

	const enabled = marketsToTrack.totalMarkets > 0;

	const allMarketsToTrack = marketsToTrack.allMarkets;

	// If not enabled then pass a blank list of markets to track
	useSyncPhoenixSubscriberList(
		enabled ? marketsToTrack.categories : BLANK_MARKETS_TO_TRACK
	);
	useSyncSerumSubscriberList(enabled ? marketsToTrack : BLANK_MARKETS_TO_TRACK);

	useEffect(() => {
		if (!orderSubscriber) return;

		if (enabled) {
			// Turn the blockchain listener on
			orderSubscriber.subscribe();
		} else {
			// Turn the blockchain listener off
			orderSubscriber.usersAccounts?.clear();
			orderSubscriber.unsubscribe();
		}

		return () => {
			// Unsubscribe at end of hook to be safe
			orderSubscriber.usersAccounts?.clear();
			orderSubscriber.unsubscribe();
		};
	}, [enabled, orderSubscriber]);

	useInterval(() => {
		if (!enabled) return;

		if (DriftWindow.BREAK_BLOCKCHAIN_DLOB_FETCHING) {
			// Dev setting for testing fallback logic
			return;
		}

		(async () => {
			if (!orderSubscriber) return;

			// All markets except USDC SPOT market
			const marketsToGetDlobFor = allMarketsToTrack;

			const newDlob = await orderSubscriber.getDLOB(currentSlot);

			const newL2State: MarketStateStore['marketDataState'] = {};

			// Get the DLOB state from the VAMM
			const vammL2State = marketsToGetDlobFor.map((mkt) => {
				if (ENUM_UTILS.match(MarketType.SPOT, mkt.marketType)) {
					return DEFAULT_L2_STATE;
				}

				const oraclePriceData = getMarketPriceData(mkt)?.rawPriceData;

				if (!oraclePriceData) {
					return {
						bids: [],
						asks: [],
					};
				}

				return getVammL2ForMarket(
					mkt.marketType,
					driftClient.getPerpMarketAccount(mkt.marketIndex),
					oraclePriceData,
					1
				);
			});

			// Get the DLOB state from phoenix
			const phoenixL2State: L2OrderBook[] = marketsToGetDlobFor.map((mkt) => {
				try {
					const phoenixSubscriber = getPhoenixSubscriber(mkt);

					if (!phoenixSubscriber) return { bids: [], asks: [] };

					const bids = Array.from(phoenixSubscriber.getL2Bids());
					const asks = Array.from(phoenixSubscriber.getL2Asks());

					return {
						bids,
						asks,
					};
				} catch (e) {
					console.log('error getting phoenix l2 state', e);
					return { bids: [], asks: [] };
				}
			});

			// Get the DLOB state from serum
			const serumL2State: L2OrderBook[] = marketsToGetDlobFor.map((mkt) => {
				try {
					const serumSubscriber = getSerumSubscriber(mkt);
					if (!serumSubscriber) return { bids: [], asks: [] };

					const bids = Array.from(serumSubscriber.getL2Bids());
					const asks = Array.from(serumSubscriber.getL2Asks());

					return { bids, asks };
				} catch (e) {
					console.log('error getting serum l2 state', e);
					return { bids: [], asks: [] };
				}
			});

			// Get the DLOB state for the Drift DLOB
			const driftDlobL2State = marketsToGetDlobFor.map((mkt) => {
				// Skip USDC market
				if (
					mkt.marketIndex === 0 &&
					ENUM_UTILS.match(mkt.marketType, MarketType.SPOT)
				)
					return;

				const oraclePriceData = getMarketPriceData(mkt)?.rawPriceData;

				if (!oraclePriceData) {
					return {
						bids: [],
						asks: [],
					};
				}

				const bidsGenerator = newDlob.getRestingLimitBids(
					mkt.marketIndex,
					currentSlot,
					mkt.marketType,
					oraclePriceData
				);

				const asksGenerator = newDlob.getRestingLimitAsks(
					mkt.marketIndex,
					currentSlot,
					mkt.marketType,
					oraclePriceData
				);

				const bids: L2Level[] = Array.from(bidsGenerator).map((node) => {
					const size = node.order.baseAssetAmount.sub(
						node.order.baseAssetAmountFilled
					);
					return {
						price: node.getPrice(oraclePriceData, currentSlot),
						size,
						sources: { dlob: size },
					};
				});

				const asks: L2Level[] = Array.from(asksGenerator).map((node) => {
					const size = node.order.baseAssetAmount.sub(
						node.order.baseAssetAmountFilled
					);

					return {
						price: node.getPrice(oraclePriceData, currentSlot),
						size,
						sources: { dlob: size },
					};
				});

				if (ENUM_UTILS.match(mkt.marketType, MarketType.PERP)) {
					return {
						bids,
						asks,
					};
				} else {
					return {
						bids,
						asks,
					};
				}
			});

			// Merge the different DLOB sources
			const mergedDlobState = marketsToGetDlobFor.map((_mktInfo, index) => {
				const dlobValue = driftDlobL2State[index];
				const vammValue = vammL2State[index];
				const phoenixValue = phoenixL2State[index];
				const serumValue = serumL2State[index];
				return mergeL2Data([dlobValue, vammValue, phoenixValue, serumValue]);
			});

			// Fill the new L2 state with the merged data
			marketsToGetDlobFor.forEach((mktId, index) => {
				const dlobStateForMarket = mergedDlobState[index];

				const oraclePriceStoreData = getMarketPriceData(mktId);

				if (!oraclePriceStoreData) return;

				// TODO : This method loops through all orders, should be able to assume that they are sorted here and just pick the ones off the top (applies to the other places this is used too)
				const spreadBidAskMark = COMMON_MATH.calculateSpreadBidAskMark(
					dlobStateForMarket,
					oraclePriceStoreData?.rawPriceData?.price
				);

				const orderbookToUse = uncrossDisabled.current
					? dlobStateForMarket
					: UNCROSS_ONLY_FOR_UI
					? dlobStateForMarket
					: uncross.uncrossL2Orderbook(
							dlobStateForMarket,
							oraclePriceStoreData.rawPriceData,
							mktId
					  );

				const newMarketStateDate: MarketState = {
					marketId: mktId,
					orderbook: orderbookToUse,
					oracle: {
						price: BigNum.fromPrint(
							oraclePriceStoreData.priceData.price.toString(),
							PRICE_PRECISION_EXP
						),
						slot: new BN(oraclePriceStoreData.priceData.slot),
						confidence: new BN(oraclePriceStoreData.priceData.confidence),
						hasSufficientNumberOfDataPoints:
							oraclePriceStoreData.priceData.hasSufficientNumberOfDataPoints,
						twap: oraclePriceStoreData.priceData.twap
							? BigNum.from(
									oraclePriceStoreData.priceData.twap,
									PRICE_PRECISION_EXP
							  )
							: undefined,
						twapConfidence: oraclePriceStoreData.priceData.twapConfidence
							? new BN(oraclePriceStoreData.priceData.twapConfidence)
							: undefined,
						maxPrice: BigNum.fromPrint(
							oraclePriceStoreData.priceData.maxPrice.toString(),
							PRICE_PRECISION_EXP
						),
					},
					derivedState: {
						markPrice: BigNum.from(
							spreadBidAskMark.markPrice,
							PRICE_PRECISION_EXP
						),
						// TODO : Fallbacks
						fallbackBid: BigNum.from(
							spreadBidAskMark.bestBidPrice,
							PRICE_PRECISION_EXP
						),
						fallbackAsk: BigNum.from(
							spreadBidAskMark.bestAskPrice,
							PRICE_PRECISION_EXP
						),
						bestBid: BigNum.from(
							spreadBidAskMark.bestBidPrice,
							PRICE_PRECISION_EXP
						),
						bestAsk: BigNum.from(
							spreadBidAskMark.bestAskPrice,
							PRICE_PRECISION_EXP
						),
						updateSlot: bulkAccountLoader.mostRecentSlot,
					},
				};

				newL2State[mktId.key] = newMarketStateDate;
			});

			// Update the store state with the new L2 state
			setMarketStateStore((s) => {
				for (const market of marketsToGetDlobFor) {
					s.marketDataState[market.key] = newL2State[market.key];
				}
				s.latestUpdateSlot = Math.max(
					s.latestUpdateSlot ?? 0,
					bulkAccountLoader.mostRecentSlot
				);
			});
		})();
	}, refreshRateToUse);
};

export default useSyncMarketDataWithBlockchain;
