'use client';

import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { AggregateLiqState } from 'src/hooks/Liquidations/useAggregateLiquidationDataForUser';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ButtonGroup } from './Button';
import TableV2 from './Tables/TableV2';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	UISerializableLiquidationRecord,
} from '@drift/common';
import { LiquidationType } from '@drift-labs/sdk';
import Text from './Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import useDateDisplayState from 'src/hooks/useDateDisplayState';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import AnimatedToggleDrawer from './AnimatedDrawer/AnimatedToggleDrawer';
import { EyeShow, Open } from '@drift-labs/icons';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';

const OPTION_LOOKUP = {
	deposits: {
		value: 'deposits',
		label: 'Deposits',
	},
	perps: {
		value: 'perps',
		label: 'Perp Positions',
	},
	pnl: {
		value: 'pnl',
		label: 'Unsettled',
	},
	borrows: {
		value: 'borrows',
		label: 'Borrows',
	},
};

const PERP_TABLE_GRID =
	'200px minmax(200px, 1fr) minmax(140px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr)';
const PERPS_TABLE_MIN_WIDTH_CLASS = 'min-w-[960px]'; // total width of the perps table

const DetailTableWrapper = (props: PropsWithChildren<any>) => {
	return (
		<div className="flex flex-col border rounded-sm border-darkBlue-70">
			{props.children}
		</div>
	);
};

const DrawerDetailTableWrapper = ({
	children,
	tableMinWidthClass,
}: {
	children: React.ReactNode;
	tableMinWidthClass: string; // this is required to ensure all contents' width are consistent
}) => {
	return (
		<div className="w-full max-w-full overflow-auto border border-container-border thin-scroll">
			<div className={twMerge(tableMinWidthClass)}>{children}</div>
		</div>
	);
};

export const PerpPositionTable = (props: {
	data: AggregateLiqState['liqdPerps'];
}) => {
	return (
		<DetailTableWrapper>
			<TableV2.HeaderRow grid={PERP_TABLE_GRID} lastColumnJustify="start">
				<TableV2.HeaderCell>Market</TableV2.HeaderCell>
				<TableV2.HeaderCell>Size</TableV2.HeaderCell>
				<TableV2.HeaderCell>Notional</TableV2.HeaderCell>
				<TableV2.HeaderCell>Price</TableV2.HeaderCell>
				<TableV2.HeaderCell>Fee</TableV2.HeaderCell>
				<TableV2.HeaderCell>BAL Shares Burned</TableV2.HeaderCell>
			</TableV2.HeaderRow>
			<>
				{props.data.map((row) => (
					<>
						<TableV2.BodyRow
							className="relative last:border-b-0 hover:bg-container-bg text-text-default"
							grid={PERP_TABLE_GRID}
							lastColumnJustify="start"
						>
							<TableV2.MarketCell marketSymbol={row.market.symbol} />
							<TableV2.AssetCell
								value={row.baseSize}
								assetSymbol={row.market.baseAssetSymbol}
							/>
							<TableV2.NotionalCell value={row.quoteSize.abs()} />
							<TableV2.BodyCell>
								{row.avgPrice.toNotional(true)}
							</TableV2.BodyCell>
							<TableV2.BodyCell>{row.fee.toNotional(true)}</TableV2.BodyCell>
							<TableV2.BodyCell>{row.lpShares.prettyPrint()}</TableV2.BodyCell>
						</TableV2.BodyRow>
					</>
				))}
			</>
		</DetailTableWrapper>
	);
};

export const PerpPositionDrawerTable = (props: { data: AggregateLiqState }) => {
	const [expandedRowIndices, setExpandedRowIndices] = useState<number[]>([]);

	const toggleExpanded = useCallback(
		(index: number) => {
			if (expandedRowIndices.includes(index)) {
				setExpandedRowIndices(
					expandedRowIndices.filter((item) => item !== index)
				);
			} else {
				setExpandedRowIndices([...expandedRowIndices, index]);
			}
		},
		[expandedRowIndices]
	);

	return (
		<DrawerDetailTableWrapper tableMinWidthClass={PERPS_TABLE_MIN_WIDTH_CLASS}>
			<TableV2.HeaderRow grid={PERP_TABLE_GRID} lastColumnJustify="start">
				<TableV2.HeaderCell>Market</TableV2.HeaderCell>
				<TableV2.HeaderCell>Size</TableV2.HeaderCell>
				<TableV2.HeaderCell>Notional</TableV2.HeaderCell>
				<TableV2.HeaderCell>Price</TableV2.HeaderCell>
				<TableV2.HeaderCell>Fee</TableV2.HeaderCell>
				<TableV2.HeaderCell>BAL Shares Burned</TableV2.HeaderCell>
			</TableV2.HeaderRow>
			<>
				{props.data.liqdPerps.map((row, index) => {
					const expanded = expandedRowIndices.includes(index);
					const wereLpSharesBurned = props.data.liquidations.some(
						(liq) =>
							liq.liquidatePerp?.lpShares &&
							!liq.liquidatePerp?.lpShares?.eqZero()
					);

					return (
						<>
							<TableV2.BodyRow
								className={twMerge(
									'relative thin-scroll last:border-b-0 text-text-default cursor-pointer',
									expanded &&
										'bg-button-secondary-selected hover:bg-button-secondary-selected'
								)}
								grid={PERP_TABLE_GRID}
								lastColumnJustify="start"
								onClick={() => toggleExpanded(index)}
							>
								<TableV2.MarketCell
									marketSymbol={row.market.symbol}
									expandable={{
										isParent: true,
										expanded,
									}}
									suffix={wereLpSharesBurned && '(BAL Shares Burned)'}
								/>
								<TableV2.AssetCell
									value={row.baseSize}
									assetSymbol={row.market.baseAssetSymbol}
									isPredictionMarket={PredictionMarketConfigs.isPredictionMarket(
										row.market.symbol
									)}
								/>
								<TableV2.NotionalCell value={row.quoteSize.abs()} />
								<TableV2.BodyCell>
									{row.avgPrice.toNotional(true)}
								</TableV2.BodyCell>
								<TableV2.BodyCell>{row.fee.toNotional(true)}</TableV2.BodyCell>
								<TableV2.BodyCell>
									{row.lpShares.prettyPrint()}
								</TableV2.BodyCell>
							</TableV2.BodyRow>
							<AnimatedToggleDrawer expanded={expanded} expandedHeight={'auto'}>
								<div className="border-b bg-container-bg-selected border-darkBlue-70">
									<PerpsBreakdownTable
										liquidationRecords={props.data.liquidations.filter(
											(record) =>
												ENUM_UTILS.match(
													record.liquidationType,
													LiquidationType.LIQUIDATE_PERP
												) &&
												record.liquidatePerp?.marketIndex ===
													row.market.marketIndex
										)}
										minWidthClass={PERPS_TABLE_MIN_WIDTH_CLASS}
									/>
								</div>
							</AnimatedToggleDrawer>
						</>
					);
				})}
			</>
		</DrawerDetailTableWrapper>
	);
};

export const DepositTable = (props: {
	data: AggregateLiqState['liqdDeposits'];
}) => {
	return (
		<DetailTableWrapper>
			<TableV2.HeaderRow grid={PERP_TABLE_GRID}>
				<TableV2.HeaderCell>Market</TableV2.HeaderCell>
				<TableV2.HeaderCell>Size</TableV2.HeaderCell>
				<TableV2.HeaderCell>Notional</TableV2.HeaderCell>
				<TableV2.HeaderCell>Price</TableV2.HeaderCell>
				<TableV2.HeaderCell />
			</TableV2.HeaderRow>
			<>
				{props.data.map((row) => {
					const bankSymbol = OrderedSpotMarkets[row.bankIndex].symbol;
					return (
						<>
							<TableV2.BodyRow
								className="relative last:border-b-0 hover:bg-container-bg text-text-default"
								grid={PERP_TABLE_GRID}
							>
								<TableV2.BankCell bankIndex={row.bankIndex} />
								<TableV2.AssetCell
									value={row.baseSize}
									assetSymbol={bankSymbol}
								/>
								<TableV2.NotionalCell value={row.quoteSize} />
								<TableV2.BodyCell>
									{row.avgPrice.toNotional(true)}
								</TableV2.BodyCell>
								<TableV2.BodyCell />
							</TableV2.BodyRow>
						</>
					);
				})}
			</>
		</DetailTableWrapper>
	);
};

export const PnlTable = (props: {
	data: AggregateLiqState['liqdPerpPnls'];
}) => {
	return (
		<DetailTableWrapper>
			<TableV2.HeaderRow grid={PERP_TABLE_GRID}>
				<TableV2.HeaderCell>Market</TableV2.HeaderCell>
				<TableV2.HeaderCell>Unrealized P&amp;L</TableV2.HeaderCell>
				<TableV2.HeaderCell>Transfer Value</TableV2.HeaderCell>
				<TableV2.HeaderCell />
			</TableV2.HeaderRow>
			<>
				{props.data.map((row) => {
					return (
						<>
							<TableV2.BodyRow
								className="relative last:border-b-0 hover:bg-container-bg text-text-default"
								grid={PERP_TABLE_GRID}
							>
								<TableV2.MarketCell marketSymbol={row.market.symbol} />
								<TableV2.NotionalCell value={row.pnlSize} />
								<TableV2.NotionalCell value={row.transferQuoteSize} />
								<TableV2.BodyCell />
							</TableV2.BodyRow>
						</>
					);
				})}
			</>
		</DetailTableWrapper>
	);
};

export const PerpsBreakdownTable = ({
	liquidationRecords,
	minWidthClass,
}: {
	liquidationRecords: UISerializableLiquidationRecord[];
	minWidthClass?: string;
}) => {
	const { toggleDateDisplayType, displayDatesAsTimestamp } =
		useDateDisplayState();
	const isDevMode = useDevSwitchIsOn();

	const BREAKDOWN_TABLE_GRID = useMemo(
		() =>
			'100px 1fr minmax(100px, 1fr) 1fr' + (isDevMode ? ' 1fr' : '') + ' 40px',
		[isDevMode]
	);

	if (liquidationRecords.length === 0) {
		return null;
	}

	return (
		<DetailTableWrapper>
			<TableV2.HeaderRow grid={BREAKDOWN_TABLE_GRID} className={minWidthClass}>
				<TableV2.HeaderCell>Direction</TableV2.HeaderCell>
				<TableV2.HeaderCell>Filled</TableV2.HeaderCell>
				<TableV2.HeaderCell>Fill Price</TableV2.HeaderCell>
				<TableV2.HeaderCell>
					<div
						onClick={toggleDateDisplayType}
						className={'cursor-pointer flex items-center gap-2'}
					>
						<span>Timestamp</span>
						{<EyeShow size={16} />}
					</div>
				</TableV2.HeaderCell>
				{isDevMode && <TableV2.HeaderCell>IF Fee/Liq Fee</TableV2.HeaderCell>}
				<TableV2.HeaderCell>Txn</TableV2.HeaderCell>
			</TableV2.HeaderRow>
			{liquidationRecords.map((record) => {
				return (
					<TableV2.BodyRow
						grid={BREAKDOWN_TABLE_GRID}
						key={record.txSig}
						className="last:border-b-0 hover:bg-container-bg text-text-default"
					>
						<TableV2.BodyCell
							className={twMerge(
								'font-numeral',
								record.liquidatePerp.baseAssetAmount.isPos()
									? `text-positive-green`
									: `text-negative-red`
							)}
						>
							{record.liquidatePerp.baseAssetAmount.isPos() ? 'Long' : 'Short'}
						</TableV2.BodyCell>
						<TableV2.BodyCell>
							{record.liquidatePerp.baseAssetAmount.abs().toTradePrecision()}
						</TableV2.BodyCell>
						<TableV2.BodyCell>
							$
							{COMMON_UI_UTILS.calculateAverageEntryPrice(
								record.liquidatePerp.quoteAssetAmount,
								record.liquidatePerp.baseAssetAmount
							).toTradePrecision(true)}
						</TableV2.BodyCell>
						<TableV2.DateCell
							displayAsTimestamp={displayDatesAsTimestamp}
							date={new Date(record.ts.toNumber() * 1000)}
						/>

						{isDevMode && (
							<TableV2.BodyCell>
								{`$${record.liquidatePerp.ifFee.toTradePrecision()}/$${record.liquidatePerp.liquidatorFee.toTradePrecision()}`}
							</TableV2.BodyCell>
						)}

						<TableV2.BodyCell>
							<div
								className="flex items-center cursor-pointer hover:opacity-80"
								onClick={() => {
									UI_UTILS.openUrl(UI_UTILS.getUrlForTx(record.txSig));
								}}
							>
								<Open color="var(--text-label)" size={14} />
							</div>
						</TableV2.BodyCell>
					</TableV2.BodyRow>
				);
			})}
		</DetailTableWrapper>
	);
};

export const PerpsBreakdownTableContainer = ({
	liquidationRecords,
}: {
	liquidationRecords: UISerializableLiquidationRecord[];
}) => {
	if (liquidationRecords.length === 0) {
		return null;
	}

	return (
		<div className="flex flex-col w-full gap-2">
			<Text.H4 className="text-text-label">Breakdown</Text.H4>
			<PerpsBreakdownTable liquidationRecords={liquidationRecords} />
		</div>
	);
};

export const LiquidationDetails = ({ liq }: { liq: AggregateLiqState }) => {
	const showDeposits = liq.liqdDeposits.length > 0;
	const showPerps = liq.liqdPerps.length > 0;
	const showUnsettledPNL = liq.liqdPerpPnls.length > 0;
	const showBorrows = liq.liqdBorrows.length > 0;

	const options = [
		showPerps ? OPTION_LOOKUP['perps'] : undefined,
		showDeposits ? OPTION_LOOKUP['deposits'] : undefined,
		showBorrows ? OPTION_LOOKUP['borrows'] : undefined,
		showUnsettledPNL ? OPTION_LOOKUP['pnl'] : undefined,
	].filter((option) => option);

	const [selectedOption, setSelectedOption] = useState(
		options[0]?.value ?? 'deposits'
	);

	return (
		<div className="flex flex-col items-start space-y-4">
			<ButtonGroup.Segmented
				options={options}
				selected={selectedOption}
				size="SMALL"
				selectAction={(option: string) => {
					setSelectedOption(option);
				}}
			/>

			<div className="w-full">
				{selectedOption === 'perps' ? (
					<PerpPositionTable data={liq.liqdPerps} />
				) : selectedOption === 'deposits' ? (
					<DepositTable data={liq.liqdDeposits} />
				) : selectedOption === 'borrows' ? (
					<DepositTable data={liq.liqdBorrows} />
				) : selectedOption === 'pnl' ? (
					<PnlTable data={liq.liqdPerpPnls} />
				) : (
					<></>
				)}
			</div>

			{selectedOption === 'perps' && (
				<PerpsBreakdownTableContainer
					liquidationRecords={liq.liquidations.filter((record) =>
						ENUM_UTILS.match(
							record.liquidationType,
							LiquidationType.LIQUIDATE_PERP
						)
					)}
				/>
			)}
		</div>
	);
};
