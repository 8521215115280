'use client';

import useDriftActions from 'src/hooks/useDriftActions';
import React, { useState } from 'react';
import { getTimeoutNotificationData, notify } from '../utils/notifications';
import DepositModalContent from './DepositModalContent';
import Modal from './Modals/Modal';
import WithdrawalModalContent from './WithdrawModalContent';
import useDevStore from '../stores/useDevStore';
import Button from './Button';
import useAppEventEmitter from 'src/hooks/useAppEventEmitter';
import { DriftWindow } from 'src/window/driftWindow';

const ForcedErrorComponent = () => {
	throw new Error('Forced Error');
	//return <div>Error</div>;
};

const DevModal = () => {
	const appEventEmitter = useAppEventEmitter();
	const devStore = useDevStore();
	const [showTargetModal, setShowTargetModal] = useState<string | false>(false);
	const [showErrorComponent, setShowErrorComponent] = useState(false);

	const actions = useDriftActions();

	switch (showTargetModal) {
		case 'deposit':
			return <DepositModalContent onClose={() => setShowTargetModal(false)} />;
		case 'withdraw':
			return (
				<WithdrawalModalContent onClose={() => setShowTargetModal(false)} />
			);
		default:
			break;
	}

	const hide = () => {
		actions.showModal('showDevModal', false);
	};

	const triggerRpcHealth = () => {
		devStore.set((s) => {
			s.triggerBadRpcHealth = true;
		});
	};

	const forceError = () => {
		setShowErrorComponent(true);
	};

	const showEmulationModal = () => {
		hide();
		actions.showModal('showEmulationModal', true);
	};

	return (
		<Modal onClose={hide}>
			<Modal.Body>
				<Modal.Header showX onClose={hide}>
					Dev Menu
				</Modal.Header>

				<div className={`py-6 px-5 flex flex-col space-y-2`}>
					<Button.Secondary
						size="MEDIUM"
						onClick={() => {
							notify({
								...getTimeoutNotificationData(appEventEmitter),
								action: { type: 'txnLink', txnSig: 'bler' },
								id: 'bler',
							});
						}}
					>
						30 sec timeout
					</Button.Secondary>
					<Button.Secondary size="MEDIUM" onClick={triggerRpcHealth}>
						Trigger RPC Bad Health
					</Button.Secondary>
					<Button.Secondary
						size="MEDIUM"
						onClick={() => {
							DriftWindow.BREAK_BLOCKCHAIN_DLOB_FETCHING =
								!DriftWindow.BREAK_BLOCKCHAIN_DLOB_FETCHING;
						}}
					>
						{`${
							DriftWindow.BREAK_BLOCKCHAIN_DLOB_FETCHING ? 'Disable' : 'Enable'
						} BREAK_BLOCKCHAIN_DLOB_FETCHING`}
					</Button.Secondary>
					<Button.Secondary
						size="MEDIUM"
						onClick={() => {
							DriftWindow.BREAK_DLOB_SERVER_POLLING =
								!DriftWindow.BREAK_DLOB_SERVER_POLLING;
						}}
					>
						{`${
							DriftWindow.BREAK_DLOB_SERVER_POLLING ? 'Disable' : 'Enable'
						} BREAK_DLOB_SERVER_POLLING`}
					</Button.Secondary>
					<Button.Secondary size="MEDIUM" onClick={forceError}>
						Force Error
					</Button.Secondary>
					<Button.Secondary size="MEDIUM" onClick={showEmulationModal}>
						Emulate Account (with current signer)
					</Button.Secondary>
				</div>
			</Modal.Body>

			{showErrorComponent && <ForcedErrorComponent />}
		</Modal>
	);
};

export default React.memo(DevModal);
