'use client';

import { User } from '@drift-labs/sdk';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import useHistoryFetcher from './useHistoryFetcher';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useAccountData from './useAccountData';
import { useEffect } from 'react';
import { useLocalStorageState } from 'src/utils/localStorageUtils';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const useDownloadRequests = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	const setAccountsStore = useDriftAccountsStore((s) => s.set);
	const currentAccount = useAccountData();
	const isEmulationMode = useDriftStore((s) => s.isEmulatingAccount);

	// Comma-separated list of pending download keys so we can detect if a new one becomes available
	const [pendingDownloadKeys, setPendingDownloadKeys] = useLocalStorageState(
		'pendingDownloadKeys',
		''
	);

	const getDownloadRequests = async (
		users: User[],
		skipUpdateLocalStorage?: boolean
	) => {
		const authority = users[0]?.getUserAccount()?.authority;

		if (!authority) return;

		const downloadRequestInfo = await ExchangeHistoryClient.getDownloadRequests(
			authority,
			isEmulationMode
		);

		if (!downloadRequestInfo.success) return false;

		let newAvailable = false;
		const newPendingKeyArr = [];

		if (pendingDownloadKeys) {
			const localStoragePendingKeyArr = pendingDownloadKeys.split(',');
			const availableKeys = downloadRequestInfo.body.data.records.available.map(
				(record) => record.key
			);
			const pendingKeys = downloadRequestInfo.body.data.records.pending.map(
				(record) => record.key
			);

			localStoragePendingKeyArr
				.filter((item) => !!item)
				.forEach((localPendingKey) => {
					if (
						availableKeys.includes(localPendingKey) &&
						!pendingKeys.includes(localPendingKey)
					) {
						newAvailable = true;
					} else if (pendingKeys.includes(localPendingKey)) {
						newPendingKeyArr.push(localPendingKey);
					} else {
						// somehow got a mismatch between api and local storage, possible user changed wallets
						// clear the local storage in this case
					}
				});
		}

		setAccountsStore((s) => {
			s.downloadFiles = {
				initialHistoryLoaded: true,
				newAvailableDetected: newAvailable,
				records: downloadRequestInfo.body.data.records,
				counts: downloadRequestInfo.body.data.counts,
			};
		});

		if (newAvailable) {
			setDriftStore((s) => {
				s.driftFeatureAlert = {
					alertType: 'downloadReady',
					alertData: '',
				};
			});
		}

		if (!skipUpdateLocalStorage) {
			const newLocalStorageValue =
				newPendingKeyArr.length > 0 ? newPendingKeyArr.join(',') : '';

			if (pendingDownloadKeys !== newLocalStorageValue) {
				setPendingDownloadKeys(newLocalStorageValue);
			}
		}

		return true;
	};

	useHistoryFetcher(getDownloadRequests, 'downloadFiles');

	// if there are pending requests, re-fetch the records once a every 20s and notify if it comes through
	useEffect(() => {
		if (!pendingDownloadKeys || !currentAccount) return;

		getDownloadRequests([currentAccount?.client], true);
		const interval = setInterval(async () => {
			await getDownloadRequests([currentAccount?.client], false);
		}, 20000);

		return () => {
			clearInterval(interval);
		};
	}, [pendingDownloadKeys, currentAccount?.userKey]);
};

export default useDownloadRequests;
