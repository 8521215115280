'use client';

import useAccountData from './useAccountData';
import { singletonHook } from 'react-singleton-hook';

const useAccountsLoaded = (): boolean => {
	const account = useAccountData();
	return account && account.userId != null && account.isSubscribed;
};

export default singletonHook(false, useAccountsLoaded);
