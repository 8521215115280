import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from '../Modals/Modal';
import { Typo } from '../Text/Typo';
import CheckboxInput from '../CheckboxInput';
import Button from '../Button';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import { VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY } from 'src/constants/vaults/misc';
import { useState } from 'react';

export const VaultTermsDisclaimerModal = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	const hasSelectedDoNotShowAgain = useDriftStore(
		(s) => s[VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY]
	);

	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
	const [_1, setAgreedVaultTermsDoNotShowAgain] = useLocalStorageState(
		VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY
	);

	const depositCallback = useDriftStore(
		(s) => s.modalsProps.showVaultTermsDisclaimerModal?.depositCallback
	);

	const hideVaultTermsAndConditionModal = () => {
		setDriftStore((s) => {
			s.modals.showVaultTermsDisclaimerModal = false;
		});
	};

	const handleAgreeTerms = () => {
		if (!hasAcceptedTerms) return;

		setAgreedVaultTermsDoNotShowAgain(hasSelectedDoNotShowAgain);
		hideVaultTermsAndConditionModal();
		depositCallback();
	};

	return (
		<Modal onClose={hideVaultTermsAndConditionModal}>
			<Modal.Body className="flex flex-col h-full pb-0">
				<Modal.Header
					onClose={hideVaultTermsAndConditionModal}
					showX
					className="py-0 sm:pt-3 sm:pb-2"
				>
					<Typo.T1>Disclaimer</Typo.T1>
				</Modal.Header>
				<Modal.Content className="flex flex-col flex-grow gap-4 p-0 sm:p-4">
					<Typo.B4 className="text-text-secondary">
						Vaults are experimental. Your use of this interface or any third
						party linked interface is at your sole risk. Please ensure you
						understand any platforms or protocols underlying these Vaults.
						<br />
						<br />
						By continuing, you agree and acknowledge{' '}
						<a
							href="https://docs.drift.trade/legal-and-regulations/terms-of-use"
							target="_blank"
							rel="noopener noreferrer"
						>
							Drift Terms
						</a>{' '}
						together with any further terms set out on this interface or linked
						interfaces. You further agree that you accept all risks, including
						the possible loss of all funds. Drift will not be liable for any
						losses, claims, damages, errs, disruptions, or delays, inter alia.
					</Typo.B4>

					<CheckboxInput
						checked={hasAcceptedTerms}
						onChange={() => setHasAcceptedTerms((prev) => !prev)}
						label="I have read, understood, and agree to be the terms above"
						secondaryStyle
					/>
					<CheckboxInput
						checked={hasSelectedDoNotShowAgain}
						onChange={() =>
							setDriftStore((s) => {
								s[VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY] =
									!hasSelectedDoNotShowAgain;
							})
						}
						label="Do not show this message again"
						secondaryStyle
					/>

					<Button.Primary
						size="MEDIUM"
						className="w-full"
						positiveGreen
						disabled={!hasAcceptedTerms}
						onClick={handleAgreeTerms}
					>
						Agree & Deposit
					</Button.Primary>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};
