'use client';

import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useDevStore from '../../stores/useDevStore';
import Text from '../Text/Text';

const DevTopWrapper = () => {
	const showRefStatus = useDevStore((s) => s.showRefCode);
	const currentReferralCode = useDriftStore((s) => s.referrerParam);
	const referralAuthority = useDriftStore((s) => s.referredByAuthority);

	return (
		<>
			{showRefStatus && (
				<div className="flex w-full space-x-1 text-text-emphasis">
					<Text.MICRO1>Ref Code : {currentReferralCode}</Text.MICRO1>
					<Text.MICRO1>
						Ref Authority : {referralAuthority?.toString()}
					</Text.MICRO1>
				</div>
			)}
		</>
	);
};

export default DevTopWrapper;
