'use client';

import { User } from '@drift-labs/sdk';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useHistoryFetcher from './useHistoryFetcher';
import { COMMON_UI_UTILS } from '@drift/common';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';

const usePredictionsTradeHistory = () => {
	const getState = useDriftStore((s) => s.get);

	const setAccountsStore = useDriftAccountsStore((s) => s.set);

	// Get the initial trade history, and find out what the total size of the trade history is for the user - to help with pagination
	const getNewPredictionTradesFromHistoryServer = async (users: User[]) => {
		const isConnected =
			getState().wallet.current.adapter.connected &&
			getState().connection.current;
		if (!isConnected) return;

		const pubKeys = users.map((user) => user.getUserAccountPublicKey());
		const userKeys = users.map((user) =>
			COMMON_UI_UTILS.getUserKey(
				user.getUserAccount().subAccountId,
				user.getUserAccount().authority
			)
		);

		if (pubKeys.find((a) => !a)) return;

		const results = await ExchangeHistoryClient.getTradesForUsers(
			pubKeys,
			0,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			true
		);

		if (!results.success) return false;

		const { records, totalCounts } = results?.body?.data || {};

		const newPredictionTrades = records ?? [];

		if (totalCounts) {
			setAccountsStore((s) => {
				userKeys.forEach((userKey, index) => {
					const predictionTradesForUser = newPredictionTrades[index];
					const predictionTradeCountForUser = totalCounts?.[index] ?? 0;

					if (!s.accounts[userKey]) return; // accounts not loaded yet

					s.accounts[userKey].predictionsTradeHistory.loadedUserTradeHistory =
						predictionTradesForUser;

					s.accounts[
						userKey
					].predictionsTradeHistory.userTradeHistoryTotalCount =
						predictionTradeCountForUser;

					s.accounts[userKey].predictionsTradeHistory.initialHistoryLoaded =
						true;
				});
			});
		}

		return true;
	};

	useHistoryFetcher(
		getNewPredictionTradesFromHistoryServer,
		'predictionsTradeHistory'
	);
};

export default usePredictionsTradeHistory;
