'use client';

import { BigNum, BN, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useSPLTokenBalance from './useSPLTokenBalance';

const useWalletUsdcBalance = () => {
	const setState = useDriftStore((s) => s.set);
	const tokenAddress = Env.usdcMint;

	// get the user's whitelist token balance using the token address
	const [usdcBalance] = useSPLTokenBalance(tokenAddress);

	const setUsdcBalance = (balance: number) => {
		setState((s) => {
			s.wallet.usdcBalance = BigNum.from(new BN(balance ?? 0)).shift(
				QUOTE_PRECISION_EXP
			);
		});
	};

	// update the usdc balance in store state whenever it changes
	useEffect(() => {
		setUsdcBalance(usdcBalance);
	}, [usdcBalance]);
};

export default useWalletUsdcBalance;
