'use client';

import { ChevronRight } from '@drift-labs/icons';
import Modal from './Modals/Modal';
import Text from './Text/Text';
import useSafePush from 'src/hooks/useSafePush';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import { WORMHOLE_WALLET_WHITELIST } from 'src/constants/constants';

const SwapOrBridgeCta = ({ onClose }: { onClose: () => void }) => {
	const safePush = useSafePush();
	const currentWallet = useCurrentWalletAdapter();

	const routeToBridgePage = () => {
		if (WORMHOLE_WALLET_WHITELIST.includes(currentWallet.name)) {
			safePush('/bridge');
		} else {
			safePush('/bridge/mayan');
		}
		onClose();
	};

	return (
		<Modal.Body className="mt-1">
			<div
				className="flex items-center justify-between px-6 py-4 mt-1 cursor-pointer"
				onClick={routeToBridgePage}
			>
				<div className="flex items-center gap-3">
					<img src="/assets/images/bridge-chains-icon.webp" className="w-9" />
					<Text.H5 className="text-text-emphasis">
						Swap or bridge to deposit funds from another chain
					</Text.H5>
				</div>
				<span className="grid text-text-label place-items-center">
					<ChevronRight />
				</span>
			</div>
		</Modal.Body>
	);
};

export default SwapOrBridgeCta;
