'use client';

import { MouseEvent, useEffect, useState } from 'react';
import Text from 'src/components/Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import { MarginMode, UserStatus, ZERO } from '@drift-labs/sdk';
import MarginUpdateRow from './MarginUpdateRow';
import useDriftAccountStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import { matchEnum } from '@drift/common';
import useDriftActions from 'src/hooks/useDriftActions';
import useHighLeverageSpotsOpen from 'src/hooks/useHighLeverageSpotsOpen';
//import useGoToRoute from 'src/hooks/useGoToRoute';

// Current subaccount on-chain state
type SubAccountInfo = {
	accountId: number;
	accountName: string;
	isDelegate?: boolean;
	accountHasLp?: boolean;
	maxLeverage: number;
	marginMode: MarginMode;
	marginTradingEnabled: boolean;
	advancedLp: boolean;
	reduceOnly: boolean;
};

// Updates for subaccount
export type SubAccountSettingsUpdateEntry = {
	subAccountId?: number;
	maxLeverage?: number; // Displayed as "Max Leverage"
	marginMode?: MarginMode; // High leverage mode enabled/disabled
	marginTradingEnabled?: boolean; // Spot margin enabled
	advancedLp?: boolean; // Displayed as "Auto-derisk BAL"
	reduceOnly?: boolean;
};

// All subaccount updates keyed by subaccount id
export type AllSubaccountUpdates = Record<
	number,
	SubAccountSettingsUpdateEntry
>;

const MarginLeverageSettings = (props: {
	allSubAccountUpdates: Record<number, SubAccountSettingsUpdateEntry>;
	addToMarginRatioUpdates: (subaccountId: number, newValue: number) => void;
	addToMarginTradingUpdates: (subaccountId: number, newValue: boolean) => void;
	addToDlpAdvancedUpdates: (subaccountId: number, newValue: boolean) => void;
	addToReduceOnlyUpdates: (subaccountId: number, newValue: boolean) => void;
	addToMarginModeUpdates: (subaccountId: number, newValue: MarginMode) => void;
}) => {
	const { updateHighLeverageSlotsOpen } = useHighLeverageSpotsOpen();
	//const goToRoute = useGoToRoute();

	const actions = useDriftActions();
	const subAccounts = useDriftAccountStore((s) => s.accounts) as Record<
		string,
		AccountData
	>;
	const [subAccountInfos, setSubAccountInfos] = useState<SubAccountInfo[]>([]);

	const fetchSubAccountInfos = () => {
		setSubAccountInfos(
			Object.values(subAccounts).map((acct) => {
				if (!acct.client.isSubscribed) return;

				const userAccount = acct.client.getUserAccount();

				const customMarginRatio = userAccount.maxMarginRatio;

				const maxLeverage =
					UI_UTILS.convertMarginRatioToLeverage(customMarginRatio);

				return {
					accountId: acct.userId,
					accountName: acct.isDelegatedTo
						? UI_UTILS.getDelegateAccountLabel(acct)
						: acct.name,
					isDelegate: acct.isDelegatedTo ?? false,
					accountHasLp: acct.marginInfo?.quoteInLpOrders?.gt(ZERO),
					maxLeverage,
					marginMode: userAccount.marginMode,
					marginTradingEnabled: userAccount.isMarginTradingEnabled,
					advancedLp: acct.client.hasStatus(UserStatus.ADVANCED_LP),
					reduceOnly: acct.client.hasStatus(UserStatus.REDUCE_ONLY),
				};
			})
		);
	};

	const setMaxLeverage = (accountId: number, newValue: number | undefined) => {
		const subaccountInfo = subAccountInfos.find(
			(info) => info.accountId === accountId
		);
		// Kick users out of high leverage mode if they set a max leerage
		if (
			newValue > 0 &&
			(matchEnum(subaccountInfo.marginMode, MarginMode.HIGH_LEVERAGE) ||
				matchEnum(
					props.allSubAccountUpdates[accountId]?.marginMode,
					MarginMode.HIGH_LEVERAGE
				))
		) {
			props.addToMarginModeUpdates(accountId, MarginMode.DEFAULT);
		}
		props.addToMarginRatioUpdates(accountId, newValue);
	};

	const setMarginMode = (accountId: number, newValue: MarginMode) => {
		const subaccountInfo = subAccountInfos.find(
			(info) => info.accountId === accountId
		);

		// If users select high leverage mode, remove their max leverage setting
		if (
			matchEnum(newValue, MarginMode.HIGH_LEVERAGE) &&
			(subaccountInfo.maxLeverage > 0 ||
				props.allSubAccountUpdates[accountId]?.maxLeverage > 0)
		) {
			props.addToMarginRatioUpdates(accountId, undefined);
		}
		props.addToMarginModeUpdates(accountId, newValue);
	};

	const setMarginEnabled = (accountId: number, newValue: boolean) => {
		props.addToMarginTradingUpdates(accountId, newValue);
	};

	const setAdvancedLp = (accountId: number, newValue: boolean) => {
		props.addToDlpAdvancedUpdates(accountId, newValue);
	};

	const setReduceOnly = (accountId: number, newValue: boolean) => {
		props.addToReduceOnlyUpdates(accountId, newValue);
	};

	const handleAddNewSubaccount = (event: MouseEvent) => {
		event.preventDefault();
		actions.showModal('showSettingsModal', false);
		actions.showModal('showNewSubaccountModal', true);
	};

	// const goToFeeTierDocs = () => {
	// 	goToRoute('https://docs.drift.trade/trading/trading-fees');
	// };

	useEffect(() => {
		fetchSubAccountInfos();
		updateHighLeverageSlotsOpen();
	}, [subAccounts, updateHighLeverageSlotsOpen]);

	return (
		<div className="flex flex-col">
			<div className="mb-2">
				<Text.BODY1 className="text-text-emphasis">
					Margin/Leverage Settings
				</Text.BODY1>
			</div>
			<div className="mb-2">
				<Text.BODY2 className="text-text-secondary">
					You can customize your margin and leverage settings per subaccount.
				</Text.BODY2>
			</div>
			<div className="mb-4">
				<Text.BODY2 className="text-text-secondary">
					The maximum leverage applies to your subaccount&apos;s total leverage,
					not your position. To open an isolated position, you can create a new
					subaccount instead{' '}
					<a href="" onClick={handleAddNewSubaccount}>
						here.
					</a>
				</Text.BODY2>
			</div>
			<div className="mb-2">
				<Text.BODY2 className="text-text-secondary">
					Note that High Leverage Mode comes with increased perp taker fees
					regardless of fee tier. Make sure to preview fees when placing orders.
				</Text.BODY2>
			</div>
			{/* <div className="px-4 my-3 text-text-label">
				High leverage mode is available to a limited number of Drift user
				accounts at a time. Spots remaining:{' '}
				<span className="text-warn-yellow">
					{highLeverageSpotsOpen}/{highLeverageMaxUsers}
				</span>
			</div> */}
			<div>
				{subAccountInfos
					.filter((subAcct) => !subAcct.isDelegate)
					.map((subAcct) => {
						const highLeverageEnabled = matchEnum(
							props.allSubAccountUpdates[subAcct.accountId]?.marginMode ||
								subAcct.marginMode ||
								MarginMode.DEFAULT,
							MarginMode.HIGH_LEVERAGE
						);

						const hasMaxLeverageSettingUpdate =
							props.allSubAccountUpdates[subAcct.accountId] &&
							Object.keys(
								props.allSubAccountUpdates[subAcct.accountId]
							).includes('maxLeverage');

						const currentMaxLev = hasMaxLeverageSettingUpdate
							? props.allSubAccountUpdates[subAcct.accountId]?.maxLeverage
							: subAcct.maxLeverage;

						return (
							<MarginUpdateRow
								key={`${subAcct.accountName}_margin`}
								label={subAcct.accountName}
								highLeverageEnabled={highLeverageEnabled}
								currentMaxLev={currentMaxLev}
								marginEnabledChecked={subAcct.marginTradingEnabled}
								// use the opposite of contract flag for ui so it shows on by default
								dlpAutoDerisk={!subAcct.advancedLp}
								reduceOnly={subAcct.reduceOnly}
								onChangeEnabled={(newValue: boolean) => {
									setMarginEnabled(subAcct.accountId, newValue);
								}}
								onChangeRatio={(newValue: number | undefined) => {
									setMaxLeverage(subAcct.accountId, newValue);
								}}
								onChangeDlpAutoDerisk={(newValue: boolean) => {
									// use the opposite of contract flag for ui so it shows on by default
									setAdvancedLp(subAcct.accountId, !newValue);
								}}
								onChangeReduceOnly={(newValue: boolean) => {
									setReduceOnly(subAcct.accountId, newValue);
								}}
								onChangeMarginMode={(newValue: MarginMode) => {
									setMarginMode(subAcct.accountId, newValue);
								}}
								disabled={false}
							/>
						);
					})}
				{subAccountInfos.filter((subAcct) => subAcct.isDelegate)?.length >
					0 && (
					<div className="flex grid items-center w-full mt-4 mb-2 space-x-1">
						<Text.BODY2 className="text-text-label">
							Delegated accounts
						</Text.BODY2>
					</div>
				)}
				{subAccountInfos
					.filter((subAcct) => subAcct.isDelegate)
					.map((subAcct) => {
						const highLeverageEnabled = matchEnum(
							props.allSubAccountUpdates[subAcct.accountId]?.marginMode ||
								subAcct.marginMode ||
								MarginMode.DEFAULT,
							MarginMode.HIGH_LEVERAGE
						);

						const hasMaxLeverageSettingUpdate =
							props.allSubAccountUpdates[subAcct.accountId] &&
							Object.keys(
								props.allSubAccountUpdates[subAcct.accountId]
							).includes('maxLeverage');

						const currentMaxLev = hasMaxLeverageSettingUpdate
							? props.allSubAccountUpdates[subAcct.accountId]?.maxLeverage
							: subAcct.maxLeverage;

						return (
							<MarginUpdateRow
								key={`${subAcct.accountName}_margin`}
								label={subAcct.accountName}
								highLeverageEnabled={highLeverageEnabled}
								currentMaxLev={currentMaxLev}
								marginEnabledChecked={subAcct.marginTradingEnabled}
								dlpAutoDerisk={!subAcct.advancedLp}
								reduceOnly={subAcct.reduceOnly}
								onChangeEnabled={(_newValue: boolean) => {
									// disabled
								}}
								onChangeRatio={(_newValue: number | undefined) => {
									// disabled
								}}
								onChangeDlpAutoDerisk={(_newValue: boolean) => {
									// disabled
								}}
								onChangeReduceOnly={(_newValue: boolean) => {
									// disabled
								}}
								onChangeMarginMode={(_newValue: MarginMode) => {
									// disabled
								}}
								disabled={true}
							/>
						);
					})}
			</div>
		</div>
	);
};

export default MarginLeverageSettings;
