'use client';

import React, { MouseEvent } from 'react';
import InfoMessage from '../TradeForm/InfoMessage';
import Text from '../Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import UIHelpTextLink from '../UIHelpTextLink';

const SettleUnsettledInfoMessage = (props: {
	onlyShowIfPositive?: boolean;
	className?: string;
}) => {
	const actions = useDriftActions();
	const unsettledBN = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.marginInfo?.totalUnsettledPnl
	);

	const unsettled = unsettledBN
		? BigNum.from(unsettledBN, QUOTE_PRECISION_EXP).toNum()
		: 0;

	const handleSettleNow = (event: MouseEvent) => {
		event.preventDefault();
		actions.settleAllPnls();
	};

	if (!unsettledBN || (props.onlyShowIfPositive && unsettled <= 0)) {
		return null;
	}

	return (
		<InfoMessage
			type="info"
			message={
				<>
					<Text.BODY1 className="tracking-normal text-text-secondary">
						<div className="mt-1 mb-2 leading-4">
							You have an unsettled amount of {UI_UTILS.toNotional(unsettled)}{' '}
							that can be settled to USDC for staking, transfer, or withdrawal.
						</div>

						<div className="mt-2 mb-1">
							<UIHelpTextLink text="Settle now" onClick={handleSettleNow} />
						</div>
					</Text.BODY1>
				</>
			}
			className={props.className}
		/>
	);
};

export default React.memo(SettleUnsettledInfoMessage);
