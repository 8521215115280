'use client';

import { MarketId } from '@drift/common';
import { useContext, useMemo } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useCurrentAccountOpenPerpPositions from './useCurrentAccountOpenPerpPositions';
import useMarketStateStore, {
	DlobListeningSelection,
} from '../stores/useMarketStateStore';
import { ALL_TRADEABLE_MARKET_IDS } from '../environmentVariables/EnvironmentVariables';
import { OrderbookDisplayContext } from 'src/providers/orderbookDisplayProvider';

export enum MarketStatePriority {
	SelectedMarket = 'OrderbookDisplay',
	BackgroundDeep = 'DeepPriceData',
	BackgroundShallow = 'ShallowPriceData',
}

export type MarketDlobLiquidityCategorisation = {
	[MarketStatePriority.SelectedMarket]?: MarketId[];
	[MarketStatePriority.BackgroundDeep]?: MarketId[];
	[MarketStatePriority.BackgroundShallow]?: MarketId[];
};

export type MarketTrackingState = {
	categories: {
		[MarketStatePriority.SelectedMarket]?: MarketId[];
		[MarketStatePriority.BackgroundDeep]?: MarketId[];
		[MarketStatePriority.BackgroundShallow]?: MarketId[];
	};
	allMarkets: MarketId[];
	totalMarkets: number;
};

/**
 * To  optimise bandwidth usage, we only want to track the DLOB state for NECESSARY markets, instead of all of them. This hook is responsible for determining the list of markets that need to be tracked at any point in the UI.
 *
 * The Logic is:
 *
 * We have three different "types" of price data being tracked:
 * - Orderbook Display Data : Currently Selected market only => basically depends on user's grouping size selected
 * -- We skip this when we're not on a trade page though.
 * - Deep price data : Current Positions and Currently Selected market => necessary to calculate price impact etc. This is required for these markets because they are the ones where the UI may require this data.
 * - Shallow price data : Other markets => really just necessary to correctly show the current price of markets wherever it may be displayed in the UI.
 */
const useMarketsForDlobToTrack = (
	filterByListeningSelection?: DlobListeningSelection
): MarketTrackingState => {
	const currentlySelectedMarketId = useDriftStore(
		(s) => s.selectedMarket.marketId
	);
	const currentPerpPositions = useCurrentAccountOpenPerpPositions();
	const openBorrows = useDriftStore((s) => s.borrowLendData);
	const getSubscriptionStateForMarket = useMarketStateStore(
		(s) => s.getSubscriptionStateForMarket
	);
	const isOnPageWithOrderbook = useContext(
		OrderbookDisplayContext
	).isDisplayingOrderbook;

	const marketSubscriptionStateUniqueness = useMarketStateStore((s) =>
		Object.entries(s.marketSubscriptionState)
			.map(
				([key, subscriptionState]) =>
					`${key}_${subscriptionState.listeningSelection}`
			)
			.sort()
			.join(',')
	);

	// Memoize perp positions into a string so this doesn't cause a re-render if it's just the size of a position that changes
	const perpPositionIndexes = useMemo(() => {
		return currentPerpPositions.map((p) => p.marketIndex).join(',');
	}, [currentPerpPositions]);

	const openBorrowIndexes = useMemo(() => {
		return (
			openBorrows
				.filter((b) => !b.totalUserNetBalanceBase.eqZero())
				.map((o) => o.bankIndex)
				// Filter out USDC from DLOB Tracking
				.filter((index) => index !== 0)
				.join(',')
		);
	}, [openBorrows]);

	const listeningSelectionFilter = (marketId: MarketId) => {
		if (!filterByListeningSelection) return true;

		const subscriptionState = getSubscriptionStateForMarket(marketId);
		const marketListeningSelection = subscriptionState?.listeningSelection;

		if (!marketListeningSelection) {
			return (
				subscriptionState?.preferredListeningSelection ===
				filterByListeningSelection
			);
		}

		return marketListeningSelection === filterByListeningSelection;
	};

	const returnValue = useMemo(() => {
		// Deep Markets should be all markets with an open position, except the currently selected market
		const deepPriceMarketsInfo = [
			...perpPositionIndexes
				.split(',')
				// When string is empty, the split results in an array with an empty string, filter this out
				.filter((a) => !!a)
				.map((index) => MarketId.createPerpMarket(+index)),
			...openBorrowIndexes
				.split(',')
				// When string is empty, the split results in an array with an empty string, filter this out
				.filter((a) => !!a)
				.map((index) => MarketId.createSpotMarket(+index)),
		].filter((market) => {
			return market.key !== currentlySelectedMarketId.key;
		});

		// Shallow markets should be all other markets (not an open position, not the selected market)
		const shallowPriceMarketsInfo = ALL_TRADEABLE_MARKET_IDS.filter(
			(market) => {
				return (
					market.key !== currentlySelectedMarketId.key &&
					!deepPriceMarketsInfo.find(
						(deepMarket) => deepMarket.key === market.key
					)
				);
			}
		);

		// If we're not on the trade page - then we can ignore the extra deep "selected market subscription". But we should still track it in the regular deep category.
		const selectedMarkets = isOnPageWithOrderbook
			? [currentlySelectedMarketId]
			: [];
		const deepMarkets = isOnPageWithOrderbook
			? deepPriceMarketsInfo
			: [...deepPriceMarketsInfo, currentlySelectedMarketId];

		const marketCategories: MarketTrackingState['categories'] = {
			[MarketStatePriority.SelectedMarket]: selectedMarkets.filter(
				listeningSelectionFilter
			),
			[MarketStatePriority.BackgroundDeep]: deepMarkets.filter(
				listeningSelectionFilter
			),
			[MarketStatePriority.BackgroundShallow]: shallowPriceMarketsInfo.filter(
				listeningSelectionFilter
			),
		};

		const returnValue = {
			categories: marketCategories,
			allMarkets: [
				...marketCategories[MarketStatePriority.SelectedMarket],
				...marketCategories[MarketStatePriority.BackgroundDeep],
				...marketCategories[MarketStatePriority.BackgroundShallow],
			],
			totalMarkets:
				marketCategories[MarketStatePriority.SelectedMarket].length +
				marketCategories[MarketStatePriority.BackgroundDeep].length +
				marketCategories[MarketStatePriority.BackgroundShallow].length,
		};

		return returnValue;
	}, [
		perpPositionIndexes,
		currentlySelectedMarketId,
		openBorrowIndexes,
		marketSubscriptionStateUniqueness,
		isOnPageWithOrderbook,
	]);

	return returnValue;
};

export default useMarketsForDlobToTrack;
