'use client';

import XButton from 'src/components/Buttons/XButton';
import Text from 'src/components/Text/Text';
import React from 'react';
import PopupAlertWrapper from './PopupAlertWrapper';
import { ArrowRight, Checkmark } from '@drift-labs/icons';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useGoToRoute from 'src/hooks/useGoToRoute';

const DownloadReadyAlert = () => {
	const setAccountStore = useDriftAccountStore((s) => s.set);
	const setDriftStore = useDriftStore((s) => s.set);
	const goToRoute = useGoToRoute();

	const goToDownload = () => {
		handleSeen();
		goToRoute('/overview/history/exports');
	};

	const handleSeen = () => {
		setAccountStore((s) => {
			s.downloadFiles.newAvailableDetected = false;
		});

		setDriftStore((s) => {
			s.driftFeatureAlert = {
				alertData: '',
				alertType: '',
			};
		});
	};

	const Content = (
		<div className="relative py-3 pl-3 pr-5">
			<div className="flex flex-row items-start">
				<div className="relative pt-1">
					<div
						className={
							'shadow-[0px_0px_60px_50px] opacity-15 absolute left-4 top-4 shadow-green-50'
						}
					/>
					<div
						className={'w-8 h-8 rounded-full bg-opacity-10 p-1.5 bg-green-50'}
					>
						<div
							className={
								'rounded-full h-full w-full flex items-center justify-center bg-green-50'
							}
						>
							<Checkmark
								className="w-6 h-6"
								color="var(--text-primary-button)"
							/>
						</div>
					</div>
				</div>

				<div className="pl-2">
					<div className="text-text-emphasis">
						<Text.BODY3>{'Your statement is ready for download!'}</Text.BODY3>
					</div>
					<div className="pt-1 text-xs leading-4 text-text-default">
						<Text.BODY3
							onClick={goToDownload}
							className="inline-flex items-center text-positive-green hover:cursor-pointer"
						>
							{'View now'}
							<ArrowRight
								className="ml-1"
								size={15}
								color={'var(--positive-green)'}
							/>
						</Text.BODY3>
					</div>
				</div>
			</div>
			<div className="absolute top-2 sm:top-3 right-2 sm:right-3 ">
				<XButton
					stroke="var(--text-interactive)"
					onClick={(_e) => {
						handleSeen();
					}}
					size={20}
				/>
			</div>
		</div>
	);

	return (
		<PopupAlertWrapper
			className="border border-[var(--positive-green)] bg-container-bg"
			width="370px"
		>
			{Content}
		</PopupAlertWrapper>
	);
};

export default DownloadReadyAlert;
