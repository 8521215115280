import { LoggingServerPacket } from '@drift-labs/logging-server';

export class MetricsServerClient {
	metricsEndpoint: string;

	constructor(loggingServerUrl: string) {
		this.metricsEndpoint = `${loggingServerUrl}/log-metrics`;
	}

	sendMetrics = async (metricsBody: LoggingServerPacket) => {
		try {
			await fetch(this.metricsEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(metricsBody),
			});
		} catch (e) {
			console.log('Failed to report metrics: ', e);
		}
	};
}
