'use client';

import { BigNum } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useContext, useEffect, useMemo } from 'react';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import { fetchInsuranceFundData } from 'src/utils/insuranceFund';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';
import useDriftClient from '../../hooks/useDriftClient';
import useDriftClientIsReady from '../../hooks/useDriftClientIsReady';
import useInterval from '../../hooks/useInterval';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import useIfDataStore from './useIfDataStore';
import { IfStakeSubscriberContext } from 'src/providers/ifStakeSubscriberProvider';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';

const useIfVaultBalances = () => {
	const tokenAccountSubscribers = useContext(
		IfStakeSubscriberContext
	)?.ifStakeVaultSubscribers;

	const getVaultBalances = useMemo(
		() => ({
			getIFVaultBalance: (spotMarketIndex: number): [boolean, BigNum] => {
				const targetTokenAccountSubscriber =
					tokenAccountSubscribers[spotMarketIndex];

				if (
					!targetTokenAccountSubscriber ||
					!targetTokenAccountSubscriber.isSubscribed
				)
					return [false, BigNum.zero()];

				const balanceStr = targetTokenAccountSubscriber
					.getTokenAccountAndSlot()
					?.data?.amount.toString?.(10);

				if (!balanceStr) return [true, BigNum.zero()];

				const balancePrecision =
					SPOT_MARKETS_LOOKUP[spotMarketIndex].precisionExp;

				const balanceBigNum = BigNum.from(balanceStr, balancePrecision);
				return [true, balanceBigNum];
			},
		}),
		[tokenAccountSubscribers]
	);

	return getVaultBalances;
};

const useUserIfStakeAccounts = () => {
	return useContext(IfStakeSubscriberContext)?.userIfStakeSubscribers;
};

export const useTriggerRefreshIfStakeData = () => {
	const setIfData = useIfDataStore((s) => s.setIfData);
	const IFVaultBalances = useIfVaultBalances();
	const userStakeAccounts = useUserIfStakeAccounts();

	const connected = useWalletIsConnected();
	const driftClient = useDriftClient();

	const getOraclePriceForMarket = useGetOraclePriceForMarket();

	const refreshIfStakeData = async () => {
		const spotMarkets = driftClient.getSpotMarketAccounts();

		// await Promise.all(
		spotMarkets.map(async (spotMarket) => {
			const oraclePrice =
				getOraclePriceForMarket(
					MarketId.createSpotMarket(spotMarket.marketIndex)
				)?.toNum() || 0;

			let userStakeAccount = undefined;

			try {
				userStakeAccount = connected
					? userStakeAccounts
							?.find(
								(val) =>
									val?.getInsuranceFundStakeAccountAndSlot()?.data
										?.marketIndex === spotMarket.marketIndex
							)
							?.getInsuranceFundStakeAccountAndSlot()?.data
					: undefined;
			} catch (err) {
				// do nothing
			}

			const [loadingVaultBalance, vaultBalance] =
				IFVaultBalances.getIFVaultBalance(spotMarket.marketIndex);

			if (!loadingVaultBalance) return;

			const newIfData = await fetchInsuranceFundData(
				spotMarket,
				oraclePrice,
				vaultBalance,
				userStakeAccount,
				connected
			);

			setIfData(spotMarket.marketIndex, newIfData);
		});
	};

	return refreshIfStakeData;
};

export const useSyncIfStakeData = () => {
	const connected = useWalletIsConnected();
	const wallet = useCurrentWalletAdapter();
	const driftClientIsReady = useDriftClientIsReady();
	const refreshRate = 3000;

	const userStakeAccounts = useUserIfStakeAccounts();

	const hasStartedSubscribing = userStakeAccounts.some(
		(acc) => acc.isSubscribed
	);
	const refreshIfStakeData = useTriggerRefreshIfStakeData();

	// Update once whenever the drift client is ready, user is connected, and the public key changes
	useEffect(() => {
		if (!driftClientIsReady) return;
		refreshIfStakeData();
	}, [connected, driftClientIsReady, wallet?.publicKey, hasStartedSubscribing]);

	// Also update every 5 seconds regardless of other dependencies changing -- do we want to do this?
	// It's kinda necessary to refresh the status of users' unstaking requests and current stake after they submit the tx
	// Nick update: going to up this refresh rate to 30 seconds if user is not viewing IF page
	useInterval(() => {
		if (!driftClientIsReady) return;
		refreshIfStakeData();
	}, refreshRate);
};
