'use client';

import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

type DotProps = {
	active: boolean;
};

const Dot = styled.div<DotProps>`
	width: 5px;
	height: 5px;
	transition: background-color none !important;
	background-color: ${(props) =>
		props.active ? `white` : `var(--darkBlue-50)`};
`;

const LoadingDots = () => {
	const [currentCount, setCurrentCount] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentCount((count) => count + 1);
		}, 166);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<div className="flex space-x-2">
			<Dot active={currentCount % 6 === 0} />
			<Dot active={currentCount % 6 === 1} />
			<Dot active={currentCount % 6 === 2} />
			<Dot active={currentCount % 6 === 3} />
			<Dot active={currentCount % 6 === 4} />
			<Dot active={currentCount % 6 === 5} />
		</div>
	);
};

export default LoadingDots;
