import {
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	L2OrderBook,
	MarketType,
	OraclePriceData,
	PRICE_PRECISION_EXP,
	PublicKey,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
	WrappedEvent,
} from '@drift-labs/sdk';
import { ENUM_UTILS, UIMarket } from '@drift/common';

export type L2WithOracle = L2OrderBook & { oracleData: OraclePriceData };

export type RawL2Output = {
	marketIndex: number;
	marketType: MarketType;
	marketName: string;
	asks: {
		price: string;
		size: string;
		sources: {
			[key: string]: string;
		};
	}[];
	bids: {
		price: string;
		size: string;
		sources: {
			[key: string]: string;
		};
	}[];
	oracleData: {
		price: string;
		slot: string;
		confidence: string;
		hasSufficientNumberOfDataPoints: boolean;
		twap?: string;
		twapConfidence?: string;
		maxPrice?: string;
	};
	slot?: number;
};

export type RawTradeOutput = {
	action: string;
	actionExplanation: string;
	baseAssetAmountFilled: number;
	filler: string;
	maker: string;
	makerFee: number;
	makerOrderBaseAssetAmount: number;
	makerOrderCumulativeBaseAssetAmountFilled: number;
	makerOrderCumulativeQuoteAssetAmountFilled: number;
	makerOrderDirection: 'long' | 'short';
	makerOrderId: number;
	marketIndex: number;
	marketType: 'perp' | 'spot';
	oraclePrice: number;
	quoteAssetAmountFilled: number;
	quoteAssetAmountSurplus: number;
	referrer: string;
	referrerReward: number;
	slot: number;
	taker: string;
	takerFee: number;
	takerOrderBaseAssetAmount: number;
	takerOrderCumulativeBaseAssetAmountFilled: number;
	takerOrderCumulativeQuoteAssetAmountFilled: number;
	takerOrderDirection: 'perp' | 'spot';
	takerOrderId: number;
	ts: number;
	txSig: string;
	takerPnl: number;
	makerPnl: number;
	makerRebate: number;
	takerOrderFee: number;
	makerOrderFee: number;
	spotFulfillmentMethodFee: number;
	fillRecordId: number;
	refereeDiscount: number;
	txSigIndex: number;
};

export const deserializeTradeResponse = (
	serializedTrade: RawTradeOutput
): WrappedEvent<'OrderActionRecord'> => {
	const market = new UIMarket(
		serializedTrade.marketIndex,
		ENUM_UTILS.toObj(serializedTrade.marketType)
	);
	const basePrecision = market.isPerp
		? BASE_PRECISION_EXP
		: (market.market as SpotMarketConfig).precisionExp;

	return {
		eventType: 'OrderActionRecord',
		txSig: serializedTrade.txSig,
		slot: serializedTrade.slot,
		ts: new BN(serializedTrade.ts),
		action: ENUM_UTILS.toObj(serializedTrade.action),
		actionExplanation: ENUM_UTILS.toObj(serializedTrade.actionExplanation),
		marketIndex: serializedTrade.marketIndex,
		marketType: ENUM_UTILS.toObj(serializedTrade.marketType),
		filler: serializedTrade.filler
			? new PublicKey(serializedTrade.filler)
			: undefined,
		referrerReward: serializedTrade.referrerReward,
		fillRecordId: serializedTrade.fillRecordId
			? new BN(serializedTrade.fillRecordId)
			: undefined,
		baseAssetAmountFilled: BigNum.fromPrint(
			serializedTrade.baseAssetAmountFilled.toString(),
			basePrecision
		).val,
		quoteAssetAmountFilled: BigNum.fromPrint(
			serializedTrade.quoteAssetAmountFilled.toString(),
			QUOTE_PRECISION_EXP
		).val,
		takerFee: BigNum.fromPrint(
			serializedTrade.takerFee?.toString() ?? '0',
			QUOTE_PRECISION_EXP
		).val,
		quoteAssetAmountSurplus: BigNum.fromPrint(
			serializedTrade.quoteAssetAmountSurplus?.toString() ?? '0',
			QUOTE_PRECISION_EXP
		).val,
		takerOrderBaseAssetAmount: BigNum.fromPrint(
			serializedTrade.takerOrderBaseAssetAmount?.toString() ?? '0',
			basePrecision
		).val,
		takerOrderCumulativeBaseAssetAmountFilled: BigNum.fromPrint(
			serializedTrade.takerOrderCumulativeBaseAssetAmountFilled?.toString() ??
				'0',
			basePrecision
		).val,
		takerOrderCumulativeQuoteAssetAmountFilled: BigNum.fromPrint(
			serializedTrade.takerOrderCumulativeQuoteAssetAmountFilled?.toString() ??
				'0',
			QUOTE_PRECISION_EXP
		).val,
		makerOrderBaseAssetAmount: BigNum.fromPrint(
			serializedTrade.makerOrderBaseAssetAmount?.toString() ?? '0',
			basePrecision
		).val,
		makerOrderCumulativeBaseAssetAmountFilled: BigNum.fromPrint(
			serializedTrade.makerOrderCumulativeBaseAssetAmountFilled?.toString() ??
				'0',
			basePrecision
		).val,
		makerOrderCumulativeQuoteAssetAmountFilled: BigNum.fromPrint(
			serializedTrade.makerOrderCumulativeQuoteAssetAmountFilled?.toString() ??
				'0',
			QUOTE_PRECISION_EXP
		).val,
		oraclePrice: BigNum.fromPrint(
			serializedTrade.oraclePrice?.toString() ?? '0',
			PRICE_PRECISION_EXP
		).val,
		makerFee: BigNum.fromPrint(
			serializedTrade.makerFee?.toString() ?? '0',
			QUOTE_PRECISION_EXP
		).val,
		fillerReward: BigNum.fromPrint(
			serializedTrade.makerFee?.toString() ?? '0',
			QUOTE_PRECISION_EXP
		).val,
		txSigIndex: serializedTrade.txSigIndex,
		taker: serializedTrade.taker
			? new PublicKey(serializedTrade.taker)
			: undefined,
		takerOrderId: serializedTrade.takerOrderId,
		takerOrderDirection: ENUM_UTILS.toObj(serializedTrade.takerOrderDirection),
		maker: serializedTrade.maker
			? new PublicKey(serializedTrade.maker)
			: undefined,
		makerOrderId: serializedTrade.makerOrderId,
		makerOrderDirection: ENUM_UTILS.toObj(serializedTrade.makerOrderDirection),
		spotFulfillmentMethodFee: serializedTrade.spotFulfillmentMethodFee
			? new BN(serializedTrade.spotFulfillmentMethodFee)
			: undefined,
	};
};

export const deserializeL2Response = (
	serializedOrderbook: RawL2Output
): L2WithOracle => {
	return {
		asks: serializedOrderbook.asks.map((ask) => ({
			price: new BN(ask.price),
			size: new BN(ask.size),
			sources: Object.entries(ask.sources).reduce((previous, [key, val]) => {
				return {
					...previous,
					[key]: new BN(val),
				};
			}, {}),
		})),
		bids: serializedOrderbook.bids.map((bid) => ({
			price: new BN(bid.price),
			size: new BN(bid.size),
			sources: Object.entries(bid.sources).reduce((previous, [key, val]) => {
				return {
					...previous,
					[key]: new BN(val),
				};
			}, {}),
		})),
		oracleData: {
			price: serializedOrderbook.oracleData.price
				? new BN(serializedOrderbook.oracleData.price)
				: undefined,
			slot: serializedOrderbook.oracleData.slot
				? new BN(serializedOrderbook.oracleData.slot)
				: undefined,
			confidence: serializedOrderbook.oracleData.confidence
				? new BN(serializedOrderbook.oracleData.confidence)
				: undefined,
			hasSufficientNumberOfDataPoints:
				serializedOrderbook.oracleData.hasSufficientNumberOfDataPoints,
			twap: serializedOrderbook.oracleData.twap
				? new BN(serializedOrderbook.oracleData.twap)
				: undefined,
			twapConfidence: serializedOrderbook.oracleData.twapConfidence
				? new BN(serializedOrderbook.oracleData.twapConfidence)
				: undefined,
			maxPrice: serializedOrderbook.oracleData.maxPrice
				? new BN(serializedOrderbook.oracleData.maxPrice)
				: undefined,
		},
		slot: serializedOrderbook.slot,
	};
};
