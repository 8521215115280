'use client';

import { PublicKey } from '@drift-labs/sdk';
import { BigNum, LAMPORTS_EXP } from '@drift-labs/sdk';
import { Connection } from '@solana/web3.js';
import { useEffect, useRef } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import useCurrentWalletConnectionState from './useCurrentWalletConnectionState';
import useInterval from './useInterval';

/**
 * !! This hook will keep the wallet balance in the store up to date. use drift account store to GET the current balance in a UI component
 * @returns
 */
const useSolBalance = () => {
	const listenerId = useRef(null);
	const connection = useDriftStore((s) => s.connection?.current) as Connection;
	const wallet = useCurrentWalletAdapter();
	const connected = wallet?.connected;
	const set = useDriftStore((s) => s.set);
	const currentSolBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const currentWalletConnectionState = useCurrentWalletConnectionState();

	const handleNewBalance = (newBalance: number) => {
		if (newBalance === currentSolBalance.toNum() && newBalance !== 0) return;

		set((s) => {
			s.wallet.currentSolBalance = BigNum.from(newBalance, LAMPORTS_EXP);
		});

		currentWalletConnectionState.instance.progress('BalanceLoaded');
	};

	const getBalance = async (authority: PublicKey) => {
		const accountInfo = await connection.getAccountInfo(authority);

		const newBalance = accountInfo ? accountInfo.lamports : 0;

		handleNewBalance(newBalance);
	};

	const updateBalance = () => {
		if (connected && connection && wallet?.publicKey) {
			getBalance(wallet.publicKey);

			const newListenerId = connection.onAccountChange(
				wallet.publicKey,
				(accountInfo) => {
					handleNewBalance(accountInfo.lamports);
				}
			);

			listenerId.current = newListenerId;
		} else if (connection) {
			set((s) => {
				s.wallet.currentSolBalance = BigNum.zero(LAMPORTS_EXP);
			});

			if (listenerId.current === null) return;

			connection.removeAccountChangeListener(listenerId.current);
			listenerId.current = null;
		}
	};

	useEffect(() => {
		updateBalance();
	}, [connected, connection, currentWalletConnectionState]);

	useInterval(() => {
		if (
			currentWalletConnectionState.instance.AdapterConnected &&
			!currentWalletConnectionState.instance.BalanceLoaded
		) {
			updateBalance();
		}
	}, 1000);

	return;
};

export default useSolBalance;
