import { twMerge } from 'tailwind-merge';
import Text from '../Text/Text';
import { Key } from 'react';

type ButtonTabProps = {
	label: React.ReactNode;
	active: boolean;
	onSelect: () => void;
	className?: string;
	selectedClassName?: string;
	disabled?: boolean;
};

const ButtonTab = ({
	label,
	active,
	className,
	selectedClassName,
	onSelect,
	disabled,
}: ButtonTabProps) => {
	return (
		<div
			className={twMerge(
				'cursor-pointer px-2 py-1',
				active && 'bg-tab-selected-bg hover:bg-tab-selected-bg rounded-sm',
				active && selectedClassName,
				disabled && 'cursor-not-allowed text-text-disabled',
				className
			)}
			onClick={disabled ? undefined : onSelect}
		>
			<Text.BODY2 className={twMerge(active && 'text-gradient-1')}>
				{label}
			</Text.BODY2>
		</div>
	);
};

type ButtonTabsProps<T extends Key> = {
	tabs: {
		label: ButtonTabProps['label'];
		value: T;
		disabled?: ButtonTabProps['disabled'];
	}[];
	activeTab: T;
	onSelect: (tab: T) => void;
	className?: string;
	tabClassName?: string;
	selectedTabClassName?: string;
};

const ButtonTabs = <T extends Key>({
	tabs,
	activeTab,
	onSelect,
	className,
	tabClassName,
	selectedTabClassName,
}: ButtonTabsProps<T>) => {
	return (
		<div className={twMerge('flex items-center gap-1', className)}>
			{tabs.map((tab) => (
				<ButtonTab
					key={tab.value}
					label={tab.label}
					active={activeTab === tab.value}
					onSelect={() => onSelect(tab.value)}
					className={tabClassName}
					selectedClassName={selectedTabClassName}
					disabled={tab.disabled}
				/>
			))}
		</div>
	);
};

export default ButtonTabs;
