'use client';

import {
	BN,
	BN_MAX,
	BigNum,
	OracleClient,
	OraclePriceData,
	OracleSource,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	PublicKey,
	ZERO,
	calculateBidAskPrice,
	getOracleClient,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId, UIMarket } from '@drift/common';
import { immerable } from 'immer';
import { useEffect, useMemo, useRef } from 'react';
import {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { PriceInfo } from 'src/stores/types';
import useOraclePriceStore, {
	OraclePriceStore,
} from 'src/stores/useOraclePriceStore';
import NumLib from 'src/utils/NumLib';
import { useImmer } from 'use-immer';
import useMarketStateStore, {
	DlobListeningSelection,
} from '../stores/useMarketStateStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import useInterval from './useInterval';
import useMarketsForDlobToTrack from './useMarketsForDlobToTrack';

export type FormattedOraclePriceData = {
	price: number;
	slot: number;
	confidence: number;
	twap?: number;
	twapConfidence?: number;
	hasSufficientNumberOfDataPoints: OraclePriceData['hasSufficientNumberOfDataPoints'];
	maxPrice?: number;
};

export class OracleSymbolMap {
	[immerable] = true;

	constructor() {
		this.symbolMap = {};
	}

	public symbolMap: {
		[index: string]: PriceInfo;
	};
}

const ORACLE_CLIENT_KEYS = Object.values(OracleSource).map(
	(value) => Object.keys(value)[0]
);
type OracleClientsMap = Map<string, OracleClient | undefined>;

const useSyncOracleDataWithRpc = () => {
	const connection = useDriftStore((s) => s.connection);
	const bulkLoader = useDriftStore((s) => s.connection.accountLoader);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const driftClientIsReady = useDriftClientIsReady();

	const oracleClientMap = useRef<OracleClientsMap>(new Map());

	const marketsToTrackWithBlockchain = useMarketsForDlobToTrack(
		DlobListeningSelection.BLOCKCHAIN
	);

	const usdcMarket = MarketId.createSpotMarket(0);

	const marketsAndAccountsToUse: {
		market: UIMarket;
		accountToUse: PublicKey;
	}[] = useMemo(() => {
		return [usdcMarket, ...marketsToTrackWithBlockchain.allMarkets].map(
			(market) => {
				const isPerp = market.isPerp;

				return {
					market: isPerp
						? UIMarket.createPerpMarket(market.marketIndex)
						: UIMarket.createSpotMarket(market.marketIndex),
					accountToUse: isPerp
						? PERP_MARKETS_LOOKUP[market.marketIndex].oracle
						: SPOT_MARKETS_LOOKUP[market.marketIndex].oracle,
				};
			}
		);
	}, [
		CurrentPerpMarkets,
		CurrentSpotMarkets,
		marketsToTrackWithBlockchain.categories,
	]);

	const getMarketState = useMarketStateStore((s) => s.get);

	const set = useOraclePriceStore((s) => s.set);

	// Keep a local price store state so that the app isn't re-rendering non-stop for every price change
	const [localPriceStoreState, setLocalPriceStoreState] = useImmer<
		OraclePriceStore['symbolMap']
	>({});

	const bulkLoaderCallbacks = useRef([]);

	// Initialize the oracle clients
	useEffect(() => {
		if (!driftClient?.program || !connection.current) return;

		ORACLE_CLIENT_KEYS.forEach((oracleSourceKey) => {
			const oracleSource = ENUM_UTILS.toObj(oracleSourceKey);

			oracleClientMap.current.set(
				oracleSourceKey,
				getOracleClient(oracleSource, connection.current, driftClient.program)
			);
		});
	}, [connection.current, driftClient?.program]);

	const getClient = useMemo(
		() => (oracleSource: OracleSource) => {
			if (!driftClient) return undefined;

			const oracleSourceString = ENUM_UTILS.toStr(oracleSource);
			const oracleClient = oracleClientMap.current.get(oracleSourceString);

			if (!oracleClient) {
				throw new Error(`Oracle client not found for ${oracleSourceString}`);
			}

			return oracleClient;
		},
		[driftClient]
	);

	useEffect(() => {
		if (!connection) return;
		if (!driftClientIsReady) return;
		if (!driftClient) return;

		const updateOracleDataWithAccountBuffer = async (
			market: UIMarket,
			accountDataBuffer: Buffer
		) => {
			const accountClient = getClient(market.market.oracleSource);
			if (!accountDataBuffer) {
				return;
			}
			const oraclePriceData = await accountClient.getOraclePriceDataFromBuffer(
				accountDataBuffer
			);

			let markPrice: BigNum;
			let bid: BN;
			let ask: BN;

			let fallbackBid: BN;
			let fallbackAsk: BN;

			if (market.isPerp) {
				[bid, ask] = calculateBidAskPrice(
					driftClient.getPerpMarketAccount(market.market.marketIndex)?.amm,
					oraclePriceData
				);

				fallbackBid = bid;
				fallbackAsk = ask;

				const l2StateForMarket = getMarketState().getDlobStateForMarket(
					new MarketId(market.market.marketIndex, market.marketType)
				);

				const dlobBestBid = l2StateForMarket.bids[0]?.price;
				if (dlobBestBid) {
					bid = BN.max(bid || ZERO, dlobBestBid);
				}

				const dlobBestAsk = l2StateForMarket.asks[0]?.price;
				if (dlobBestAsk) {
					ask = BN.min(ask || BN_MAX, dlobBestAsk);
				}
			} else {
				const l2StateForMarket = getMarketState().getDlobStateForMarket(
					new MarketId(market.market.marketIndex, market.marketType)
				);

				const dlobBestBid = l2StateForMarket.bids[0]?.price;
				if (dlobBestBid) {
					bid = BN.max(bid || ZERO, dlobBestBid);
				}

				const dlobBestAsk = l2StateForMarket.asks[0]?.price;
				if (dlobBestAsk) {
					ask = BN.min(ask || BN_MAX, dlobBestAsk);
				}

				// if no liquidity on the book show oracle price
				markPrice = BigNum.from(oraclePriceData.price, PRICE_PRECISION_EXP);
			}

			let mid: BigNum;

			// if bid/ask cross, force it to be the one closer to oracle, if oracle is in the middle, use oracle price
			if (bid && ask && bid.gt(ask)) {
				if (bid.gt(oraclePriceData.price) && ask.gt(oraclePriceData.price)) {
					mid = BigNum.from(BN.min(bid, ask), PRICE_PRECISION_EXP);
				} else if (
					bid.lt(oraclePriceData.price) &&
					ask.lt(oraclePriceData.price)
				) {
					mid = BigNum.from(BN.max(bid, ask), PRICE_PRECISION_EXP);
				} else {
					mid = BigNum.from(oraclePriceData.price, PRICE_PRECISION_EXP);
				}
			} else {
				mid =
					bid && ask
						? BigNum.from(bid, PRICE_PRECISION_EXP)
								.add(BigNum.from(ask, PRICE_PRECISION_EXP))
								.scale(1, 2)
						: markPrice;
			}

			setLocalPriceStoreState((s) => {
				s[market.key] = {
					market,
					priceData: {
						price: NumLib.formatBn.toRawNum(
							oraclePriceData.price,
							PRICE_PRECISION
						),
						slot: oraclePriceData.slot.toNumber(),
						confidence: NumLib.formatBn.toRawNum(
							oraclePriceData.confidence,
							PRICE_PRECISION
						),
						twap: NumLib.formatBn.toRawNum(
							oraclePriceData.twap,
							PRICE_PRECISION
						),
						twapConfidence: NumLib.formatBn.toRawNum(
							oraclePriceData.twapConfidence,
							PRICE_PRECISION
						),
						hasSufficientNumberOfDataPoints:
							oraclePriceData.hasSufficientNumberOfDataPoints,
						maxPrice: NumLib.formatBn.toRawNum(
							oraclePriceData.maxPrice,
							PRICE_PRECISION
						),
					},
					rawPriceData: oraclePriceData,
					markPrice: mid,
					fallbackBid: BigNum.from(fallbackBid, PRICE_PRECISION_EXP),
					fallbackAsk: BigNum.from(fallbackAsk, PRICE_PRECISION_EXP),
					bestBid: BigNum.from(bid, PRICE_PRECISION_EXP),
					bestAsk: BigNum.from(ask, PRICE_PRECISION_EXP),
				};
			});
		};

		// Subsubscribe to account updates
		marketsAndAccountsToUse.forEach(({ market, accountToUse }) => {
			bulkLoader
				.addAccount(accountToUse, async (accountDataBuffer) => {
					updateOracleDataWithAccountBuffer(market, accountDataBuffer);
				})
				.then((callbackId) => {
					bulkLoaderCallbacks.current.push([callbackId, accountToUse]);
				});
		});

		// Trigger a manual initial update for all markets
		marketsAndAccountsToUse.forEach(({ market, accountToUse }) => {
			const prefetchedResult = bulkLoader.bufferAndSlotMap.get(
				accountToUse.toString()
			);

			if (!prefetchedResult) return;

			updateOracleDataWithAccountBuffer(market, prefetchedResult.buffer);
		});

		return () => {
			// Clean up bulk loader account listeners
			if (bulkLoaderCallbacks.current.length) {
				bulkLoaderCallbacks.current.forEach(([callbackId, accountKey]) => {
					bulkLoader.removeAccount(accountKey, callbackId);
				});
				bulkLoaderCallbacks.current = [];
			}
		};
	}, [connection, bulkLoader, driftClientIsReady, marketsAndAccountsToUse]);

	// Update the price store every 500ms
	useInterval(() => {
		set((s) => {
			s.symbolMap = localPriceStoreState;
		});
	}, 1000);
};

export default useSyncOracleDataWithRpc;
