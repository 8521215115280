'use client';

import { COMMON_UI_UTILS } from '@drift/common';
import { useEffect } from 'react';
//import { singletonHook } from 'react-singleton-hook';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useCurrentUserAccount from './useCurrentUserAccount';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import useWalletIsConnected from './useWalletIsConnected';

const useSyncAccountExists = () => {
	const connected = useWalletIsConnected();
	const wallet = useCurrentWalletAdapter();
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const [userAccountNotInitialized, currentAccountKey] = useDriftAccountStore(
		(s) => [s.userAccountNotInitialized, s.currentUserKey]
	);
	const currentUserAccount = useCurrentUserAccount();
	const setDriftAccountStore = useDriftAccountStore((s) => s.set);

	const getAndSetUserExists = async () => {
		if (!currentUserAccount) {
			setDriftAccountStore((s) => {
				s.currentUserExists = false;
			});
			return;
		}

		const accountExists = await COMMON_UI_UTILS.userExists(
			driftClient,
			currentUserAccount.userId,
			currentUserAccount.authority
		);

		setDriftAccountStore((s) => {
			s.currentUserExists = accountExists;
		});
	};

	useEffect(() => {
		if (!connected || !driftClientIsReady) {
			setDriftAccountStore((s) => {
				s.currentUserExists = undefined;
			});
			return;
		}
		getAndSetUserExists();
	}, [
		connected,
		wallet,
		driftClientIsReady,
		currentAccountKey,
		userAccountNotInitialized,
		!!currentUserAccount?.positionsLoaded,
	]);
};

// wasn't updating properly with singleton hook, need to debug/refactor if we need it
export default useSyncAccountExists;
