'use client';

import type { UISerializableOrderActionRecord } from '@drift/common';
import { useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useAppEventEmitter from './useAppEventEmitter';
import useDriftActions from './useDriftActions';
import { BigNum, PRICE_PRECISION_EXP, ZERO } from '@drift-labs/sdk';
import useCurrentSettings from './useCurrentSettings';

const usePnlModalDisplayListener = () => {
	const [savedSettings] = useCurrentSettings();
	const appEventEmitter = useAppEventEmitter();
	const ordersAwaitingPnlModalDisplay = useDriftStore(
		(s) => s.ordersAwaitingPnlModalDisplay
	);
	const actions = useDriftActions();

	const displayPnlModalForFilledOrders = ({
		orderId,
		orderActionRecord,
	}: {
		orderId: number;
		orderActionRecord: UISerializableOrderActionRecord;
	}) => {
		const key = `${orderActionRecord.marketIndex}-${orderId}`;
		const orderToDisplayInModal = ordersAwaitingPnlModalDisplay[key];
		if (orderToDisplayInModal) {
			const position = orderToDisplayInModal.openPositionInfo;

			const actualExitPrice =
				orderActionRecord.quoteAssetAmountFilled.toNum() /
				orderActionRecord.baseAssetAmountFilled.toNum();

			const baseAssetPriceDifference =
				position.direction === 'short'
					? (BigNum.from(position?.entryPrice, PRICE_PRECISION_EXP)?.toNum() ??
							0) - actualExitPrice
					: actualExitPrice -
					  (BigNum.from(position?.entryPrice, PRICE_PRECISION_EXP)?.toNum() ??
							0);

			const pnlDollarValue =
				orderActionRecord.baseAssetAmountFilled.toNum() *
				baseAssetPriceDifference;

			setDriftStore((s) => {
				delete s.ordersAwaitingPnlModalDisplay[key];
				// dont popup for lp positions since numbers can be wrong
				if (pnlDollarValue > 0 && position.lpShares.eq(ZERO)) {
					s.pnlModalPosition = {
						position,
						actualExitPrice,
						pnl: pnlDollarValue,
						isClosed: true,
						isLpPosition: false,
					};
				}
				return s;
			});

			if (
				pnlDollarValue > 0 &&
				position.lpShares.eq(ZERO) &&
				savedSettings.showPnlOnClose
			) {
				actions.showModal('showPnlModal');
			}
		}
	};

	useEffect(() => {
		appEventEmitter.on('orderFillComplete', displayPnlModalForFilledOrders);

		return () => {
			appEventEmitter.removeListener(
				'orderFillComplete',
				displayPnlModalForFilledOrders
			);
		};
	}, [ordersAwaitingPnlModalDisplay, appEventEmitter]);

	const setDriftStore = useDriftStore((s) => s.set);
};

export default usePnlModalDisplayListener;
