'use client';

import { PollingDriftClientAccountSubscriber } from '@drift-labs/sdk';
import { useEffect, useRef } from 'react';
import Env from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import useIdleLevels from './utils/useIdleLevels';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { usePageVisibility } from './utils/usePageVisibility';

const BASE_POLLING_RATE = Env.pollingFrequencyMs;
const IDLE_1_MIN_POLLING_MULTIPLIER = 10;
const IDLE_10_MIN_POLLING_MULTIPLIER = 60;

/**
 * Switches the polling rate of the bulkAccountLoader based on idle time of the user
 */
const useIdlePollingRateSwitcher = () => {
	const { idle1Min, idle10Min } = useIdleLevels();
	const pageIsVisible = usePageVisibility();

	const wasIdle = useRef(false);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const setDriftStore = useDriftStore((s) => s.set);

	useEffect(() => {
		if (!driftClientIsReady) return;

		if (window.localStorage.getItem('disableIdle')) {
			console.log(`Idle Polling Switcher Disabled`);
			return;
		}

		if (
			driftClient.accountSubscriber instanceof
			PollingDriftClientAccountSubscriber
		) {
			if (idle10Min && !pageIsVisible) {
				wasIdle.current = true;
				console.log(`Idle => 10 Min`);
				driftClient.accountSubscriber.updateAccountLoaderPollingFrequency(
					IDLE_10_MIN_POLLING_MULTIPLIER * BASE_POLLING_RATE
				);
				setDriftStore((s) => {
					s.pollingMultiplier = IDLE_10_MIN_POLLING_MULTIPLIER;
				});
			} else if (idle1Min && !pageIsVisible) {
				wasIdle.current = true;
				console.log(`Idle => 1 Min`);
				driftClient.accountSubscriber.updateAccountLoaderPollingFrequency(
					IDLE_1_MIN_POLLING_MULTIPLIER * BASE_POLLING_RATE
				);
				setDriftStore((s) => {
					s.pollingMultiplier = IDLE_1_MIN_POLLING_MULTIPLIER;
				});
			} else if (wasIdle.current) {
				wasIdle.current = false;
				console.log(`Idle ==> false`);
				driftClient.accountSubscriber.updateAccountLoaderPollingFrequency(
					BASE_POLLING_RATE
				);
				setDriftStore((s) => {
					s.pollingMultiplier = 1;
				});
			}
		}
	}, [idle1Min, idle10Min, pageIsVisible, driftClientIsReady]);
};

export default useIdlePollingRateSwitcher;
