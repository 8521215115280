'use client';

import { useEffect, useRef } from 'react';

import { LARGE, MEDIUM, SMALL, XLARGE } from '../constants/breakpoints';
import { SizeString, useWindowSizeStore } from 'src/stores/useWindowSizeStore';

const getHeightString = () => {
	const height = window.innerHeight;
	return height < 700 ? 'short' : height >= 1000 ? 'tall' : 'medium';
};

const getSizeString = (): SizeString => {
	const widthToUse = window.innerWidth;
	return widthToUse < SMALL
		? 'xs'
		: widthToUse >= SMALL && widthToUse < MEDIUM
		? 'sm'
		: widthToUse >= MEDIUM && widthToUse < LARGE
		? 'md'
		: widthToUse >= LARGE && widthToUse < XLARGE
		? 'lg'
		: 'xl';
};

/**
 * This hook listens to the window resize event and updates the window size store with the new size and dimensions.
 *
 * Try to avoid using realtimeHeight and realtimeWidth and use size isntead in components to avoid unnecessary re-renders.
 */
const useSyncWindowSizeStore = () => {
	const set = useWindowSizeStore((s) => s.set);

	const hasSetHandler = useRef(false);

	useEffect(() => {
		// We're using a single, one-time handler because adding/removing a new handler for every resize event is expensive. The handler is not dependent on any state inside of this hook so this is safe.
		if (hasSetHandler.current) return;

		const handleResize = () => {
			set((s) => {
				s.size = getSizeString();
				s.heightString = getHeightString();
				s.realtimeHeight = window.innerHeight;
				s.realtimeWidth = window.innerWidth;
			});
		};

		window.addEventListener('resize', handleResize);

		handleResize();

		// Needed to add this because sometimes so many resize events fire that the UI gets jamemd up and the resize event fires "before it should" while other things are still rendering / figuring out their size .. this makes sure that if we hit that state
		const interval = setInterval(handleResize, 2000);

		hasSetHandler.current = true;

		return () => {
			clearInterval(interval);
		};
	}, []);
};

export default useSyncWindowSizeStore;
