import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Wrapper for Popups. It handles fixing the content in the center of the screen, applying a background colour, and making sure it renders above the rest of the page's content
 * @param props
 * @returns
 */
const PopupAlertWrapper = (
	props: PropsWithChildren<{
		width: string;
		height?: string;
		className?: string;
	}>
) => {
	return (
		<div
			className={twMerge(
				'fixed z-100 bg-container-bg-selected max-w-[90%] rounded rounded-md overflow-hidden',
				props.className
			)}
			style={{
				top: '10%',
				left: '50%',
				transform: 'translateX(-50%)',
				width: props.width,
				height: props?.height,
			}}
		>
			{props.children}
		</div>
	);
};

export default PopupAlertWrapper;
