'use client';

import { ENUM_UTILS } from '@drift/common';
import { useEffect, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import useMarkPrice from './useMarkPrice';

/**
 * Uses trading history from the history server to calculate price change
 * @returns
 */
const usePriceChange = () => {
	const setStore = useDriftStore((s) => s.set);

	const currentlySelectedMarketId = useDriftStore(
		(s) => s.selectedMarket.marketId
	);

	const currentMarketPrice = useMarkPrice(currentlySelectedMarketId);
	const driftClientIsReady = useDriftClientIsReady();
	const marketsData = useDriftStore((s) => s.marketsData24H);

	// store price change locally
	const [currentPriceChange, setCurrentPriceChange] = useState<{
		percent: number;
		absolute: number;
	}>({ percent: undefined, absolute: undefined });

	const setBaseState = () => {
		setCurrentPriceChange({
			percent: 0,
			absolute: 0,
		});
	};

	const updatePriceChange = async () => {
		if (!currentMarketPrice) return;

		const currentMarketPriceNum = currentMarketPrice.toNum();

		if (!marketsData || marketsData.length == 0) {
			return;
		} else {
			const selectedMarketData = marketsData.find(
				(mkt) =>
					mkt.marketIndex === currentlySelectedMarketId.marketIndex &&
					ENUM_UTILS.match(mkt.marketType, currentlySelectedMarketId.marketType)
			);

			if (!selectedMarketData) {
				setBaseState();
				return;
			}

			if (!selectedMarketData?.price24hAgo || !currentMarketPriceNum) {
				setCurrentPriceChange({
					percent: 0,
					absolute: 0,
				});
				return;
			}

			const absChange = currentMarketPriceNum - selectedMarketData.price24hAgo;
			const percentChange = (absChange / selectedMarketData.price24hAgo) * 100;

			if (
				percentChange !== currentPriceChange.percent ||
				absChange !== currentPriceChange.absolute
			) {
				setCurrentPriceChange({
					percent: percentChange,
					absolute: absChange,
				});
			}
		}
	};

	// update price change in store when local value changes
	useEffect(() => {
		const percent =
			!currentPriceChange.percent ||
			isNaN(currentPriceChange.percent) ||
			!isFinite(currentPriceChange.percent)
				? 0
				: currentPriceChange.percent;

		const absolute =
			!currentPriceChange.absolute ||
			isNaN(currentPriceChange.absolute) ||
			!isFinite(currentPriceChange.absolute)
				? 0
				: currentPriceChange.absolute;

		setStore((s) => {
			s.selectedMarket.priceChangePercent = percent;
			s.selectedMarket.priceChangeAbsolute = absolute;
		});
	}, [currentPriceChange]);

	// update price change
	useEffect(() => {
		updatePriceChange();
	}, [
		currentMarketPrice?.toString(),
		marketsData,
		currentlySelectedMarketId?.key,
		driftClientIsReady,
	]);
};

export default usePriceChange;
