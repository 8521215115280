'use client';

import {
	Event,
	EventType,
	MarketType,
	OrderAction,
	OrderActionRecord,
	PublicKey,
	WrappedEvent,
} from '@drift-labs/sdk';
import {
	DepositRecordEvent,
	ENUM_UTILS,
	FundingPaymentRecordEvent,
	LiquidationRecordEvent,
	OrderRecordEvent,
	Serializer,
	SettlePnlRecordEvent,
	SwapRecordEvent,
	UIMarket,
	UISerializableDepositRecord,
	UISerializableFundingPaymentRecord,
	UISerializableOrderActionRecord,
	UISerializableSettlePnlRecord,
	getSortScoreForOrderActionRecords,
	getSortScoreForOrderRecords,
	matchEnum,
	sortUIOrderActionRecords,
} from '@drift/common';
import { useCallback, useContext, useEffect } from 'react';
import {
	OrderedPerpMarkets,
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import { OrderMatcherContext } from 'src/providers/orderRecordMatcherProvider';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import { notify } from 'src/utils/notifications';
import UI_UTILS from 'src/utils/uiUtils';
import { DriftBlockchainEventSubjectContext } from '../../providers/driftEvents/driftEventSubjectProvider';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useAppEventEmitter from '../useAppEventEmitter';
import useLazySubAccounts from '../useLazySubAccounts';

/**
 * This hook is responsible for listening to the drift events that come through the relevant event subject. All subscription optimisation should be done BEFORE the events get piped into these subjects.
 */

const EVENTS_TO_LISTEN_TO = [
	'OrderActionRecord',
	'OrderRecord',
	'SwapRecord',
	'SettlePnlRecord',
	'DepositRecord',
	'LiquidationRecord',
	'FundingPaymentRecord',
];

const MAX_RECENT_TRADES = 100;
const TRADE_DEDUPE_MARKER = 20;

const getEventRecordIdentifier = (
	eventRecord:
		| FundingPaymentRecordEvent
		| DepositRecordEvent
		| UISerializableSettlePnlRecord
		| UISerializableFundingPaymentRecord
		| UISerializableDepositRecord
) => {
	return `${eventRecord.txSig}-${eventRecord.txSigIndex}`;
};

const useDriftMarketEventListener = () => {
	const eventSubjectContext = useContext(DriftBlockchainEventSubjectContext);

	const setState = useDriftAccountsStore((s) => s.set);
	const getState = useDriftAccountsStore((s) => s.get);
	const getDriftState = useDriftStore((s) => s.get);
	const setDriftState = useDriftStore((s) => s.set);

	const accounts = useLazySubAccounts();

	const appEventEmitter = useAppEventEmitter();

	const orderMatcher = useContext(OrderMatcherContext);

	const findAccountToAppendTo = useCallback(
		(pubKey: PublicKey): string => {
			if (!accounts?.length || !pubKey) return;
			return accounts.find((acct) => acct?.pubKey?.equals(pubKey))?.userKey;
		},
		[accounts]
	);

	const appendFundingPaymentRecord = (
		newFundingPaymentRecord: FundingPaymentRecordEvent
	) => {
		const matchingUserKey = findAccountToAppendTo(newFundingPaymentRecord.user);

		if (matchingUserKey === undefined) {
			return;
		}

		const fundingPayments =
			getState().accounts[matchingUserKey].fundingHistory.records;

		const fundingPaymentsTxSignatures = fundingPayments.map((p) =>
			getEventRecordIdentifier(p)
		);

		if (
			!fundingPaymentsTxSignatures.includes(
				getEventRecordIdentifier(newFundingPaymentRecord)
			)
		) {
			const formattedFundingPaymentRecord =
				Serializer.Deserialize.UIFundingPayment(newFundingPaymentRecord);

			setState((s) => {
				s.accounts[matchingUserKey].fundingHistory.records = [
					formattedFundingPaymentRecord,
					...fundingPayments,
				].sort((a, b) => b.ts.toNumber() - a.ts.toNumber());
				s.accounts[matchingUserKey].fundingHistory.totalCount++;
				s.accounts[matchingUserKey].fundingHistory.cumulative += parseFloat(
					formattedFundingPaymentRecord.fundingPayment.print()
				);
			});
		}
	};

	const appendLiquidationRecord = (newLiqRecord: LiquidationRecordEvent) => {
		const matchingUserKey = findAccountToAppendTo(newLiqRecord.user);

		if (matchingUserKey === undefined) {
			return;
		}

		const prevLiquidations =
			getState().accounts[matchingUserKey].liquidationHistory.liquidations;

		const lastSeenLiqSlot = prevLiquidations[0]?.slot ?? 0;

		const formattedNewLiq = Serializer.Deserialize.UILiquidation(newLiqRecord);

		const addNewLiqToStore = () => {
			setState((s) => {
				const newLiqs = [formattedNewLiq, ...prevLiquidations];

				s.accounts[matchingUserKey].liquidationHistory.liquidations = newLiqs;

				const maxPrevId = Math.max(
					...prevLiquidations.map((liq) => liq.liquidationId)
				);

				// If the new record has a higher ID than the previously seen ones then it's a new record and the total liq count can be incremented
				if (formattedNewLiq.liquidationId > maxPrevId) {
					s.accounts[matchingUserKey].liquidationHistory.totalCount++;
				}
			});
		};

		// If the new record is in a newer slot than one we've seen before then it's definitely new
		if (newLiqRecord.slot > lastSeenLiqSlot) {
			addNewLiqToStore();
		} else {
			// If not .. it might still be new but we need to do a more expensive test
			const formattedLiquidation =
				Serializer.Deserialize.UILiquidation(newLiqRecord);

			const haveSameRecordInStore = prevLiquidations.find((prevLiq) => {
				const stringPrevLiq = JSON.stringify(
					Serializer.Serialize.UIOrderRecord(prevLiq)
				);
				const stringNewLiq = JSON.stringify(
					Serializer.Serialize.UIOrderRecord(formattedLiquidation)
				);

				return stringPrevLiq === stringNewLiq;
			});

			if (!haveSameRecordInStore) {
				addNewLiqToStore();
			}
		}
	};

	const appendDepositRecord = (newDepositRecord: DepositRecordEvent) => {
		const matchingUserKey = findAccountToAppendTo(newDepositRecord.user);

		if (matchingUserKey === undefined) {
			return;
		}

		const formattedDepositRecord =
			Serializer.Deserialize.UIDeposit(newDepositRecord);

		const depositRecords =
			getState().accounts[matchingUserKey]?.depositWithdrawalHistory
				.depositsWithdrawals;

		const depositRecordsTxSignatures = depositRecords.map((p) =>
			getEventRecordIdentifier(p)
		);

		if (
			!depositRecordsTxSignatures.includes(
				getEventRecordIdentifier(newDepositRecord)
			)
		) {
			setState((s) => {
				s.accounts[
					matchingUserKey
				].depositWithdrawalHistory.depositsWithdrawals = [
					formattedDepositRecord,
					...depositRecords,
				].sort((a, b) => b.ts.toNumber() - a.ts.toNumber());
				s.accounts[matchingUserKey].depositWithdrawalHistory.totalCount++;
			});

			appEventEmitter.emit('newDepositRecord');
		}
	};

	const appendSettlePnlRecord = (newSettlePnlRecord: SettlePnlRecordEvent) => {
		const matchingUserKey = findAccountToAppendTo(newSettlePnlRecord.user);

		if (matchingUserKey === undefined) {
			return;
		}

		const formattedSettlePnlRecord =
			Serializer.Deserialize.UISettlePnl(newSettlePnlRecord);

		const settlePnlRecords =
			getState().accounts[matchingUserKey]?.settlePnlHistory.settlePnls;

		const settlePnlRecordsTxSignatures = settlePnlRecords.map((p) =>
			getEventRecordIdentifier(p)
		);

		if (
			!settlePnlRecordsTxSignatures.includes(
				getEventRecordIdentifier(formattedSettlePnlRecord)
			)
		) {
			setState((s) => {
				s.accounts[matchingUserKey].settlePnlHistory.settlePnls = [
					formattedSettlePnlRecord,
					...settlePnlRecords,
				].sort((a, b) => b.ts.toNumber() - a.ts.toNumber());
				s.accounts[matchingUserKey].settlePnlHistory.totalCount++;
			});
		}
	};

	const appendTradeRecord = (
		newOrderActionRecord: Event<OrderActionRecord>
	) => {
		// If the action wasn't a fill, doesn't affect trade history
		if (!matchEnum(newOrderActionRecord.action, OrderAction.FILL)) {
			return;
		}

		// # Handling if it's a trade for the current user

		const matchingTaker = findAccountToAppendTo(newOrderActionRecord.taker);
		const matchingMaker = findAccountToAppendTo(newOrderActionRecord.maker);

		// Adjust the timestamp on the live trades if it does not match the current time .. the difference would be from drift which the blockchain allows but which will break tradingview + other UI assumptions. It's safe to assume that any events coming in through the listener are "live events" because that's what the event listener is supposed to do.

		const matchingUserKey =
			matchingTaker !== undefined
				? matchingTaker
				: matchingMaker !== undefined
				? matchingMaker
				: undefined;

		const serializedOrderActionRecord =
			Serializer.Serialize.OrderActionRecord(newOrderActionRecord);
		const formattedOrderActionRecord =
			Serializer.Deserialize.UIOrderActionRecord(serializedOrderActionRecord);

		// If the action is for the currentuser then try match it with its order information
		if (matchingTaker !== undefined) {
			orderMatcher.addActionRecord(formattedOrderActionRecord);
		}

		if (matchingUserKey !== undefined) {
			const historyKey = ENUM_UTILS.match(
				MarketType.SPOT,
				formattedOrderActionRecord.marketType
			)
				? 'tradeHistory'
				: UIMarket.checkIsPredictionMarket(
						OrderedPerpMarkets[formattedOrderActionRecord.marketIndex]
				  )
				? 'predictionsTradeHistory'
				: 'tradeHistory';

			// Add trade record to user trade history
			const userTrades = getState().accounts[matchingUserKey][historyKey];

			const tradeAlreadyAdded = userTrades.loadedUserTradeHistory.find(
				(tradeInStore) =>
					orderMatcher.ordersAreIdentical(
						tradeInStore,
						formattedOrderActionRecord
					)
			);

			if (!tradeAlreadyAdded) {
				setState((s) => {
					s.accounts[matchingUserKey][historyKey].loadedUserTradeHistory =
						sortUIOrderActionRecords([
							...userTrades.loadedUserTradeHistory,
							formattedOrderActionRecord,
						]) as UISerializableOrderActionRecord[];
					s.accounts[matchingUserKey][historyKey].userTradeHistoryTotalCount++;
				});
			}
		}

		// # Handling for global trades, adding to recent trades list etc.

		const selectedUiMarket = getDriftState().selectedMarket.current;

		const tradeMatchesSelectedMarket =
			selectedUiMarket.market.marketIndex ===
				formattedOrderActionRecord.marketIndex &&
			ENUM_UTILS.match(
				selectedUiMarket.marketType,
				formattedOrderActionRecord.marketType
			);

		if (!tradeMatchesSelectedMarket) {
			return;
		}

		// Add trade record to market trade history
		const marketTrades = getDriftState().marketTradeHistory?.trades;

		const mostRecentSeenTrade = marketTrades?.[0];

		const tradeIsNew = !mostRecentSeenTrade
			? true
			: getSortScoreForOrderActionRecords(
					newOrderActionRecord,
					mostRecentSeenTrade
			  ) === 1;

		if (!tradeIsNew) {
			// Can skip further processing if trade isn't new
			return;
		}

		// Trim the market trades we check for duplicates against because the duplicate check is expensive
		const filteredMarketTrades = marketTrades.filter(
			(trade) => trade.slot >= newOrderActionRecord.slot
		);

		const tradeIsDuplicate = filteredMarketTrades.find((tradeInStore) =>
			orderMatcher.ordersAreIdentical(tradeInStore, formattedOrderActionRecord)
		);

		if (tradeIsDuplicate) {
			return;
		}

		setDriftState((s) => {
			// Skip adding this record if it doesn't match the currently selected market at the time of adding it to the store
			if (
				s.selectedMarket.current.market.marketIndex !==
					formattedOrderActionRecord.marketIndex ||
				!ENUM_UTILS.match(
					s.selectedMarket.current.marketType,
					formattedOrderActionRecord.marketType
				)
			) {
				return;
			}

			let newTradesList = [formattedOrderActionRecord, ...marketTrades];

			// re-sort/dedupe/trim every x trades to stop memory leaks and make sure list is clean
			if (newTradesList.length % TRADE_DEDUPE_MARKER === 0) {
				newTradesList = orderMatcher.getSortedDedupedTrimmedRecords(
					newTradesList,
					MAX_RECENT_TRADES
				);
			}

			s.marketTradeHistory.trades = newTradesList;
		});
	};

	const appendOrderRecord = (newOrderRecord: OrderRecordEvent) => {
		const matchingUserKey = findAccountToAppendTo(newOrderRecord.user);

		// If the order record is not for the current user, then we don't care about it
		if (matchingUserKey === undefined) {
			return;
		}

		const formattedEvent = Serializer.Deserialize.UIOrderRecord(newOrderRecord);

		orderMatcher.addOrderRecord(formattedEvent);

		const userOrders = getState().accounts[matchingUserKey].orderHistory;
		const lastSeenOrder = userOrders.loadedUserOrderHistory[0];

		if (
			!lastSeenOrder ||
			getSortScoreForOrderRecords(newOrderRecord, lastSeenOrder.orderRecord) !==
				-1
		) {
			setState((s) => {
				s.accounts[matchingUserKey].orderHistory.marketOrderCounts[
					ENUM_UTILS.toStr(newOrderRecord.order.marketType)
				]++;
			});
		}
	};

	const appendSwapRecord = (newSwapRecord: SwapRecordEvent) => {
		const matchingUserKey = findAccountToAppendTo(newSwapRecord.user);

		// If the swap record is not for the current user, then we don't care about it
		if (matchingUserKey === undefined) {
			return;
		}

		const formattedEvent = Serializer.Deserialize.UISwapRecord(newSwapRecord);

		const userSwaps = getState().accounts[matchingUserKey].swapHistory;
		const lastSeenSwap = userSwaps.swaps[0];

		if (!lastSeenSwap || lastSeenSwap.slot < formattedEvent.slot) {
			const toastId = UI_UTILS.getSwapToastId(
				formattedEvent.inMarketIndex,
				formattedEvent.outMarketIndex
			);

			// Its good to confirm a swap thru the event subscriber, rather than optimistically
			// notifying the user of a successful swap after sending the swap tx to the blockchain,
			// since Jupiter swap has a higher failure rate after sending the tx than Drift txs
			notify({
				type: 'success',
				message: `Swap Successful`,
				description: `Successfully swapped ${formattedEvent.amountIn.toNum()} ${
					OrderedSpotMarkets[formattedEvent.inMarketIndex].symbol
				} to ${formattedEvent.amountOut.toNum()} ${
					OrderedSpotMarkets[formattedEvent.outMarketIndex].symbol
				}`,
				updatePrevious: true,
				id: toastId,
				lengthMs: 10_000,
				subDescription: undefined,
				blockSubsequentToasts: true,
				action: undefined,
			});

			appEventEmitter.emit('successfulSwap', newSwapRecord);

			setState((s) => {
				s.accounts[matchingUserKey].swapHistory.swaps = [
					formattedEvent,
					...userSwaps.swaps,
				];
				s.accounts[matchingUserKey].swapHistory.totalCount++;
			});
		}
	};

	const parseUserEvents = (event: WrappedEvent<EventType>) => {
		try {
			if (!eventFilter(event)) return;

			switch (event.eventType) {
				case 'DepositRecord':
					appendDepositRecord(event as DepositRecordEvent);
					break;
				case 'FundingPaymentRecord':
					appendFundingPaymentRecord(event as FundingPaymentRecordEvent);
					break;
				case 'LiquidationRecord':
					appendLiquidationRecord(event as LiquidationRecordEvent);
					break;
				case 'OrderRecord':
					appendOrderRecord(event as OrderRecordEvent);
					break;
				case 'SettlePnlRecord':
					appendSettlePnlRecord(event as SettlePnlRecordEvent);
					break;
				case 'SwapRecord':
					appendSwapRecord(event as Event<SwapRecordEvent>);
					break;
				case 'OrderActionRecord':
				// Can ignore these .. fills get handled by the global order action event handler, other types of order action records get handled in other places
			}
		} catch (e) {
			console.error('Error in Drift event listener: ', e);
			return;
		}
	};

	const parseGlobalFillEvents = (event: WrappedEvent<EventType>) => {
		try {
			const isActionRecord = event.eventType === 'OrderActionRecord';
			const isFillRecord =
				isActionRecord &&
				ENUM_UTILS.match((event as OrderActionRecord).action, OrderAction.FILL);

			if (!isFillRecord) {
				throw new Error(
					'Global fill event handler should only receive fill records'
				);
			}

			switch (event.eventType) {
				case 'OrderActionRecord':
					appendTradeRecord(event as Event<OrderActionRecord>);
					break;
			}
		} catch (e) {
			console.error('Error in Drift event listener: ', e);
			return;
		}
	};

	const eventFilter = (event: WrappedEvent<EventType>) =>
		EVENTS_TO_LISTEN_TO.includes(event.eventType as string);

	useEffect(() => {
		if (!eventSubjectContext.userEventObservable) return;
		if (!eventSubjectContext.globalFillEventObservable) return;

		const userEventSubscription =
			eventSubjectContext.userEventObservable.subscribe(parseUserEvents);

		const globalFillEventSubscription =
			eventSubjectContext.globalFillEventObservable.subscribe(
				parseGlobalFillEvents
			);

		return () => {
			userEventSubscription.unsubscribe();
			globalFillEventSubscription.unsubscribe();
		};
	}, [eventSubjectContext]);

	return;
};

export default useDriftMarketEventListener;
