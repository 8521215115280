import React from 'react';
import useUtilizationIsTooHighForIFUnstake from '../../hooks/useUtilizationIsTooHighForIFUnstake';
import InfoMessage from '../TradeForm/InfoMessage';
import Text from '../Text/Text';
import Utility from '../Inputs/Utility';

const InsuranceFundUnstakeUtilizationCutoffMessage = (
	props: ReturnType<typeof useUtilizationIsTooHighForIFUnstake> & {
		type: 'IFPage' | 'UnstakeModal';
	}
) => {
	const semanticMessage =
		props.type === 'IFPage'
			? 'You will need to wait until it drops to a safer level to withdraw your stake.'
			: `You will still be able to begin the unstake process from this modal, but you won't be able to do the final withdrawal until it drops to a safer level.`;

	return (
		<InfoMessage
			type={'warn'}
			message={
				<div className="flex flex-col">
					<Text.BODY1>
						{`Unstaking from the Insurance Fund is disabled while borrow utilization is high, for protocol safety. ${semanticMessage}`}
					</Text.BODY1>
					<Utility.VERTSPACERL />
					<div className="flex space-x-4">
						<div className="flex flex-col">
							<Text.BODY1>{`Current Utilization`}</Text.BODY1>
							<Text.BODY1>{`Cutoff Utilization`}</Text.BODY1>
						</div>
						<div className="flex flex-col">
							<Text.BODY1>{`${props.currentUtilizationPercent}%`}</Text.BODY1>
							<Text.BODY1>{`${props.unstakingCutoffPct}%`}</Text.BODY1>
						</div>
					</div>
					<Utility.VERTSPACERS />
				</div>
			}
			linkDescription="Learn more about Insurance Fund Staking"
			link="https://docs.drift.trade/insurance-fund/insurance-fund-intro"
		/>
	);
};

export default InsuranceFundUnstakeUtilizationCutoffMessage;
