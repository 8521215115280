'use client';

import useGoToRoute from 'src/hooks/useGoToRoute';
import { PropsWithChildren } from 'react';
import Link from 'next/link';

function ActiveLink({
	children,
	href,
	className,
	skipHoverOpacity,
	onClick,
	preventDefaultRouting,
}: PropsWithChildren<{
	href: string;
	className?: string;
	skipHoverOpacity?: boolean;
	onClick?: () => void;
	/**
	 * If true, clicking will NOT go to the route and you must pass in a click handler that navigates to the route
	 */
	preventDefaultRouting?: boolean;
}>) {
	const goToRoute = useGoToRoute();

	const handleClick = (e) => {
		e.preventDefault();

		if (onClick) {
			onClick();
		}

		if (preventDefaultRouting) {
			return;
		} else {
			goToRoute(href);
		}
	};

	return (
		<Link
			href={href}
			passHref
			onClick={handleClick}
			className={`${className} ${skipHoverOpacity ?? 'skipHoverOpacity'}`}
		>
			{children}
		</Link>
	);
}

export default ActiveLink;
