import {
	OptionalOrderParams,
	OrderType,
	PositionDirection,
	ZERO,
} from '@drift-labs/sdk';
import { ENUM_UTILS } from '@drift/common';

/**
 * Should match : @drift-labs/sdk > src > validation > order.rs > validate_auction_params
 * @param order
 * @returns
 */
export function validateMarketOrderParams(order: OptionalOrderParams): boolean {
	if (
		order.auctionStartPrice.eq(ZERO) &&
		!ENUM_UTILS.match(order.orderType, OrderType.ORACLE) // Start price is allowed to be zero for oracle orders
	) {
		return false;
	}

	if (order.auctionEndPrice.eq(ZERO)) {
		return false;
	}

	switch (ENUM_UTILS.toStr(order.direction)) {
		case ENUM_UTILS.toStr(PositionDirection.LONG):
			if (order.auctionStartPrice.gt(order.auctionEndPrice)) {
				console.log(
					`LONG Auction start price (${order.auctionStartPrice}) was greater than auction end price (${order.auctionEndPrice})`
				);
				return false;
			}

			if (!order.price?.eq(ZERO) && order.price?.lt(order.auctionEndPrice)) {
				console.log(
					`LONG Order price (${order.price}) was less than auction end price (${order.auctionEndPrice})`
				);
				return false;
			}
			break;

		case ENUM_UTILS.toStr(PositionDirection.SHORT):
			if (order.auctionStartPrice.lt(order.auctionEndPrice)) {
				console.log(
					`SHORT Auction start price (${order.auctionStartPrice}) was less than auction end price (${order.auctionEndPrice})`
				);
				return false;
			}

			if (!order.price?.eq(ZERO) && order.price?.gt(order.auctionEndPrice)) {
				console.log(
					`SHORT Order price (${order.price}) was greater than auction end price (${order.auctionEndPrice})`
				);
				return false;
			}
			break;
	}

	return true;
}
