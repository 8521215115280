'use client';

import { ReactNode, useEffect, useState } from 'react';
import { dlog } from '../../dev';
import useDriftActions from '../../hooks/useDriftActions';
import Button from '../Button';
import Utility from '../Inputs/Utility';
import Text from '../Text/Text';
import Link from '../Utils/Link';
import SvgBgDiv from '../Utils/SvgBgDiv';
import { checkIfPreLaunchMarketWarningRequired } from './PreLaunchMarketAlertUtils';
import { PRE_MARKET_ACKNOWLEDGED_WARNING_KEY } from './PreLaunchMarketConstants';
import Modal from '../Modals/Modal';

const PreLaunchMarketWarningAlertBase = (props: {
	bannerContent: ReactNode;
	innerContent: ReactNode;
}) => {
	return (
		<Modal onClose={() => {}}>
			<Modal.Body className="flex flex-col">
				<SvgBgDiv
					svgPath="/assets/images/prelaunch/warning-alert-bg.svg"
					className="text-text-emphasis h-[194px] flex flex-col justify-center items-center"
				>
					{props.bannerContent}
				</SvgBgDiv>
				{props.innerContent}
			</Modal.Body>
		</Modal>
	);
};

const PreLaunchMarketTermsAlert = (props: { onContinue: () => void }) => {
	return (
		<PreLaunchMarketWarningAlertBase
			bannerContent={
				<div className="flex flex-col items-center space-x-1">
					<Text.BODY3>{`Introducing`}</Text.BODY3>
					<Text.XL>{`Pre-Launch Markets`}</Text.XL>
				</div>
			}
			innerContent={
				<div className="flex flex-col p-6 pb-4 text-text-emphasis">
					<Text.H3 className="">
						Pre-Launch Markets are for Traders interested in speculating on the
						listing price of a token that has not been launched.
					</Text.H3>
					<Utility.VERTSPACERL />
					<Text.H3 className="">Learn how Prelaunch markets work here:</Text.H3>
					<Utility.VERTSPACERL />
					<Link
						withChevron
						href={`https://docs.drift.trade/trading/prelaunch-markets`}
					>
						<Text.H3>{`About Pre-Launch Markets`}</Text.H3>
					</Link>
					<Utility.VERTSPACERXL />
					<Button.BigSemantic
						neutral
						onClick={() => {
							localStorage.setItem(PRE_MARKET_ACKNOWLEDGED_WARNING_KEY, 'true');
							props.onContinue();
						}}
					>
						I understand
					</Button.BigSemantic>
				</div>
			}
		/>
	);
};

export const PreLaunchMarketWarningAlert = () => {
	const [alertToShow, setAlertToShow] = useState(
		checkIfPreLaunchMarketWarningRequired()
	);
	const actions = useDriftActions();

	useEffect(() => {
		if (!alertToShow) {
			dlog('pre_markets', 'alertToShow effect hook hiding alert');
			actions.showModal('showPreLaunchMarketWarningAlert', false);
		}

		dlog('pre_markets', `alertToShow effect hook : ${alertToShow}`);
	}, [alertToShow]);

	const handleContinue = () => {
		setAlertToShow(checkIfPreLaunchMarketWarningRequired());
	};

	if (alertToShow === 'acknowledge_terms') {
		return <PreLaunchMarketTermsAlert onContinue={handleContinue} />;
	}

	return null;
};
