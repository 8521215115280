'use client';

import { useTheme } from 'next-themes';
import {
	DriftTheme,
	UserSettings,
} from '../../environmentVariables/EnvironmentVariables';
import SettingsSwitch from './SettingsSwitch';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';

const INTERFACE_SETTINGS: {
	label: string;
	key: keyof UserSettings;
}[] = [
	{
		label: 'Show account balances by default',
		key: 'showAccountValues',
	},
	{
		label: 'Show P&L on close',
		key: 'showPnlOnClose',
	},
	{
		label: 'Include leverage in P&L calculation',
		key: 'includeLeverageInPnl',
	},
	{
		label: 'Show order confirmation',
		key: 'showTradeConfirmation',
	},
	{
		label: 'Show fill notifications',
		key: 'showFillNotifications',
	},
	{
		label: 'Blink on value change',
		key: 'blinkOnValueChange',
	},
	{
		label: 'Show positions and orders on chart',
		key: 'showPositionsAndOrdersOnChart',
	},
	{
		label: 'Load saved chart colors',
		key: 'loadSavedChartColors',
	},
];

export interface InterfaceSettingsProps {
	localSettings: any;
	toggleSetting: (key) => void;
}

const InterfaceSettings = ({
	localSettings,
	toggleSetting,
}: InterfaceSettingsProps) => {
	const { theme: currentTheme, setTheme } = useTheme();
	const isLiteMode = useIsLiteMode();
	const { captureEvent } = usePostHogCapture();

	return (
		<>
			{!isLiteMode && (
				<SettingsSwitch
					key={'showMarketBar'}
					label={'Show Markets Bar'}
					checked={localSettings['showMarketBar']}
					onChange={() => {
						captureEvent('changed_show_markets_bar_setting', {
							enabled: localSettings['showMarketBar'],
						});

						toggleSetting('showMarketBar');
					}}
				/>
			)}
			{INTERFACE_SETTINGS.map(({ label, key }) => (
				<SettingsSwitch
					key={label}
					label={label}
					checked={localSettings[key]}
					onChange={() => {
						toggleSetting(key);
					}}
				/>
			))}
			<div className="mt-[-6px]">
				<SettingsSwitch
					label={'Dark mode'}
					checked={currentTheme === DriftTheme.dark}
					onChange={() => {
						if (currentTheme === DriftTheme.dark) {
							setTheme(DriftTheme.light);
						} else {
							setTheme(DriftTheme.dark);
						}
					}}
				/>
			</div>
		</>
	);
};

export default InterfaceSettings;
