'use client';

import Drawer from 'src/components/Drawer';
import MarketIcon from 'src/components/Utils/MarketIcon';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import Text from '../../Text/Text';
import { Open } from '@drift-labs/icons';
import Utility from 'src/components/Inputs/Utility';
import UI_UTILS from 'src/utils/uiUtils';
import { useEffect, useState } from 'react';
import { format } from 'timeago.js';
import Link from 'next/link';
import {
	BASE_NAVIGATION_OPTIONS,
	RouteNavigationResult,
} from 'src/components/Navigation';
import { IfVaultPerformanceGraph } from './IFVaultPerformanceGraph';
import {
	DEFAULT_PERFORMANCE_DATA,
	PERFORMANCE_TIMEFRAMES,
	PerformanceDataSet,
	PnLTimeSeriesDataPoint,
	PnlTimePeriodOption,
} from 'src/@types/types';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import ColourCodedValue from 'src/components/Utils/ColourCodedValue';
import { ButtonGroup } from 'src/components/Button';

const Section = ({
	sectionLabel,
	topRightNode,
	children,
}: {
	sectionLabel: string;
	topRightNode?: React.ReactNode;
	children: React.ReactNode;
}) => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between w-full">
				<Text.H5 className="text-text-default">{sectionLabel}</Text.H5>
				{!!topRightNode && (
					<Text.BODY2 className="text-text-label">{topRightNode}</Text.BODY2>
				)}
			</div>
			<div className="flex flex-col gap-2">{children}</div>
		</div>
	);
};

const DetailRow = ({
	label,
	value,
	marketSymbol,
}: {
	label: React.ReactNode;
	value: React.ReactNode;
	marketSymbol?: string;
}) => (
	<div className="flex justify-between w-full">
		<Text.BODY3 className="text-text-label">{label}</Text.BODY3>
		<div className="flex items-center gap-1">
			{marketSymbol && (
				<MarketIcon marketSymbol={marketSymbol} sizeClass="w-4 h-4" />
			)}
			<Text.BODY3 className="text-text-default">{value}</Text.BODY3>
		</div>
	</div>
);

const useIFVaultPerformanceData = (marketIndex: number) => {
	const [allPerformanceData, setAllPerformanceData] =
		useState<PerformanceDataSet>(DEFAULT_PERFORMANCE_DATA);
	const [chartData, setChartData] = useState<PnLTimeSeriesDataPoint[]>([]);
	const [pctDiff, setPctDiff] = useState(0);
	const [selectedTimeFrame, setSelectedTimeFrame] = useState(
		PERFORMANCE_TIMEFRAMES[2]
	);

	useEffect(() => {
		fetchAndSetIFRecords();
	}, [marketIndex]);

	useEffect(() => {
		if (
			allPerformanceData[selectedTimeFrame] &&
			allPerformanceData[selectedTimeFrame].length > 0
		) {
			setChartData(allPerformanceData[selectedTimeFrame]);

			const diff =
				allPerformanceData[selectedTimeFrame][
					allPerformanceData[selectedTimeFrame].length - 1
				].totalPnl - allPerformanceData[selectedTimeFrame][0].totalPnl;
			setPctDiff(diff / allPerformanceData[selectedTimeFrame][0].totalPnl);
		}
	}, [allPerformanceData, selectedTimeFrame]);

	async function fetchAndSetIFRecords() {
		const result = await ExchangeHistoryClient.getInsuranceFundRecords(
			marketIndex
		);

		if (result.success) {
			const allDataPoints = result?.body.data.insuranceFundRecords
				.filter((record) => !record.totalIfSharesAfter.eqZero())
				.map((record) => {
					return {
						date: new Date(record.ts.toNumber() * 1000),
						totalPnl: record.insuranceVaultAmountBefore
							.add(record.amount)
							// shares can be extremely small
							.scale(1000000, record.totalIfSharesAfter.toNum() * 1000000)
							.toNum(),
					};
				});
			const points90d = allDataPoints;
			const points30d = UI_UTILS.applyTimeFilter(allDataPoints, '30d');
			const points7d = UI_UTILS.applyTimeFilter(allDataPoints, '7d');
			const points24h = UI_UTILS.applyTimeFilter(allDataPoints, '24h');
			setAllPerformanceData({
				'24h': points24h,
				'7d': points7d,
				'30d': points30d,
				all: points90d,
			});
		} else {
			console.error('Failed to fetch IF records', result);
		}
	}

	return { selectedTimeFrame, setSelectedTimeFrame, chartData, pctDiff };
};

export const InsuranceFundVaultDrawer = () => {
	const ifData = useDrawersStore((s) => s.insuranceFundVault.ifData);
	const handleCloseHistoryPanel = useDrawersStore((s) => s.handleCloseDrawer);

	const spotMarketConfig = OrderedSpotMarkets[ifData.marketIndex];
	const marketSymbol = spotMarketConfig.symbol;
	const decimals = spotMarketConfig.precisionExp.toNumber();

	// Vault Details
	const currentApr = ifData.vault.nextApr;
	const vaultBalance = ifData.vault.totalStakeBigNum;
	const nextPayoutSeconds = ifData.vault.secondsUntilNextRevenueSettle;
	const [nextPayoutFormatted, setNextPayoutTs] = useState(
		format(new Date().valueOf() + nextPayoutSeconds * 1000)
	);
	const nextVaultPayout = ifData.vault.nextPayoutTotal;

	// ensures that when the timer reaches 0 seconds, the next payout time is updated instead of showing the past time
	useEffect(() => {
		const interval = setInterval(() => {
			setNextPayoutTs(format(new Date().valueOf() + nextPayoutSeconds * 1000));
		}, 1000);

		return () => clearInterval(interval);
	}, [nextPayoutSeconds]);

	// User Details
	const userStake = ifData.userStake.currentStakeBigNum;
	const userSharePct =
		((ifData.userStake.currentStake ?? 0) / (ifData.vault.totalStake ?? 1)) *
		100;
	const userSharePctString =
		userSharePct < 0.01 ? '<0.01' : userSharePct.toFixed(2);
	const userCumulativeEarnings =
		ifData.userStake.currentStake - ifData.userStake.costBasis;
	const userNextPayout = nextVaultPayout * (userSharePct / 100);

	// Vault Performance Graph
	const { selectedTimeFrame, setSelectedTimeFrame, chartData, pctDiff } =
		useIFVaultPerformanceData(ifData.marketIndex);
	const isHourly = selectedTimeFrame === '24h';

	// Vault Revenue Details
	const stakersAprCap = ifData.vault.ratioForStakers * ifData.vault.maxApr;
	const protocolAprCap =
		(1 - ifData.vault.ratioForStakers) * ifData.vault.maxApr;
	const revenueSettlePeriod = ifData.vault.revenueSettlePeriodMinutes;
	const currentRevenuePool = ifData.vault.revenuePoolBigNum;
	const payoutPerSettlePct = ifData.vault.payoutRatio * 100;
	const stakersPayoutSharePct = ifData.vault.ratioForStakers * 100;
	const protocolPayoutSharePct = (1 - ifData.vault.ratioForStakers) * 100;
	const lastPayoutFormatted = format(ifData.vault.lastRevenueSettleMs);
	const stakersNextPayout =
		ifData.vault.nextPayoutTotal * ifData.vault.ratioForStakers;
	const protocolNextPayout =
		ifData.vault.nextPayoutTotal * (1 - ifData.vault.ratioForStakers);

	const onClose = () => {
		handleCloseHistoryPanel('insuranceFundVault');
	};

	return (
		<Drawer
			drawerName="insuranceFundVault"
			className="w-full sm:max-w-[400px] sm:w-[400px] thin-scroll overflow-y-auto"
		>
			<Drawer.Header
				titleContent={
					<div className="flex items-center gap-1">
						<MarketIcon marketSymbol={spotMarketConfig.symbol} />
						<span>{spotMarketConfig.symbol} Vault</span>
					</div>
				}
				className="p-4"
				onClose={onClose}
			/>

			<div className="flex flex-col gap-4 p-4">
				{/** About */}
				<Section
					sectionLabel="About"
					topRightNode={
						<Link
							className="flex items-center gap-1 text-text-label"
							href={UI_UTILS.getUrlForAccount(
								ifData.vault.account.vault.toBase58()
							)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<span>View on Explorer</span>
							<Open />
						</Link>
					}
				>
					<DetailRow
						label="Current APR"
						value={
							<span className="text-positive-green">
								{currentApr.toFixed(2)}%
							</span>
						}
					/>
					<DetailRow
						label="Vault balance"
						value={vaultBalance.prettyPrint()}
						marketSymbol={marketSymbol}
					/>
					<DetailRow
						label={`Next payout ${nextPayoutFormatted}`}
						value={nextVaultPayout.toFixed(decimals)}
						marketSymbol={marketSymbol}
					/>
				</Section>

				<Utility.VERTDIVIDER />

				{/** Your Info */}
				<Section
					sectionLabel="Your Info"
					topRightNode={
						<Link
							className="flex items-center gap-1 text-text-label"
							href={
								(
									BASE_NAVIGATION_OPTIONS.insuranceFundStakingHistory.handler() as RouteNavigationResult
								).route
							}
						>
							<span>Staking History</span>
							<Open />
						</Link>
					}
				>
					<DetailRow
						label="Current stake"
						value={userStake.prettyPrint()}
						marketSymbol={marketSymbol}
					/>
					<DetailRow label="Vault share" value={`${userSharePctString}%`} />
					<DetailRow
						label={`Your next payout ${nextPayoutFormatted}`}
						value={userNextPayout.toFixed(decimals)}
						marketSymbol={marketSymbol}
					/>
					<DetailRow
						label="Cumulative earnings"
						value={userCumulativeEarnings.toFixed(decimals)}
						marketSymbol={marketSymbol}
					/>
				</Section>

				<Utility.VERTDIVIDER />

				{/** Vault Performance */}
				<Section
					sectionLabel="Vault Performance (by Share Price)"
					topRightNode={
						<ColourCodedValue value={pctDiff}>
							{`${pctDiff >= 0 ? '+' : ''}${parseFloat(
								(pctDiff * 100).toFixed(2)
							)}%`}
						</ColourCodedValue>
					}
				>
					<IfVaultPerformanceGraph chartData={chartData} isHourly={isHourly} />
					<div>
						<ButtonGroup.Segmented
							options={PERFORMANCE_TIMEFRAMES.map((label) => {
								return {
									label: label === 'all' ? '90d' : label,
									value: label,
								};
							})}
							selected={selectedTimeFrame}
							size="MEDIUM"
							selectAction={(val) =>
								setSelectedTimeFrame(val as PnlTimePeriodOption)
							}
							className="w-full"
							optionClassName="flex-1"
						/>
					</div>
				</Section>

				<Utility.VERTDIVIDER />

				{/** Vault Revenue Details */}
				<Section sectionLabel="Vault Revenue Details">
					<DetailRow
						label="APR cap"
						value={`${stakersAprCap}% (stakers) / ${protocolAprCap}% (protocol)`}
					/>
					<DetailRow
						label="Revenue settle period"
						value={`${revenueSettlePeriod} minutes`}
					/>

					<Utility.VERTDIVIDER />

					<DetailRow
						label="Current revenue pool"
						value={currentRevenuePool.prettyPrint()}
						marketSymbol={marketSymbol}
					/>
					<DetailRow
						label="Payout per settle"
						value={`${payoutPerSettlePct}%`}
					/>
					<DetailRow
						label="Share of payout"
						value={`${stakersPayoutSharePct}% (stakers) / ${protocolPayoutSharePct}% (protocol)`}
					/>
					<DetailRow
						label="Next payout"
						value={
							<div className="flex flex-col items-end gap-1">
								<div className="flex items-center gap-1">
									<MarketIcon marketSymbol={marketSymbol} sizeClass="h-4 w-4" />
									<span>{stakersNextPayout.toFixed(decimals)} (stakers)</span>
								</div>
								<div className="flex items-center gap-1">
									<MarketIcon marketSymbol={marketSymbol} sizeClass="h-4 w-4" />
									<span>{protocolNextPayout.toFixed(decimals)} (protocol)</span>
								</div>
							</div>
						}
					/>
					<DetailRow label="Next revenue settle" value={nextPayoutFormatted} />
					<DetailRow label="Last revenue settle" value={lastPayoutFormatted} />
				</Section>
			</div>
		</Drawer>
	);
};
