'use client';

import { BigNum, BN, MarketType, SpotMarketConfig } from '@drift-labs/sdk';
import { COMMON_UI_UTILS, MarketId } from '@drift/common';
import { Open } from '@drift-labs/icons';
import React, { useEffect, useMemo, useState } from 'react';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useDisplayPrecisionForCollateral from 'src/hooks/useDisplayPrecisionForCollateral';
import useDriftActions from 'src/hooks/useDriftActions';
import { IfData } from 'src/utils/insuranceFund';
import useUtilizationIsTooHighForIFUnstake from '../hooks/useUtilizationIsTooHighForIFUnstake';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useIfDataStore from '../stores/ifDataStore/useIfDataStore';
import UI_UTILS from '../utils/uiUtils';
import Button from './Button';
import UnstakeUtilizationCutoffMessage from './Earn/InsuranceFundUnstakeUtilizationCutoffMessage';
import { CollateralInput } from './Inputs/CollateralInput';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import Text from './Text/Text';
import InfoMessage from './TradeForm/InfoMessage';
import ValueDisplay from './ValueDisplay';
import Env, {
	DRIFT_TOKEN_SPOT_MARKET,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { Arrow } from './ValueChangeDisplay';

/** Show the user stake, total shares, and user shares at full precision */
const debug = false;

const UnstakeModalContent = ({ onClose }: { onClose: () => void }) => {
	// # Constants
	const marketFromStore = useDriftStore((s) => s.selectedIfStakeMarket);
	const actions = useDriftActions();
	const setState = useDriftStore((s) => s.set);
	const { captureEvent } = usePostHogCapture();
	const ifStakeData = useIfDataStore((s) => s.IFData);
	const isDriftStaking = useDriftStore(
		(s) => s.modalsProps.showStakeModal?.isDriftStaking
	);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	// # State
	const [submitting, setSubmitting] = useState(false);
	const [maxButtonTransition, setMaxButtonTransition] = useState(false);
	const [selectedIfMarket, setSelectedIfMarket] =
		useState<SpotMarketConfig>(marketFromStore);
	const [unstakeAmount, setUnstakeAmount] = useState('');
	const [isMax, setIsMax] = useState(false);
	const selectedMarketId: MarketId = useMemo(
		() => new MarketId(selectedIfMarket.marketIndex, MarketType.SPOT),
		[selectedIfMarket.marketIndex]
	);

	const unstakeUtilizationCutoffResult =
		useUtilizationIsTooHighForIFUnstake(selectedMarketId);

	const displayPrecision = useDisplayPrecisionForCollateral(selectedIfMarket);
	const excludedMarkets = Object.values(ifStakeData)
		.filter((ifData) =>
			isDriftStaking
				? ifData.marketIndex !== DRIFT_TOKEN_SPOT_MARKET.marketIndex
				: ifData.pausedOperations.includes(
						COMMON_UI_UTILS.InsuranceFundOperationsMap.INIT
				  )
		)
		.map((vault) => vault.marketIndex);

	const currentIfStakeData = ifStakeData[
		selectedIfMarket.marketIndex
	] as IfData;

	const unstakePeriod =
		currentIfStakeData?.vault?.account?.unstakingPeriod?.toNumber() * 1000;

	const unstakingPeriodAsDays = Math.floor(unstakePeriod / 1000 / 60 / 60 / 24);

	// # State-Dependent constants
	const hasExistingUnstakeRequest =
		!!currentIfStakeData?.userStake?.unstakeRequest?.amount;
	const existingUnstakeRequestAmount =
		currentIfStakeData?.userStake?.unstakeRequest?.amount;
	const existingUnstakeRequestTimestamp = new Date(
		currentIfStakeData?.userStake?.unstakeRequest?.timestamp + unstakePeriod
	);
	const canWithdraw =
		hasExistingUnstakeRequest &&
		Date.now() >=
			currentIfStakeData?.userStake?.unstakeRequest?.timestamp + unstakePeriod;

	const userStakeLoaded = currentIfStakeData.userStake?.loaded;
	const userStakeExists =
		userStakeLoaded && currentIfStakeData?.userStake?.account;
	const unstakeAmountBignum = BigNum.fromPrint(
		unstakeAmount ? unstakeAmount : '0',
		selectedIfMarket.precisionExp
	);
	const exceededMax = unstakeAmountBignum.gt(
		currentIfStakeData?.userStake?.currentStakeBigNum
	);
	const allowConfirmation =
		!submitting &&
		userStakeExists &&
		unstakeAmount &&
		unstakeAmountBignum.gtZero() &&
		!hasExistingUnstakeRequest &&
		!exceededMax;

	const requestWithdrawPaused = currentIfStakeData.pausedOperations.includes(
		COMMON_UI_UTILS.InsuranceFundOperationsMap.REQUEST_REMOVE
	);
	const withdrawPaused = currentIfStakeData.pausedOperations.includes(
		COMMON_UI_UTILS.InsuranceFundOperationsMap.REMOVE
	);

	const allowCancelExistingUnstakeRequest =
		!submitting && hasExistingUnstakeRequest;

	// Todo: get this from the vault account instead of hard coding:
	// I just don't know what precision it's in?
	// const unstakingPeriod = currentIfStakeData.vault?.account?.unstakingPeriod;

	// Able to withdraw new unstake request after 2 weeks:
	const newWithdrawalDate = new Date(
		Date.now() +
			currentIfStakeData.vault?.account?.unstakingPeriod?.toNumber() * 1000
	);

	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(selectedIfMarket.marketIndex)) ||
		undefined;

	const fuelEarnRate = BigNum.from(
		new BN(
			(Env.fuelBaseEarnRates?.staking || 1) *
				(spotMarketAccount.fuelBoostInsurance || 0) || 0
		)
	);

	const oldFuelEarnAmount = BigNum.fromPrint(
		`${currentIfStakeData?.userStake?.currentStake || 0}`,
		selectedIfMarket.precisionExp
	).mul(fuelEarnRate);
	const fuelEarnAmount = oldFuelEarnAmount.sub(
		unstakeAmountBignum.mul(fuelEarnRate)
	);

	const updateCollateralType = (newCollat: SpotMarketConfig) => {
		setSelectedIfMarket(newCollat);
		setState((s) => {
			s.modalCollateralType = newCollat.marketIndex;
		});
	};

	// # Effect Hooks
	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setUnstakeAmount('');
	}, [selectedIfMarket?.marketIndex]);

	// # Methods

	/**
	 * Make new unstake request
	 */
	const handleUnstakeRequest = async () => {
		setSubmitting(true);

		const amount = unstakeAmountBignum.val;

		const result = await actions.requestInsuranceFundUnstake({
			displayAmount: unstakeAmount,
			withdrawalDate: newWithdrawalDate.toLocaleDateString(),
			amount,
			marketIndex: selectedIfMarket?.marketIndex,
		});

		setSubmitting(false);

		captureEvent('submitted_unstake_request', {
			spot_market_symbol: selectedIfMarket.symbol,
		});

		if (result === true) {
			onClose();
		}
	};

	/**
	 * Cancel an existing unstake request
	 */
	const handleCancelUnstakeRequest = async () => {
		setSubmitting(true);

		const result = await actions.cancelInsuranceFundUnstakeRequest({
			marketIndex: selectedIfMarket?.marketIndex,
		});

		captureEvent('canceled_unstake_request', {
			spot_market_symbol: selectedIfMarket.symbol,
		});

		setSubmitting(false);

		if (result === true) {
			onClose();
		}
	};

	const handleWithdrawUnstakedAssets = async () => {
		setSubmitting(true);

		const result = await actions.withdrawInsuranceFundUnstakedAssets({
			marketIndex: selectedIfMarket?.marketIndex,
		});

		captureEvent('withdrew_unstaked_assets', {
			spot_market_symbol: selectedIfMarket.symbol,
		});

		setSubmitting(false);

		if (result === true) {
			onClose();
		}
	};

	const handleValueChange = (newValue: string) => {
		setIsMax(false);
		setUnstakeAmount(newValue);
	};

	const handleMax = () => {
		setUnstakeAmount(
			currentIfStakeData?.userStake?.currentStakeBigNum?.print()
		);
		setIsMax(true);
	};

	useEffect(() => {
		if (exceededMax && isMax) {
			setUnstakeAmount(
				currentIfStakeData?.userStake?.currentStakeBigNum?.print()
			);
		}
	}, [exceededMax, isMax]);

	// # Rendering
	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					<div className="items-center block">
						<Text.P1 className="mr-1 text-text-default">
							Funds will be available to withdraw {unstakingPeriodAsDays} days
							after making an unstake request.
						</Text.P1>
					</div>
					<Utility.VERTSPACERM />
					<div className="items-center block">
						<Text.P1>
							You can only have one pending unstake request per vault at a time.
							You can cancel a request at any time, noting your{' '}
							{unstakingPeriodAsDays} day cool down period will restart upon any
							new unstake request.
						</Text.P1>{' '}
						<Text.P1 className="inline-flex items-center text-text-secondary hover:cursor-pointer">
							<a
								href="https://docs.drift.trade/insurance-fund/insurance-fund-staking"
								target="_blank"
								rel="noreferrer"
								className="inline-flex items-center gap-1 text-text-secondary"
							>
								<div>Learn more</div>
								<Open />
							</a>
						</Text.P1>
					</div>

					<Utility.VERTSPACERXL />

					<CollateralInput
						selectedMarket={selectedIfMarket}
						maxAmount={currentIfStakeData?.userStake?.currentStakeBigNum}
						onChangeMarket={updateCollateralType}
						label={
							isDriftStaking
								? 'Amount to unstake'
								: 'Vault and amount to unstake'
						}
						value={unstakeAmount}
						onChangeValue={handleValueChange}
						onMax={handleMax}
						amountLabel={'Your stake'}
						disabled={hasExistingUnstakeRequest}
						excludedMarketIndices={excludedMarkets}
						source={{
							type: 'ifStake',
						}}
					/>

					<Utility.VERTSPACERXL />

					{debug && (
						<>
							<div>
								currentStakeBigNum:{' '}
								{currentIfStakeData?.userStake?.currentStakeBigNum?.toString()}
							</div>
							<div>
								totalShares:
								{currentIfStakeData?.vault?.account?.totalShares?.toString()}
							</div>
							<div>
								currentShares:
								{currentIfStakeData?.userStake?.currentShares?.toString()}
							</div>
						</>
					)}

					{hasExistingUnstakeRequest &&
						(canWithdraw ? (
							<InfoMessage
								type={'success'}
								message={
									<div className="my-1">
										<Text.BODY1>
											{`You have ${existingUnstakeRequestAmount.toFixed(
												displayPrecision
											)} ${
												selectedIfMarket.symbol
											} unstaked and ready to withdraw.`}
										</Text.BODY1>
										<div className="mt-2 ml-1">
											<Button.Secondary
												size="MEDIUM"
												disabled={submitting || withdrawPaused}
												onClick={handleWithdrawUnstakedAssets}
											>
												Withdraw{' '}
												{existingUnstakeRequestAmount.toFixed(displayPrecision)}{' '}
												{selectedIfMarket.symbol}
											</Button.Secondary>
										</div>
									</div>
								}
							/>
						) : (
							<InfoMessage
								type={'warn'}
								message={
									<div className="my-1 ml-1">
										<Text.BODY1>
											{`You have an unstake request in progress.`}
											<br />
											{`${existingUnstakeRequestAmount.toFixed(
												displayPrecision
											)} ${
												selectedIfMarket.symbol
											} will be available to withdraw on ${UI_UTILS.printDate(
												existingUnstakeRequestTimestamp
											)}`}
										</Text.BODY1>
										<div className="mt-2">
											<Button.Secondary
												size="MEDIUM"
												disabled={!allowCancelExistingUnstakeRequest}
												onClick={handleCancelUnstakeRequest}
											>
												Cancel unstake request
											</Button.Secondary>
										</div>
									</div>
								}
							/>
						))}

					{unstakeUtilizationCutoffResult.unstakingIsDisabled && (
						<>
							<UnstakeUtilizationCutoffMessage
								{...unstakeUtilizationCutoffResult}
								type="UnstakeModal"
							/>
						</>
					)}

					{!hasExistingUnstakeRequest && allowConfirmation && (
						<div>
							Available for withdrawal on{' '}
							{UI_UTILS.printDate(newWithdrawalDate)}
						</div>
					)}

					{/* {hasExistingUnstakeRequest ? (
							canWithdraw ? (
								<Button.Secondary
									size="MEDIUM"
									className="w-full"
									disabled={submitting}
									onClick={handleWithdrawUnstakedAssets}
								>
									Withdraw{' '}
									{existingUnstakeRequestAmount.toFixed(displayPrecision)}{' '}
									{selectedIfMarket.symbol}
								</Button.Secondary>
							) : (
								<Button.Secondary
									size="MEDIUM"
									className="w-full"
									disabled={!allowCancelExistingUnstakeRequest}
									onClick={handleCancelUnstakeRequest}
								>
									Cancel unstake request
								</Button.Secondary>
							)
						) */}

					<Utility.VERTSPACERXL />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />

					<ValueDisplay.ValueChange
						label="Amount Staked"
						className="h-6"
						previousValue={currentIfStakeData?.userStake?.currentStakeBigNum}
						afterValue={
							hasExistingUnstakeRequest
								? currentIfStakeData?.userStake?.currentStakeBigNum
								: currentIfStakeData?.userStake?.currentStakeBigNum?.sub(
										unstakeAmountBignum
								  )
						}
						previousValuePrint={currentIfStakeData?.userStake?.currentStakeBigNum?.toFixed(
							displayPrecision
						)}
						afterValuePrint={
							hasExistingUnstakeRequest
								? currentIfStakeData?.userStake?.currentStakeBigNum?.toFixed(
										displayPrecision
								  )
								: currentIfStakeData?.userStake?.currentStakeBigNum
										?.sub(unstakeAmountBignum)
										?.toFixed(displayPrecision)
						}
						forceWhite
					/>

					{selectedIfMarket.marketIndex ===
						DRIFT_TOKEN_SPOT_MARKET.marketIndex &&
						oldFuelEarnAmount.gtZero() && (
							<>
								<ValueDisplay.Default
									label="FUEL earn"
									className={'h-6'}
									value={
										<span className="flex flex-row items-center gap-1">
											<img
												src="/assets/images/fuel/fuel-droplet.svg"
												alt="Fuel droplet"
												className="w-5 h-5"
											/>
											{!oldFuelEarnAmount.eq(fuelEarnAmount) && (
												<>
													<Text.BODY3>
														{oldFuelEarnAmount.toMillified()}
													</Text.BODY3>
													<Arrow />
												</>
											)}
											<Text.BODY3>
												{fuelEarnAmount.toMillified()} FUEL for every 28 days
												staked
											</Text.BODY3>
										</span>
									}
								/>
							</>
						)}

					<ValueDisplay.ValueChange
						className="h-6"
						label="Requested for Unstake"
						previousValue={
							hasExistingUnstakeRequest ? existingUnstakeRequestAmount : 0
						}
						afterValue={
							hasExistingUnstakeRequest ? 0 : unstakeAmountBignum.toNum()
						}
						previousValuePrint={
							hasExistingUnstakeRequest
								? existingUnstakeRequestAmount.toFixed(displayPrecision)
								: '0'
						}
						afterValuePrint={
							hasExistingUnstakeRequest
								? '0'
								: unstakeAmountBignum?.toFixed(displayPrecision)
						}
						forceWhite
					/>

					<Utility.VERTSPACERXL />

					<div className={`flex items-center w-full space-x-3`}>
						<Button.Secondary
							size="MEDIUM"
							className="w-full"
							disabled={!allowConfirmation || requestWithdrawPaused}
							onClick={handleUnstakeRequest}
						>
							Request Unstake
						</Button.Secondary>
					</div>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(UnstakeModalContent);
