'use client';

import { useEffect, useRef } from 'react';
import { referralLinkBannedCharactersRegex } from 'src/constants/constants';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import UI_UTILS from 'src/utils/uiUtils';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import useWalletIsConnected from './useWalletIsConnected';
import useAccountData from './useAccountData';

export default function useReferrerNameAccounts() {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const accountsStoreSet = useDriftAccountStore((s) => s.set);
	const currentPublicKey = useAccountData()?.authority;
	const dataLoadedForWallet = useRef(currentPublicKey);

	const referrerAccountsLoading = useDriftAccountStore(
		(s) => s.referrerNameAccounts.loading
	);
	const initialReferrerNameAccountsLoaded = useDriftAccountStore(
		(s) => s.referrerNameAccounts.initialReferrerNameAccountsLoaded
	);
	const userAccountNotInitialized = useDriftAccountStore(
		(s) => s.userAccountNotInitialized
	);
	const referrerNameAccounts = useDriftAccountStore(
		(s) => s.referrerNameAccounts
	);
	const referredAccounts = useDriftAccountStore((s) => s.referredAccounts);

	/**
	 * Reset referrer name accounts state when wallet disconnects
	 */
	useWalletIsConnected({
		onDisconnectedCallback: () => {
			accountsStoreSet((s) => {
				s.referrerNameAccounts.accounts = [];
				s.referrerNameAccounts.loading = false;
				s.referrerNameAccounts.initialReferrerNameAccountsLoaded = false;
				s.referredAccounts.accounts = [];
				s.referredAccounts.loading = false;
				s.referredAccounts.initialReferredAccountsLoaded = false;
			});
		},
	});

	/**
	 * Fetches referrer names created by current authority
	 * This should be done once on initial app load, and once each time a user successfully creates a referral code from the UI
	 */
	const fetchReferrerNameAccounts = async () => {
		if (!currentPublicKey || !driftClientIsReady || referrerAccountsLoading) {
			return;
		}

		// Just set empty state + loaded if drift account is not initialized
		if (userAccountNotInitialized) {
			accountsStoreSet((s) => {
				s.referrerNameAccounts.accounts = [];
				s.referrerNameAccounts.loading = false;
				s.referrerNameAccounts.initialReferrerNameAccountsLoaded = true;
				s.referredAccounts.accounts = [];
				s.referredAccounts.loading = false;
				s.referredAccounts.initialReferredAccountsLoaded = true;
			});
			return;
		}

		accountsStoreSet((s) => {
			s.referrerNameAccounts.loading = true;
			s.referredAccounts.loading = true;
		});

		const referrerNameAccounts =
			await driftClient.getReferrerNameAccountsForAuthority(currentPublicKey);

		const decodedAccounts = referrerNameAccounts.map((account) => {
			return {
				...account,
				decodedName: UI_UTILS.decodeName(account.name).replace(
					referralLinkBannedCharactersRegex,
					''
				),
			};
		});

		// Todo maybe we should do this in the same rpc call as getting the referrer name accounts? Hmm...
		// But the driftClient should be able to do it either way.
		const referredAccounts = (
			await driftClient.getReferredUserStatsAccountsByReferrer(currentPublicKey)
		).map((account) => account.authority);

		accountsStoreSet((s) => {
			s.referrerNameAccounts.accounts = decodedAccounts;
			s.referrerNameAccounts.loading = false;
			s.referrerNameAccounts.initialReferrerNameAccountsLoaded = true;
			s.referredAccounts.accounts = referredAccounts;
			s.referredAccounts.loading = false;
			s.referredAccounts.initialReferredAccountsLoaded = true;
		});
	};

	useEffect(() => {
		if (!initialReferrerNameAccountsLoaded && !referrerAccountsLoading) {
			fetchReferrerNameAccounts();
		}
	}, [
		userAccountNotInitialized,
		driftClientIsReady,
		referrerAccountsLoading,
		initialReferrerNameAccountsLoaded,
	]);

	useEffect(() => {
		if (
			currentPublicKey &&
			(!dataLoadedForWallet.current ||
				!dataLoadedForWallet.current?.equals(currentPublicKey))
		) {
			fetchReferrerNameAccounts();
		}
	}, [currentPublicKey]);

	return {
		referredAccounts,
		referrerNameAccounts,
		fetchReferrerNameAccounts,
	};
}
