'use client';

import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import { useEffect, useRef } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useTickedAllSubAccounts from './useTickedAllSubAccounts';
import useAppEventEmitter from './useAppEventEmitter';
import useInterval from './useInterval';
import { PnLTimeSeriesDataPoint } from './usePnlHistory';
import { getTotalAllTimePnlForUserAccount } from './useTotalPnl';
import useWalletIsConnected from './useWalletIsConnected';
import useReferrerNameAccounts from './useReferrerNameAccounts';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useAccountData from './useAccountData';
import { ZERO_BIGNUM } from 'src/constants/math';

const REFRESH_RATE = 1000 * 5;
const UPDATE_AFTER_DEPOSIT_DELAY = 1000 * 3;

const useCurrentSnapshot = () => {
	const set = useDriftAccountStore((s) => s.set);
	const connected = useWalletIsConnected();
	const accounts = useTickedAllSubAccounts();
	const { referredAccounts } = useReferrerNameAccounts();
	const userStatsAccount = useDriftStore((s) => s.userStatsAccount);
	const latestUserSnapshot = useAccountData()?.latestSnapshot;

	const appEventEmitter = useAppEventEmitter();

	const lastFetch = useRef(0);

	const calculateCurrentDataPoint = async () => {
		if (!accounts || !accounts.length) return;

		lastFetch.current = Date.now();

		accounts.forEach((acct) => {
			const newDataPoint: PnLTimeSeriesDataPoint = {
				date: new Date(),
				totalPnl: 0,
				accountValue: 0,
				referralRewards: 0,
				referralVolume: 0,
				referralCount: 0,
			};

			newDataPoint.referralVolume =
				latestUserSnapshot?.cumulativeReferralVolume?.toNum();
			newDataPoint.referralCount = referredAccounts.accounts.length;

			const rewards = userStatsAccount?.fees?.totalReferrerReward
				? BigNum.from(
						userStatsAccount.fees.totalReferrerReward,
						QUOTE_PRECISION_EXP
				  )
				: ZERO_BIGNUM;

			newDataPoint.referralRewards = rewards.toNum();

			const newAccountValue = BigNum.from(
				acct.marginInfo.netUsdValue,
				QUOTE_PRECISION_EXP
			).toNum();

			newDataPoint.accountValue = newAccountValue;

			const allTimeTotalPnl = getTotalAllTimePnlForUserAccount(
				acct.client
			).toNum();

			newDataPoint.totalPnl = allTimeTotalPnl;

			set((s) => {
				if (s.accounts[acct.userKey]) {
					s.accounts[acct.userKey].currentPnlPoint = newDataPoint;
					s.currentPointsTs = Date.now();
				}
			});
		});
	};

	// Update when first connects and when new accounts get created
	useEffect(() => {
		if (!connected) return;
		if (accounts.length === 0) return;

		calculateCurrentDataPoint();
	}, [connected, accounts.length]);

	// update every 30 sec
	useInterval(() => {
		if (!connected) return;
		if (lastFetch.current > Date.now() - REFRESH_RATE) return;

		calculateCurrentDataPoint();
	}, REFRESH_RATE);

	// Update a few seconds after the user makes a deposit or withdrawal
	useEffect(() => {
		if (!connected) return;

		const eventHandler = () => {
			setTimeout(() => {
				calculateCurrentDataPoint();
			}, UPDATE_AFTER_DEPOSIT_DELAY);
		};

		appEventEmitter.on('newDepositRecord', eventHandler);

		return () => {
			appEventEmitter.removeListener('newDepositRecord', eventHandler);
		};
	}, [appEventEmitter, connected, accounts]);
};

export default useCurrentSnapshot;
