'use client';

import HistoryDrawer from './HistoryDrawer';
import Text from 'src/components/Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	UIMarket,
	UISerializableOrderActionRecord,
	UISerializableOrderRecord,
} from '@drift/common';
import { twMerge } from 'tailwind-merge';
import {
	MarketType,
	OrderAction,
	OrderActionExplanation,
	PublicKey,
	isVariant,
} from '@drift-labs/sdk';
import { ErrorFilled, LiqHalf, Open, SuccessFilled } from '@drift-labs/icons';
import { format } from 'timeago.js';
import { getCounterpartyIcon } from 'src/components/Counterparty';
import { DEFAULT_PUBLIC_KEY } from 'src/@types/types';
import Drawer from 'src/components/Drawer';
import { useEffect, useMemo, useState } from 'react';
import { DrawerName, useDrawersStore } from 'src/stores/useDrawersStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import InlineLoadingBar from 'src/components/InlineLoadingBar/InlineLoadingBar';

const orderActionRecordsSortFn = (recordB, recordA) => {
	if (
		ENUM_UTILS.match(recordA.actionExplanation, OrderActionExplanation.NONE)
	) {
		return -1;
	} else if (recordB.actionExplanation === OrderActionExplanation.NONE) {
		return 1;
	}

	return recordA.ts.toNumber() - recordB.ts.toNumber();
};

const OrderDetailsRow = ({
	details,
}: {
	details: { label: string; value: string }[];
}) => {
	return (
		<div className="flex flex-row items-center divide-x divide-container-border">
			{details.map((detail) => (
				<Drawer.DetailBox
					key={detail.label}
					{...detail}
					className="min-w-[110px] pl-4 first:pl-0 "
				/>
			))}
		</div>
	);
};

const OrderSummary = ({
	orderRecord,
	tradeInfo,
}: {
	orderRecord: UISerializableOrderRecord;
	tradeInfo: ReturnType<typeof UI_UTILS.getTradeInfoFromMatchedRecord>;
}) => {
	const uiMarket = new UIMarket(
		tradeInfo.marketIndex,
		ENUM_UTILS.toObj(tradeInfo.marketType)
	);

	const orderStatus = UI_UTILS.getOrderStatusString(
		tradeInfo.action,
		tradeInfo.totalBaseAmountFilled,
		tradeInfo.baseAssetAmount
	);
	const direction = UI_UTILS.getDirectionString(
		tradeInfo.direction,
		uiMarket.isPerp
	);

	const orderType = COMMON_UI_UTILS.getOrderLabelFromOrderDetails(
		orderRecord.order
	);
	const flags = UI_UTILS.getFlagsFromOrder(orderRecord);
	const triggerPrice = UI_UTILS.getTriggerPriceFromOrder(orderRecord);
	const limitPrice = UI_UTILS.getLimitPriceFromOrder(orderRecord);
	const averagePrice = COMMON_UI_UTILS.calculateAverageEntryPrice(
		tradeInfo.totalQuoteAmountFilled,
		tradeInfo.totalBaseAmountFilled
	).toNotional(true);

	const orderDetailsRowOne = [
		{
			label: 'Type',
			value: orderType,
		},
		{
			label: 'Average Price',
			value: averagePrice,
		},
	];

	const orderDetailsRowTwo = [
		{
			label: 'Trigger',
			value: triggerPrice,
		},
		{
			label: 'Limit',
			value: limitPrice,
		},
		{
			label: 'Flags',
			value: flags,
		},
	];

	return (
		<div className="flex flex-col gap-4">
			{/** Market details */}
			<div className="flex gap-2">
				<Drawer.MarketPosition
					marketSymbol={uiMarket.market.symbol}
					direction={direction}
				/>
				<div>
					<Text.BODY3
						className={twMerge(
							'uppercase bg-button-secondary-bg-hover p-[2px] rounded-sm relative -top-[1px]',
							orderStatus === 'Filled' && 'text-positive-green',
							orderStatus === 'Cancelled' && 'text-negative-red'
						)}
					>
						{orderStatus}
					</Text.BODY3>
				</div>
			</div>

			{/** Order details */}
			<div className="flex flex-col gap-2">
				<OrderDetailsRow details={orderDetailsRowOne} />
				<OrderDetailsRow details={orderDetailsRowTwo} />
			</div>
		</div>
	);
};

const ICON_SIZE = 16;
const getOrderActionIcon = (
	status: ReturnType<typeof UI_UTILS.getOrderStatusString>
) => {
	if (status.includes('Partially Filled')) {
		return <LiqHalf size={ICON_SIZE} color="var(--text-label)" />;
	} else if (status === 'Filled') {
		return <SuccessFilled size={ICON_SIZE} />;
	} else if (['Cancelled', 'Expired'].includes(status)) {
		return <ErrorFilled size={ICON_SIZE} />;
	} else if (['Open', 'Triggered'].includes(status)) {
		return (
			<svg
				width={ICON_SIZE}
				height={ICON_SIZE}
				viewBox={`0 0 ${ICON_SIZE} ${ICON_SIZE}`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx={ICON_SIZE / 2}
					cy={ICON_SIZE / 2}
					r={ICON_SIZE / 2 - 2}
					stroke="var(--text-label)"
					strokeWidth="1"
				/>
			</svg>
		);
	} else {
		return null;
	}
};

const OrderActionRow = ({
	orderActionRecord,
	currentAccountPubKey,
}: {
	orderActionRecord: UISerializableOrderActionRecord;
	currentAccountPubKey: PublicKey | undefined;
}) => {
	const tradeInfo = UI_UTILS.getTradeInfoFromOrderActionRecord(
		currentAccountPubKey,
		orderActionRecord
	);

	const orderStatus = UI_UTILS.getOrderStatusString(
		orderActionRecord.action,
		tradeInfo.totalBaseAmountFilled,
		tradeInfo.baseAssetAmount
	);
	const isOpenAction = ENUM_UTILS.match(
		orderActionRecord.action,
		OrderAction.PLACE
	);
	const isCancel = ENUM_UTILS.match(
		orderActionRecord.action,
		OrderAction.CANCEL
	);

	const averagePrice = COMMON_UI_UTILS.calculateAverageEntryPrice(
		tradeInfo.quoteAssetAmountFilled,
		tradeInfo.baseAssetAmountFilled
	).toNotional(true);
	const filledAmount = tradeInfo.baseAssetAmountFilled.toTradePrecision();
	const totalOrderedAmount = tradeInfo.baseAssetAmount.toTradePrecision();

	const counterparty = useMemo(() => {
		return tradeInfo.counterparty?.equals(DEFAULT_PUBLIC_KEY)
			? ''
			: tradeInfo.counterparty?.toString();
	}, [tradeInfo.counterparty, DEFAULT_PUBLIC_KEY]);
	const isPerp = isVariant(MarketType.PERP, tradeInfo.marketType);
	const ammCounterparty = isPerp
		? 'Drift AMM'
		: ENUM_UTILS.match(
				orderActionRecord.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_SERUM
		  )
		? 'OpenBook'
		: 'Phoenix';

	const cancelExplanation = isCancel
		? ENUM_UTILS.match(
				orderActionRecord.actionExplanation,
				OrderActionExplanation.NONE
		  )
			? 'Cancelled by user'
			: UI_UTILS.lowerCaseNonFirstWords(
					UI_UTILS.splitByCapitalLetters(
						ENUM_UTILS.toStr(orderActionRecord.actionExplanation)
					)
			  )
		: undefined;

	const date = new Date(tradeInfo.ts.toNumber() * 1000);

	return (
		<div className="flex flex-col gap-2 pt-4 pl-5 text-text-default first:pt-0">
			<div className="relative flex justify-between">
				<div className="flex items-start gap-1">
					<Text.BODY1 className="uppercase text-text-emphasis">
						{orderStatus}
					</Text.BODY1>
					<a
						href={UI_UTILS.getUrlForTx(orderActionRecord.txSig)}
						target="_blank"
						rel="noopener noreferrer"
						className="flex items-center"
					>
						<Open color="var(--interactive-link)" size={16} />
					</a>
				</div>

				<div className="flex flex-col text-right">
					<Text.BODY3>{date.toLocaleString().replace(',', '')}</Text.BODY3>
					<Text.BODY3>{format(date)}</Text.BODY3>
				</div>

				<div className="absolute left-0 -translate-x-5 -top-[1px]">
					{getOrderActionIcon(orderStatus)}
				</div>
			</div>
			{!isOpenAction && (
				<>
					<Drawer.DetailBox label="Average Fill Price" value={averagePrice} />
					<Drawer.DetailBox
						label="Filled / Cumulative Filled / Ordered"
						value={
							<div className="flex flex-wrap items-center gap-1">
								<div className="flex items-center">
									<Text.BODY2 className="uppercase">
										{filledAmount} /{' '}
										{tradeInfo.totalBaseAmountFilled.toTradePrecision()} /{' '}
										{totalOrderedAmount}
									</Text.BODY2>
								</div>

								{cancelExplanation && (
									<div
										className={
											'flex items-center gap-1 bg-button-secondary-bg py-[2px] px-1 rounded-sm text-chip-default-text'
										}
									>
										<Text.BODY3 className="first-letter:capitalize">
											{cancelExplanation}
										</Text.BODY3>
									</div>
								)}

								{Number(filledAmount) > 0 && (
									<div
										className={twMerge(
											'flex items-center gap-1 bg-button-secondary-bg py-[2px] px-1 rounded-sm',
											counterparty
												? 'text-interactive-link'
												: 'text-chip-default-text'
										)}
									>
										{getCounterpartyIcon(
											{
												counterparty,
												actionExplanation: orderActionRecord.actionExplanation,
												marketType: ENUM_UTILS.toObj(tradeInfo.marketType),
												isGenericTrade: false,
											},
											counterparty
												? 'var(--interactive-link)'
												: 'var(--chip-default-text)'
										)}
										<Text.BODY3
											className={twMerge(counterparty && 'underline')}
										>
											{counterparty ? (
												<a
													href={UI_UTILS.getUrlForAccount(counterparty)}
													target="_blank"
													rel="noopener noreferrer"
												>
													CounterParty[
													{UI_UTILS.abbreviateAddress(counterparty)}]
												</a>
											) : (
												`Filled by ${ammCounterparty}`
											)}
										</Text.BODY3>
									</div>
								)}
							</div>
						}
					/>
				</>
			)}
		</div>
	);
};

const OrderBreakdownContent = ({
	orderActionRecords,
	currentAccountPubKey,
}: {
	orderActionRecords: UISerializableOrderActionRecord[];
	currentAccountPubKey: PublicKey | undefined;
}) => {
	if (orderActionRecords.length === 0) {
		return (
			<div className="flex items-center justify-center h-full grow">
				<InlineLoadingBar />
			</div>
		);
	}

	return (
		<div className="overflow-hidden grow">
			<div className="flex flex-col gap-4 overflow-y-auto divide-y divide-container-border thin-scroll">
				{orderActionRecords
					.sort((a, b) => b.ts?.toNumber() - a.ts?.toNumber())
					.map((actionRecord, index) => (
						<OrderActionRow
							key={`${actionRecord.txSig}_${index}`}
							orderActionRecord={actionRecord}
							currentAccountPubKey={currentAccountPubKey}
						/>
					))}
			</div>
		</div>
	);
};

const OrderDrawer = () => {
	const MODAL_STORE_KEY: DrawerName = 'orders';
	const currentAccountPubKey = useDriftAccountStore(
		(s) => s.getCurrentUserAccount().pubKey
	);
	const order = useDrawersStore((s) => s.orders.order);

	const mostRecentOrderActionRecord = order.actionRecords.sort(
		orderActionRecordsSortFn
	)[0];
	const matchedRecord = {
		orderRecord: order.orderRecord,
		actionRecord: mostRecentOrderActionRecord,
	};

	const handleCloseHistoryPanel = useDrawersStore((s) => s.handleCloseDrawer);

	const [orderActionRecords, setOrderActionRecords] = useState<
		UISerializableOrderActionRecord[]
	>([]);

	useEffect(() => {
		if (currentAccountPubKey) {
			ExchangeHistoryClient.getOrderActionRecordsForUserOrder(
				currentAccountPubKey,
				order.orderRecord.order.orderId,
				0,
				300 // 300 is an arbitrary max for now
			).then((res) => {
				if (res.success && res.body.data.orderActionRecords.length > 0) {
					setOrderActionRecords(
						res.body.data.orderActionRecords.sort(orderActionRecordsSortFn)
					);
				}
			});
		}
	}, [currentAccountPubKey, order.orderRecord.order.orderId]);

	useEffect(() => {
		if (!currentAccountPubKey) {
			handleCloseHistoryPanel(MODAL_STORE_KEY);
		}
	}, [currentAccountPubKey]);

	if (!currentAccountPubKey) {
		return undefined;
	}

	const lastActionTradeInfo = UI_UTILS.getTradeInfoFromMatchedRecord(
		currentAccountPubKey,
		matchedRecord
	);

	return (
		<HistoryDrawer
			title={'Order'}
			summarySection={
				<OrderSummary
					orderRecord={order.orderRecord}
					tradeInfo={lastActionTradeInfo}
				/>
			}
			contentSection={
				<OrderBreakdownContent
					orderActionRecords={orderActionRecords}
					currentAccountPubKey={currentAccountPubKey}
				/>
			}
			modalStoreKey={MODAL_STORE_KEY}
		/>
	);
};

export default OrderDrawer;
