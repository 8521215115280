'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import { DetailsRow } from '../PerpPageStats/ViewDetailsForMarket';
import Text from '../Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import Utility from '../Inputs/Utility';
import InlineLoadingBar from '../InlineLoadingBar/InlineLoadingBar';
import useGoToRoute from 'src/hooks/useGoToRoute';

const ModalContent = (props: { onClose: () => void }) => {
	const goToRoute = useGoToRoute();
	const insuranceFundStats = useDriftStore((s) => s.insuranceFundStats);

	const routeToBankruptcies = () => {
		props.onClose();
		goToRoute('/stats/bankruptcies');
	};

	return insuranceFundStats ? (
		<>
			<div className="w-full mb-2">
				<Text.H3 className="text-text-default">7 Day Performance</Text.H3>
				<Text.BODY3 className="block text-text-default">
					The breakdown below reflects the last 7 day performance of all
					Insurance Fund Vaults. The performance of individual vaults is
					viewable via each vault card.
				</Text.BODY3>
			</div>
			<DetailsRow fullWidth={true}>
				<Text.BODY3 className="text-text-label">Total Revenue</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.toNotional(insuranceFundStats.totalRevenue, 2)}
				</Text.BODY3>
			</DetailsRow>
			<Utility.VERTDIVIDER />
			<DetailsRow fullWidth={true}>
				<Text.BODY3 className="text-text-label">Perp Liquidations</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.toNotional(insuranceFundStats.perpLiquidationTotal, 2)}
				</Text.BODY3>
			</DetailsRow>
			<Utility.VERTDIVIDER />
			<DetailsRow fullWidth={true}>
				<Text.BODY3 className="text-text-label">Spot Liquidations</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.toNotional(insuranceFundStats.spotLiquidationTotal, 2)}
				</Text.BODY3>
			</DetailsRow>
			<div className="w-full my-2">
				<Text.H3 className="text-text-default">Bankruptcies</Text.H3>
				<div className="block">
					<Text.BODY3 className="text-text-default">
						{`You can view the history of bankruptcies `}
						<p
							className="inline-flex text-purple-40 hover:cursor-pointer"
							onClick={routeToBankruptcies}
						>
							here.
						</p>
					</Text.BODY3>
				</div>
			</div>
			<DetailsRow fullWidth={true}>
				<Text.BODY3 className="text-text-label">
					Last Perp Bankruptcy
				</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.timeAgoPreciseString(
						new Date(insuranceFundStats.lastPerpBankruptcy * 1000)
					)}
				</Text.BODY3>
			</DetailsRow>
			<Utility.VERTDIVIDER />
			<DetailsRow fullWidth={true}>
				<Text.BODY3 className="text-text-label">
					Last Spot Bankruptcy
				</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.timeAgoPreciseString(
						new Date(insuranceFundStats.lastSpotBankruptcy * 1000)
					)}
				</Text.BODY3>
			</DetailsRow>
		</>
	) : (
		<>
			<div className="flex items-center justify-center w-full h-full p-4">
				<InlineLoadingBar />
			</div>
		</>
	);
};

const InsuranceFundRevenueModal = () => {
	const setState = useDriftStore((s) => s.set);

	const hideModal = () => {
		setState((s) => {
			s.modals.showInsuranceFundRevenueModal = false;
		});
	};

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Insurance Fund Performance</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<ModalContent onClose={hideModal} />
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default InsuranceFundRevenueModal;
