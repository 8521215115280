'use client';

import React, { useEffect, useRef, useState } from 'react';
import Text from '../Text/Text';
import { ChevronDown } from '@drift-labs/icons';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';

const DepositGuidesPopup = ({
	onClose = () => {},
}: {
	onClose?: () => void;
}) => {
	const popupRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		const eventWasInsideModal = UI_UTILS.clickedInsideElement(
			event,
			popupRef?.current
		);
		if (eventWasInsideModal) {
			return;
		}
		onClose();
	};

	useEffect(() => {
		setTimeout(() => {
			document.body.addEventListener('click', handleClickOutside);
		}, 100);
		return () => {
			document.body.removeEventListener('click', handleClickOutside);
		};
	});

	return (
		<div
			ref={popupRef}
			className={twMerge(
				'absolute z-50 bg-tooltip-bg drop-shadow-md top-[100%] mt-2 p-3 flex flex-col gap-2 text-left whitespace-nowrap rounded-[4px]'
			)}
		>
			<a
				href="https://www.drift.trade/learn/dex-deposit-withdraw"
				target="_blank"
				rel="noreferrer"
			>
				<Text.BODY2 className="text-text-interactive">
					How to deposit from a CEX
				</Text.BODY2>
			</a>
			<a
				href="https://www.drift.trade/learn/how-to-bridge-assets"
				target="_blank"
				rel="noreferrer"
			>
				<Text.BODY2 className="text-text-interactive">
					How to bridge assets
				</Text.BODY2>
			</a>
		</div>
	);
};

function DepositGuidesDropdown() {
	const [showPopup, setShowPopup] = useState(false);

	const handleTogglePopup = () => {
		setShowPopup(!showPopup);
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	return (
		<div className="relative">
			<button
				onClick={handleTogglePopup}
				className="relative flex flex-row items-center gap-1 text-interactive-link"
			>
				<Text.BODY2>Deposit Guides</Text.BODY2>
				<ChevronDown
					className={twMerge(
						`transition-transform`,
						showPopup ? 'rotate-180' : 'rotate-0'
					)}
				/>
				{showPopup && <DepositGuidesPopup onClose={handleClosePopup} />}
			</button>
		</div>
	);
}

export default React.memo(DepositGuidesDropdown);
