import MarketIcon from '../Utils/MarketIcon';
import PopupAlert from './PopupAlert';

const MarketAlert = ({
	marketBaseAssetSymbol,
}: {
	marketBaseAssetSymbol: string;
}) => {
	return (
		<PopupAlert
			heading={`${marketBaseAssetSymbol} has landed!`}
			featureKey={`newMarket_${marketBaseAssetSymbol}`}
			icon={
				<MarketIcon marketSymbol={marketBaseAssetSymbol} sizeClass="w-6 h-6" />
			}
			withHref={`/${marketBaseAssetSymbol}-PERP`}
		>
			Start trading {marketBaseAssetSymbol}-PERP today!
		</PopupAlert>
	);
};

export default MarketAlert;
