'use client';

import {
	BulkAccountLoader,
	InsuranceFundStakeAccountSubscriber,
	PollingInsuranceFundStakeAccountSubscriber,
	PublicKey,
	WebSocketInsuranceFundStakeAccountSubscriber,
	getInsuranceFundStakeAccountPublicKey,
	getInsuranceFundVaultPublicKey,
	PollingTokenAccountSubscriber,
	TokenAccountSubscriber,
} from '@drift-labs/sdk';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	OrderedSpotMarkets,
	isDev,
} from '../environmentVariables/EnvironmentVariables';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { dlog } from 'src/dev';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';

const INITIAL_CONTEXT_VALUE: {
	ifStakeVaultSubscribers: TokenAccountSubscriber[];
	userIfStakeSubscribers: InsuranceFundStakeAccountSubscriber[];
} = {
	ifStakeVaultSubscribers: [],
	userIfStakeSubscribers: [],
};

export const IfStakeSubscriberContext = React.createContext<{
	ifStakeVaultSubscribers: TokenAccountSubscriber[];
	userIfStakeSubscribers: InsuranceFundStakeAccountSubscriber[];
}>(INITIAL_CONTEXT_VALUE);

const IfStakeSubscriberProvider = (props: PropsWithChildren<any>) => {
	const connection = useDriftStore((s) => s.connection.current);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const bulkAccountLoader = useDriftStore((s) => s.currentBulkAccountLoader);
	const connected = useWalletIsConnected();

	const [contextValue, setContextValue] = useState(INITIAL_CONTEXT_VALUE);
	const subscribersRef = useRef(contextValue);

	const createSubscriber = async (
		program: ConstructorParameters<
			typeof PollingInsuranceFundStakeAccountSubscriber
		>[0],
		publicKey: PublicKey,
		usePolling: boolean,
		isUser: boolean,
		bulkAccountLoader?: BulkAccountLoader
	) => {
		let sub;
		if (isUser) {
			sub = usePolling
				? new PollingInsuranceFundStakeAccountSubscriber(
						program,
						publicKey,
						bulkAccountLoader
				  )
				: new WebSocketInsuranceFundStakeAccountSubscriber(
						program,
						publicKey,
						3000, //todo - confirm this
						DEFAULT_COMMITMENT_LEVEL
				  );
		} else {
			sub = new PollingTokenAccountSubscriber(publicKey, bulkAccountLoader);
		}
		await sub.subscribe();
		return sub;
	};

	const createVaultSubscribers = async (
		usePolling: boolean
	): Promise<TokenAccountSubscriber[]> => {
		const subs = await Promise.all(
			OrderedSpotMarkets.map(async (spotMarket) => {
				try {
					const pubKey = await getInsuranceFundVaultPublicKey(
						driftClient.program.programId,
						spotMarket.marketIndex
					);

					return await createSubscriber(
						driftClient.program,
						pubKey,
						usePolling,
						false,
						bulkAccountLoader
					);
				} catch (err) {
					if (isDev()) {
						console.error(err);
					}
				}
			})
		);

		return subs;
	};

	const createUserSubscribers = async (
		usePolling: boolean,
		userAuthority: PublicKey
	): Promise<InsuranceFundStakeAccountSubscriber[]> => {
		const subs = await Promise.all(
			OrderedSpotMarkets.map(async (spotMarket) => {
				try {
					const pubKey = await getInsuranceFundStakeAccountPublicKey(
						driftClient.program.programId,
						userAuthority,
						spotMarket.marketIndex
					);

					return await createSubscriber(
						driftClient.program,
						pubKey,
						usePolling,
						true,
						bulkAccountLoader
					);
				} catch (err) {
					console.error(err);
				}
			})
		);

		return subs;
	};

	// set up subscribers
	useEffect(() => {
		if (
			!connection ||
			!driftClient ||
			!driftClient.isSubscribed ||
			!bulkAccountLoader
		)
			return;

		// TODO - revisit this, need to check that accounts exists before opening websockets
		const usePolling = true;

		const createAndSubscribe = async () => {
			const ifStakeVaultSubscribers = await createVaultSubscribers(usePolling);

			let userIfStakeSubscribers = [];
			if (connected) {
				userIfStakeSubscribers = await createUserSubscribers(
					usePolling,
					driftClient.provider?.wallet?.publicKey
				);
			}

			setContextValue({
				ifStakeVaultSubscribers,
				userIfStakeSubscribers,
			});
			subscribersRef.current = {
				ifStakeVaultSubscribers,
				userIfStakeSubscribers,
			};
		};

		const teardown = () => {
			subscribersRef.current.ifStakeVaultSubscribers?.forEach((sub) => {
				try {
					sub.unsubscribe();
				} catch (err) {
					console.error(err);
					dlog(
						'if_stake_unsubscribe - ifStakeVaultSubscribers',
						subscribersRef.current.ifStakeVaultSubscribers
					);
					dlog('if_stake_unsubscribe - current subscriber', sub);
				}
			});
			subscribersRef.current.userIfStakeSubscribers?.forEach((sub) => {
				if (sub) {
					sub.unsubscribe();
				}
			});
			setContextValue(INITIAL_CONTEXT_VALUE);
		};

		createAndSubscribe();

		return teardown;
	}, [connection, driftClient, bulkAccountLoader, connected]);

	return (
		<IfStakeSubscriberContext value={contextValue}>
			{props.children}
		</IfStakeSubscriberContext>
	);
};

export default IfStakeSubscriberProvider;
