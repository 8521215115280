import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/ui/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/src/components/AppWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/src/styles/dev.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/src/styles/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/@dialectlabs/react-ui/dist/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/tippy.js/animations/scale.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/node_modules/tippy.js/dist/tippy.css");
