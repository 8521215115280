import { SettingsOther as SettingsOtherIcon } from '@drift-labs/icons';

const SettingsOther = (props: { active: boolean }) => {
	return (
		<SettingsOtherIcon
			color={props.active ? 'url(#icon-gradient)' : '#6683A7'}
			size={18}
		/>
	);
};

export default SettingsOther;
