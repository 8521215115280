'use client';

import XButton from 'src/components/Buttons/XButton';
import Text from 'src/components/Text/Text';
import { useLocalStorageStringState } from 'src/hooks/useLocalStorageState';

const DRIFTMAS_URL = 'https://www.drift.trade/updates/12daysofdriftmas';

const DriftmasAlert = () => {
	const [hasSeenAlert, setHasSeenAlert] = useLocalStorageStringState(
		'driftmas-alert',
		'false'
	);

	if (hasSeenAlert === 'true') return null;

	return (
		<div
			className="fixed z-50 bg-container-bg-selected w-[430px] max-w-[90%]"
			style={{
				top: '10%',
				left: '50%',
				transform: 'translateX(-50%)',
			}}
		>
			<div className="relative py-3 pl-3 pr-5 shadow-lg rainbow-gradient-border">
				<div className="flex flex-row items-start justify-between">
					<div className="pt-1 pr-1 shrink-0">
						<img src="/assets/icons/driftmas-tree.svg" height={24} width={24} />
					</div>
					<div className="pl-1">
						<div className="text-text-emphasis">
							<Text.BODY3>12 Days of Driftmas</Text.BODY3>
						</div>
						<div className="pt-1 text-xs leading-4 text-text-default">
							<Text.BODY3 className="tracking-normal font-extralight">
								Join us for 12 days of holiday challenges and giveaways for your
								chance to win $7,500 in prizes!{' '}
								<a href={DRIFTMAS_URL} target={'_blank'} rel={'noreferrer'}>
									Learn more
								</a>
							</Text.BODY3>
						</div>
					</div>
				</div>
				<div className="absolute top-2 sm:top-4 right-2 sm:right-3 ">
					<XButton
						stroke="var(--text-interactive)"
						onClick={(e) => {
							setHasSeenAlert('true');
							e.preventDefault();
							e.stopPropagation();
						}}
						className="w-6 sm:w-4 h-6 sm:h-4 ml-4 mb-0.5"
					/>
				</div>
			</div>
		</div>
	);
};

export default DriftmasAlert;
