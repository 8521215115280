'use client';

import React from 'react';

/**
 *
 * @returns true if the current device is a tablet or mobile phone
 */
const useIsMobileDevice = () => {
	const [isMobile, setMobile] = React.useState(false);

	// trigger update on resize

	React.useEffect(() => {
		const checkAndSetIsMobile = () => {
			const userAgent =
				typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

			const mobile = Boolean(
				userAgent.match(
					/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
				)
			);
			setMobile(mobile);
		};

		checkAndSetIsMobile();

		window.addEventListener('resize', checkAndSetIsMobile);

		return () => {
			window.removeEventListener('resize', checkAndSetIsMobile);
		};
	}, []);

	return isMobile;
};

export default useIsMobileDevice;
