'use client';

import {
	BN,
	OraclePriceData,
	calculateLongShortFundingRate,
	convertToNumber,
} from '@drift-labs/sdk';
import { useCallback, useEffect, useState } from 'react';
import {
	PERP_MARKET_IDS,
	PERP_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import useMarketStateStore, {
	MarketState,
} from '../stores/useMarketStateStore';
import useUiUpdateInterval from './useUiUpdateInterval';
import { UIMarket } from '@drift/common';

const getRawOracleData = (data: MarketState['oracle']): OraclePriceData => {
	return {
		price: data.price.val,
		slot: data.slot,
		confidence: data.confidence,
		hasSufficientNumberOfDataPoints: data.hasSufficientNumberOfDataPoints,
		twap: data.twap?.val,
		twapConfidence: data.twapConfidence,
		maxPrice: data.maxPrice?.val,
	};
};

type MarketFundingRate = {
	marketIndex: number;
	fundingRate: number;
};

const areFundingsEqual = (a: MarketFundingRate[], b: MarketFundingRate[]) => {
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; i++) {
		if (
			a[i].marketIndex !== b[i].marketIndex ||
			a[i].fundingRate !== b[i].fundingRate
		)
			return false;
	}

	return true;
};

/**
 * Uses trading history from the history server to calculate price change
 * @returns
 */
const usePredictedFunding = () => {
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const setStore = useDriftStore((s) => s.set);
	const getOraclePriceData = useMarketStateStore(
		(s) => s.getOracleDataForMarket
	);
	const getMarkPrice = useMarketStateStore((s) => s.getMarkPriceForMarket);

	const [currentPredictedFunding, setCurrentPredictedFunding] = useState<
		MarketFundingRate[]
	>([]);

	const updatePredictedFunding = useCallback(async () => {
		if (!driftClientIsReady) return;

		const fundings = [];
		await Promise.all([
			PERP_MARKET_IDS.map(async (marketId) => {
				const oraclePriceData = getOraclePriceData(marketId);
				const currentMarkPrice = getMarkPrice(marketId);

				// Skip if we don't have the necessary data
				if (!oraclePriceData || !currentMarkPrice) return;

				const isPredictionMarket = UIMarket.checkIsPredictionMarket(
					PERP_MARKETS_LOOKUP[marketId.marketIndex]
				);

				// Skip if it's a prediction market because they don't have funding. ALSO if we change this later : There has been a bug observed where when a prediction market is resolved the oracle price inside calculateLongShortFundingRate is zero and creates a divide by zero error, make sure that is handled if we change this logic.
				if (isPredictionMarket) return;

				const marketInfo = driftClient.getPerpMarketAccount(
					marketId.marketIndex
				);

				const nowBN = new BN((Date.now() / 1000).toFixed(0));

				const fundingInfo = await calculateLongShortFundingRate(
					marketInfo,
					getRawOracleData(oraclePriceData),
					currentMarkPrice.val,
					nowBN
				);

				const longFundingRate = fundingInfo[0] ?? new BN(0);
				const shortFundingRate = fundingInfo[1] ?? new BN(0);

				const nextFundingRateNum = convertToNumber(
					longFundingRate.abs().gt(shortFundingRate.abs())
						? longFundingRate
						: shortFundingRate
				);

				fundings.push({
					marketIndex: marketId.marketIndex,
					fundingRate: nextFundingRateNum,
				});
			}),
		]);

		if (!areFundingsEqual(fundings, currentPredictedFunding)) {
			setCurrentPredictedFunding(fundings);
		}
	}, [driftClient, driftClientIsReady]);

	// update price change in store when local value changes
	useEffect(() => {
		setStore((s) => {
			s.predictedFundings = currentPredictedFunding;
		});
	}, [currentPredictedFunding]);

	// update price change
	useUiUpdateInterval(updatePredictedFunding, true, true);
};

export default usePredictedFunding;
