'use client';

import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import useInfoForCurrentlySelectedMarket from './useInfoForCurrentlySelectedMarket';

const useShowInsuranceWarningForMarket = () => {
	const currentMarketInfo = useInfoForCurrentlySelectedMarket();
	const currentMarketName = currentMarketInfo?.info?.config?.symbol;
	const [shouldShowWarning, setShouldShowWarning] = useState(false);

	const getNoInsuranceWarningKeyForMarket = () =>
		`seen_market_${currentMarketName}`;

	const setNoInsuranceWarningSeen = () => {
		window.localStorage.setItem(getNoInsuranceWarningKeyForMarket(), 'true');
		setShouldShowWarning(false);
	};

	useEffect(() => {
		if (!currentMarketInfo?.showInsuranceFundWarning) {
			setShouldShowWarning(false);
			return;
		}

		const key = getNoInsuranceWarningKeyForMarket();
		const marketHasBeenSeen = window.localStorage.getItem(key) === 'true';

		setShouldShowWarning(!marketHasBeenSeen);
	}, [currentMarketInfo?.info?.marketId?.key]);

	return { shouldShowWarning, setNoInsuranceWarningSeen };
};

export default singletonHook(
	{ shouldShowWarning: false, setNoInsuranceWarningSeen: () => {} },
	useShowInsuranceWarningForMarket
);
