'use client';

import Button from 'src/components/Button';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import useDriftActions from 'src/hooks/useDriftActions';
import { memo, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import Modal from './Modal';
import useTargetAccountData from '../../hooks/useTargetAccountData';
import { SUPER_STAKE_ACCOUNT_NAMES } from 'src/constants/constants';
import { COMMON_UI_UTILS } from '@drift/common';
import UI_UTILS from 'src/utils/uiUtils';

// TODO - This should come from SDK
const maxChars = 32;

const EditSubaccountModal = () => {
	const set = useDriftAccountStore((s) => s.set);
	const subaccountIdToEdit = useDriftStore(
		(s) => s.subAccountsInfo.accountIdToEdit
	);
	const walletAuthority = useDriftStore(
		(s) => s.wallet.current.adapter.publicKey
	);

	const userKey = COMMON_UI_UTILS.getUserKey(
		subaccountIdToEdit,
		walletAuthority
	);
	const selectedSubAccount = useTargetAccountData(userKey);

	const actions = useDriftActions();
	const showModal = actions.showModal;

	const hideModal = () => showModal('showEditSubaccountModal', false);

	const [newAccountName, setNewAccountName] = useState<string>(
		selectedSubAccount.name
	);

	if (!selectedSubAccount) return;

	const isSuperstakeAccount = SUPER_STAKE_ACCOUNT_NAMES.includes(
		selectedSubAccount.name
	);

	const isLpAccount = UI_UTILS.accountNameIsLp(selectedSubAccount.name);

	const buttonDisabled =
		newAccountName.length === 0 ||
		newAccountName.length > maxChars ||
		isSuperstakeAccount ||
		isLpAccount;

	const handleEditAccount = async () => {
		const renameResult = await actions.renameSubaccount(
			selectedSubAccount.userId,
			newAccountName
		);

		if (renameResult === true) {
			set((s) => {
				s.accounts[userKey].name = newAccountName;
			});
			hideModal();
		}
	};

	const charsRemaining = maxChars - newAccountName.length;

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Edit account name</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<div className="flex flex-col items-end">
						<LabelledInput label="Account name">
							<TextField.Default
								type="text"
								value={newAccountName}
								onChange={setNewAccountName}
								disabled={isSuperstakeAccount}
							/>
						</LabelledInput>
						<Utility.VERTSPACERXS />
						<Utility.FORMLABEL02 label={`${charsRemaining} chars remaining`} />

						{charsRemaining < 0 && (
							<>
								<Utility.VERTSPACERM />
								<InfoMessage
									type="error"
									messageTitle="Invalid account name"
									message="You can't exceed the account name character limit"
								/>
							</>
						)}

						{newAccountName.length === 0 && (
							<>
								<Utility.VERTSPACERM />
								<InfoMessage
									type="error"
									messageTitle="Invalid account name"
									message="Account name cannot be empty"
								/>
							</>
						)}
					</div>
					<Modal.ButtonBar>
						<Button.Secondary
							className="w-[50%] flex-grow sm:grow-0"
							onClick={hideModal}
							size="MEDIUM"
						>
							CANCEL
						</Button.Secondary>
						<Button.Secondary
							className="w-[50%] flex-grow sm:grow-0"
							disabled={buttonDisabled}
							onClick={handleEditAccount}
							size="MEDIUM"
							highlight
						>
							SAVE
						</Button.Secondary>
					</Modal.ButtonBar>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default memo(EditSubaccountModal);
