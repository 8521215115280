'use client';

import Modal from './Modal';
import { memo, ReactNode } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftActions from 'src/hooks/useDriftActions';
import Text from '../Text/Text';
import { formatValue } from '../Utils/NumberDisplayV2';
import Utility from '../Inputs/Utility';
import {
	BigNum,
	BN,
	PRICE_PRECISION_EXP,
	SpotBalanceType,
} from '@drift-labs/sdk';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import Tooltip from '../Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import MarketIcon from '../Utils/MarketIcon';
import useAccountData from 'src/hooks/useAccountData';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import Button from '../Button';
import useSetCollateralModalParams from 'src/hooks/useSetCollateralModalParams';
import ToggleableAccountValue from '../ToggleableAccountValue';
import useAprBreakdowns from 'src/hooks/useAprBreakdowns';
import { PYUSD_SPOT_MARKET } from 'src/environmentVariables/EnvironmentVariables';
import { AprBreakdownRow } from '../Tooltip/AprBreakdownTooltip';

const DataRow = ({
	label,
	value,
	secondaryValue,
	tooltip,
}: {
	label: string;
	value: string;
	secondaryValue?: string;
	tooltip?: ReactNode;
}) => (
	<div className="flex justify-between w-full">
		<div className="flex gap-1 text-text-label">
			<Text.BODY2>{label}</Text.BODY2>
			{!!tooltip && (
				<Tooltip content={tooltip} allowHover>
					<Info />
				</Tooltip>
			)}
		</div>
		<div className="flex flex-col justify-end text-right text-text-default">
			<Text.BODY2>{value}</Text.BODY2>
			{!!secondaryValue && <Text.MICRO1>{secondaryValue}</Text.MICRO1>}
		</div>
	</div>
);

const UserDepositDetailsCard = ({
	symbol,
	depositBaseBalance,
}: {
	symbol: string;
	depositBaseBalance: BigNum;
}) => {
	return (
		<div className="py-4 px-2 rounded-[4px] bg-container-bg-selected flex flex-col text-center w-full justify-center items-center">
			<Text.BODY2 className="text-text-label">Your Deposits</Text.BODY2>
			<ToggleableAccountValue
				value={formatValue({
					value: depositBaseBalance,
					displayType: 'asset',
					isInvalid: false,
					assetSymbol: symbol,
					toTradePrecision: true,
				})}
				Component={Text.H3}
				className="text-text-default"
			/>
		</div>
	);
};

const UserBorrowDetailsCard = ({
	symbol,
	borrowLiqPrice,
	borrowBaseBalance,
}: {
	symbol: string;
	borrowLiqPrice: BigNum;
	borrowBaseBalance: BigNum;
}) => {
	return (
		<div className="py-4 px-2 rounded-[4px] bg-container-bg-selected flex w-full divide-x divide-container-border">
			<div className="flex flex-col items-center flex-1 text-center">
				<Text.BODY2 className="text-text-label">Your Borrows</Text.BODY2>
				<ToggleableAccountValue
					value={formatValue({
						value: borrowBaseBalance,
						displayType: 'asset',
						isInvalid: false,
						assetSymbol: symbol,
						toTradePrecision: true,
					})}
					Component={Text.H3}
					className="text-text-default"
				/>
			</div>

			<div className="flex flex-col items-center flex-1 text-center">
				<Text.BODY2 className="flex items-center gap-1 text-text-label">
					Liquidation Price{' '}
					<Tooltip
						content={
							'If the asset reaches this oracle price, your account would begin being liquidated.'
						}
					>
						<Info />
					</Tooltip>
				</Text.BODY2>
				<Text.H3 className="text-text-default">
					{formatValue({
						value: borrowLiqPrice,
						displayType: 'notional',
						isInvalid: false,
					})}
				</Text.H3>
			</div>
		</div>
	);
};

const AssetDetailsModal = () => {
	const aprBreakdowns = useAprBreakdowns();
	const [allBorrowMarketData, selectedAssetDetailsSymbol] = useDriftStore(
		(s) => [s.borrowLendData, s.selectedAssetDetailsSymbol]
	);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const currentAccount = useAccountData();
	const borrowAccountBalances = useAccountSpotBalances(
		currentAccount?.userKey,
		SpotBalanceType.BORROW
	);
	const depositAccountBalances = useAccountSpotBalances(
		currentAccount?.userKey,
		SpotBalanceType.DEPOSIT
	);
	const setCollateralModalParams = useSetCollateralModalParams();
	const actions = useDriftActions();

	const borrowMarketData = allBorrowMarketData.find(
		(market) => market.symbol === selectedAssetDetailsSymbol
	);

	const onClose = () => {
		actions.showModal('showAssetDetailsModal', false);
	};

	const openActionModal =
		(modalName: Parameters<typeof setCollateralModalParams>[0]) => () => {
			setCollateralModalParams(
				modalName,
				borrowMarketData.bankIndex,
				currentAccount?.userKey
			);
		};

	if (!borrowMarketData) {
		onClose();
	}

	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(
				borrowMarketData.bankConfig?.marketIndex
			)) ||
		undefined;

	const maxDeposits = BigNum.from(
		spotMarketAccount?.maxTokenDeposits || new BN(0),
		borrowMarketData.bankConfig?.precisionExp
	);

	const borrowLiqPrice = BigNum.from(
		currentAccount?.client.spotLiquidationPrice(borrowMarketData.bankIndex),
		PRICE_PRECISION_EXP
	);
	const userBorrowBalance = borrowAccountBalances.find(
		(acctBalance) => acctBalance.asset.marketIndex == borrowMarketData.bankIndex
	);
	const userDepositBalance = depositAccountBalances.find(
		(acctBalance) => acctBalance.asset.marketIndex == borrowMarketData.bankIndex
	);

	const marketAprBreakdowns =
		aprBreakdowns[borrowMarketData.bankConfig.marketIndex] ?? [];

	const lendingApr = borrowMarketData.depositApr.mul(BigNum.from(100));

	let displayApr = lendingApr;

	if (
		borrowMarketData.bankConfig.marketIndex === PYUSD_SPOT_MARKET.marketIndex &&
		marketAprBreakdowns?.[0]?.apr
	) {
		displayApr = lendingApr.add(
			BigNum.fromPrint(
				`${marketAprBreakdowns[0].apr}`,
				borrowMarketData.bankConfig.precisionExp
			)
		);
	}

	return (
		<Modal onClose={onClose} sizeConfig={{ xs: 12, sm: 10, md: 6, xl: 4 }}>
			<Modal.Body className="flex flex-col" overflow>
				<Modal.Header onClose={onClose} showX>
					<div className="flex items-center gap-2">
						<MarketIcon
							marketSymbol={selectedAssetDetailsSymbol}
							sizeClass="h-4 w-4 sm:h-6 md:w-6"
						/>
						<span className="mt-0.5">
							{borrowMarketData.symbol} Asset Details
						</span>
					</div>
				</Modal.Header>

				<div className="flex flex-col px-4 py-6 space-y-8">
					<div className="flex flex-col gap-4">
						<Text.H3 className="text-text-default">Deposits</Text.H3>

						<UserDepositDetailsCard
							symbol={selectedAssetDetailsSymbol}
							depositBaseBalance={userDepositBalance?.balance ?? BigNum.zero()}
						/>

						<div className="flex flex-col gap-2">
							<DataRow
								label="Global Deposits"
								value={`${formatValue({
									value: borrowMarketData.totalDepositsBase,
									displayType: 'millified',
									isInvalid: false,
								})} ${selectedAssetDetailsSymbol}`}
								secondaryValue={`${formatValue({
									value: borrowMarketData.totalDepositsQuote,
									displayType: 'notional',
									isInvalid: false,
								})}`}
							/>
							<Utility.VERTDIVIDER />
							<DataRow
								label="Global Max"
								value={
									maxDeposits.eqZero()
										? 'No Deposit Cap'
										: `${formatValue({
												value: maxDeposits,
												displayType: 'millified',
												isInvalid: false,
										  })} ${selectedAssetDetailsSymbol}`
								}
							/>
							<Utility.VERTDIVIDER />
							<DataRow
								label="Deposit APR"
								value={`${formatValue({
									value: displayApr,
									displayType: 'percentage',
									isInvalid: false,
								})} APR`}
								tooltip={
									<div className={`flex flex-col w-[220px]`}>
										<AprBreakdownRow
											source="Lending"
											apr={+lendingApr.toFixed(2)}
										/>
										{marketAprBreakdowns.map((breakdown) => (
											<AprBreakdownRow
												key={breakdown.source}
												source={breakdown.source}
												apr={+breakdown.apr.toFixed(2)}
												helperText={breakdown.helperText}
											/>
										))}
									</div>
								}
							/>
							<Utility.VERTDIVIDER />
							<DataRow
								label="Asset Weight"
								value={`${formatValue({
									value: BigNum.from(borrowMarketData.assetWeight),
									displayType: 'percentage',
									isInvalid: false,
								})}`}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<Text.H3 className="text-text-default">Borrows</Text.H3>

						<UserBorrowDetailsCard
							symbol={selectedAssetDetailsSymbol}
							borrowLiqPrice={
								borrowLiqPrice.gtZero() ? borrowLiqPrice : BigNum.zero()
							}
							borrowBaseBalance={userBorrowBalance?.balance ?? BigNum.zero()}
						/>

						<div className="flex flex-col gap-2">
							<DataRow
								label="Global Borrows"
								value={`${formatValue({
									value: borrowMarketData.totalBorrowsBase,
									displayType: 'millified',
									isInvalid: false,
								})} ${selectedAssetDetailsSymbol}`}
								secondaryValue={`${formatValue({
									value: borrowMarketData.totalBorrowsQuote,
									displayType: 'notional',
									isInvalid: false,
								})}`}
							/>
							<Utility.VERTDIVIDER />
							<DataRow
								label="Available Liquidity"
								value={`${formatValue({
									value: BigNum.max(
										borrowMarketData.borrowLimitBase,
										BigNum.zero()
									),
									displayType: 'millified',
									isInvalid: false,
								})} ${selectedAssetDetailsSymbol}`}
								secondaryValue={`${formatValue({
									value: BigNum.max(
										borrowMarketData.borrowLimitQuote,
										BigNum.zero()
									),
									displayType: 'notional',
									isInvalid: false,
								})}`}
								tooltip={
									<>
										As a safe guard, borrows are throttled based on current
										utilization and TWAP of both deposits and borrows.{' '}
										<a
											href="https://docs.drift.trade/risk-and-safety/risk-parameters"
											rel="noreferrer"
											target="_blank"
										>
											Learn more
										</a>
									</>
								}
							/>
							<Utility.VERTDIVIDER />
							<DataRow
								label="Borrow APR"
								value={`${formatValue({
									value: borrowMarketData.borrowApr.mul(BigNum.from(100)),
									displayType: 'percentage',
									isInvalid: false,
								})} APR`}
							/>
							<Utility.VERTDIVIDER />
							<DataRow
								label="Liability Weight"
								value={`${formatValue({
									value: BigNum.from(borrowMarketData.liabilityWeight),
									displayType: 'percentage',
									isInvalid: false,
									toFixed: 0,
								})}`}
							/>
						</div>
					</div>

					<div className="flex w-full gap-2">
						<Button.Secondary
							size="MEDIUM"
							className="flex-1"
							onClick={openActionModal('showDepositModal')}
						>
							{userBorrowBalance?.balance.gtZero() ? 'Repay' : 'Deposit'}
						</Button.Secondary>
						<Button.Secondary
							size="MEDIUM"
							className="flex-1"
							onClick={openActionModal('showWithdrawModal')}
						>
							Withdraw
						</Button.Secondary>
						<Button.Secondary
							size="MEDIUM"
							className="flex-1"
							onClick={openActionModal('showBorrowModal')}
						>
							Borrow
						</Button.Secondary>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default memo(AssetDetailsModal);
