'use client';

import { useEffect } from 'react';
import useMarketStateStore from '../../stores/useMarketStateStore';
import useMarketsForDlobToTrack, {
	MarketStatePriority,
} from '../useMarketsForDlobToTrack';

export const useTrackDlobSourceMetrics = () => {
	const marketsToSubscribe = useMarketsForDlobToTrack();
	const marketSubscriptionCategories = marketsToSubscribe.categories;
	const currentMarketSubscriptionMarketId =
		marketSubscriptionCategories[MarketStatePriority.SelectedMarket][0];
	const currentmarketDlobSource = useMarketStateStore(
		(s) =>
			s.marketSubscriptionState?.[currentMarketSubscriptionMarketId?.key]
				?.listeningSelection
	);

	useEffect(() => {
		if (!currentmarketDlobSource) return;

		window.UNCONSUMED_GRAFANA_METRICS.SELECTED_MARKET_DLOB_SOURCE =
			currentmarketDlobSource;
		window.UNCONSUMED_GRAFANA_METRICS.SELECTED_MARKET_ID =
			currentMarketSubscriptionMarketId.key;
	}, [currentmarketDlobSource]);
};
