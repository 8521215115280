'use client';

import {
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	MarketStatus,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
	ZERO,
	calculateMaxRemainingDeposit,
} from '@drift-labs/sdk';
import { ENUM_UTILS, ONE_DAY_MS, UIMarket } from '@drift/common';
import React, { useEffect, useMemo, useState } from 'react';
import { Info, Open } from '@drift-labs/icons';
import { MIN_LEFTOVER_SOL } from 'src/constants/constants';
import Env, {
	OrderedSpotMarkets,
	isDev,
} from 'src/environmentVariables/EnvironmentVariables';
import useAccountsBeingLiquidated from 'src/hooks/Liquidations/useAccountsBeingLiquidated';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useAccountExists from 'src/hooks/useAccountExists';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useDisplayPrecisionForCollateral from 'src/hooks/useDisplayPrecisionForCollateral';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import UI_UTILS from 'src/utils/uiUtils';
import useTargetAccountData from '../hooks/useTargetAccountData';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import Button from './Button';
import { AmountSelector, CollateralInput } from './Inputs/CollateralInput';
import LabelledInput from './Inputs/LabelledInput';
import Select from './Inputs/Select';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import DepositGuidesDropdown from './Onboarding/DepositGuidesDropdown';
import Text from './Text/Text';
import InfoMessage from './TradeForm/InfoMessage';
import ValueDisplay from './ValueDisplay';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import useWalletBalancesStore from 'src/stores/useWalletBalancesStore';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import NewAccountCreationCost from './Utils/NewAccountCreationCost';
import useNumberOfAccountsCreatedLast24h from 'src/hooks/utils/useNumberOfAccountsCreatedLast24h';
import { MaxSubaccountsWarning } from './Utils/MaxSubaccountsWarning';
import { useMaxSubaccountsReached } from 'src/hooks/useMaxSubaccountsReached';
import { twMerge } from 'tailwind-merge';
import { BASE_NAVIGATION_OPTIONS } from './Navigation';
import { useExceedsMaxSpotBalance } from 'src/hooks/useMaxSpotBalance';
import { MAX_SPOT_POSITIONS_ERROR_MESSAGE } from 'src/constants/markets';

const MIN_DEPOSIT_AMOUNT_FOR_NEW_ACCT = Env.minInitialDepositValue;

const SHOW_FAUCET_BUTTON = isDev();

const DepositModalContent = (props: { onClose: () => void }) => {
	// # Constants
	const borrowData = useDriftStore((s) => s.borrowLendData);
	const availableSubaccounts = useLazySubAccounts();
	const actions = useDriftActions();
	const accountExists = useAccountExists();
	const selectedTargetAccountKey = useDriftStore(
		(s) => s.modalTargetAccountKey
	);
	const targetAccount = useTargetAccountData(selectedTargetAccountKey);
	const currentKey = targetAccount?.userKey ?? '';
	const accountsBeingLiquidated = useAccountsBeingLiquidated();
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const setState = useDriftStore((s) => s.set);
	const setAccountState = useDriftAccountStore((s) => s.set);
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const { captureEvent } = usePostHogCapture();
	const maxSubaccountsReached = useMaxSubaccountsReached();

	const collateralTypeIndex = useDriftStore((s) => s.modalCollateralType);
	const defaultMax = useDriftStore(
		(s) => s.modalsProps['showDepositModal']?.defaultMax
	);
	const biggestAssetMarket = useWalletBalancesStore((s) =>
		s.getBiggestAssetMarket()
	);

	const { totalCost: totalAccountCreationCost } = useAccountCreationCost();

	const solRequiredToDeposit = (
		accountExists ? BigNum.zero(BASE_PRECISION_EXP) : totalAccountCreationCost
	).add(MIN_LEFTOVER_SOL);
	const hasEnoughSolInWallet = solBalance.gte(solRequiredToDeposit);

	// # State
	const [targetAccountKey, setTargetAccountKey] = useState(currentKey);

	const [selectedCollatBank, setSelectedCollatBank] =
		useState<SpotMarketConfig>(
			defaultMax ? biggestAssetMarket : OrderedSpotMarkets[collateralTypeIndex]
		);

	const [submitting, setSubmitting] = useState(false);

	const [maxButtonTransition, setMaxButtonTransition] = useState(false);

	const [collatTransferAmount, setCollatTransferAmount] = useState('');

	const [airdropping, setAirdropping] = useState(false);

	const [isMaxRepay, setIsMaxRepay] = useState(false);

	const [userHasAgreedToFee, setUserHasAgreedToFee] = useState(false);

	const {
		numberOfAccountsCreatedLast24h,
		lastCreatedAccountTimestamp,
		incrementNumberOfAccountsCreated,
	} = useNumberOfAccountsCreatedLast24h();

	const exceedsMaxSpotBalance = useExceedsMaxSpotBalance(
		selectedCollatBank.marketIndex
	);

	const hitMaxNumberOfAccountsIn24h =
		!accountExists &&
		Date.now() - lastCreatedAccountTimestamp < ONE_DAY_MS &&
		numberOfAccountsCreatedLast24h >= Env.maxNumberOfAccountsPer24h;

	// # State-Dependent constants
	const currentCollatBalanceBigNum = useWalletBalancesStore(
		(s) =>
			s.balances[selectedCollatBank.symbol]?.base ??
			BigNum.zero(selectedCollatBank.precisionExp)
	);

	const collateralDisplayPrecision =
		useDisplayPrecisionForCollateral(selectedCollatBank);

	const uiMarket = UIMarket.createSpotMarket(selectedCollatBank.marketIndex);
	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(selectedCollatBank.marketIndex)) ||
		undefined;

	const interestRateBigNum =
		borrowData
			?.find((item) => item.bankIndex == selectedCollatBank.marketIndex)
			?.depositApr.mul(new BN(100)) ?? BigNum.zero();

	const [afterDepositInterestRateBigNum, setAfterDepositInterestRateBigNum] =
		useState(interestRateBigNum);

	const borrowMarket = borrowData.find(
		(item) => item.bankIndex == selectedCollatBank.marketIndex
	);
	const currentDeposits = borrowMarket.totalDepositsBase;

	const maxDepositsBn = spotMarketAccount?.maxTokenDeposits || new BN(0);

	const maxDepositsBigNum = BigNum.from(
		maxDepositsBn,
		selectedCollatBank.precisionExp
	);

	const walletDepositableBalanceBigNum = useTransferableWalletCollateralBalance(
		selectedCollatBank,
		!accountExists
	);
	const remainingDepositsAllowed = useMemo(
		() =>
			BigNum.from(
				calculateMaxRemainingDeposit(spotMarketAccount),
				selectedCollatBank.precisionExp
			),
		[selectedCollatBank.precisionExp, spotMarketAccount]
	);

	const showDepositCap =
		maxDepositsBn.gt(ZERO) && // When maxDeposits = 0 then there is an infinite deposit cap on the market. Therefore don't show it.
		walletDepositableBalanceBigNum.gt(remainingDepositsAllowed);

	const effectiveDepositableBalanceBigNum = maxDepositsBn.eq(ZERO)
		? walletDepositableBalanceBigNum
		: BigNum.min(remainingDepositsAllowed, walletDepositableBalanceBigNum);

	const collatTransferAmountBignum = BigNum.fromPrint(
		collatTransferAmount ? collatTransferAmount : '0',
		selectedCollatBank.precisionExp
	);

	const isMaxSolDeposit =
		collatTransferAmount !== '' &&
		collatTransferAmountBignum.eq(walletDepositableBalanceBigNum) &&
		selectedCollatBank.symbol === 'SOL';

	const targetAccountBankBalance = useAccountTargetSpotBalance(
		selectedCollatBank,
		targetAccountKey
	);

	const targetAccountCurrentNetBaseBalance =
		targetAccountBankBalance.netBaseBalance;

	const targetAccountTotalNetQuoteValue = BigNum.from(
		targetAccount?.client?.getNetSpotMarketValue() ?? 0,
		QUOTE_PRECISION_EXP
	);

	const oraclePrice = useMemoizedOraclePrice(uiMarket.marketId);

	const assetCurrentPrice =
		oraclePrice?.shiftTo(QUOTE_PRECISION_EXP) ??
		BigNum.zero(QUOTE_PRECISION_EXP);

	const quoteAmountToAdd =
		selectedCollatBank.symbol == 'USDC'
			? collatTransferAmountBignum.shiftTo(QUOTE_PRECISION_EXP)
			: collatTransferAmountBignum
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(assetCurrentPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

	const selectedAccountCanbeLiqd = accountsBeingLiquidated.find(
		(account) => account.userKey === selectedTargetAccountKey
	);

	const liquidationDepositAmount = selectedAccountCanbeLiqd
		? BigNum.from(
				selectedAccountCanbeLiqd.client
					.getMaintenanceMarginRequirement()
					.sub(selectedAccountCanbeLiqd.client.getTotalCollateral()),
				QUOTE_PRECISION_EXP
		  )
		: BigNum.zero(QUOTE_PRECISION_EXP);

	const fuelEarnRate =
		(Env.fuelBaseEarnRates?.deposit || 1) *
			(spotMarketAccount.fuelBoostDeposits || 0) || 0;
	const fuelEarnAmount = quoteAmountToAdd.mul(
		BigNum.from(new BN(fuelEarnRate))
	);

	const updateCollateralType = (newCollat: SpotMarketConfig) => {
		setSelectedCollatBank(newCollat);
		setState((s) => {
			s.modalCollateralType = newCollat.marketIndex;
		});
	};

	// # Effect Hooks
	useEffect(() => {
		captureEvent('opened_deposit_modal');
	}, []);

	// reset defaultMax after modal is closed so other triggers that open deposit modal won't default to max
	useEffect(() => {
		if (defaultMax) {
			setState((s) => {
				s.modalsProps['showDepositModal'].defaultMax = false;
			});
		}
	}, [defaultMax]);

	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setCollatTransferAmount('');
	}, [selectedCollatBank?.marketIndex]);

	// keep the new interest rate updated with the borrow amount the user inputs
	useEffect(() => {
		if (spotMarketAccount) {
			const newInterestRateBigNum = UI_UTILS.getDepositRateFromDelta(
				collatTransferAmountBignum.val,
				spotMarketAccount
			);
			setAfterDepositInterestRateBigNum(newInterestRateBigNum);
		}
	}, [collatTransferAmountBignum.toNum(), spotMarketAccount.marketIndex]);

	// # Methods
	const handleDeposit = async () => {
		setSubmitting(true);
		actions.addLoadingItemToQueue({ key: 'modal' });
		await depositCollateral(targetAccountKey);

		setSubmitting(false);
		actions.removeLoadingItemFromQueue({ key: 'modal' });
		props.onClose();
	};

	const handleValueChange = (newValue: string) => {
		setIsMaxRepay(false);
		setCollatTransferAmount(newValue);
	};

	const handleMaxRepay = () => {
		setCollatTransferAmount(
			targetAccountBankBalance.netBaseBalance.abs().printShort()
		);
		setIsMaxRepay(true);
	};

	const depositCollateral = async (accountKey: string) => {
		const collateralAmount = BigNum.fromPrint(
			collatTransferAmount,
			selectedCollatBank.precisionExp
		);

		if (accountExists) {
			captureEvent('submitted_deposit', {
				spot_market_symbol: selectedCollatBank.symbol,
				newAccount: false,
				depositAmount: collateralAmount.toNum(),
			});
			await actions.depositCollateralForExistingAccount(
				collateralAmount,
				selectedCollatBank,
				accountKey,
				isMaxRepay
			);
		} else {
			captureEvent('submitted_deposit', {
				spot_market_symbol: selectedCollatBank.symbol,
				newAccount: true,
				depositAmount: collateralAmount.toNum(),
			});

			const success = await actions.initializeAndDepositCollateralForAccount(
				collateralAmount,
				selectedCollatBank,
				undefined,
				undefined
			);

			if (success) {
				incrementNumberOfAccountsCreated();
			}
		}

		setState((s) => {
			s.userInfoTable.currentTab = 'balances';
		});
		return;
	};

	const handleTargetAccountChange = (newAccountKey: string) => {
		setTargetAccountKey(newAccountKey);
		setState((s) => {
			s.modalTargetAccountKey = newAccountKey;
		});
		setAccountState((s) => {
			s.currentUserKey = newAccountKey;
		});
	};

	const handleAirdrop = async () => {
		if (airdropping) return;
		setAirdropping(true);
		await actions.tryFaucetAirdrop(selectedCollatBank);
		setAirdropping(false);
	};

	const exceededMax = collatTransferAmountBignum.gt(
		effectiveDepositableBalanceBigNum
	);

	const exceedsMinDeposit =
		collatTransferAmountBignum
			.shiftTo(QUOTE_PRECISION_EXP)
			.mul(assetCurrentPrice)
			.toNum() >= MIN_DEPOSIT_AMOUNT_FOR_NEW_ACCT;

	const enforceMinimumDeposit = !exceedsMinDeposit && !accountExists;

	const allowConfirmation =
		!exceededMax &&
		effectiveDepositableBalanceBigNum.gtZero() &&
		!submitting &&
		collatTransferAmount &&
		collatTransferAmountBignum.gt(
			BigNum.zero(collatTransferAmountBignum.precision),
			true
		) &&
		!enforceMinimumDeposit &&
		hasEnoughSolInWallet &&
		(userHasAgreedToFee || accountExists) &&
		(accountExists ||
			(!hitMaxNumberOfAccountsIn24h && !maxSubaccountsReached)) &&
		!exceedsMaxSpotBalance;

	useEffect(() => {
		if (exceededMax) {
			setCollatTransferAmount(effectiveDepositableBalanceBigNum.print());
		}
	}, [exceededMax]);

	const marketReduceOnly = ENUM_UTILS.match(
		spotMarketAccount.status,
		MarketStatus.REDUCE_ONLY
	);

	// # Rendering
	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					{SHOW_FAUCET_BUTTON && (
						<div className="flex flex-col items-start">
							<Button.Secondary
								size="SMALL"
								onClick={handleAirdrop}
								disabled={airdropping}
								highlight
							>
								Airdrop {selectedCollatBank.symbol}
							</Button.Secondary>
							<Utility.VERTSPACERL />
						</div>
					)}

					<div className="items-center block">
						{marketReduceOnly ? (
							<Text.P1 className="mr-1 text-warn-yellow">
								New deposits are currently disabled for this market; only borrow
								repayments are allowed.
							</Text.P1>
						) : (
							<>
								<Text.P1 className="mr-1 text-text-emphasis">
									Deposited assets automatically earn yield through lending.
								</Text.P1>
								<div
									className={twMerge(
										'flex items-center divide-x divide-container-border [&>*]:px-2 [&>*:first-child]:pl-0 whitespace-nowrap'
									)}
								>
									<a
										href="https://docs.drift.trade/lend-borrow/what-is-lend-borrow"
										target="_blank"
										rel="noreferrer"
									>
										<Text.BODY2>Learn more</Text.BODY2>
									</a>
									<DepositGuidesDropdown />
								</div>
							</>
						)}
					</div>

					<Utility.VERTSPACERL />

					{availableSubaccounts.length > 1 && (
						<>
							<LabelledInput label="Deposit to">
								<Select.Subaccount
									id="depositAccountSelection"
									onChange={handleTargetAccountChange}
									options={availableSubaccounts}
									initialSelection={targetAccountKey}
									includeDelegates
								/>
							</LabelledInput>
							<Utility.VERTSPACERXL />
						</>
					)}

					<CollateralInput
						selectedMarket={selectedCollatBank}
						maxAmount={effectiveDepositableBalanceBigNum}
						currentBalance={currentCollatBalanceBigNum}
						onChangeMarket={updateCollateralType}
						label="Transfer type and Amount"
						customElementRight={
							<Text.MICRO3 className="inline-flex items-center text-text-secondary hover:cursor-pointer">
								<div className="mt-0.5">
									{`Deposit APR ${interestRateBigNum?.prettyPrint() ?? 0}%`}
								</div>
								<Open
									size={14}
									onClick={() => {
										BASE_NAVIGATION_OPTIONS.borrowLend.handler();
										props.onClose();
									}}
									className="ml-1"
								/>
							</Text.MICRO3>
						}
						value={collatTransferAmount}
						onChangeValue={handleValueChange}
						amountLabel={'Wallet balance'}
						source={{
							type: 'wallet',
						}}
					/>

					{isMaxSolDeposit && (
						<>
							<Utility.VERTSPACERXS />
							<div className="text-text-label">
								<Text.BODY3 className="text-xs leading-4">
									We hold back {MIN_LEFTOVER_SOL.toFixed(2)} SOL on max deposits
									to cover transaction fees
								</Text.BODY3>
							</div>
						</>
					)}

					{!accountExists ? (
						hitMaxNumberOfAccountsIn24h ? (
							<>
								<Utility.VERTSPACERXS />
								<InfoMessage
									type="warn"
									messageTitle=""
									message={
										<>
											You can only create 2 new Drift accounts every 24 hours.
											Please connect a different wallet with an existing account
											or try again later.
										</>
									}
								/>
							</>
						) : maxSubaccountsReached ? (
							<>
								<Utility.VERTSPACERXS />
								<MaxSubaccountsWarning />
							</>
						) : (
							<>
								<Utility.VERTSPACERXS />
								<NewAccountCreationCost
									checkboxUpdated={(selection) =>
										setUserHasAgreedToFee(selection)
									}
									forceAgreement={true}
								/>
								<Utility.VERTSPACERXS />
							</>
						)
					) : !hasEnoughSolInWallet ? (
						<>
							<InfoMessage
								type="warn"
								messageTitle={
									<div className="flex items-center space-x-1">
										<span>Not enough SOL in your wallet</span>
									</div>
								}
								message={
									<>
										{`You need to have ${solRequiredToDeposit.prettyPrint(
											true
										)} SOL in your wallet to cover account creation and transaction fees.`}
									</>
								}
							/>
						</>
					) : (
						<></>
					)}

					{targetAccountBankBalance.netBaseBalance.isNeg() && (
						<div>
							<AmountSelector
								symbol={selectedCollatBank.symbol}
								maxAmount={targetAccountBankBalance.netBaseBalance.abs()}
								label="Transfer type and Amount"
								onChangeValue={handleValueChange}
								amountLabel="Borrows"
								isNegative
								onMax={handleMaxRepay}
							/>
							<Text.MICRO1 className="inline-flex items-center w-full h-full px-1 py-2 border-t text-text-default bg-th-blue-70 border-container-border">
								<Info className="w-4 h-4 mr-1 text-text-label" />
								<div className="mt-0.5">
									{`${selectedCollatBank.symbol} deposits will automatically go towards repaying your borrows`}
								</div>
							</Text.MICRO1>
						</div>
					)}
					{showDepositCap && (
						<>
							<Utility.VERTSPACERM />
							<ValueDisplay.Default
								label={`Global ${selectedCollatBank.symbol} Deposits/Max`}
								value={`${currentDeposits.toMillified()} / ${maxDepositsBigNum.toMillified()} ${
									selectedCollatBank.symbol
								}`}
							/>
						</>
					)}

					<Utility.VERTSPACERL />

					{selectedAccountCanbeLiqd && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="warn"
								messageTitle=""
								message={
									<>
										This account is currently being liquidated. To stop
										liquidation deposit{' '}
										{liquidationDepositAmount.abs().toNotional()} or more in
										assets
									</>
								}
							/>
						</>
					)}

					{exceedsMaxSpotBalance && (
						<InfoMessage
							type="error"
							messageTitle={MAX_SPOT_POSITIONS_ERROR_MESSAGE.title}
							message={MAX_SPOT_POSITIONS_ERROR_MESSAGE.message}
						/>
					)}

					<Utility.VERTSPACERM />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />
					<ValueDisplay.ValueChange
						className={'h-6'}
						label="Asset Balance"
						previousValue={targetAccountCurrentNetBaseBalance}
						afterValue={
							isMaxRepay
								? BigNum.zero(selectedCollatBank.precisionExp)
								: targetAccountCurrentNetBaseBalance.add(
										collatTransferAmountBignum
								  )
						}
						previousValuePrint={`${targetAccountCurrentNetBaseBalance.toFixed(
							collateralDisplayPrecision
						)} ${selectedCollatBank.symbol}`}
						afterValuePrint={
							isMaxRepay
								? `0 ${selectedCollatBank.symbol}`
								: `${targetAccountCurrentNetBaseBalance
										.add(collatTransferAmountBignum)
										.toFixed(collateralDisplayPrecision)} ${
										selectedCollatBank.symbol
								  }`
						}
					/>

					<ValueDisplay.ValueChange
						className={'h-6'}
						label="Net Account Balance (USD)"
						previousValue={targetAccountTotalNetQuoteValue}
						afterValue={targetAccountTotalNetQuoteValue.add(quoteAmountToAdd)}
						previousValuePrint={UI_UTILS.toNotional(
							targetAccountTotalNetQuoteValue.toNum()
						)}
						afterValuePrint={UI_UTILS.toNotional(
							targetAccountTotalNetQuoteValue.add(quoteAmountToAdd).toNum()
						)}
					/>

					{fuelEarnAmount.gtZero() && (
						<>
							<ValueDisplay.Default
								label="FUEL earn"
								className={'h-6'}
								value={
									<span className="flex flex-row items-center gap-1">
										<img
											src="/assets/images/fuel/fuel-droplet.svg"
											alt="Fuel droplet"
											className="w-5 h-5"
										/>
										<Text.BODY3>
											{fuelEarnAmount.toMillified()} FUEL for every 28 days
											deposited
										</Text.BODY3>
									</span>
								}
							/>
						</>
					)}

					<ValueDisplay.ValueChange
						className={'h-6'}
						label="Interest Rate"
						previousValue={interestRateBigNum}
						afterValue={afterDepositInterestRateBigNum}
						previousValuePrint={`${interestRateBigNum.prettyPrint()}%`}
						afterValuePrint={`${afterDepositInterestRateBigNum.prettyPrint()}%`}
					/>

					<Utility.VERTSPACERL />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleDeposit}
						customLabel={
							enforceMinimumDeposit
								? `Minimum Initial Deposit: $${MIN_DEPOSIT_AMOUNT_FOR_NEW_ACCT}`
								: `Confirm Deposit${
										targetAccount?.isDelegatedTo ? ' To Delegated Account' : ''
								  }`
						}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(DepositModalContent);
