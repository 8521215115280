import { ResponsiveContainer, CartesianGrid, Area, AreaChart } from 'recharts';
import { twMerge } from 'tailwind-merge';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';

const data = [
	{ name: 'A', value: 200 },
	{ name: 'B', value: 300 },
	{ name: 'C', value: 250 },
	{ name: 'D', value: 278 },
	{ name: 'E', value: 359 },
	{ name: 'F', value: 329 },
	{ name: 'G', value: 439 },
	{ name: 'H', value: 600 },
];

export const LoadingChart = ({
	yAxisLabelClassName = 'w-12 h-4',
	xAxisLabelClassName = 'flex-1 h-4',
	className,
}: {
	yAxisLabelClassName?: string;
	xAxisLabelClassName?: string;
	className?: string;
}) => {
	return (
		<div className={twMerge('w-full h-[200px]', className)}>
			<div className="flex flex-col w-full h-full gap-2">
				<div className="flex gap-2 grow">
					<div className="flex flex-col justify-between">
						{/** Y-axis loading skeletons */}
						{Array.from({ length: 5 }).map((_, index) => (
							<SkeletonValuePlaceholder
								key={index}
								className={yAxisLabelClassName}
								loading
							/>
						))}
					</div>
					<div className="grow animate-pulse">
						{/** Faux Graph */}
						<ResponsiveContainer width="100%" height="100%">
							{/* @ts-ignore */}
							<AreaChart data={data} overflow={'visible'}>
								<CartesianGrid stroke={'var(--stroke-secondary)'} />
								<Area
									type="monotone"
									dataKey={'value'}
									strokeWidth={2}
									dot={false}
									className="z-50"
									stroke="var(--stroke-secondary)"
									fill={'var(--stroke-secondary)'}
								/>
							</AreaChart>
						</ResponsiveContainer>
					</div>
				</div>

				<div className="flex w-full gap-2 py-1">
					<div className={twMerge(yAxisLabelClassName, 'shrink-0')}>
						{/** Faux Y-axis */}
					</div>
					<div className="flex justify-between w-full gap-2">
						{/** X-axis loading skeletons */}
						{Array.from({ length: 7 }).map((_, index) => (
							<SkeletonValuePlaceholder
								key={index}
								className={xAxisLabelClassName}
								loading
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
