'use client';

import useDriftActions from 'src/hooks/useDriftActions';
import Modal from '../Modals/Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { Typo } from '../Text/Typo';
import { PredictionMarketIcon } from './PredictionMarketIcon';
import { PredictionMarketClaimPnlCard } from './PredictionMarketClaimPnlCard';
import { useTargetPerpMarketAccount } from 'src/hooks/useTargetMarketAccount';

export const PredictionMarketClaimPnlModal = () => {
	const actions = useDriftActions();
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);

	const marketAccount = useTargetPerpMarketAccount(selectedMarket.marketId);

	const predictionMarketConfig =
		PredictionMarketConfigs.getConfigFromMarketSymbol(selectedMarket.symbol);

	const onClose = () => {
		actions.showModal('showPredictionMarketsClaimPnlModal', false);
	};

	return (
		<Modal onClose={onClose}>
			<Modal.Body className="flex flex-col">
				<Modal.Header onClose={onClose} showX>
					<div className="flex items-center gap-4">
						<PredictionMarketIcon
							symbol={selectedMarket.symbol}
							className="w-8 h-8"
						/>
						<Typo.T3>{predictionMarketConfig?.title}</Typo.T3>
					</div>
				</Modal.Header>

				<PredictionMarketClaimPnlCard marketAccount={marketAccount} mobile />
			</Modal.Body>
		</Modal>
	);
};
