import React from 'react';
import { DRIFT_DISCORD_URL } from '../../constants/constants';

const Discord = (props: { className?: string; color?: string }) => {
	return (
		<a href={DRIFT_DISCORD_URL} target="_blank" rel="noreferrer noopener">
			<div className={`${props.className ?? ''}`}>
				<svg
					className="h-full"
					viewBox="0 0 14 11"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.4148 0.96113C10.5544 0.553152 9.63326 0.253053 8.67033 0.082408C8.65324 0.0784851 8.63614 0.0882923 8.62665 0.103984C8.50889 0.321703 8.37594 0.60611 8.28478 0.829714C7.24778 0.668876 6.21647 0.668876 5.20226 0.829714C5.11109 0.602188 4.97434 0.321703 4.85469 0.103984C4.84519 0.0882923 4.8281 0.0804466 4.81101 0.082408C3.84807 0.253053 2.92693 0.553152 2.06655 0.96113C2.05896 0.965053 2.05326 0.970937 2.04756 0.976821C0.30023 3.67379 -0.178388 6.30407 0.0552232 8.90297C0.0571224 8.91474 0.0628203 8.92847 0.0723166 8.93631C1.22518 9.81111 2.34385 10.3427 3.43973 10.6938C3.45682 10.6996 3.47582 10.6918 3.48721 10.6781C3.74741 10.3113 3.97722 9.92684 4.17665 9.52082C4.18804 9.49728 4.17665 9.46982 4.15386 9.46002C3.7873 9.31683 3.43783 9.1403 3.10166 8.9422C3.07507 8.92651 3.07317 8.88728 3.09786 8.86766C3.16813 8.81274 3.23841 8.75586 3.30678 8.69898C3.31818 8.68917 3.33527 8.68721 3.35046 8.6931C5.55742 9.73462 7.94671 9.73462 10.1271 8.6931C10.1423 8.68525 10.1594 8.68721 10.1708 8.69898C10.2391 8.75586 10.3094 8.8147 10.3797 8.86766C10.4044 8.88728 10.4025 8.92651 10.3759 8.9422C10.0397 9.14423 9.69024 9.31683 9.32368 9.46002C9.29899 9.46982 9.2895 9.49728 9.30089 9.52278C9.50411 9.9288 9.73393 10.3132 9.98843 10.68C9.99982 10.6957 10.0188 10.7016 10.0359 10.6957C11.1375 10.3446 12.2543 9.81307 13.409 8.93828C13.4185 8.93043 13.4261 8.91866 13.4261 8.90689C13.7072 5.90198 12.9551 3.29327 11.4319 0.980744C11.43 0.968975 11.4224 0.963091 11.4148 0.96113ZM4.50712 7.32206C3.84238 7.32206 3.29539 6.69244 3.29539 5.91767C3.29539 5.1429 3.83288 4.51328 4.50712 4.51328C5.18706 4.51328 5.73026 5.14879 5.71886 5.91767C5.71886 6.69047 5.18136 7.32206 4.50712 7.32206ZM8.98751 7.32206C8.32276 7.32206 7.77577 6.69244 7.77577 5.91767C7.77577 5.1429 8.31327 4.51328 8.98751 4.51328C9.66745 4.51328 10.2106 5.14879 10.1992 5.91767C10.1992 6.69047 9.66745 7.32206 8.98751 7.32206Z"
						fill={props.color ?? '#6B6F74'}
					/>
				</svg>
			</div>
		</a>
	);
};

export default Discord;
