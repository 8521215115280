'use client';

import { MarketType, PublicKey, SerumSubscriber } from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId } from '@drift/common';
import { useEffect, useRef } from 'react';
import Env, {
	CurrentSpotMarkets,
	syncGetCurrentSettings,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useMarketStateStore from '../../stores/useMarketStateStore';
import useDriftClientIsReady from '../useDriftClientIsReady';
import {
	MarketDlobLiquidityCategorisation,
	MarketStatePriority,
} from '../useMarketsForDlobToTrack';

const DLOB_LEVELS_TO_TRACK: (keyof MarketDlobLiquidityCategorisation)[] = [
	MarketStatePriority.BackgroundDeep,
	MarketStatePriority.SelectedMarket,
	MarketStatePriority.BackgroundShallow,
];

/**
 * Given a list of markets to track (categorised into DLOB levels), this hook will make sure that the appropriate serum subscribers are in the store and subscribed or unsubscribed.
 * @param marketsToTrack
 */
const useSyncSerumSubscriberList = (
	marketsToTrack: MarketDlobLiquidityCategorisation
) => {
	const connection = useDriftStore((s) => s.connection);
	const set = useMarketStateStore((s) => s.set);
	const driftClientIsReady = useDriftClientIsReady();
	const previouslySubscribedMarkets = useRef<MarketId[]>([]);
	const getSerumSubscriberFromStore = useMarketStateStore(
		(s) => s.getSerumSubscriberForMarket
	);
	const serumEnabled = useMarketStateStore((s) => s.serumEnabled);

	const generateSerumSubscriber = async (marketIndex: number) => {
		const market = CurrentSpotMarkets.find(
			(spotMkt) => spotMkt.marketIndex === marketIndex
		);

		if (!market.serumMarket) {
			return undefined;
		}

		const settings = syncGetCurrentSettings();

		try {
			const serumSubscriber = new SerumSubscriber({
				connection: connection.current,
				programId: new PublicKey(Env.serumAddress),
				marketAddress: market.serumMarket,
				accountSubscription: {
					type: settings.accountSubscriberType,
					accountLoader: connection.accountLoader,
				},
			});
			return serumSubscriber;
		} catch (e) {
			console.log('Error setting serumSubscriber ', e);
			return undefined;
		}
	};

	const handleSubscribingNewMarket = async (marketId: MarketId) => {
		// Create the serum subscriber
		const subscriber = await generateSerumSubscriber(marketId.marketIndex);

		if (!subscriber) return;

		// Do the subscription
		await subscriber.subscribe();

		// Add it to the subscriber store
		set((s) => {
			s.serumSubscribers[marketId.key] = subscriber;
		});

		previouslySubscribedMarkets.current.push(marketId);
	};

	const handleUnsubscribingNewMarket = (marketId: MarketId) => {
		const previousSubscriber = getSerumSubscriberFromStore(marketId);
		if (!previousSubscriber) return;

		// Unsubscribe the previous subscriber
		previousSubscriber.unsubscribe();

		// Remove it from the store
		set((s) => {
			delete s.serumSubscribers[marketId.key];
		});
	};

	const isPreviouslySubscribed = (marketId: MarketId) => {
		return (
			previouslySubscribedMarkets.current.find((market) =>
				market.equals(marketId)
			) ?? false
		);
	};

	useEffect(() => {
		if (!driftClientIsReady) return;

		(async () => {
			const marketsWhichShouldBeSubscribed = serumEnabled
				? Object.entries(marketsToTrack)
						.filter(([key]) => {
							//@ts-ignore
							return DLOB_LEVELS_TO_TRACK.includes(key);
						})
						.map(([_key, val]) => val)
						.flat()
						.filter((mkt) => ENUM_UTILS.match(mkt.marketType, MarketType.SPOT))
				: [];

			// Want to find markets to unsubscrbe from .. these are the markets which ARE previously subscribed but not in the list of expected subscribed markets
			const marketsToUnsubscribe = previouslySubscribedMarkets.current.filter(
				(mkt) =>
					!marketsWhichShouldBeSubscribed.find((mktToBeSubscribed) =>
						mktToBeSubscribed.equals(mkt)
					)
			);

			// Want to find markets to newly subscribe to
			const marketsToNewlySubscribe = marketsWhichShouldBeSubscribed.filter(
				(mkt) => !isPreviouslySubscribed(mkt)
			);

			marketsToUnsubscribe.forEach((marketId) =>
				handleUnsubscribingNewMarket(marketId)
			);

			marketsToNewlySubscribe.forEach((marketId) =>
				handleSubscribingNewMarket(marketId)
			);
		})();
	}, [driftClientIsReady, marketsToTrack]);
};

export default useSyncSerumSubscriberList;
