'use client';

import { ExchangeStatus } from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import { usePathname } from 'next/navigation';
import React, { useCallback, useEffect } from 'react';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { useLocalStorageStringState } from 'src/hooks/useLocalStorageState';
import usePnlModalDisplayListener from 'src/hooks/usePnlModalDisplayListener';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import { useShallow } from 'zustand/react/shallow';
import useShowInsuranceWarningForMarket from '../hooks/useShowInsuranceWarningForMarket';
import DevModal from './DevModal';
import { DownloadModal } from './Downloader';
import AccountHealthModal from './Modals/AccountHealthModal';
import AssetDetailsModal from './Modals/AssetDetailsModal';
import CollateralModal from './Modals/CollateralModal';
import CreateReferralLinkModal from './Modals/CreateReferralLinkModal';
import DelegateModal from './Modals/DelegateModal';
import DeleteSubaccountModal from './Modals/DeleteSubaccountModal';
import EditOrderModal from './Modals/EditOrderModal';
import EditSubaccountModal from './Modals/EditSubaccountModal';
import ExchangePausedModal from './Modals/ExchangePausedModal';
import InsuranceFundDetailsModal from './Modals/InsuranceFundDetailsModal';
import InsuranceFundRevenueModal from './Modals/InsuranceFundRevenueModal';
import LPDetailsModal from './Modals/LPDetailsModal';
import LPModal from './Modals/LPModal';
import LpPerformanceModal from './Modals/LpPerformanceModal';
import MarketDetailsModal from './Modals/MarketDetailsModal';
import NewLpSubaccountModal from './Modals/NewLpSubaccountModal';
import NewSubaccountModal from './Modals/NewSubaccountModal';
import NoInsuranceFundForMarketWarningModal from './Modals/NoInsuranceFundForMarketWarningModal';
import PnlModal from './Modals/PnlModal';
import SettingsModal from './Modals/SettingsModal';
import StakeModal from './Modals/StakeModal';
import TradeDetailsModal from './Modals/TradeDetailsModal';
import UserCumFundingModal from './Modals/UserCumFundingModal';
import AccountSidebarModal from './Modals/AccountDrawer/AccountDrawer';
import SendReceiveModal from './Modals/SendFromWalletModal';
import ReceiveToWalletModal from './Modals/ReceiveToWalletModal';
import PageBottomLoadingBar from './PageBottomLoadingBar/PageBottomLoadingBar';
import FeatureAlerts from './PopupAlert/FeatureAlerts';
import EvmWalletsSelectorModal from './Modals/EvmWalletsSelectorModal';
import ToastList from './Toasts/ToastList';
import WalletConnectModal from './WalletConnectModal';
import LiquidationDrawer from './Modals/HistoryDrawers/LiquidationDrawer';
import OrderDrawer from './Modals/HistoryDrawers/OrdersDrawer';
import CloseAllPositionsModal from './Modals/CloseAllPositionsModal';
import RateHistoryModal from './Modals/RateHistoryModal';
import DlpIntroModal from './Modals/DlpIntroModal';
import useAppEventEmitter from '../hooks/useAppEventEmitter';
import { DriftModalKey } from '../stores/DriftStore/driftStoreTypes';
import useDriftActions from '../hooks/useDriftActions';
import { PreLaunchMarketWarningAlert } from './PreLaunchMarketAlerts/PreLaunchMarketWarningAlert';
import useShowPreLaunchMarketWarningAlert from '../hooks/useShowPreLaunchMarketWarningAlert';
import TradeFormModal from './Modals/TradeFormModal';
import TradeModeSelectionModal from './Modals/TradeModeSelectionModal';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import { useSheetsStore } from 'src/stores/useSheetsStore';
import CctpTxHashModal from './Modals/CctpTxHashModal';
import ClosePositionModal from './Modals/ClosePositionModal';
import BalanceCardMoreSheet from './Sheets/BalanceCardMoreSheetSheet';
import CloseBorrowModal from './Modals/CloseBorrowModal';
import { InsuranceFundVaultDrawer } from './Modals/InsuranceFundVaultDrawer/InsuranceFundVaultDrawer';
import { PositionDrawer } from './Modals/PositionDrawer/PositionDrawer';
import EmulateWalletModal from './Modals/EmulateWalletModal';
import { MarketDetailsDrawer } from './Modals/MarketDetailsDrawer/MarketDetailsDrawer';
import DepositToTradeModal from 'src/components/Modals/DepositToTradeModal';
import { BetMarketDetailsDrawer } from './Modals/MarketDetailsDrawer/BetMarketDetailsDrawer';
import { TradeModal } from 'src/components/TradeModal';
import BorrowLendDetailsDrawer from './Modals/BorrowLendDetailsDrawer/BorrowLendDetailsDrawer';
import { PredictionMarketClaimPnlModal } from './PredictionMarkets/PredictionMarketClaimPnlModal';
import useNavigationStore from 'src/stores/useNavigationStore';
import SharedModalBackground from 'src/components/Modals/SharedModalBackground';
import VaultDepositFormModal from './Modals/VaultDepositFormModal';
import { VaultTermsDisclaimerModal } from './Vaults/VaultTermsDisclaimerModal';
import MarginLeverageSettingsDrawer from './Modals/MarginLeverageSettingsDrawer';
import SwapConfirmationModal from './SwapConfirmationModal';

/**
 * Component responsible for rendering general modals, loading indicators, notifications, etc. Anything which is rendered "seperately" to the core of the app. This is done to reduce the initial load bundle size.
 * @returns
 */
const FloatingUI = () => {
	// # Constants
	const setState = useDriftStore((s) => s.set);
	const loadingQueue = useDriftStore((s) => s.loadingElements);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const pnlDisplayPosition = useDriftStore((s) => s.pnlModalPosition);
	const showInsuranceFundWarning =
		useShowInsuranceWarningForMarket().shouldShowWarning;
	const actions = useDriftActions();
	const appEventEmitter = useAppEventEmitter();

	useShowPreLaunchMarketWarningAlert();

	useEffect(() => {
		const handler = (modalType: DriftModalKey) => {
			actions.showModal(modalType);
		};

		appEventEmitter.on('showModal', handler);

		return () => {
			appEventEmitter.off('showModal', handler);
		};
	}, [appEventEmitter]);

	const modalState = useDriftStore((s) => s.modals);

	const {
		showDepositModal,
		showWithdrawModal,
		showBorrowModal,
		showDownloadModal,
		showLoadingBar,
		showNewSubaccountModal,
		showEditSubaccountModal,
		showDeleteSubaccountModal,
		showCollateralTransferModal,
		showPnlModal,
		showWalletConnectionModal,
		showDevModal,
		showSettingsModal,
		showNetworkModal,
		showOtherSettingsModal,
		showMarginModal,
		showEditOrderModal,
		showMarketDetailsModal,
		showInsuranceFundDetailsModal,
		showTradeDetailsModal,
		showCreateReferralLinkModal,
		showDelegateAccountModal,
		showStakeModal,
		showUnstakeModal,
		showAssetDetailsModal,
		showAddLiquidityModal,
		showRemoveLiquidityModal,
		showLpDetailsModal,
		showLpPerformanceModal7d,
		showLpPerformanceModal30d,
		showNewLpSubaccountModal,
		showUserCumFundingModal,
		showAccountHealthModal,
		showInsuranceFundRevenueModal,
		showSendFromWalletModal,
		showReceiveToWalletModal,
		showCloseAllPositionsModal,
		rateHistoryModal,
		showDlpIntroModal,
		showEvmWalletsSelectorModal,
		showPreLaunchMarketWarningAlert,
		showTradeFormModal,
		showTradeModeSelectionModal,
		showCctpTxHashModal,
		showClosePositionModal,
		showCloseBorrowModal,
		showEmulationModal,
		showDepositToTradeModal,
		showTradeConfirmationModal,
		showSwapConfirmationModal,
		showPredictionMarketsClaimPnlModal,
		showVaultDepositWithdrawFormModal,
		showVaultTermsDisclaimerModal: showVaultTermsAndConditionModal,
	} = modalState;

	const [
		showAccountsDrawer,
		showLiquidationDrawer,
		showOrderDrawer,
		showInsuranceFundVaultDrawer,
		showMarketDetailsDrawer,
		showBetMarketDetailsDrawer,
		showBorrowLeandDetailsDrawer,
		showPositionDrawer,
		showMarginLeverageSettingsDrawer,
	] = useDrawersStore(
		useShallow((s) => [
			s.accounts.show,
			s.liquidations.show,
			s.orders.show,
			s.insuranceFundVault.show,
			s.marketDetails.show,
			s.betMarketDetails.show,
			s.borrowLendDetails.show,
			s.position.show,
			s.marginLeverageSettings.show,
		])
	);

	const [showBalanceCardMoreSheet] = useSheetsStore(
		useShallow((s) => [s.balanceCardMore.show])
	);

	const isMobile = useIsMobileScreenSize();

	// # State

	const pathname = usePathname();

	const canShowPauseBlock =
		pathname?.includes('/redeem') ||
		pathname?.includes('/terms') ||
		pathname?.includes('/privacy') ||
		pathname?.includes('/redemptionTerms');

	const currentNavigationCategory = useNavigationStore(
		(s) => s.currentNavigationCategory
	);
	const isTradePage = currentNavigationCategory === 'trade';

	const [hideExchangePaused] = useLocalStorageStringState(
		'hideExchangePaused',
		'false'
	);

	const hideModal = useCallback((modalToHide: keyof DriftStore['modals']) => {
		setState((s) => {
			s.modals[modalToHide] = false;
		});
	}, []);

	// # Effect Hooks
	// # Rendering

	const initialDownloadType = useDriftStore((s) => s.downloadType);

	usePnlModalDisplayListener();

	const hideStakeModal = useCallback(() => {
		hideModal('showStakeModal');
	}, []);

	const hideUnstakeModal = useCallback(() => {
		hideModal('showUnstakeModal');
	}, []);

	// TODO : is there a constant time way to check which modal to display instead of looping through all of them each render cycle?

	const showingAnyModal = Object.keys(modalState).some((key) => {
		if (!isTradePage && key === 'showTradeModeSelectionModal') {
			return false;
		}

		return !!modalState[key];
	});

	return (
		<>
			{showingAnyModal && <SharedModalBackground />}

			{/* Render modals */}
			{!canShowPauseBlock &&
			hideExchangePaused !== 'true' &&
			driftClient &&
			driftClient.isSubscribed &&
			matchEnum(
				driftClient.getStateAccount().exchangeStatus,
				ExchangeStatus.PAUSED
			) ? (
				<ExchangePausedModal />
			) : showInsuranceFundWarning ? (
				<>
					<NoInsuranceFundForMarketWarningModal />
				</>
			) : showDevModal ? (
				<DevModal />
			) : showTradeConfirmationModal ? (
				<TradeModal />
			) : showSwapConfirmationModal ? (
				<SwapConfirmationModal />
			) : showWalletConnectionModal ? (
				<WalletConnectModal />
			) : showDepositToTradeModal ? (
				<DepositToTradeModal />
			) : showSettingsModal ? (
				<SettingsModal />
			) : showNetworkModal ? (
				<SettingsModal settingGroupIndex={1} />
			) : showMarginModal ? (
				<SettingsModal settingGroupIndex={2} />
			) : showOtherSettingsModal ? (
				<SettingsModal settingGroupIndex={4} />
			) : showDepositModal ? (
				<CollateralModal
					onClose={() => {
						hideModal('showDepositModal');
					}}
				/>
			) : showWithdrawModal ? (
				<CollateralModal
					initialSide="withdraw"
					onClose={() => {
						hideModal('showWithdrawModal');
					}}
				/>
			) : showBorrowModal ? (
				<CollateralModal
					initialSide="borrow"
					onClose={() => {
						hideModal('showBorrowModal');
					}}
				/>
			) : showCollateralTransferModal ? (
				<CollateralModal
					initialSide="manage"
					onClose={() => {
						hideModal('showCollateralTransferModal');
					}}
				/>
			) : showStakeModal ? (
				<StakeModal onClose={hideStakeModal} />
			) : showUnstakeModal ? (
				<StakeModal initialSide="unstake" onClose={hideUnstakeModal} />
			) : showAddLiquidityModal ? (
				<LPModal />
			) : showRemoveLiquidityModal ? (
				<LPModal initialSide="remove" />
			) : showDownloadModal ? (
				<DownloadModal initialType={initialDownloadType} />
			) : showNewSubaccountModal ? (
				<NewSubaccountModal />
			) : showEditSubaccountModal ? (
				<EditSubaccountModal />
			) : showDeleteSubaccountModal ? (
				<DeleteSubaccountModal />
			) : showPnlModal && pnlDisplayPosition ? (
				<PnlModal
					onClose={() => {
						hideModal('showPnlModal');
					}}
					modalPosition={pnlDisplayPosition}
				/>
			) : showEditOrderModal ? (
				<EditOrderModal />
			) : showInsuranceFundDetailsModal ? (
				<InsuranceFundDetailsModal />
			) : showMarketDetailsModal ? (
				<MarketDetailsModal />
			) : showTradeDetailsModal && isMobile ? (
				<TradeDetailsModal />
			) : showCreateReferralLinkModal ? (
				<CreateReferralLinkModal />
			) : showDelegateAccountModal ? (
				<DelegateModal />
			) : showAssetDetailsModal ? (
				<AssetDetailsModal />
			) : showLpDetailsModal ? (
				<LPDetailsModal />
			) : showLpPerformanceModal7d ? (
				<LpPerformanceModal timeframeIndex={1} />
			) : showLpPerformanceModal30d ? (
				<LpPerformanceModal timeframeIndex={2} />
			) : showDlpIntroModal ? (
				<DlpIntroModal />
			) : showNewLpSubaccountModal ? (
				<NewLpSubaccountModal />
			) : showUserCumFundingModal ? (
				<UserCumFundingModal />
			) : showAccountHealthModal ? (
				<AccountHealthModal />
			) : showInsuranceFundRevenueModal ? (
				<InsuranceFundRevenueModal />
			) : showSendFromWalletModal ? (
				<SendReceiveModal />
			) : showReceiveToWalletModal ? (
				<ReceiveToWalletModal />
			) : showCloseAllPositionsModal ? (
				<CloseAllPositionsModal />
			) : showEvmWalletsSelectorModal ? (
				<EvmWalletsSelectorModal />
			) : rateHistoryModal?.show ? (
				<RateHistoryModal
					marketIndex={rateHistoryModal?.marketIndex}
					type={rateHistoryModal?.type}
				/>
			) : showLiquidationDrawer ? (
				<LiquidationDrawer />
			) : showOrderDrawer ? (
				<OrderDrawer />
			) : showInsuranceFundVaultDrawer ? (
				<InsuranceFundVaultDrawer />
			) : showMarketDetailsDrawer ? (
				<MarketDetailsDrawer />
			) : showBetMarketDetailsDrawer ? (
				<BetMarketDetailsDrawer />
			) : showBorrowLeandDetailsDrawer ? (
				<BorrowLendDetailsDrawer />
			) : showVaultDepositWithdrawFormModal ? (
				<VaultDepositFormModal />
			) : showVaultTermsAndConditionModal ? (
				<VaultTermsDisclaimerModal />
			) : showPositionDrawer ? (
				<PositionDrawer />
			) : showMarginLeverageSettingsDrawer ? (
				<MarginLeverageSettingsDrawer />
			) : showAccountsDrawer ? (
				// This should be the last modal in the list as much as possible, because there may be states
				// where it is transitioning to closing while another modal is opened. This means this large
				// if statement will short-circuit at the first modal that is open, and we want it to be the
				// primary modal displayed.
				<AccountSidebarModal />
			) : showPreLaunchMarketWarningAlert ? (
				<PreLaunchMarketWarningAlert />
			) : showTradeFormModal ? (
				<TradeFormModal />
			) : showTradeModeSelectionModal && isTradePage ? (
				<TradeModeSelectionModal />
			) : showCctpTxHashModal ? (
				<CctpTxHashModal />
			) : showClosePositionModal ? (
				<ClosePositionModal />
			) : showCloseBorrowModal ? (
				<CloseBorrowModal />
			) : showEmulationModal ? (
				<EmulateWalletModal />
			) : showPredictionMarketsClaimPnlModal ? (
				<PredictionMarketClaimPnlModal />
			) : showAccountsDrawer ? (
				// This should be the last modal in the list as much as possible, because there may be states
				// where it is transitioning to closing while another modal is opened. This means this large
				// if statement will short-circuit at the first modal that is open, and we want it to be the
				// primary modal displayed.
				<AccountSidebarModal />
			) : (
				<></>
			)}
			{/* Sheets - mobile only */}
			{/* Should be able to show on top of modals, not a replacement for modals. */}
			{!isMobile ? (
				<></>
			) : showBalanceCardMoreSheet ? (
				<BalanceCardMoreSheet />
			) : (
				<></>
			)}
			{/* Notifications */}
			<ToastList />
			{/* Feature Alerts */}

			{!showTradeModeSelectionModal && <FeatureAlerts />}

			{/* Render loading bar */}
			<PageBottomLoadingBar
				visible={
					Object.values(loadingQueue).filter(
						(entry) => entry.isLoading === true
					).length > 0 || showLoadingBar
				}
			/>
		</>
	);
};

export default React.memo(FloatingUI);
