import {
	FEES_PLACEHOLDER,
	PERIOD_PLACEHOLDER,
	UiVaultConfig,
	VaultManagerConfig,
} from 'src/@types/vaults';
import { PublicKey } from '@drift-labs/sdk';
import { Ewma } from 'src/utils/vault/ewma';
import { ONE_DAY_MS } from '../math';
import { S3_BUCKET_DRIFT_PUBLIC_MAINNET } from 'src/environmentVariables/EnvironmentVariables';
import { Email, Link, Telegram, Twitter } from '@drift-labs/icons';

const VAULT_MANAGER_IMG_BUCKET = `${S3_BUCKET_DRIFT_PUBLIC_MAINNET}/vaults/managers`;

const CIRCUIT_VAULT_MANAGER: VaultManagerConfig = {
	name: 'Circuit',
	imgSrc: `${VAULT_MANAGER_IMG_BUCKET}/circuit.webp`,
};

const GAUNTLET_VAULT_MANAGER: VaultManagerConfig = {
	name: 'Gauntlet',
	imgSrc: `${VAULT_MANAGER_IMG_BUCKET}/gauntlet.svg`,
};

const NEUTRAL_TRADE_VAULT_MANAGER: VaultManagerConfig = {
	name: 'Neutral Trade',
	imgSrc: `${VAULT_MANAGER_IMG_BUCKET}/neutral-trade.svg`,
};

const CIRCUIT_SUPERCHARGER_VAULT: UiVaultConfig = {
	name: 'Supercharger',
	vaultManager: CIRCUIT_VAULT_MANAGER,
	vaultPubkeyString: 'GXyE3Snk3pPYX4Nz9QRVBrnBfbJRTAQYxuy5DRdnebAn', // authority pubkey
	vaultManagerPubkeyString: 'GT3RSBy5nS2ACpT3LCkycHWm9CVJCSuqErAgf4sE33Qu',
	driftUserPubkeyString: 'BRksHqLiq2gvQw1XxsZq6DXZjD3GB5a9J63tUBgd6QS9', // user account pubkey
	description: 'SOL focused delta-neutral MM strategies',
	depositAsset: 0, // USDC
	assetsOperatedOn: [1], // SOL
	feesFraction: 0.3,
	featured: false,
	externalUiLink: 'https://app.circuit.trade/vault/supercharger',
};

const CIRCUIT_TURBOCHARGER_VAULT = {
	name: 'Turbocharger',
	vaultManager: CIRCUIT_VAULT_MANAGER,
	vaultPubkeyString: 'F3no8aqNZRSkxvMEARC4feHJfvvrST2ZrHzr2NBVyJUr',
	vaultManagerPubkeyString: 'GT3RSBy5nS2ACpT3LCkycHWm9CVJCSuqErAgf4sE33Qu',
	driftUserPubkeyString: '2aMcirYcF9W8aTFem6qe8QtvfQ22SLY6KUe6yUQbqfHk',
	description: 'SOL/BTC/ETH/JUP focused delta-neutral MM strategies',
	permissioned: false,
	depositAsset: 0,
	assetsOperatedOn: [1, 3, 4, 11],
	feesFraction: 0.3,
	featured: false,
	externalUiLink: 'https://app.circuit.trade/vault/turbocharger',
};

const CIRCUIT_JITOSOL_BASIS_VAULT = {
	name: 'JitoSOL Basis Vault',
	vaultManager: CIRCUIT_VAULT_MANAGER,
	vaultPubkeyString: 'ACmnVY5gf1z9UGhzBgnr2bf3h2ZwXW2EDW1w8RC9cQk4',
	vaultManagerPubkeyString: 'GT3RSBy5nS2ACpT3LCkycHWm9CVJCSuqErAgf4sE33Qu',
	driftUserPubkeyString: 'FPMWfMBQp81PkMEBSyaVwadzesfkTkYFbegaaoGBcpPB',
	description: 'Basis trading strategy for JitoSOL',
	depositAsset: 6,
	assetsOperatedOn: [6],
	feesFraction: 0.3,
	smoothing: new Ewma(60 * ONE_DAY_MS),
	externalUiLink: 'https://app.circuit.trade/vault/jitosol-basis-vault',
};

const CIRCUIT_JLP_DELTA_NEUTRAL_JLP_BASED_VAULT = {
	name: 'Delta Neutral JLP (JLP)',
	vaultManager: CIRCUIT_VAULT_MANAGER,
	description: 'Delta Neutral strategy for JLP',
	depositAsset: 19,
	vaultPubkeyString: '5A1pDM2XVBKmuWFvgQx775ikFGL5P1q3gWKjiX91XC1L',
	vaultManagerPubkeyString: 'GT3RSBy5nS2ACpT3LCkycHWm9CVJCSuqErAgf4sE33Qu',
	driftUserPubkeyString: 'HydmgFLAu8aLD9xHYzg6JCK3Lmz9tzy25vb3EG3PTEFv',
	assetsOperatedOn: [19],
	feesFraction: 0.3,
	minimumDeposit: 10,
	externalUiLink: 'https://app.circuit.trade/vault/delta-neutral-jlp',
	hidden: true,
};

const CIRCUIT_JLP_DELTA_NEUTRAL_USDC_BASED_VAULT = {
	name: 'Delta Neutral JLP (USDC)',
	vaultManager: CIRCUIT_VAULT_MANAGER,
	description: 'Delta Neutral strategy for JLP',
	depositAsset: 0,
	vaultPubkeyString: '2xTTLUAR8QhenYfnGNpefnmAeyCXeK1G1mAkYwGXaPer',
	vaultManagerPubkeyString: 'GT3RSBy5nS2ACpT3LCkycHWm9CVJCSuqErAgf4sE33Qu',
	driftUserPubkeyString: 'zfXdBw5ErjpQMJLvyhnPkLmRuorUrrnU8xbGUySSsUZ',
	assetsOperatedOn: [19],
	feesFraction: 0.3,
	minimumDeposit: 10,
	externalUiLink: 'https://app.circuit.trade/vault/delta-neutral-jlp-(usdc)',
	hidden: true,
};

const GAUNTLET_HJLP_USDC_BASED_VAULT = {
	name: 'Gauntlet - hJLP (USDC)',
	vaultManager: GAUNTLET_VAULT_MANAGER,
	description: 'Delta Neutral strategy for JLP',
	depositAsset: 0,
	vaultPubkeyString: 'CoHd9JpwfcA76XQGA4AYfnjvAtWKoBQ6eWBkFzR1A2ui',
	vaultManagerPubkeyString: 'G6L1NE8tLYYzvMHYHbkHZqPFvfEsiRAsHSvyNQ2hut3o',
	driftUserPubkeyString: 'Fu8AWYqw7bPZJAxumXJHs62BQZTMcsUkgGdwoh4v3js2',
	assetsOperatedOn: [19, 1, 3, 4],
	feesFraction: 0.3,
	temporaryApy: 30,
	featured: true,
	new: true,
	hidden: false,
	overviewSection: [
		{
			title: 'Strategy Description',
			paragraphs: [
				{
					text: 'Hedged JLP seeks to generate yield by providing liquidity to the Jupiter Liquidity Pool (JLP) while neutralizing the delta exposure (price risk) of the underlying assets via short positions in the risk assets (SOL, ETH, BTC). Liquidity providers in the hJLP vault enjoy reduced volatility, lower risk with sustained yield during downturns, and the convenience of passive management for attractive risk-adjusted returns.',
				},
				{
					text: 'This vault is non-custodial, which means that only you as private key holder can move the funds.',
				},
				{
					text: 'Please read Gauntlet hJLP Whitepaper here',
					link: 'https://www.gauntlet.xyz/resources/hedged-jlp-whitepaper',
				},
			],
		},
		{
			title: 'Risks',
			paragraphs: [
				{
					text: 'JLP is a token generated by a smart contract and is subject to changes and upgrades, such as updated parameters, revised asset composition, and smart contract updates. While the code is audited by third parties, JLP holders assume risks of hacks or malfunction. JLP is also subject to market risk including high market volatility resulting in socialized losses to the liquidity pool.',
				},
				{
					text: 'Read more about JLP on the Jupiter website',
					link: 'https://station.jup.ag/guides/jlp/JLP',
				},
				{
					text: 'The hedged vault carries additional risks related to the management of positions including the risk of data errors, execution risk in the hedging strategy, low liquidity on Drift inhibiting the vault from hedging the full delta exposure, and liquidation risk on the hedge positions during times of high volatility. While every effort is made to mitigate these, vault users will always deploy their assets at their own risk.',
				},
				{
					text: 'Please read Gauntlet’s full disclosures here',
					link: 'https://www.gauntlet.xyz/drift-hjlp-vault-disclaimer',
				},
			],
		},
		{
			title: 'Lock Up Period & Withdrawals',
			paragraphs: [
				{
					text: [
						'Deposited funds are subject to a ',
						`${PERIOD_PLACEHOLDER} redemption period.`,
					],
				},
				{
					text: 'Withdrawal requests can be submitted at any time. Funds will become available for withdrawal at the conclusion of the redemption period. Any upside profit accrued during the redemption period is forfeited. ',
				},
				// {
				// 	text: 'See Circuit Vault documentation for further details.', // TODO: is this right?
				// },
			],
		},
		{
			title: 'Fees',
			paragraphs: [
				{
					text: [
						'A ',
						`performance fee of ${FEES_PLACEHOLDER} of profits`,
						' applied by Gauntlet and is assessed when the depositor takes action - e.g. deposit, withdrawal request, withdrawal.',
					],
				},
			],
		},
		{
			title: 'White Glove Service',
			paragraphs: [
				{
					text: 'For deposits over $100,000 contact Gauntlet to learn more about their white glove service through Telegram @riskringer or email to perpetuals@gauntlet.xyz',
				},
			],
		},
		{
			title: 'About Gauntlet',
			paragraphs: [
				{
					contacts: [
						{
							name: 'Gauntlet.xyz',
							link: 'https://gauntlet.xyz/',
							icon: Link,
						},
						{
							name: '@gauntlet_xyz',
							link: 'https://x.com/gauntlet_xyz',
							icon: Twitter,
						},
						{
							name: '@riskringer',
							link: 'https://t.me/riskringer',
							icon: Telegram,
						},
						{
							name: 'perpetuals@gauntlet.xyz',
							link: 'mailto:perpetuals@gauntlet.xyz',
							icon: Email,
						},
					],
				},
				{
					text: 'Gauntlet’s applied research and optimization teams model market risk, economic incentives, and drive sustainable growth for crypto’s top protocols, chains, and onchain treasuries.',
				},
			],
		},
	],
};

const NEUTRAL_TRADE_SOL_SUPER_STAKING_VAULT = {
	name: 'Neutral Trade - SOL Super Staking',
	vaultManager: NEUTRAL_TRADE_VAULT_MANAGER,
	description:
		'Earn JLP fees while maintaining SOL exposure and hedging BTC/ETH',
	depositAsset: 1,
	vaultPubkeyString: 'EuSLjg23BrtwYAk1t4TFe5ArYSXCVXLBqrHRBfWQiTeJ',
	vaultManagerPubkeyString: 'C77bxLHWjnAVeG9HdMxu1gunFnjRCcWUDZYfa7xbacHr',
	driftUserPubkeyString: 'CPguqyTLnywQggEFYKEA428npP9JTbkfLjcWbLLuTbgJ',
	assetsOperatedOn: [19, 1, 3, 4],
	feesFraction: 0.25,
	temporaryApy: 30,
	featured: true,
	new: true,
	hidden: false,
	externalUiLink: 'https://www.app.neutral.trade/strategies/sol-super-staking',
};

const NEUTRAL_TRADE_JLP_DELTA_NEUTRAL_VAULT = {
	name: 'Neutral Trade - JLP Delta Neutral',
	vaultManager: NEUTRAL_TRADE_VAULT_MANAGER,
	description: 'Delta Neutral strategy for JLP',
	depositAsset: 0,
	vaultPubkeyString: '41Y8C4oxk4zgJT1KXyQr35UhZcfsp5mP86Z2G7UUzojU',
	vaultManagerPubkeyString: 'C77bxLHWjnAVeG9HdMxu1gunFnjRCcWUDZYfa7xbacHr',
	driftUserPubkeyString: 'E26KQcz6VRDoAbENADE9oNoq3QsN6cyksfqe2vqwCnsA',
	assetsOperatedOn: [19, 1, 3, 4],
	feesFraction: 0.25,
	temporaryApy: 30,
	featured: false,
	new: true,
	hidden: false,
};

const NEUTRAL_TRADE_JLP_TRADERS_REKT_VAULT = {
	name: 'Neutral Trade - JLP Traders Rekt',
	vaultManager: NEUTRAL_TRADE_VAULT_MANAGER,
	description: "Bet on Jupiter Perps' traders loses while capturing JLP fees",
	depositAsset: 0,
	vaultPubkeyString: '2r81MPMDjGSrbmGRwzDg6aqhe3t3vbKcrYfpes5bXckS',
	vaultManagerPubkeyString: 'C77bxLHWjnAVeG9HdMxu1gunFnjRCcWUDZYfa7xbacHr',
	driftUserPubkeyString: 'EfXaVfKdH7fqyUi5LJ4NzwUb5Vf5Bt8nQFBvbgc5PpRM',
	assetsOperatedOn: [19, 1, 3, 4],
	feesFraction: 0.2,
	temporaryApy: 30,
	featured: false,
	new: true,
	hidden: false,
};

export const VAULTS: UiVaultConfig[] = [
	GAUNTLET_HJLP_USDC_BASED_VAULT,
	NEUTRAL_TRADE_SOL_SUPER_STAKING_VAULT,
	NEUTRAL_TRADE_JLP_DELTA_NEUTRAL_VAULT,
	NEUTRAL_TRADE_JLP_TRADERS_REKT_VAULT,
	CIRCUIT_SUPERCHARGER_VAULT,
	CIRCUIT_TURBOCHARGER_VAULT,
	CIRCUIT_JITOSOL_BASIS_VAULT,
	CIRCUIT_JLP_DELTA_NEUTRAL_JLP_BASED_VAULT,
	CIRCUIT_JLP_DELTA_NEUTRAL_USDC_BASED_VAULT,
];

export const getUiVaultConfig = (vaultPubkeyString: string | PublicKey) => {
	return VAULTS.find(
		(vault) => vault.vaultPubkeyString === vaultPubkeyString.toString()
	);
};
