'use client';

import { SpotMarketConfig, BigNum, BASE_PRECISION_EXP } from '@drift-labs/sdk';
import { createTransferCheckedInstruction } from '@solana/spl-token';
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { useState, useEffect } from 'react';
import { MIN_LEFTOVER_SOL } from 'src/constants/constants';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { notify } from 'src/utils/notifications';
import Button from '../Button';
import { CollateralInput } from '../Inputs/CollateralInput';
import LabelledInput from '../Inputs/LabelledInput';
import TextField from '../Inputs/TextField';
import InfoMessage from '../TradeForm/InfoMessage';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import Text from '../Text/Text';
import Modal from './Modal';
import UI_UTILS from 'src/utils/uiUtils';
import { useSyncWalletBalances } from 'src/hooks/useSyncWalletBalances';
import { ConfirmTransactionStep, LoadingStep } from '../Toasts/Notification';
import { createTokenAccountIx } from '@drift/common';
import useDriftClient from 'src/hooks/useDriftClient';

// Move SOL to the top of list
const CurrentBanks = [...CurrentSpotMarkets].sort((item) => {
	if (item.symbol === 'SOL') {
		return -1;
	} else {
		return 0;
	}
});

const SendPageContent = ({ handleCancel }: { handleCancel: () => void }) => {
	const [submitting, setSubmitting] = useState(false);
	const [destinationAddress, setDestinationAddress] = useState('');
	const [destinationPublicKey, setDestinationPublicKey] =
		useState<PublicKey | null>(null);
	const [destinationAddressIsValid, setDestinationAddressIsValid] =
		useState(false);
	const [transferAmount, setTransferAmount] = useState('');
	const [selectedBank, setSelectedBank] = useState<SpotMarketConfig>(
		CurrentBanks[0]
	);
	const [allowedMaxSend, setAllowedMaxSend] = useState(false);
	const [connection] = useDriftStore((s) => [
		s.connection?.current,
		s.driftClient?.client,
	]);
	const wallet = useCurrentWalletAdapter();
	const driftClient = useDriftClient();
	useSyncWalletBalances();

	const [currentBalance] = useCurrentWalletCollateralBalance(selectedBank);
	const transferableBalance =
		useTransferableWalletCollateralBalance(selectedBank);
	const transferAmountBigNum = BigNum.fromPrint(
		transferAmount ? transferAmount : '0',
		selectedBank.precisionExp
	);
	const exceededMax = transferAmountBigNum.gt(
		allowedMaxSend ? currentBalance : transferableBalance
	);

	const isMaxSolTransfer =
		transferAmount !== '' &&
		transferAmountBigNum.eq(
			allowedMaxSend ? currentBalance : transferableBalance
		) &&
		selectedBank.symbol === 'SOL';

	const allowConfirmation =
		!exceededMax &&
		destinationAddressIsValid &&
		!submitting &&
		transferAmount &&
		transferAmountBigNum.gt(BigNum.zero(BASE_PRECISION_EXP), true);

	useEffect(() => {
		if (exceededMax) {
			setTransferAmount(
				(allowedMaxSend ? currentBalance : transferableBalance).print()
			);
		}
	}, [exceededMax]);

	const handleMaxBypass = () => {
		setAllowedMaxSend(true);
		setTransferAmount(currentBalance.toString());
	};

	const handleChangeTransferAmount = (newValue: string) => {
		setTransferAmount(newValue);
	};

	const handleChangeAssetType = (bank: SpotMarketConfig) => {
		setTransferAmount('');
		setSelectedBank(bank);
	};

	const handleChangeDestinationAddress = (str) => {
		setDestinationAddress(str);
		try {
			const dstPublicKey = new PublicKey(str);
			setDestinationPublicKey(dstPublicKey);
			setDestinationAddressIsValid(true);
		} catch (e) {
			setDestinationPublicKey(null);
			setDestinationAddressIsValid(false);
		}
	};

	const handleSend = async () => {
		setSubmitting(true);

		const toastId = `transfer-${transferAmount}-${
			selectedBank.symbol
		}-${destinationAddress}-${Date.now()}`;

		const abbreviatedDst = UI_UTILS.abbreviateAddress(destinationPublicKey);

		notify({
			type: 'info',
			message: `Sending ${transferAmount} ${selectedBank.symbol} to ${abbreviatedDst}`,
			description: <ConfirmTransactionStep />,
			id: toastId,
			showUntilCancelled: true,
		});

		try {
			const { blockhash } = await connection.getLatestBlockhash('finalized');

			const sendTx = new Transaction({
				feePayer: wallet.publicKey,
				recentBlockhash: blockhash,
			});

			const transferAmountBase = transferAmountBigNum
				.shiftTo(selectedBank.precisionExp)
				.toString();

			if (selectedBank.symbol === 'SOL') {
				sendTx.add(
					SystemProgram.transfer({
						fromPubkey: wallet.publicKey,
						toPubkey: destinationPublicKey,
						lamports: Number(transferAmountBase),
					})
				);
			} else {
				const destAtaPublicKey =
					await UI_UTILS.getTokenAddressForDepositAndWithdraw(
						driftClient.getSpotMarketAccount(selectedBank.marketIndex),
						destinationPublicKey
					);

				const sourceAtaPublicKey =
					await UI_UTILS.getTokenAddressForDepositAndWithdraw(
						driftClient.getSpotMarketAccount(selectedBank.marketIndex),
						wallet.publicKey
					);

				let destAtaExists = false;
				try {
					const destAtaInfo = await connection.getAccountInfo(destAtaPublicKey);
					destAtaExists = !!destAtaInfo;
				} catch (e) {
					destAtaExists = false;
				}

				if (!destAtaExists) {
					sendTx.add(
						await createTokenAccountIx(
							destinationPublicKey,
							selectedBank.mint,
							wallet.publicKey
						)
					);
				}

				const transferCheckedIx = createTransferCheckedInstruction(
					sourceAtaPublicKey,
					selectedBank.mint,
					destAtaPublicKey,
					wallet.publicKey,
					Number(transferAmountBase),
					selectedBank.precisionExp.toNumber()
				);

				sendTx.add(transferCheckedIx);
			}

			const signed = await wallet.signTransaction(sendTx);

			notify({
				type: 'info',
				updatePrevious: true,
				message: `Sending ${transferAmount} ${selectedBank.symbol} to ${abbreviatedDst}`,
				description: <LoadingStep>Confirming Transaction</LoadingStep>,
				id: toastId,
				showUntilCancelled: true,
			});

			const txSig = await connection.sendRawTransaction(signed.serialize());

			notify({
				type: 'success',
				updatePrevious: true,
				message: `Sent ${transferAmount} ${selectedBank.symbol} to ${abbreviatedDst}!`,
				action: {
					type: 'txnLink',
					txnSig: txSig,
				},
				id: toastId,
			});
			setSubmitting(false);
		} catch (error) {
			// TODO pretty error? Not sure how likely it is to get here.
			notify({
				type: 'error',
				updatePrevious: true,
				message: `Error sending ${selectedBank.symbol}`,
				description: `${error}`,
				id: toastId,
			});
			setSubmitting(false);
		}
	};

	return (
		<div className="p-6 pt-4">
			<div className="pb-2">
				<LabelledInput label="Send to:">
					<TextField.Default
						type="text"
						value={destinationAddress}
						onChange={handleChangeDestinationAddress}
						placeholder={'Enter a Solana Address'}
					/>
				</LabelledInput>
				{destinationAddress.length > 0 && !destinationAddressIsValid && (
					<InfoMessage
						type={'error'}
						message={'Invalid Solana address'}
						className="mt-3"
					/>
				)}
			</div>
			<div className="py-4 mb-10">
				<CollateralInput
					selectedMarket={selectedBank}
					maxAmount={allowedMaxSend ? currentBalance : transferableBalance}
					currentBalance={currentBalance}
					onChangeMarket={handleChangeAssetType}
					label="Asset and amount"
					value={transferAmount}
					onChangeValue={handleChangeTransferAmount}
					amountLabel={'Wallet balance'}
					source={{ type: 'wallet' }}
				/>
				{isMaxSolTransfer &&
					(allowedMaxSend ? (
						<InfoMessage
							type={'warn'}
							message={
								'You are transferring out your entire SOL balance, so you will not  be able to make transactions after sending.'
							}
							className="mt-3"
						/>
					) : (
						<div className="mt-3 text-text-label">
							<Text.BODY3 className="text-xs leading-4">
								We will leave {MIN_LEFTOVER_SOL.toFixed(2)} SOL in your wallet
								to cover future transaction fees.{' '}
								<div
									className="inline-block opacity-50 cursor-pointer text-text-tertiary"
									onClick={handleMaxBypass}
								>
									Bypass
								</div>
							</Text.BODY3>
						</div>
					))}
			</div>
			<div className="flex flex-col">
				<Button.BigSemantic
					positive
					disabled={!allowConfirmation}
					onClick={handleSend}
				>
					{submitting
						? 'Sending...'
						: `Send ${transferAmount} ${selectedBank.symbol}`}
				</Button.BigSemantic>
				<div className="pt-6 text-center text-text-label">
					<div onClick={handleCancel} className="cursor-pointer">
						<Text.BODY1>Cancel</Text.BODY1>
					</div>
				</div>
			</div>
		</div>
	);
};

const SendReceiveModal = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	// const backModal = useDriftStore(
	// 	(s) => s.modalsProps.showSendFromWalletModal?.backModal
	// );

	const onClose = () => {
		setDriftStore((s) => {
			s.modals.showSendFromWalletModal = false;
		});
	};

	// const onBack = () => {
	// 	onClose();
	// 	setDriftStore((s) => {
	// 		s.modals[backModal] = true;
	// 	});
	// };

	return (
		<Modal onClose={onClose}>
			<Modal.Body className="bg-container-bg">
				<Modal.Header onClose={onClose}>
					<Text.H3>Send Assets</Text.H3>
				</Modal.Header>
				<SendPageContent handleCancel={onClose} />
			</Modal.Body>
		</Modal>
	);
};

export default SendReceiveModal;
