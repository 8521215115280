'use client';

import { useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { PageRoute, PageRoutes } from '../constants/constants';
import useMarkPrice from './useMarkPrice';

const useDocumentTitle = () => {
	const currentMarketName = useDriftStore((s) => s.selectedMarket.name);
	const currentRoute = useDriftStore((s) => s.currentPageRoute);

	const markPrice = useMarkPrice();

	const setTitleToRender = (newTitle: string) => {
		if (!document?.title) return;

		document.title = newTitle;
	};

	useEffect(() => {
		// check which route
		if (currentRoute) {
			if (PageRoutes.includes(currentRoute as PageRoute)) {
				switch (currentRoute as PageRoute) {
					case PageRoute.disclaimer:
						setTitleToRender('Disclaimer — Drift');
						break;
					case PageRoute.privacy:
						setTitleToRender('Privacy Policy — Drift');
						break;
					case PageRoute.terms:
						setTitleToRender('Terms — Drift');
						break;
					case PageRoute.overview:
						setTitleToRender('Overview — Drift');
						break;
					case PageRoute.funding:
						setTitleToRender('Funding — Drift');
						break;
					case PageRoute.leaderboards:
						setTitleToRender('Leaderboards — Drift');
						break;
					case PageRoute.redeem:
						setTitleToRender('Redeem - Drift');
						break;
					case PageRoute.lendBorrow:
						setTitleToRender('Lend/Borrow - Drift');
						break;
					case PageRoute.stake:
						setTitleToRender('Stake - Drift');
						break;
					case PageRoute.bridge:
						setTitleToRender(`Bridge - Drift`);
						break;
					case PageRoute.swap:
						setTitleToRender(`Swap - Drift`);
						break;
					case PageRoute.BAL:
						setTitleToRender(`Backstop AMM Liquidity - Drift`);
						break;
					case PageRoute.elections:
						setTitleToRender(`Election Center - Drift`);
						break;
					case PageRoute.bet:
						setTitleToRender(`BET - Drift`);
						break;
					case PageRoute.vaults:
						setTitleToRender(`Vaults - Drift`);
						break;
					case PageRoute.stats:
						setTitleToRender(`Stats - Drift`);
						break;
					default:
						setTitleToRender('Drift');
				}

				return;
			}
		}

		// otherwise we're at the default route
		if (markPrice && markPrice.gtZero()) {
			setTitleToRender(
				`${markPrice.toTradePrecision()} | ${currentMarketName} | Drift Trade`
			);

			return;
		}

		// otherwise just use the default title
		setTitleToRender('Drift');
	}, [currentRoute, markPrice]);
};

export default useDocumentTitle;
