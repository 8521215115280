'use client';

import Text from 'src/components/Text/Text';
import React, { useCallback } from 'react';
import Modal from './Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { RateHistoryChart } from '../RateHistoryChart';

const RateHistoryModal = (props: {
	marketIndex: number;
	type: 'deposit' | 'borrow';
}) => {
	const setState = useDriftStore((s) => s.set);

	const onClose = useCallback(() => {
		setState((s) => {
			s.modals.rateHistoryModal.show = false;
		});
	}, []);

	const capitalizedType = props.type === 'deposit' ? 'Deposit' : 'Borrow';

	return (
		<Modal
			onClose={onClose}
			id={`${props.type}_rate_history_modal_${props.marketIndex}`}
			sizeConfig={{ xs: 16, sm: 12, md: 8, xl: 6 }}
			className="p-4"
		>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>
						{`${
							OrderedSpotMarkets[props.marketIndex].symbol
						} ${capitalizedType} Rate History`}
					</Text.H2>
				</Modal.Header>

				<div className="px-6">
					<RateHistoryChart
						marketIndex={props.marketIndex}
						type={props.type}
						showAverage
						className="min-h-[450px]"
						chartClassName="min-h-[450px]"
						loadingClassName="min-h-[450px]"
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default RateHistoryModal;
