'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import Text from '../Text/Text';
import useTradeModeSetting, { TradeMode } from 'src/hooks/useTradeModeSetting';
import Button from '../Button';
import { useTheme } from 'next-themes';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';

const TradeModeSelectionModal = () => {
	const [_currentLayout, setCurrentLayout] = useLayoutConfigSetting();
	const [tradeMode, setTradeMode] = useTradeModeSetting();
	const theme = useTheme().theme;
	const set = useDriftStore((s) => s.set);
	const { captureEvent } = usePostHogCapture();

	const handleHideFromBackground = () => {
		captureEvent('new_user_trade_mode_modal_exit', {
			trade_mode: tradeMode,
		});

		set((s) => {
			s.modals.showTradeModeSelectionModal = false;
		});
	};

	const handleHideFromButton = () => {
		captureEvent('new_user_trade_mode_modal_choice', {
			trade_mode: tradeMode,
		});

		set((s) => {
			s.modals.showTradeModeSelectionModal = false;
		});
	};

	const handleChangeTradeMode = (tradeMode: TradeMode) => {
		if (tradeMode === TradeMode.LITE) {
			setCurrentLayout('defaultLite');
		} else {
			setCurrentLayout('default');
		}
		setTradeMode(tradeMode);
	};

	return (
		<Modal
			onClose={handleHideFromBackground}
			centered
			grid={false}
			className="flex flex-col items-center justify-center bg-black bg-opacity-60"
			noBackground
		>
			<div className="h-auto max-w-[700px] box-shadow-lg">
				<Modal.Body className="pb-0 h-full flex flex-col items-stretch">
					<div className="bg-container-bg-selected w-full p-6 flex flex-col items-center border-b border-container-border text-text-emphasis">
						<Text.H1 className="mb-4">Welcome to Drift!</Text.H1>
						<Text.BODY1>Get started by selecting a trading mode:</Text.BODY1>
					</div>

					<div className="flex flex-col sm:flex-row items-center space-around flex-grow divide-y sm:divide-none divide-container-border">
						<div
							className="flex flex-col items-center text-center space-between max-w-305px p-4 sm:p-4 sm:m-4 space-y-2 sm:space-y-4 text-text-default cursor-pointer"
							onClick={() => handleChangeTradeMode(TradeMode.LITE)}
						>
							<div
								className={
									tradeMode == TradeMode.LITE ? 'rainbow-gradient-border' : ''
								}
							>
								<img
									src={`/assets/icons/buttonIcons/tradeMode/lite-${theme}.svg`}
									className="w-[120px] sm:w-[170px]"
								/>
							</div>
							<Text.H3>Lite Mode</Text.H3>
							<Text.BODY2 className="text-text-secondary">
								Simplifies trading by prioritizing essential options, making it
								ideal for newcomers to perpetuals trading
							</Text.BODY2>
						</div>
						<div
							className="flex flex-col items-center text-center space-between max-w-305px p-4 sm:p-4 sm:m-4 space-y-2 sm:space-y-4 text-text-default cursor-pointer"
							onClick={() => handleChangeTradeMode(TradeMode.PRO)}
						>
							<div
								className={
									tradeMode == TradeMode.PRO ? 'rainbow-gradient-border' : ''
								}
							>
								<img
									src={`/assets/icons/buttonIcons/tradeMode/pro-${theme}.svg`}
									className="w-[120px] sm:w-[170px]"
								/>
							</div>
							<Text.H3>Pro Mode</Text.H3>
							<Text.BODY2 className="text-text-secondary">
								A comprehensive set of trading features, order types, and
								information best suited for experienced traders
							</Text.BODY2>
						</div>
					</div>

					<div className="p-4 border-t border-container-border text-center">
						<Button.BigSemantic
							positive
							onClick={handleHideFromButton}
							className="w-auto px-4 py-1"
						>
							<Text.BODY1>Let&apos;s get started</Text.BODY1>
						</Button.BigSemantic>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default TradeModeSelectionModal;
