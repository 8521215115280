'use client';

import { dlog } from '../dev';
import useNotificationStore from '../stores/useNotificationStore';
import useInterval from './useInterval';

const useClearStaleNotifications = () => {
	const setState = useNotificationStore((s) => s.set);
	const getState = useNotificationStore((s) => s.get);

	useInterval(() => {
		if (!getState().notifications || getState().notifications.length === 0)
			return;

		setState((s) => {
			const indexesToRemove = [];

			s.notifications.forEach((notification, index) => {
				if (
					notification.type === 'v2MarketOrder' ||
					notification.type === 'auction'
				) {
					// Above notification types have their own logic for removal
					return false;
				}

				let shouldRemove = true;

				if (
					notification.startTimeMs + notification.lengthMs > Date.now() ||
					notification.isHovered ||
					notification.showUntilCancelled
				) {
					shouldRemove = false;
				}

				if (shouldRemove) {
					indexesToRemove.push(index);
				}
			});

			if (indexesToRemove.length > 0) {
				dlog(
					`v2_auctions`,
					`Removing stale notification indexes:`,
					indexesToRemove
				);
				s.notifications = s.notifications.filter((_notification, index) => {
					return !indexesToRemove.includes(index);
				});
			}
		});
	}, 1000);
};

export default useClearStaleNotifications;
