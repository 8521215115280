'use client';

import { useEffect, useRef } from 'react';
import { useIdle } from 'react-use';

const TEN_MINUTES_MS = 1000 * 60 * 10;

export default function useHandleReturnFromIdle(
	callback: () => void,
	ms: number = TEN_MINUTES_MS // as much as possible, use the default to keep the logic consistent across the app
) {
	const isIdle = useIdle(ms);
	const isPreviouslyIdle = useRef(false);

	useEffect(() => {
		if (!isPreviouslyIdle.current && isIdle) {
			isPreviouslyIdle.current = true;
			return;
		}

		// user comes back from idling
		if (!isIdle && isPreviouslyIdle.current) {
			isPreviouslyIdle.current = false;
			callback();
			return;
		}
	}, [isIdle, callback]);
}
