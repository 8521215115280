'use client';

import { BigNum, QUOTE_PRECISION_EXP, SpotMarketConfig } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useMemo } from 'react';
import useMarketStateStore from '../stores/useMarketStateStore';

const useCollateralPrice = (collateral: SpotMarketConfig) => {
	const marketId = useMemo(() => {
		return MarketId.createSpotMarket(collateral.marketIndex);
	}, [collateral]);

	const getMarkPrice = useMarketStateStore((s) => s.getMarkPriceForMarket);

	if (!collateral) {
		return BigNum.from(1).shift(QUOTE_PRECISION_EXP);
	}

	if (collateral.marketIndex === 0) {
		return BigNum.from(1).shift(QUOTE_PRECISION_EXP);
	}

	const markPrice = getMarkPrice(marketId)?.shift?.(QUOTE_PRECISION_EXP);

	return markPrice;
};

export default useCollateralPrice;
