import { Subject } from 'rxjs';
import { MetricsEvent } from './metricsTypes';

export /**
 * A class which acts as a sink for any events which are pertinent to metrics. The sink should be an rxjs subject that the UI can push to.
 *
 */
class MetricsEventSink {
	private sink: Subject<MetricsEvent>;

	constructor() {
		this.sink = new Subject<MetricsEvent>();
	}

	public push(event: MetricsEvent) {
		this.sink.next(event);
	}

	public subscribe(callback: (event: MetricsEvent) => void) {
		this.sink.subscribe(callback);
	}
}
