'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import { useEffect } from 'react';
import { setInterval } from 'timers';

// Check once every 5 minutes (arbitrary number, can update)
const BASE_REFRESH_RATE_MS = 1000 * 60 * 5;

const useListenForNewDeploys = () => {
	const set = useDriftStore((s) => s.set);
	const latestDeployId = useDriftStore((s) => s.latestDeployId);

	const checkForNewDeploy = async () => {
		// get the latest production build from vercel rest api
		ExchangeHistoryClient.getLatestDeployment()
			.then(async (result) => {
				if (result.success) {
					const newLatestDeploymentId = result.body.data.deploymentId;

					if (!latestDeployId) {
						set((s) => {
							s.latestDeployId = newLatestDeploymentId;
						});
					} else if (
						newLatestDeploymentId !== '' &&
						latestDeployId != newLatestDeploymentId
					) {
						set((s) => {
							s.statusBars.showNewVersionStatusBar = true;
						});
					}
				}
			})
			.catch((_err) => {
				// do nothing.. don't want to trigger refresh if request fails for some reason
			});
	};

	useEffect(() => {
		const interval = setInterval(checkForNewDeploy, BASE_REFRESH_RATE_MS);

		return () => {
			clearInterval(interval);
		};
	}, []);
};

export default useListenForNewDeploys;
