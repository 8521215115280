'use client';

import { BigNum, PublicKey } from '@drift-labs/sdk';
import { ReactNode, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import UnderlinedTabs from 'src/components/TabbedNavigation/UnderlinedTabs';
import TableV2 from 'src/components/Tables/TableV2';
import TableStateWrapper from 'src/components/TableStateWrapper';
import Text from 'src/components/Text/Text';
import useDriftActions from 'src/hooks/useDriftActions';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';

type PnlUser = {
	userPubKey: PublicKey;
	pnl: BigNum;
};

const tabs = [
	{
		label: 'Top Negative Accounts',
		value: 'loss',
	},
	{
		label: 'Top Positive Accounts',
		value: 'gain',
	},
];

export const PnlLeaderboardPanel = (props: {
	marketIndex: number;
	poolBalanceDisplay: string;
	goBackComponent?: ReactNode;
}) => {
	const actions = useDriftActions();

	const [selectedTab, setSelectedTab] = useState<'gain' | 'loss'>('loss');
	const [gainers, setGainers] = useState<PnlUser[]>([]);
	const [losers, setLosers] = useState<PnlUser[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [settledUsers, setSettledUsers] = useState<string[]>([]);

	const settleUser = async (userPubKey: PublicKey) => {
		const success = await actions.settlePnl(props.marketIndex, userPubKey);
		// endpoint does not update instantaneously, so if user settles somebody successfully,
		// filter it out from results (at least while they're still in the panel)
		if (success) {
			setSettledUsers(settledUsers.concat(userPubKey.toString()));
		}
	};

	useEffect(() => {
		setIsLoading(true);
		ExchangeHistoryClient.getUnsettledPnlUsersForMarket(props.marketIndex)
			.then((result) => {
				setGainers(
					result.gainers
						.filter(
							(gainer) => !settledUsers.includes(gainer.userPubKey.toString())
						)
						.slice(0, 15)
				);
				setLosers(
					result.losers
						.filter(
							(loser) => !settledUsers.includes(loser.userPubKey.toString())
						)
						.slice(0, 15)
				);
				setIsLoading(false);
			})
			.catch((_e) => {
				setIsLoading(false);
			});
	}, [props.marketIndex, settledUsers]);

	return (
		<div className="flex flex-col w-full h-full px-2 pt-2">
			{props.goBackComponent && (
				<div className="justify-start w-full">{props.goBackComponent}</div>
			)}
			<div className="w-full p-4 mt-2 space-y-1 border rounded-md bg-container-bg border-container-border">
				<Text.BODY1 className="text-text-label">P&L Pool Balances</Text.BODY1>
				<Text.H2 className="block text-text-emphasis">
					{props.poolBalanceDisplay}
				</Text.H2>
			</div>
			<div className="w-full mt-4 text-text-secondary">
				<Text.BODY3>
					You can settle negative P&L balances from other accounts to boost the
					P&L pool. Balances may not update immediately.
				</Text.BODY3>
			</div>
			<div className="w-full mt-4">
				<UnderlinedTabs
					options={tabs}
					currentSelection={selectedTab}
					onChange={(newSelection: 'gain' | 'loss') => {
						setSelectedTab(newSelection);
					}}
					tabClassName={
						'grow text-center text-[14px] leading-[16px] items-center justify-center'
					}
					gradient
				/>
			</div>
			<div className={`w-full flex flex-grow mt-4`}>
				{selectedTab === 'loss' ? (
					<PnlUsersTable
						marketIndex={props.marketIndex}
						users={losers}
						positive={false}
						showSettle={true}
						settleAction={settleUser}
						loading={isLoading}
					/>
				) : (
					<PnlUsersTable
						marketIndex={props.marketIndex}
						users={gainers}
						positive={true}
						showSettle={false}
						settleAction={settleUser}
						loading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export const PnlUsersTable = (props: {
	marketIndex: number;
	users: PnlUser[];
	positive: boolean;
	showSettle: boolean;
	settleAction: (userPubKey: PublicKey) => void;
	loading?: boolean;
}) => {
	const headers = props.positive
		? ['Account', 'Positive P&L']
		: ['Account', 'Negative P&L'];
	const tableGrid = `minmax(50px,10fr) minmax(50px,10fr) ${
		props.showSettle ? 'minmax(50px,10fr)' : ''
	}`;

	if (props.showSettle) {
		headers.push('Action');
	}

	const rowHeight = 48;

	return (
		<TableStateWrapper
			records={props.users}
			emptyStateText={'Accounts could not be fetched, try again later.'}
			loading={props.loading}
			className="flex flex-grow max-h-screen"
		>
			<TableV2.Skeleton
				className="flex flex-grow"
				top={
					<TableV2.HeaderRow
						forceBottomBorder
						header
						grid={tableGrid}
						className={twMerge(
							`w-full flex grid bg-main-bg h-[${Math.round(
								rowHeight * 0.9
							)}px]`,
							props.showSettle && 'pr-4'
						)}
						lastColumnJustify="end"
					>
						{headers.map((label, index) => (
							<TableV2.HeaderCell
								className={twMerge(
									'text-text-secondary bg-main-bg',
									index === headers.length - 1 && 'justify-end',
									index !== 0 &&
										index === headers.length - 2 &&
										'justify-center'
								)}
								key={`header_${label}`.replace(/ /g, '')}
							>
								{label}
							</TableV2.HeaderCell>
						))}
					</TableV2.HeaderRow>
				}
				middle={
					<div
						className={`w-full h-full overflow-y-auto thin-scroll flex flex-col`}
					>
						{props.users.map((pnlUser, index) => {
							return (
								<TableV2.BodyRow
									key={`${index}_${pnlUser.userPubKey.toString()}`}
									grid={tableGrid}
									lastColumnJustify="end"
									noBorder={index === props.users.length - 1}
									className={`min-h-[${rowHeight}px]`}
								>
									<TableV2.BodyCell
										className={`items-center h-[${rowHeight}px]`}
										key={`pnl_account_${props.marketIndex}_${
											props.positive ? 'pos' : 'neg'
										}_${index}`}
									>
										<Text.BODY2
											className="hover:cursor-pointer"
											onClick={() => {
												UI_UTILS.openUrl(
													`/overview?userAccount=${pnlUser.userPubKey.toString()}`
												);
											}}
										>
											{UI_UTILS.abbreviateAddress(pnlUser.userPubKey)}
										</Text.BODY2>
									</TableV2.BodyCell>
									<TableV2.BodyCell
										className={twMerge(
											'items-center',
											!props.showSettle ? 'justify-end' : 'justify-center'
										)}
										key={`pnl_value_${props.marketIndex}_${
											props.positive ? 'pos' : 'neg'
										}_${index}`}
									>
										<Text.BODY2
											className={
												props.positive
													? 'text-positive-green'
													: 'text-negative-red'
											}
										>
											{pnlUser.pnl.toNotional()}
										</Text.BODY2>
									</TableV2.BodyCell>
									{props.showSettle && (
										<TableV2.BodyCell
											className="items-center justify-end"
											key={`settle_user_${props.marketIndex}_${
												props.positive ? 'pos' : 'neg'
											}_${index}`}
										>
											<Button.Secondary
												onClick={() => props.settleAction(pnlUser.userPubKey)}
												size="SMALL"
											>
												Settle
											</Button.Secondary>
										</TableV2.BodyCell>
									)}
								</TableV2.BodyRow>
							);
						})}
					</div>
				}
			/>
		</TableStateWrapper>
	);
};
