import React from 'react';

const LightningIcon = ({
	...props
}: {
	className?: string;
	style?: React.CSSProperties;
}) => {
	return (
		<svg
			viewBox="4 1 6 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="mask0_7460_54421"
				maskUnits="userSpaceOnUse"
				x="2"
				y="2"
				width="11"
				height="17"
			>
				<path
					d="M12.3637 9.62454C12.3413 9.45267 12.2335 9.32195 12.079 9.27523L8.87987 8.30771L11.2501 2.67562C11.3518 2.43395 11.2889 2.17944 11.0989 2.06541C10.9057 1.94924 10.6433 2.00957 10.4629 2.2032L2.92811 10.3136C2.79867 10.4522 2.73481 10.6407 2.75813 10.8105C2.78103 10.9814 2.88839 11.1131 3.04286 11.1598L6.242 12.1273L3.87173 17.7594C3.77002 18.0011 3.83292 18.2556 4.02295 18.3696C4.05192 18.3865 4.08219 18.4003 4.11327 18.4097C4.29059 18.4633 4.50473 18.3976 4.65898 18.2319L12.1938 10.1214C12.3227 9.98152 12.3875 9.79565 12.3637 9.62454Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_7460_54421)">
				<path
					d="M0.704346 6.77386C0.704346 4.56472 2.49521 2.77386 4.70435 2.77386L8.93994 2.77386C11.1491 2.77386 12.9399 4.56472 12.9399 6.77386L12.9399 15.6536C12.9399 17.8627 11.1491 19.6536 8.93994 19.6536L4.70435 19.6536C2.49521 19.6536 0.704345 17.8627 0.704345 15.6536L0.704346 6.77386Z"
					fill="url(#paint0_linear_7460_54421)"
				/>
				<path
					d="M0.704346 6.77386C0.704346 4.56472 2.49521 2.77386 4.70435 2.77386L8.93994 2.77386C11.1491 2.77386 12.9399 4.56472 12.9399 6.77386L12.9399 15.6536C12.9399 17.8627 11.1491 19.6536 8.93994 19.6536L4.70435 19.6536C2.49521 19.6536 0.704345 17.8627 0.704345 15.6536L0.704346 6.77386Z"
					fill="url(#paint1_linear_7460_54421)"
				/>
				<path
					d="M0.704346 6.77386C0.704346 4.56472 2.49521 2.77386 4.70435 2.77386L8.93994 2.77386C11.1491 2.77386 12.9399 4.56472 12.9399 6.77386L12.9399 15.6536C12.9399 17.8627 11.1491 19.6536 8.93994 19.6536L4.70435 19.6536C2.49521 19.6536 0.704345 17.8627 0.704345 15.6536L0.704346 6.77386Z"
					fill="url(#paint2_angular_7460_54421)"
					fillOpacity="0.2"
				/>
				<path
					d="M0.704346 6.77386C0.704346 4.56472 2.49521 2.77386 4.70435 2.77386L8.93994 2.77386C11.1491 2.77386 12.9399 4.56472 12.9399 6.77386L12.9399 15.6536C12.9399 17.8627 11.1491 19.6536 8.93994 19.6536L4.70435 19.6536C2.49521 19.6536 0.704345 17.8627 0.704345 15.6536L0.704346 6.77386Z"
					fill="url(#paint3_angular_7460_54421)"
					fillOpacity="0.1"
				/>
				<path
					d="M0.704346 6.77386C0.704346 4.56472 2.49521 2.77386 4.70435 2.77386L8.93994 2.77386C11.1491 2.77386 12.9399 4.56472 12.9399 6.77386L12.9399 15.6536C12.9399 17.8627 11.1491 19.6536 8.93994 19.6536L4.70435 19.6536C2.49521 19.6536 0.704345 17.8627 0.704345 15.6536L0.704346 6.77386Z"
					fill="url(#paint4_linear_7460_54421)"
					fillOpacity="0.2"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_7460_54421"
					x1="3.65465"
					y1="11.2138"
					x2="24.4083"
					y2="8.22432"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF3873" />
					<stop offset="0.473958" stopColor="#711EFF" />
					<stop offset="1" stopColor="#3FE5FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_7460_54421"
					x1="5.29885"
					y1="3.55103"
					x2="14.0898"
					y2="15.5843"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F6F063" />
					<stop offset="1" stopColor="#E07774" stopOpacity="0" />
				</linearGradient>
				<radialGradient
					id="paint2_angular_7460_54421"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(4.55414 8.70875) rotate(53.8167) scale(4.26583 4.6344)"
				>
					<stop offset="0.925861" stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0" />
				</radialGradient>
				<radialGradient
					id="paint3_angular_7460_54421"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(7.58942 7.14243) rotate(45.9301) scale(2.86331 4.42691)"
				>
					<stop offset="0.0864251" stopColor="white" />
					<stop offset="0.94115" stopColor="white" stopOpacity="0" />
				</radialGradient>
				<linearGradient
					id="paint4_linear_7460_54421"
					x1="6.39605"
					y1="7.46245"
					x2="15.6486"
					y2="12.7255"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default LightningIcon;
