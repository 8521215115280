'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	LPDetailsMemo,
	YourLPDetailsMemo,
} from '../PerpPageStats/ViewDetailsForMarket';
import Modal from './Modal';
import { useState } from 'react';
import Button from '../Button';
import useAccountData from 'src/hooks/useAccountData';
import { addFeeAprsToPool } from 'src/hooks/useLiquidityPools';

const LPDetailsModal = () => {
	const liquidityPools = useDriftStore((s) => s.liquidityPoolInfo);
	const currentMarket = useDriftStore((s) => s.selectedMarket?.current?.market);
	const currentAccount = useAccountData();
	const setState = useDriftStore((s) => s.set);

	// fetch fee data for pool, this call can be unnecessarily costly so only do it when users open this popup.
	// if it's already been fetched, will return early
	addFeeAprsToPool(currentMarket?.marketIndex);

	const [currentSelection, setCurrentSelection] = useState('poolDetails');

	const hideLpDetailsModal = () => {
		setState((s) => {
			s.modals.showLpDetailsModal = false;
		});
	};

	const showAddLiqModal = () => {
		hideLpDetailsModal();

		setState((s) => {
			s.modals.showAddLiquidityModal = true;
		});
	};

	const showRemoveLiqModal = () => {
		hideLpDetailsModal();

		setState((s) => {
			s.modals.showRemoveLiquidityModal = true;
		});
	};

	const currentPool = liquidityPools?.pools.find(
		(pool) =>
			pool.marketConfig.marketIndex === (currentMarket?.marketIndex || 0)
	);

	const currentPoolOrder = currentAccount?.openLpOrders?.find(
		(lpOrder) => lpOrder.marketIndex === currentPool?.marketConfig.marketIndex
	);

	return (
		<Modal onClose={hideLpDetailsModal}>
			<Modal.Body>
				<Modal.Header borderBottom={false} onClose={hideLpDetailsModal} showX>
					<Modal.Title>{currentMarket.symbol} BAL Details</Modal.Title>
				</Modal.Header>
				<Modal.Navigation
					className="justify-start"
					currentSelection={currentSelection}
					onChange={(selection) => setCurrentSelection(selection)}
					options={[
						{
							value: 'poolDetails',
							label: 'Pool Details',
						},
						{
							value: 'yourLiquidity',
							label: 'Your Liquidity',
						},
					]}
				/>
				<Modal.Content>
					{currentSelection === 'poolDetails' ? (
						<LPDetailsMemo liquidityPool={currentPool} />
					) : (
						<YourLPDetailsMemo
							liquidityPool={currentPool}
							openOrder={currentPoolOrder}
						/>
					)}
					{currentSelection === 'poolDetails' ? (
						<Modal.ButtonBar>
							<Button.Secondary
								className="flex-grow w-full sm:grow-0"
								onClick={hideLpDetailsModal}
								size="MEDIUM"
							>
								Close
							</Button.Secondary>
						</Modal.ButtonBar>
					) : (
						<Modal.ButtonBar>
							<Button.Secondary
								positiveGreen
								className="w-[50%] flex-grow sm:grow-0 items-center"
								onClick={showAddLiqModal}
								size="MEDIUM"
							>
								Add Liquidity
							</Button.Secondary>
							<Button.Secondary
								negativeRed
								className="w-[50%] flex-grow sm:grow-0 items-center"
								onClick={showRemoveLiqModal}
								size="MEDIUM"
								disabled={currentPool.userLpShares.eqZero()}
							>
								Remove
							</Button.Secondary>
						</Modal.ButtonBar>
					)}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default LPDetailsModal;
