'use client';

import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	MarketType,
	OrderAction,
	OrderActionExplanation,
	OrderStatus,
	OrderType,
	PositionDirection,
	PublicKey,
	QUOTE_PRECISION,
	QUOTE_PRECISION_EXP,
	WrappedEvent,
	ZERO,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId, UISerializableOrder } from '@drift/common';
import {
	EventHandlingProps,
	useCurrentUserForMarketOrderEventsRef,
} from './MarketOrderToastEventEmitterProvider';
import { useEffect } from 'react';
import { DevEventEmitter, DevEvents } from '../../@types';
import useAppEventEmitter from '../../hooks/useAppEventEmitter';
import useCurrentSlotRef from '../../hooks/useCurrentSlotRef';
import useMarketStateStore from '../../stores/useMarketStateStore';
import { dlog } from '../../dev';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import { SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY } from '../../constants/constants';
import { DriftWindow } from '../../window/driftWindow';
import { MarketOrderToastIdHandler } from './toastIdHandlingUtils';

const BASE_ORDER_SIZE = BASE_PRECISION.muln(10); // Buy 10 of the base asset
const BASE_INCREMENT_FILL_SIZE = BASE_PRECISION.muln(1); // increment by 10% of the base order size
const DEFAULT_AUCTION_DURATION = 20;
const DEFAULT_ORDER_MAX_LENGTH_SECONDS = 30; // Affects the maxTs field in the order record

/*
 * ⭐️ LOGIC TO ENABLE DEV_TESTING MARKET ORDER LOGIC AND EDGE CASES BY MANUALLY EMITTING EVENTS ⭐️

	REMAINING TODOS ::
	- <mandatory improvements>
	- Self filled txs are triggered correctly but the simulation is ALWAYS failing (and hence preventing the tx from getting sent)
		- This is happening in the v1 toasts as well it looks like
		- Need to test self filling once this is fixed
	- Orders re-use the same IDs until confirmed, so state can get screwed up if someone tries to make a market order while another is pending because no deterministic way to distinguish between them ... any option to solve this without blocking the new order from the UI?

	- <optional improvements>
	- Decide a way to get the on-chain timestamps to use for implicit order expiry events
	- Decide if we want to add logic to convert position-size changes to implicit fill events
	- Decide if we want to convert "an order disappearing from state" into an implicit cancel/expire event

	<waiting for other opinions + testing>
	- Decide what to do about the warning=>bail states. We've currently disabled them from working by commenting out the time_elapsed event handling in the state machine
	- Remove v1 auction logic and final refactoring after this

	- TODO Scratchpad
	- [x] Test that everything works with the v2 auctions disabled again
	- [X] Clean up TODO:WRAP_MARKET_ORDERS todos
	- [x] Chester PR feedback
		- [x] Product feedback to pass on from chester
			- Add something to indicate how much time is left in the auction
			- Add a tooltip or something to give more info about auctions
		- [x] Change "Progress:" to "Auction Progress:" , need to double check how these toasts are being handled for non-auction market orders first ... going to leave this as a todo
	- [x] Create a way to track on-chain timestamp for the implicit expiries
		- [x] Drift Clock
	- [x] Think of a solution for user submitting a second order before the previous one confirms
		- [x] Not possible to get self-filling working with this - because it always uses the initial ID. Should either figure this out or confirm that everything runs smooth here at least.
		- [x] Create some kind of "pre-confirmation" cache. Where we can store relevant order info until we get an order record that confirms the order ID. 
		- [x] Test toasts doing the right thing when creating two orders quickly
		- [x] Add unit testing for the ID handler
	- [❌] Try get fully working process working while blockchain events are disabled. 
		- Will have to test again but from memory: the issue here was that we don't get the final ORDER UPDATE when it gets completely filled (the order just disappears instead).
		- This is awkward because we lose the "cumulative quote / base filled" data which is quite important for the toast state.
		- Going to ignore for now
	- [x] Implicit order expiry events ?
		- Not going to attempt this for now, because it would introduce a race-condition where if the order was completely filled (removing the order from user's account), and we got the updated "order no longer exists" event BEFORE we get the "order was completely filled" event, then we would assume the order was expired and display the wrong toast.
	- [x] Decide what to do about the warning=>bail states
		- Going to DISABLE the bail states for now. But put this in a FLAG! The state machine uses the flag to decide whether to react to the time_elapsed events.
	TODOs 13/05:
	- [x] Caught a case where the transaction timed out but the time_elapsed events made it look like it died before it actually should have ... I think that we actually don't eneed these events anymore and the rest of the handling can be trusted enough to know when things time out or fall over.
		- [x] Update the state machine without unnecessary warning states.
	- [x] Look at TODO-SPOT-BRACKET-ORDERS
	- [x] Fix issue where jit step is still being created even for orders which don't have jit
		- [x] Is creating a jit step
	- [x] Get self-filling working
		- Possible way to test could just be running the self-filling logic early?
	- [ ] All Test Cases:
		- [ ] Test SDK Changes	
			- [✅] Multi-sign + Versioned
				- [✅] Place and take
				- [✅] Place and take w TP SL
				- [✅] JIT Market order w TP SL
			- [ ] Multi-sign + not-versioned
				- [✅] Place and take -- NOTES : These succeeded but I just realised that place and take shouldn't work with non-versioned wallets .. so I'm guessing it was a regular versioned one
				- [✅] Place and take w TP SL -- NOTES : Ditto - see above
				- [✅] JIT Market order
				- [❌] JIT Market order w TP SL -- NOTES : 1) Caught a case where the transaction expired without confirming. The weird thing though is that the countdown timer thing got to 0 well before the actual "transaction didn't confirm" event came through to trigger the toast to go into that warning state .. ⭐️ takeaway is that it looks like the timeout countdown does not match the while valid time of the transaction
				- [❌] JIT Market order w TP SL -- NOTES : 2) Caught a case where it seemed to miss the events. The order looks like it filled but was stuck on step 2. The JIT progress indicator never displayed either .. ⭐️ never caught the order record?
				- [❌] JIT Market order w TP SL -- NOTES : 3) The order got stuck on the JIT step where it was "empty" but never progessing to the "completing" stage - it eventually jumped to the complete step when fully filled .. ⭐️ should it have implicitly jumped to the completing stage when the jit timer ran out? Can only imagine that it didn't because we missed or skipped the action records that otherwised would have triggered the state change
				- [❌] JIT Market order w TP SL -- NOTES : 4) The order got stuck on the JIT step but the timer wasn't ticking down .. it eventually jumped to the expired state correctly when the order expired .. ⭐️ need to debug how this is possible. If the data was there to show the timer than it should be ticking down regardless 
				- Looks like we just need to come back to this because it's failing for a bunch of different reasons 🧐
				- [✅] JIT Market order w TP SL
				- [✅] JIT Market order w TP SL
				- [✅] JIT Market order w TP SL
				- [✅] JIT Market order w TP SL
				- [✅] JIT Market order w TP SL
			- [✅] Multi-sign + not-versioned (actual non-supporting wallet)
				- [X] Place and take (NA for non-versioned)
				- [X] Place and take w TP SL (NA for non-versioned)
				- [✅] JIT Market order
				- [✅] (order expired) JIT Market order w TP SL
				- [✅] JIT Market order w TP SL
			- [✅] Single-sign + versioned
				- [✅] Limit Order
			- [✅] Single-sign + not-versioned
				- [✅] Limit Order
			- [✅] Single-sign + not-versioned (actual non-supporting wallet)
				- [✅] Limit Order
				- [✅] Cancel All Orders
			- [ ] Test transactions with ledger
			- ⭐️ NOTES : This applies to both LIMIT ORDER tests above. Should add the logic for these toasts to react to the user signature and display the transaction timeout countdown indicator.
		- [✅] Test self-filling
	- [✅] Prep SDK PR
	- [✅] Changes to the other SDKs which use the Drift SDK
	- [✅] TP/SL order info
	OPTIONAL TODOS
	- [ ] Add back the X button?
	- [ ] Get mermaid chart in sync again
	- [ ] Test the current warning_no_order_record=>bail state. This should be very rare.
	- [ ] UI Polishing
		- [ ] Add animations and transitions
			- [ ] Animate the progress indicators
			- [ ] Animate the steps progressing
	AFTER HAPPY WITH MERGED BETA TESTING
	- [ ] Come back to the BAIL_STATES_ENABLED and END_STATE_FROM_COMPLETING_STATE_IF_TIME_ELAPSED depending on results from manual testing
	- [ ] Remove v1 auction logic and final refactoring after this
	- [ ] Go back and refactor duplicate code when fetching/generating toast IDs. Properties should be renamed to reflect the new naming conventions too.
 */

type EventHandlerConfig = [keyof DevEvents, DevEvents[keyof DevEvents]];

type SpoofedOrderInitProps = {
	identifierNonce: number;
	marketIndex: number;
	marketType: MarketType;
	orderId: number;
	marketId: MarketId;
	baseAmountOrdered: BN;
	direction: PositionDirection;
	maxTs: BN;
	orderType: string;
};

const ALREADY_SETUP_REF = { current: false };
const LATEST_ORDER_ID_REF = { current: 1 };
const LATEST_ORDER_INIT_PROPS_REF = {
	current: undefined as SpoofedOrderInitProps,
};

const getNewOrderId = () => {
	return LATEST_ORDER_ID_REF.current++;
};

const getTs = () => {
	return new BN(Math.floor(Date.now() / 1000));
};

const getMaxTs = () => {
	return new BN(
		DriftWindow.chainClock.getState(DEFAULT_COMMITMENT_LEVEL).ts +
			DEFAULT_ORDER_MAX_LENGTH_SECONDS
	);
};

const generateNewOrderProps = (): SpoofedOrderInitProps => {
	const orderId = getNewOrderId();
	const marketId = MarketId.createPerpMarket(0); // Always use SOL-PERP for now

	const spoofedProps = {
		identifierNonce: orderId,
		marketIndex: marketId.marketIndex,
		marketType: marketId.marketType,
		orderId: orderId,
		marketId,
		baseAmountOrdered: BASE_ORDER_SIZE,
		direction: PositionDirection.LONG,
		maxTs: getMaxTs(),
		orderType: 'market',
	};

	LATEST_ORDER_INIT_PROPS_REF.current = spoofedProps;
	return spoofedProps;
};

const useDevMarketOrderEvents = (props: EventHandlingProps) => {
	const appEventEmitter = useAppEventEmitter();
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();
	const knownOrders = props.knownOrders;
	const marketState = useMarketStateStore();

	const currentSlot = useCurrentSlotRef(1_000);

	const getStore = useDriftStore((s) => s.get);

	// <NEW STUFF>
	// # Event Handler Logic
	const handleInit = () => {
		const newOrderProps = generateNewOrderProps();

		const marketOrderId =
			MarketOrderToastIdHandler.handleNewMarketOrderWithUnknownOrderId({
				user: currentUserRef.current,
				idNonce: newOrderProps.identifierNonce,
				marketId: newOrderProps.marketId,
				baseSize: newOrderProps.baseAmountOrdered,
				direction: newOrderProps.direction,
			});

		props.setOrUpdateKnownOrder(marketOrderId, {
			marketId: newOrderProps.marketId,
			id: marketOrderId,
			baseAmountOrdered: newOrderProps.baseAmountOrdered,
			cumulativeBaseAmountFilled: ZERO,
			cumulativeQuoteAmountFilled: ZERO,
			maxTs: newOrderProps.maxTs,
		});

		props.onParsedEvent({
			type: 'init',
			marketOrderId: marketOrderId,
			data: {
				marketId: newOrderProps.marketId,
				id: marketOrderId,
				cumulativeBaseAmountFilled: ZERO,
				cumulativeQuoteAmountFilled: ZERO,
				baseAmountOrdered: newOrderProps.baseAmountOrdered,
				direction: newOrderProps.direction,
				auctionEnabled: true,
				lastAction: undefined,
				lastActionExplanation: undefined,
				auctionStartSlot: undefined,
				auctionDuration: undefined,
			},
		});
	};

	const handleTxSigned = () => {
		const connection = getStore().connection.current;
		connection.getBlockHeight(DEFAULT_COMMITMENT_LEVEL).then((blockHeight) => {
			/**
			 * In here we want to spoof a signed tx event for the previously created order, and we will send it through the regular app event emitter to simulate as if it was a real event
			 *
			 * At the moment these events only rely on the market id and the order id so we just need to spoof that
			 */

			// @ts-ignore :: annoying to make the types of the event string match
			appEventEmitter.emit('expectedAuctionTxSigned', {
				v2Props: LATEST_ORDER_INIT_PROPS_REF.current,
				auctionIdentifierProps: {
					orderId: LATEST_ORDER_INIT_PROPS_REF.current.orderId,
					marketIndex: LATEST_ORDER_INIT_PROPS_REF.current.marketId.marketIndex,
					marketType: LATEST_ORDER_INIT_PROPS_REF.current.marketId.marketType,
					direction: LATEST_ORDER_INIT_PROPS_REF.current.direction,
				},
				signedTxData: [
					{
						txSig: undefined,
						signedTx: undefined,
						lastValidBlockHeight:
							blockHeight + SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY,
						blockHash: undefined,
					},
				],
			});
		});
	};

	const generateOrderRecord = (): WrappedEvent<'OrderRecord'> => {
		const spoofedOrder = LATEST_ORDER_INIT_PROPS_REF.current;

		const currentMarkPriceForOrder = marketState.getMarkPriceForMarket(
			spoofedOrder.marketId
		);

		// make auction start price $1 better than the mark price, based on the direction
		const auctionStartPrice = ENUM_UTILS.match(
			spoofedOrder.direction,
			PositionDirection.LONG
		)
			? currentMarkPriceForOrder.val.sub(QUOTE_PRECISION)
			: currentMarkPriceForOrder.val.add(BASE_PRECISION);

		// make auction end price $1 worse than the mark price, based on the direction
		const auctionEndPrice = ENUM_UTILS.match(
			spoofedOrder.direction,
			PositionDirection.LONG
		)
			? currentMarkPriceForOrder.val.add(QUOTE_PRECISION)
			: currentMarkPriceForOrder.val.sub(BASE_PRECISION);

		const orderRecord: WrappedEvent<'OrderRecord'> = {
			txSig: undefined,
			slot: currentSlot.current,
			txSigIndex: 0,
			eventType: 'OrderRecord',
			ts: getTs(), // Spoofing the timestamp using the true current time, technically wouldn't match the time of live events from the blockchain .. but it shouldn't affect how the market order toasts work

			user: currentUserRef.current,
			order: {
				orderType: OrderType.MARKET, // Is this ok???
				status: OrderStatus.INIT,
				marketType: MarketType.PERP,
				slot: new BN(currentSlot.current),
				orderId: spoofedOrder.orderId,
				userOrderId: spoofedOrder.orderId,
				marketIndex: spoofedOrder.marketId.marketIndex,
				price: null, // Is this ok???
				baseAssetAmount: spoofedOrder.baseAmountOrdered,
				quoteAssetAmount: null, // Is this ok???
				baseAssetAmountFilled: ZERO,
				quoteAssetAmountFilled: ZERO,
				direction: spoofedOrder.direction,
				reduceOnly: false,
				triggerPrice: null,
				triggerCondition: null,
				existingPositionDirection: null,
				postOnly: false,
				immediateOrCancel: false,
				oraclePriceOffset: 0,
				auctionDuration: DEFAULT_AUCTION_DURATION,
				auctionStartPrice: auctionStartPrice,
				auctionEndPrice: auctionEndPrice,
				maxTs: spoofedOrder?.maxTs ?? getMaxTs(),
			},
		};

		return orderRecord;
	};

	const generateBlankOrderState = (): UISerializableOrder => {
		const previouslySpoofedOrder = LATEST_ORDER_INIT_PROPS_REF.current;

		return {
			price: null,
			baseAssetAmount: BigNum.from(
				previouslySpoofedOrder.baseAmountOrdered,
				BASE_PRECISION_EXP
			),
			baseAssetAmountFilled: BigNum.zero(BASE_PRECISION_EXP),
			quoteAssetAmount: null,
			quoteAssetAmountFilled: BigNum.zero(QUOTE_PRECISION_EXP),
			fee: null,
			triggerPrice: null,
			oraclePriceOffset: null,
			auctionStartPrice: null,
			auctionEndPrice: null,
			status: OrderStatus.INIT,
			orderType: OrderType.MARKET,
			ts: getTs(),
			orderId: previouslySpoofedOrder.orderId,
			userOrderId: previouslySpoofedOrder.orderId,
			marketIndex: previouslySpoofedOrder.marketId.marketIndex,
			direction: previouslySpoofedOrder.direction,
			reduceOnly: false,
			triggerCondition: null,
			postOnly: false,
			immediateOrCancel: false,
			existingPositionDirection: null,
			slot: new BN(currentSlot.current),
			triggered: null,
			auctionDuration: DEFAULT_AUCTION_DURATION,
			marketType: previouslySpoofedOrder.marketId.marketType,
			timeInForce: null,
			maxTs: previouslySpoofedOrder?.maxTs ?? getMaxTs(),
		};
	};

	const generateBlankOrderActionRecord =
		(): WrappedEvent<'OrderActionRecord'> => {
			const previouslySpoofedOrder = LATEST_ORDER_INIT_PROPS_REF.current;

			const spoofedMarketOrderId =
				MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
					user: currentUserRef.current,
					marketId: previouslySpoofedOrder.marketId,
					orderId: previouslySpoofedOrder.orderId,
					baseSize: previouslySpoofedOrder.baseAmountOrdered,
					direction: previouslySpoofedOrder.direction,
				});

			const knownOrder = knownOrders.current.get(spoofedMarketOrderId);

			const priceForFill = marketState.getMarkPriceForMarket(
				previouslySpoofedOrder.marketId
			);

			const fillOrderActionRecord: WrappedEvent<'OrderActionRecord'> = {
				txSig: undefined,
				slot: currentSlot.current,
				txSigIndex: 0,
				eventType: 'OrderActionRecord',
				ts: getTs(),
				action: null,
				actionExplanation: null,
				marketIndex: previouslySpoofedOrder.marketId.marketIndex,
				marketType: previouslySpoofedOrder.marketId.marketType,
				filler: null,
				fillerReward: null,
				fillRecordId: null,
				baseAssetAmountFilled: null,
				quoteAssetAmountFilled: null,
				takerFee: null,
				makerFee: null,
				referrerReward: null,
				quoteAssetAmountSurplus: null,
				spotFulfillmentMethodFee: null,
				taker: currentUserRef.current,
				takerOrderId: previouslySpoofedOrder.orderId,
				takerOrderDirection: previouslySpoofedOrder.direction,
				takerOrderBaseAssetAmount: previouslySpoofedOrder.baseAmountOrdered,
				takerOrderCumulativeBaseAssetAmountFilled:
					knownOrder?.cumulativeBaseAmountFilled ?? ZERO,
				takerOrderCumulativeQuoteAssetAmountFilled:
					knownOrder?.cumulativeQuoteAmountFilled ?? ZERO,
				maker: null,
				makerOrderId: null,
				makerOrderDirection: null,
				makerOrderBaseAssetAmount: null,
				makerOrderCumulativeBaseAssetAmountFilled: null,
				makerOrderCumulativeQuoteAssetAmountFilled: null,
				oraclePrice: priceForFill.val,
			};

			return fillOrderActionRecord;
		};

	const generateFillActionRecord = (
		fillSize: BN
	): WrappedEvent<'OrderActionRecord'> => {
		const previouslySpoofedOrder = LATEST_ORDER_INIT_PROPS_REF.current;
		const spoofedMarketOrderId =
			MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
				user: currentUserRef.current,
				marketId: previouslySpoofedOrder.marketId,
				orderId: previouslySpoofedOrder.orderId,
				baseSize: previouslySpoofedOrder.baseAmountOrdered,
				direction: previouslySpoofedOrder.direction,
			});

		const knownOrder = knownOrders.current.get(spoofedMarketOrderId);

		const priceForFill = marketState.getMarkPriceForMarket(
			previouslySpoofedOrder.marketId
		);
		const fillSizeBigNum = BigNum.from(fillSize, BASE_PRECISION_EXP);

		const quoteFilled = fillSizeBigNum
			.mul(priceForFill)
			.shiftTo(QUOTE_PRECISION_EXP);

		const fillOrderActionRecord: WrappedEvent<'OrderActionRecord'> = {
			txSig: undefined,
			slot: currentSlot.current,
			txSigIndex: 0,
			eventType: 'OrderActionRecord',
			ts: getTs(),
			action: OrderAction.FILL,
			actionExplanation: OrderActionExplanation.ORDER_FILLED_WITH_AMM,
			marketIndex: previouslySpoofedOrder.marketId.marketIndex,
			marketType: previouslySpoofedOrder.marketId.marketType,
			filler: new PublicKey('FetTyW8xAYfd33x4GMHoE7hTuEdWLj1fNnhJuyVMUGGa'),
			fillerReward: null,
			fillRecordId: null,
			baseAssetAmountFilled: fillSizeBigNum.val,
			quoteAssetAmountFilled: quoteFilled.val,
			takerFee: null,
			makerFee: null,
			referrerReward: null,
			quoteAssetAmountSurplus: null,
			spotFulfillmentMethodFee: null,
			taker: currentUserRef.current,
			takerOrderId: previouslySpoofedOrder.orderId,
			takerOrderDirection: previouslySpoofedOrder.direction,
			takerOrderBaseAssetAmount: previouslySpoofedOrder.baseAmountOrdered,
			takerOrderCumulativeBaseAssetAmountFilled: (
				knownOrder?.cumulativeBaseAmountFilled ?? ZERO
			).add(fillSizeBigNum.val),
			takerOrderCumulativeQuoteAssetAmountFilled: (
				knownOrder?.cumulativeQuoteAmountFilled ?? ZERO
			).add(quoteFilled.val),
			maker: null,
			makerOrderId: null,
			makerOrderDirection: ENUM_UTILS.match(
				knownOrder?.direction,
				PositionDirection.LONG
			)
				? PositionDirection.SHORT
				: PositionDirection.LONG,
			makerOrderBaseAssetAmount: fillSizeBigNum.val,
			makerOrderCumulativeBaseAssetAmountFilled: fillSizeBigNum.val,
			makerOrderCumulativeQuoteAssetAmountFilled: null,
			oraclePrice: priceForFill.val,
		};

		return fillOrderActionRecord;
	};

	const handleIncrementalFill: DevEventEmitter[' _emitType']['market_order_testing__manual_incremental_fill'] =
		() => {
			const context = DriftWindow.marketOrderToastTestingContext;

			dlog(`v2_auctions`, `triggering_incremental_fill::context=${context}`);

			if (context === 'blockchain_event') {
				//// Blockchain event context, need to create an appropriate spoof order action record
				const incrementalFillRecord = generateFillActionRecord(
					BASE_INCREMENT_FILL_SIZE
				);

				DriftWindow.devEventEmitter.emit(
					'market_order_testing__order_action_event',
					incrementalFillRecord
				);
			} else if (context === 'order_state') {
				//// Order state context, need to create a spoof state for expected state after an incremental fill
				const blankOrderState = generateBlankOrderState();

				const toastId = MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
					user: currentUserRef.current,
					marketId: LATEST_ORDER_INIT_PROPS_REF.current.marketId,
					orderId: LATEST_ORDER_INIT_PROPS_REF.current.orderId,
					baseSize: LATEST_ORDER_INIT_PROPS_REF.current.baseAmountOrdered,
					direction: LATEST_ORDER_INIT_PROPS_REF.current.direction,
				});

				const knownOrder = knownOrders.current.get(toastId);

				const priceForFill = marketState.getMarkPriceForMarket(
					LATEST_ORDER_INIT_PROPS_REF.current.marketId
				);

				const baseFilledBigNum = BigNum.from(
					BASE_INCREMENT_FILL_SIZE,
					BASE_PRECISION_EXP
				);

				const quoteFilledBigNum = baseFilledBigNum
					.mul(priceForFill)
					.shiftTo(QUOTE_PRECISION_EXP);

				blankOrderState.baseAssetAmountFilled = BigNum.from(
					knownOrder?.cumulativeBaseAmountFilled ?? ZERO,
					BASE_PRECISION_EXP
				).add(baseFilledBigNum);

				blankOrderState.quoteAssetAmountFilled = BigNum.from(
					knownOrder?.cumulativeQuoteAmountFilled ?? ZERO,
					QUOTE_PRECISION_EXP
				).add(quoteFilledBigNum);

				DriftWindow.devEventEmitter.emit(
					'market_order_testing__order_state_change',
					blankOrderState
				);
			} else {
				throw new Error('Unhandled test context type');
			}
		};

	const handleCompleteFill: DevEventEmitter[' _emitType']['market_order_testing__manual_complete_fill'] =
		() => {
			const context = DriftWindow.marketOrderToastTestingContext;

			dlog(`v2_auctions`, `triggering_complete_fill::context=${context}`);

			if (context === 'blockchain_event') {
				const previouslySpoofedOrder = LATEST_ORDER_INIT_PROPS_REF.current;

				const spoofedMarketOrderId =
					MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
						user: currentUserRef.current,
						marketId: previouslySpoofedOrder.marketId,
						orderId: previouslySpoofedOrder.orderId,
						baseSize: previouslySpoofedOrder.baseAmountOrdered,
						direction: previouslySpoofedOrder.direction,
					});

				const knownOrder = knownOrders.current.get(spoofedMarketOrderId);

				const orderCompletionFillSize = knownOrder.baseAmountOrdered.sub(
					knownOrder.cumulativeBaseAmountFilled
				);

				const orderCompletionFillRecord = generateFillActionRecord(
					orderCompletionFillSize
				);

				DriftWindow.devEventEmitter.emit(
					'market_order_testing__order_action_event',
					orderCompletionFillRecord
				);
			} else if (context === 'order_state') {
				const blankOrderState = generateBlankOrderState();

				const toastId = MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
					user: currentUserRef.current,
					marketId: LATEST_ORDER_INIT_PROPS_REF.current.marketId,
					orderId: LATEST_ORDER_INIT_PROPS_REF.current.orderId,
					baseSize: LATEST_ORDER_INIT_PROPS_REF.current.baseAmountOrdered,
					direction: LATEST_ORDER_INIT_PROPS_REF.current.direction,
				});

				const knownOrder = knownOrders.current.get(toastId);

				const priceForFill = marketState.getMarkPriceForMarket(
					LATEST_ORDER_INIT_PROPS_REF.current.marketId
				);

				const totalBaseFilled = BigNum.from(
					knownOrder.baseAmountOrdered,
					BASE_PRECISION_EXP
				);

				const baseFilledInUpdate = BigNum.from(
					knownOrder.baseAmountOrdered.sub(
						knownOrder.cumulativeBaseAmountFilled
					),
					BASE_PRECISION_EXP
				);

				const quoteFilledInUpdate = baseFilledInUpdate
					.mul(priceForFill)
					.shiftTo(QUOTE_PRECISION_EXP);

				const totalQuoteFilled = BigNum.from(
					knownOrder.cumulativeQuoteAmountFilled,
					QUOTE_PRECISION_EXP
				).add(quoteFilledInUpdate);

				blankOrderState.baseAssetAmountFilled = totalBaseFilled;
				blankOrderState.quoteAssetAmountFilled = totalQuoteFilled;

				DriftWindow.devEventEmitter.emit(
					'market_order_testing__order_state_change',
					blankOrderState
				);
			} else {
				throw new Error('Unhandled test context type');
			}
		};

	const handleOrderRecord: DevEventEmitter[' _emitType']['market_order_testing__manual_receive_order'] =
		() => {
			const context = DriftWindow.marketOrderToastTestingContext;

			dlog(`v2_auctions`, `triggering_order_record::context=${context}`);

			if (context === 'blockchain_event') {
				// Need to create an OrderRecord for the spoofed order
				const spoofedOrderRecord = generateOrderRecord();

				DriftWindow.devEventEmitter.emit(
					'market_order_testing__order_record_event',
					spoofedOrderRecord
				);
			} else if (context === 'order_state') {
				// Need to create a spoofed order state for the expected state after the order record
				const blankOrderState = generateBlankOrderState();

				DriftWindow.devEventEmitter.emit(
					'market_order_testing__order_state_change',
					blankOrderState
				);
			} else {
				throw new Error('Unhandled test context type');
			}
		};

	const handleOrderExpired: DevEventEmitter[' _emitType']['market_order_testing__manual_order_expired'] =
		() => {
			const blankOrderActionRecord = generateBlankOrderActionRecord();

			blankOrderActionRecord.action = OrderAction.EXPIRE;
			blankOrderActionRecord.actionExplanation =
				OrderActionExplanation.ORDER_EXPIRED;

			DriftWindow.devEventEmitter.emit(
				'market_order_testing__order_action_event',
				blankOrderActionRecord
			);
		};

	const handleOrderCancelled: DevEventEmitter[' _emitType']['market_order_testing__manual_order_cancelled'] =
		() => {
			const blankOrderActionRecord = generateBlankOrderActionRecord();

			blankOrderActionRecord.action = OrderAction.CANCEL;
			blankOrderActionRecord.actionExplanation = OrderActionExplanation.NONE;

			DriftWindow.devEventEmitter.emit(
				'market_order_testing__order_action_event',
				blankOrderActionRecord
			);
		};

	const handleOrderFailed = () => {
		// @ts-ignore
		appEventEmitter.emit('expectedAuctionTxFailed', {
			auctionIdentifierProps: LATEST_ORDER_INIT_PROPS_REF.current,
			v2Props: {
				marketId: LATEST_ORDER_INIT_PROPS_REF.current.marketId,
				orderId: LATEST_ORDER_INIT_PROPS_REF.current.orderId,
			},
		});
	};

	// # Event Handler Setup
	const eventHandlerConfigs: EventHandlerConfig[] = [
		['market_order_testing__manual_init', handleInit],
		['market_order_testing__manual_tx_signed', handleTxSigned],
		['market_order_testing__manual_incremental_fill', handleIncrementalFill],
		['market_order_testing__manual_complete_fill', handleCompleteFill],
		['market_order_testing__manual_receive_order', handleOrderRecord],
		['market_order_testing__manual_order_expired', handleOrderExpired],
		['market_order_testing__manual_order_cancelled', handleOrderCancelled],
		['market_order_testing__manual_tx_failed', handleOrderFailed],
	];

	useEffect(() => {
		if (ALREADY_SETUP_REF.current) return;

		ALREADY_SETUP_REF.current = true;

		const devEventEmitter = DriftWindow.devEventEmitter;

		eventHandlerConfigs.forEach(([key, handler]) => {
			devEventEmitter.on(key, handler);
		});
	}, []);
};

export default useDevMarketOrderEvents;
