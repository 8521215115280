'use client';

import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import { IfData } from 'src/utils/insuranceFund';
import UI_UTILS from 'src/utils/uiUtils';
import { format } from 'timeago.js';
import useIfDataStore from '../../stores/ifDataStore/useIfDataStore';
import Button from '../Button';
import { DetailsRow } from '../PerpPageStats/ViewDetailsForMarket';
import Text from '../Text/Text';
import Modal from './Modal';
import { useMemo } from 'react';

const ModalContent = () => {
	const actions = useDriftActions();
	const currentMarket = useDriftStore((s) => s.selectedIfStakeMarket);
	const ifStakeData = useIfDataStore((s) => s.IFData);
	const displayDecimals =
		useMarketsInfoStore(
			(s) =>
				s.allMarketsInfo?.spotLookup?.[currentMarket.marketIndex]?.genericInfo
					?.baseDisplayDecimals
		) ?? 2;

	const ifData = ifStakeData?.[currentMarket.marketIndex] as IfData;

	if (!ifData) {
		return null;
	}

	const ratioForProtocol = 1 - ifData.vault.ratioForStakers;
	const vaultAccountString = useMemo(() => {
		return ifData.vault.account?.vault?.toString();
	}, [ifData.vault.account]);

	const handleSettleRevenuePool = () => {
		actions.settleInsuranceFundRevenue(currentMarket.marketIndex);
	};

	const nextRevSettleSeconds = ifData.vault.secondsUntilNextRevenueSettle;

	const nextRevenueSettleDisplay = UI_UTILS.findDaysHoursMinutesFromNow(
		new Date(Date.now() + nextRevSettleSeconds * 1000)
	);

	return (
		<>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Vault Account</Text.BODY3>
				<Text.BODY3>
					<a
						href={`https://solscan.io/account/${vaultAccountString}`}
						target="_blank"
						rel="noreferrer"
					>
						View on Solscan
					</a>
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Current Vault Balance</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.toFixedLocaleString(
						ifData.vault.totalStake,
						displayDecimals
					)}{' '}
					{currentMarket.symbol}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Current Revenue Pool</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.toFixedLocaleString(
						ifData.vault.revenuePool,
						displayDecimals
					)}{' '}
					{currentMarket.symbol}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Payout Per Settle</Text.BODY3>
				<Text.BODY3 className="text-right">
					{ifData.vault.payoutRatio * 100}%
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Share of Payout</Text.BODY3>
				<Text.BODY3 className="text-right">
					{ifData.vault.ratioForStakers * 100}% (stakers) /{' '}
					{ratioForProtocol * 100}% (protocol)
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3 className="whitespace-nowrap">Next Payout</Text.BODY3>
				<Text.BODY3 className="text-right">
					{UI_UTILS.toFixedLocaleString(
						ifData.vault.ratioForStakers * ifData.vault.nextPayoutTotal,
						displayDecimals
					)}{' '}
					{currentMarket.symbol} (stakers) /{' '}
					{UI_UTILS.toFixedLocaleString(
						ratioForProtocol * ifData.vault.nextPayoutTotal,
						displayDecimals
					)}{' '}
					{currentMarket.symbol} (protocol)
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>APR Cap</Text.BODY3>
				<Text.BODY3 className="text-right">
					{ifData.vault.ratioForStakers * ifData.vault.maxApr}% (stakers) /{' '}
					{ratioForProtocol * ifData.vault.maxApr}% (protocol)
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Revenue Settle Period</Text.BODY3>
				<Text.BODY3 className="text-right">
					{ifData.vault.revenueSettlePeriodMinutes}m
				</Text.BODY3>
			</DetailsRow>
			{/* We could put the "settle now" button in this row, if it has been >= 1 hour */}
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Last Revenue Settle</Text.BODY3>
				<Text.BODY3 className="text-right">
					{format(new Date(ifData.vault.lastRevenueSettleMs))}
				</Text.BODY3>
			</DetailsRow>
			<DetailsRow fullWidth={true}>
				<Text.BODY3>Next Revenue Settle</Text.BODY3>
				<Text.BODY3 className="text-right">
					{nextRevenueSettleDisplay === 'now'
						? 'now'
						: nextRevenueSettleDisplay + ' from now'}
				</Text.BODY3>
			</DetailsRow>
			<div className="w-full mt-4">
				<Button.Primary
					size={'MEDIUM'}
					className="w-full"
					// Time until next settle should come from the sdk once the function has been merged
					disabled={nextRevSettleSeconds > 0}
					onClick={handleSettleRevenuePool}
				>
					Settle Revenue Pool
				</Button.Primary>
			</div>
		</>
	);
};

const InsuranceFundDetailsModal = () => {
	const currentMarket = useDriftStore((s) => s.selectedIfStakeMarket);
	const setState = useDriftStore((s) => s.set);

	const hideModal = () => {
		setState((s) => {
			s.modals.showInsuranceFundDetailsModal = false;
		});
	};

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>
						{currentMarket.symbol} Insurance Fund Vault Details
					</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<ModalContent />
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default InsuranceFundDetailsModal;
