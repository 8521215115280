'use client';

import {
	BN,
	BigNum,
	MarketType,
	OrderAction,
	OrderActionExplanation,
	OrderType,
	PRICE_PRECISION_EXP,
	PublicKey,
	WrappedEvent,
	ZERO,
} from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	MarketId,
	UISerializableOrder,
	sleep,
} from '@drift/common';
import React, {
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { Subject } from 'rxjs';
import { dlog } from '../../dev';
import useAppEventEmitter from '../../hooks/useAppEventEmitter';
import useCurrentUserAccount from '../../hooks/useCurrentUserAccount';
import { DriftBlockchainEventSubjectContext } from '../../providers/driftEvents/driftEventSubjectProvider';
import useDriftStore, {
	DriftAppEventEmitter,
} from '../../stores/DriftStore/useDriftStore';
import useDriftAccountStore from '../../stores/useDriftAccountsStore';
import {
	MarketOrderToastOrderInfo,
	MarketOrderToastStateTransitionEvent,
} from './MarketOrderToastStateTypes';
import useCurrentSlotRef from '../../hooks/useCurrentSlotRef';
import useInterval from '../../hooks/useInterval';
import useDevMarketOrderEvents from './useDevMarketOrderEvents';
import { DriftWindow } from '../../window/driftWindow';
import { MarketOrderToastId } from './MarketOrderToastStateTypes';
import {
	DEFAULT_COMMITMENT_LEVEL,
	SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY_OFFSET,
} from 'src/constants/constants';
import { getMarketStepSize } from '../../hooks/useMarketStepSize';
import useMarketsInfoStore from '../../stores/useMarketsInfoStore';
import { MarketOrderToastIdHandler } from './toastIdHandlingUtils';
import Env from 'src/environmentVariables/EnvironmentVariables';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';

const TEST_WITH_EVENTS_DISABLED = false;

const ARTIFICIAL_DELAY_TIME = 10_000;

const DEV_ARTIFICIALLY_DELAY_ORDER_ACTION_EVENTS = false;
const DEV_ARTIFICIALLY_DELAY_ORDER_RECORD_EVENTS = false;
const DEV_DISABLE_ORDER_ACTION_EVENTS = false;
const DEV_DISABLE_ORDER_RECORD_EVENTS = false;
const DEV_DISABLE_TX_CONFIRMED_EVENT = false;
const DEV_DISABLE_ORDER_STATE_READS = false;
const DEV_FORCE_NO_TX_EXPIRY_ATTCHED = false;

const getShouldUseBlockHeightOffset = (): boolean => {
	const defaultValue = true;

	const rpcVersion = Env.rpcVersion;

	const solanaCoreVersion = rpcVersion['solana-core'];

	if (!solanaCoreVersion) return defaultValue;

	const majorVersion = solanaCoreVersion.split('.')[0];

	if (!majorVersion) return defaultValue;

	const parsedMajorVersion = parseInt(majorVersion);

	if (isNaN(parsedMajorVersion)) return defaultValue;

	if (parsedMajorVersion >= 2) {
		return false;
	}

	return defaultValue;
};

/**
 * This provider is in charge of emitting events for the MarketOrderToast component. It listens to events from on chain, and other sources, to determine what events to emit for our context. These events should always flow through here so that there's a single source of truth for state changes.
 */

const isRelevantOrderType = (orderType: OrderType) => {
	return (
		ENUM_UTILS.match(orderType, OrderType.MARKET) ||
		ENUM_UTILS.match(orderType, OrderType.ORACLE)
	);
};

export const getIsTaker = (
	event: WrappedEvent<'OrderActionRecord'>,
	user: PublicKey
) => {
	const isTaker = event?.taker?.equals(user);
	const isMaker = event?.maker?.equals(user);

	if (!isTaker && !isMaker) {
		throw new Error(
			`Got user order action event where was not matching taker or maker`
		);
	}

	return isTaker;
};

export const getToastIdFromEvent = (
	event: WrappedEvent<'OrderActionRecord'> | WrappedEvent<'OrderRecord'>,
	user: PublicKey
): MarketOrderToastId => {
	// return event.
	if (event.eventType === 'OrderActionRecord') {
		const marketId = new MarketId(event.marketIndex, event.marketType);

		const isTaker = getIsTaker(event, user);

		const orderId = isTaker ? event.takerOrderId : event.makerOrderId;

		const baseSize = isTaker
			? event.takerOrderBaseAssetAmount
			: event.makerOrderBaseAssetAmount;

		const direction = isTaker
			? event.takerOrderDirection
			: event.makerOrderDirection;

		return MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
			user,
			marketId,
			baseSize,
			orderId,
			direction,
		});
	} else {
		if (!event.user.equals(user)) {
			dlog(`v2_auctions`, `order_record_with_non_matching_user`);
			return null; // Sometimes the order record will have the counterparty's order ID instead of the current user. Don't think anything can be done here -- but it should be fine to return null and let other events keep progressing the toast state.
		} else {
			dlog(`v2_auctions`, `order_record_with_matching_user`);
		}

		const marketId = ENUM_UTILS.match(event.order.marketType, MarketType.PERP)
			? MarketId.createPerpMarket(event.order.marketIndex)
			: MarketId.createSpotMarket(event.order.marketIndex);

		return MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
			user,
			marketId,
			orderId: event.order.orderId,
			direction: event.order.direction,
			baseSize: event.order.baseAssetAmount,
		});
	}
};

type MarketOrderToastEvent = MarketOrderToastStateTransitionEvent;

type MarketOrderToastEventSubject = {
	subject: Subject<MarketOrderToastEvent>;
};

const INITIAL_CONTEXT_VALUE: MarketOrderToastEventSubject = {
	subject: new Subject<MarketOrderToastEvent>(),
};

export const MarketOrderToastEventContext =
	React.createContext<MarketOrderToastEventSubject>(undefined);

/**
 * userEventSubscriber: EventSubscriber for the current user's events
 * globalFillEventSubscriber: EventSubscriber for market-wide order action events
 */
export const MarketOrderToastEventListenerContext = React.createContext(
	INITIAL_CONTEXT_VALUE
);

export const useCurrentUserForMarketOrderEventsRef = () => {
	const currentUserRef = useRef<PublicKey>(null);
	const currentUser = useCurrentUserAccount();
	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);

	useEffect(() => {
		currentUserRef.current = currentUser?.pubKey;
	}, [currentUser?.pubKey]);

	// To support DepositToTrade, need to enable pre-empting the user account which will be linked to market order events before the user account actually exists (or is selected in state) yet
	useEffect(() => {
		if (!appEventEmitter) return;

		const handler = (user: PublicKey) => {
			currentUserRef.current = user;
		};

		appEventEmitter.on('expectedUserAccountForMarketOrderEvents', handler);

		return () => {
			appEventEmitter.off('expectedUserAccountForMarketOrderEvents', handler);
		};
	}, [appEventEmitter]);

	return currentUserRef;
};

export const useUserEventsObservable = () => {
	const driftEventSubjectContext = useContext(
		DriftBlockchainEventSubjectContext
	);
	return driftEventSubjectContext.userEventObservable;
};

export type EventHandlingProps = {
	onParsedEvent: (event: MarketOrderToastStateTransitionEvent) => void;
	knownOrders: React.MutableRefObject<
		Map<MarketOrderToastId, MarketOrderToastOrderInfo>
	>;
	setOrUpdateKnownOrder: (
		orderId: MarketOrderToastId,
		newData: Partial<MarketOrderToastOrderInfo>
	) => void;
	checkOrderCompleteAfterUpdate: (orderId: MarketOrderToastId) => boolean;
	clearKnownOrder: (orderId: MarketOrderToastId) => void;
};

const useHandleOrderStateChanges = (props: EventHandlingProps) => {
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();

	// # Add handling for order state read updates. As opposed to only relying on blockchain events, this allows data that we read directly from the user's account to be used to update the toast state as well. It is particularly useful for populating the information about the order if we don't receive the OrderRecord event, but we do receive the following ones.
	const openOrders = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.openOrders
	);
	const marketOrders = useMemo(() => {
		if (!openOrders) return undefined;

		return openOrders.filter(
			(order) =>
				ENUM_UTILS.match(order.orderType, OrderType.MARKET) ||
				ENUM_UTILS.match(order.orderType, OrderType.ORACLE)
		);
	}, [openOrders]);

	const runMarketOrderStateThroughUpdateLogic = (
		marketOrder: UISerializableOrder
	) => {
		if (DEV_DISABLE_ORDER_STATE_READS) {
			return;
		}

		if (!currentUserRef.current) {
			throw new Error(
				'No current user pubkey when trying to handle market order events'
			);
		}

		if (!isRelevantOrderType(marketOrder.orderType)) {
			dlog(
				`v2_auctions`,
				`skipping_order_record::${ENUM_UTILS.toStr(marketOrder.orderType)}`
			);
			return;
		}

		const marketId = new MarketId(
			marketOrder.marketIndex,
			marketOrder.marketType
		);

		const marketOrderId = MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
			user: currentUserRef.current,
			marketId,
			orderId: marketOrder.orderId,
			direction: marketOrder.direction,
			baseSize: marketOrder.baseAssetAmount.val,
		});

		const knownOrder = props.knownOrders.current.get(marketOrderId);

		// Update the known order state
		props.setOrUpdateKnownOrder(marketOrderId, {
			marketId,
			id: marketOrderId,
			cumulativeQuoteAmountFilled: BN.max(
				knownOrder?.cumulativeQuoteAmountFilled ?? ZERO,
				marketOrder.quoteAssetAmountFilled.val
			),
			cumulativeBaseAmountFilled: BN.max(
				knownOrder?.cumulativeBaseAmountFilled ?? ZERO,
				marketOrder.baseAssetAmountFilled.val
			),
			baseAmountOrdered: marketOrder.baseAssetAmount.val,
			direction: marketOrder.direction,
			auctionStartSlot: marketOrder.slot.toNumber(),
			auctionDuration: marketOrder.auctionDuration,
			maxTs: marketOrder.maxTs,
			auctionMaxPrice: marketOrder.auctionEndPrice.prettyPrint(true),
			startTs: marketOrder.ts,
		});

		const newOrderStateAfterUpdate =
			props.knownOrders.current.get(marketOrderId);

		props.onParsedEvent({
			type: 'order_state_read',
			marketOrderId,
			data: newOrderStateAfterUpdate,
		});
	};

	useEffect(() => {
		if (!marketOrders || marketOrders.length === 0) {
			return;
		}

		for (const marketOrder of marketOrders) {
			runMarketOrderStateThroughUpdateLogic(marketOrder);
		}
	}, [marketOrders]);

	useEffect(() => {
		DriftWindow.devEventEmitter.on(
			'market_order_testing__order_state_change',
			(orderState: UISerializableOrder) => {
				runMarketOrderStateThroughUpdateLogic(orderState);
			}
		);
	}, []);
};

const useHandleBlockchainEvents = (props: EventHandlingProps) => {
	const userEventsObservable = useUserEventsObservable();
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();

	const knownOrders = props.knownOrders;

	const handleOrderActionRecord = async (
		event: WrappedEvent<'OrderActionRecord'>
	) => {
		if (DEV_ARTIFICIALLY_DELAY_ORDER_ACTION_EVENTS) {
			await sleep(ARTIFICIAL_DELAY_TIME);
		}

		const isPlaceEvent = ENUM_UTILS.match(event.action, OrderAction.PLACE);

		const actionTypeStr = ENUM_UTILS.toStr(event.action);

		dlog(`v2_auctions`, `order_action_record::${actionTypeStr}`);

		const orderAction = event.action;

		const isTaker = event?.taker?.equals(currentUserRef.current);
		const isMaker = event?.maker?.equals(currentUserRef.current);

		if (!isTaker && !isMaker) {
			/**
			 * TODO - NEED TO DOUBLE CHECK : I am confused about this. The current_user_events_subject should only have events relevant for the current user. Why for example do I see cancels come through where a MAKER is cancelling an order - how is that linked to the current user? What is the meaning? Should I be ignoring them or is there some implicit meaning? The PROBLEM is that these events don't have anything for me to use to associate it back to the data we have in the store for these market orders, it doesn't have the user's order ID or anything like that.
			 */
			return;
		}

		const marketOrderId = getToastIdFromEvent(event, currentUserRef.current);

		if (!marketOrderId) {
			// Bracket orders can create events which we won't have a toast ID to link to, so we're happy to ignore a non-matching toast ID ... this _is_ a problem if it happens outside of bracket orders though
			return;
		}

		const baseAmountOrdered = isTaker
			? event.takerOrderBaseAssetAmount
			: event.makerOrderBaseAssetAmount;

		const cumulativeBaseFilled = isTaker
			? event.takerOrderCumulativeBaseAssetAmountFilled
			: event.makerOrderCumulativeBaseAssetAmountFilled;

		const cumulativeQuoteFilled = isTaker
			? event.takerOrderCumulativeQuoteAssetAmountFilled
			: event.makerOrderCumulativeQuoteAssetAmountFilled;

		const prevKnownOrder = knownOrders.current.get(marketOrderId);

		const orderCompletelyFilled = !!(
			prevKnownOrder?.baseAmountOrdered &&
			cumulativeBaseFilled.eq(prevKnownOrder?.baseAmountOrdered)
		);

		const updateData: Partial<MarketOrderToastOrderInfo> = {
			marketId: new MarketId(event.marketIndex, event.marketType),
			id: marketOrderId,
			baseAmountOrdered,
			cumulativeQuoteAmountFilled: cumulativeQuoteFilled,
			cumulativeBaseAmountFilled: cumulativeBaseFilled,
			lastAction: event.action,
			lastActionExplanation: event.actionExplanation,
			direction: isTaker
				? event.takerOrderDirection
				: event.makerOrderDirection,
		};

		if (isPlaceEvent) {
			updateData.startTs = event.ts;
		}

		props.setOrUpdateKnownOrder(marketOrderId, updateData);

		// Get new order state after update
		const newOrderState = knownOrders.current.get(marketOrderId);

		if (!newOrderState) {
			return;
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.CANCEL)) {
			if (
				ENUM_UTILS.match(
					event.actionExplanation,
					OrderActionExplanation.ORDER_EXPIRED
				)
			) {
				props.onParsedEvent({
					marketOrderId,
					type: 'action_expired',
					data: newOrderState,
				});
			} else {
				props.onParsedEvent({
					marketOrderId,
					type: 'action_cancelled',
					data: newOrderState,
				});
			}
			return;
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.EXPIRE)) {
			props.onParsedEvent({
				marketOrderId,
				type: 'action_expired',
				data: newOrderState,
			});
			return;
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.FILL)) {
			if (orderCompletelyFilled) {
				// Is a completely filled order
				props.onParsedEvent({
					marketOrderId,
					type: 'order_completely_filled',
					data: newOrderState,
				});
			} else {
				// Is a partially filled order
				props.onParsedEvent({
					marketOrderId,
					type: 'receive_fill_action',
					data: newOrderState,
				});
			}
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.PLACE)) {
			if (prevKnownOrder && !prevKnownOrder.auctionEnabled) {
				props.onParsedEvent({
					marketOrderId,
					type: 'received_confirmation_and_auction_disabled',
					data: newOrderState,
				});
			} else {
				props.onParsedEvent({
					marketOrderId,
					type: 'receive_confirmation',
					data: newOrderState,
				});
			}
		}
	};

	const handleOrderRecord = async (event: WrappedEvent<'OrderRecord'>) => {
		if (DEV_ARTIFICIALLY_DELAY_ORDER_RECORD_EVENTS) {
			await sleep(ARTIFICIAL_DELAY_TIME);
		}

		if (!isRelevantOrderType(event.order.orderType)) {
			dlog(
				`v2_auctions`,
				`skipping_order_record::${ENUM_UTILS.toStr(event.order.orderType)}`
			);
			return;
		}

		const marketId = new MarketId(
			event.order.marketIndex,
			event.order.marketType
		);

		const marketOrderId = getToastIdFromEvent(event, currentUserRef.current);

		if (!marketOrderId) {
			// Bracket orders can create events which we won't have a toast ID to link to, so we're happy to ignore a non-matching toast ID ... this _is_ a problem if it happens outside of bracket orders though
			return;
		}

		const knownOrder = knownOrders.current.get(marketOrderId);

		if (!knownOrder) {
			return;
		}

		props.setOrUpdateKnownOrder(marketOrderId, {
			marketId,
			id: marketOrderId,
			baseAmountOrdered: event.order.baseAssetAmount,
			cumulativeBaseAmountFilled:
				knownOrder?.cumulativeBaseAmountFilled ?? ZERO,
			cumulativeQuoteAmountFilled:
				knownOrder?.cumulativeQuoteAmountFilled ?? ZERO,
			direction: event.order.direction,
			auctionStartSlot: event.order.slot.toNumber(),
			auctionDuration: event.order.auctionDuration,
			maxTs: event.order.maxTs,
			auctionMaxPrice: BigNum.from(
				event.order.auctionEndPrice,
				PRICE_PRECISION_EXP
			).prettyPrint(true),
			startTs: event.ts,
		});

		if (!knownOrder.auctionEnabled) {
			props.onParsedEvent({
				marketOrderId,
				type: 'received_confirmation_and_auction_disabled',
				data: knownOrder,
			});
			return;
		}

		// Check if the order is already finished .. this can happen if we receive the order actions for the filled order before we receive the order record
		const orderIsFinished = props.checkOrderCompleteAfterUpdate(marketOrderId);

		if (orderIsFinished) {
			const knownOrder = knownOrders.current.get(marketOrderId);

			props.onParsedEvent({
				marketOrderId,
				type: 'order_completely_filled',
				data: {
					cumulativeBaseAmountFilled: knownOrder.cumulativeBaseAmountFilled,
					cumulativeQuoteAmountFilled: knownOrder.cumulativeQuoteAmountFilled,
					...knownOrder,
				},
			});
		} else {
			props.onParsedEvent({
				marketOrderId,
				type: 'receive_order_record',
				data: knownOrder,
			});
		}
	};

	useEffect(() => {
		if (TEST_WITH_EVENTS_DISABLED) return;
		if (!userEventsObservable) return;

		userEventsObservable.subscribe((event) => {
			if (!currentUserRef.current) {
				console.warn(
					`Got user event when there was no user account in MarketOrderToastEventProvider.`
				);
				return;
			}

			switch (event.eventType) {
				case 'OrderActionRecord': {
					if (DEV_DISABLE_ORDER_ACTION_EVENTS) {
						break;
					}
					const typedEvent = event as WrappedEvent<'OrderActionRecord'>;
					handleOrderActionRecord(typedEvent);
					break;
				}
				case 'OrderRecord': {
					if (DEV_DISABLE_ORDER_RECORD_EVENTS) {
						break;
					}
					const typedEvent = event as WrappedEvent<'OrderRecord'>;
					handleOrderRecord(typedEvent);
					break;
				}
				default: {
					// Do nothing - These events don't matter to us
				}
			}
		});
	}, [userEventsObservable]);

	useEffect(() => {
		if (TEST_WITH_EVENTS_DISABLED) return;

		// Allow dev testing spoof events to go through the regular event handling logic
		DriftWindow.devEventEmitter.on(
			'market_order_testing__order_action_event',
			(event) => {
				handleOrderActionRecord(event);
			}
		);

		DriftWindow.devEventEmitter.on(
			'market_order_testing__order_record_event',
			(event) => {
				handleOrderRecord(event);
			}
		);
	}, []);
};

const useHandleAppEvents = (hookProps: EventHandlingProps) => {
	const appEventEmitter = useAppEventEmitter();
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();
	const connection = useDriftStore((s) => s.connection.current);
	const marketInfoStoreGetter = useMarketsInfoStore((s) => s.get);
	const { captureEvent } = usePostHogCapture();

	// # Handle expect incoming auction
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionInit'] =
			(handlerProps) => {
				const marketId = handlerProps.v2Props.marketId;

				const orderId =
					MarketOrderToastIdHandler.handleNewMarketOrderWithUnknownOrderId({
						idNonce: handlerProps.v2Props.identifierNonce,
						user: currentUserRef.current,
						marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});

				const orderProps: MarketOrderToastOrderInfo = {
					marketId: handlerProps.v2Props.marketId,
					id: orderId,
					cumulativeBaseAmountFilled: ZERO,
					cumulativeQuoteAmountFilled: ZERO,
					baseAmountOrdered: handlerProps.v2Props.baseAmountOrdered,
					direction: handlerProps.v2Props.direction,
					auctionEnabled: handlerProps.v2Props.auctionEnabled,
					lastAction: undefined,
					lastActionExplanation: undefined,
					auctionStartSlot: undefined,
					auctionDuration: undefined,
					maxTs: undefined,
					includesSlOrder: handlerProps.v2Props.includesSlOrder,
					includesTpOrder: handlerProps.v2Props.includesTpOrder,
					stepSize: getMarketStepSize(marketId, marketInfoStoreGetter),
				};

				hookProps.setOrUpdateKnownOrder(orderId, orderProps);

				hookProps.onParsedEvent({
					type: 'init',
					marketOrderId: orderId,
					data: orderProps,
				});
			};

		appEventEmitter.on('expectedAuctionInit', handler);

		return () => {
			appEventEmitter.off('expectedAuctionInit', handler);
		};
	}, [appEventEmitter]);

	// # Handle cancel expected auction
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionTxFailed'] =
			(handlerProps) => {
				let marketOrderId: MarketOrderToastId;

				try {
					marketOrderId =
						MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
							user: currentUserRef.current,
							idNonce: handlerProps.v2Props.identifierNonce,
							marketId: handlerProps.v2Props.marketId,
							baseSize: handlerProps.v2Props.baseAmountOrdered,
							direction: handlerProps.v2Props.direction,
						});

					MarketOrderToastIdHandler.clearStateForUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.v2Props.identifierNonce,
						marketId: handlerProps.v2Props.marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});
				} catch (e) {
					// It's ok if we can't find the toast id when handling a failure event
					return;
				}

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					return;
				}

				hookProps.onParsedEvent({
					marketOrderId,
					type: 'tx_failed',
					data: knownOrder,
				});

				hookProps.clearKnownOrder(marketOrderId);
			};

		appEventEmitter.on('expectedAuctionTxFailed', handler);

		return () => {
			appEventEmitter.off('expectedAuctionTxFailed', handler);
		};
	}, [appEventEmitter]);

	// # Handle market order confirmed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionTxConfirmed'] =
			(handlerProps) => {
				if (DEV_DISABLE_TX_CONFIRMED_EVENT) {
					return;
				}

				const marketOrderId =
					MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.v2Props.identifierNonce,
						marketId: handlerProps.v2Props.marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					return;
				}

				hookProps.onParsedEvent({
					marketOrderId,
					type: knownOrder.auctionEnabled
						? 'receive_confirmation'
						: 'received_confirmation_and_auction_disabled',
					data: knownOrder,
				});
			};

		appEventEmitter.on('expectedAuctionTxConfirmed', handler);

		return () => {
			appEventEmitter.off('expectedAuctionTxConfirmed', handler);
		};
	}, [appEventEmitter]);

	// # Handle Tx Signed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionTxSigned'] =
			async (handlerProps) => {
				const marketOrderId =
					MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.v2Props.identifierNonce,
						marketId: handlerProps.v2Props.marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					const errorMessage = `Could not find known order for toast id ${marketOrderId} when handling tx signed event`;

					captureEvent('market_order_errors', {
						message: errorMessage,
					});

					throw new Error(errorMessage);
				}

				let signedTxLastValidBlockHeight =
					handlerProps?.signedTxData?.[0]?.lastValidBlockHeight;

				if (!signedTxLastValidBlockHeight || DEV_FORCE_NO_TX_EXPIRY_ATTCHED) {
					const errorMessage = `Received expectedAuctionTxSigned event without a lastValidBlockHeight in the signedTxData`;

					dlog(
						`v2_auctions`,
						`no_last_valid_block_height_on_tx_signed => falling back to current block height`
					);

					captureEvent('market_order_errors', {
						message: errorMessage,
					});

					const currentBlockHeightResult =
						await connection.getLatestBlockhashAndContext();
					signedTxLastValidBlockHeight =
						currentBlockHeightResult.value.lastValidBlockHeight;
				}

				const useOffset = getShouldUseBlockHeightOffset();

				const txExpiryBlockHeight =
					signedTxLastValidBlockHeight +
					(useOffset ? SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY_OFFSET : 0);

				dlog(
					`v2_auctions`,
					`set_expiry_slot_with_tx_signature .. ${txExpiryBlockHeight}`
				);

				hookProps.setOrUpdateKnownOrder(marketOrderId, {
					transactionExpiryBlockHeight: txExpiryBlockHeight,
				});

				const newOrderStateAfterUpdate =
					hookProps.knownOrders.current.get(marketOrderId);

				hookProps.onParsedEvent({
					marketOrderId,
					type: 'tx_signed',
					data: newOrderStateAfterUpdate,
				});
			};

		appEventEmitter.on('expectedAuctionTxSigned', handler);

		return () => {
			appEventEmitter.off('expectedAuctionTxSigned', handler);
		};
	}, [appEventEmitter, connection]);
};

/**
 * This hook provides a subject for domain-specific Market Order events. It listens to a variety of sources to determine which events to output, to increase reliability, including blockchain events, user events, and app events.
 * @param props
 * @returns
 */
export const MarketOrderToastEventEmitterProvider = (
	props: PropsWithChildren<any>
) => {
	const contextValue = INITIAL_CONTEXT_VALUE;
	const marketOrderToastEventsSubject = contextValue.subject;
	const currentSlotRef = useCurrentSlotRef(1000);
	const appEventEmitter = useAppEventEmitter();

	const knownOrders = useRef<
		Map<MarketOrderToastId, MarketOrderToastOrderInfo>
	>(new Map());

	const setOrUpdateKnownOrder = (
		orderId: MarketOrderToastId,
		newData: Partial<MarketOrderToastOrderInfo>
	) => {
		const existing = knownOrders.current.get(orderId);

		if (existing) {
			knownOrders.current.set(orderId, {
				...existing,
				...newData,
			});
		} else {
			knownOrders.current.set(orderId, newData as MarketOrderToastOrderInfo);
		}
	};

	const clearKnownOrder = (orderId: MarketOrderToastId) => {
		knownOrders.current.delete(orderId);
	};

	const checkOrderCompleteAfterUpdate = (orderId: MarketOrderToastId) => {
		const order = knownOrders.current.get(orderId);

		if (!order) return false;

		return (
			order.baseAmountOrdered?.eq(order.cumulativeBaseAmountFilled) ?? false
		);
	};

	// # Set up the handler to clear known orders when the toast is removed
	useEffect(() => {
		appEventEmitter.on(
			'marketOrderToastRemoved',
			(toastId: MarketOrderToastId) => {
				clearKnownOrder(toastId);
			}
		);
	}, [appEventEmitter]);

	// #  ⭐️ The CORE logic for triggering market order events happens in the below hooks
	// ## Trigger events from blockchain source
	useHandleBlockchainEvents({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// ## Trigger events from event event emitter source
	useHandleAppEvents({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// ## Trigger events from live order state changes
	useHandleOrderStateChanges({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// ## Dev testing
	useDevMarketOrderEvents({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// # Handle slot progress
	useInterval(() => {
		knownOrders.current.forEach((order, orderId) => {
			// # Catch Auction Expired
			//// If the auction has already ended or we don't have the necessary data, don't do anything
			const canSkipCheckingAuctionExpired =
				order.auctionEnded ||
				!order?.auctionStartSlot ||
				!order?.auctionDuration ||
				!order?.auctionEnabled ||
				!currentSlotRef.current;

			if (!canSkipCheckingAuctionExpired) {
				const startSlot = order?.auctionStartSlot;
				const duration = order?.auctionDuration;
				const currentSlot = currentSlotRef.current;

				const auctionIsExpired = currentSlot > startSlot + duration;

				setOrUpdateKnownOrder(orderId, {
					auctionEnded: auctionIsExpired,
				});

				if (auctionIsExpired) {
					marketOrderToastEventsSubject.next({
						type: 'auction_finished',
						marketOrderId: orderId,
						data: order,
					});
				}
			}

			// # Catch Order Expired
			const currentTs = DriftWindow.chainClock.getState(
				DEFAULT_COMMITMENT_LEVEL
			).ts;

			const canSkipCheckingOrderExpired = !order?.maxTs || !currentTs;

			if (!canSkipCheckingOrderExpired) {
				const orderIsExpired = currentTs > order.maxTs.toNumber();
				if (orderIsExpired) {
					dlog(
						`v2_auctions`,
						`triggering_implied_expired_event`,
						orderId,
						order
					);
					marketOrderToastEventsSubject.next({
						type: 'action_expired',
						marketOrderId: orderId,
						data: order,
					});
				}
			}
		});
	}, 2_000);

	return (
		<MarketOrderToastEventContext value={contextValue}>
			{props.children}
		</MarketOrderToastEventContext>
	);
};
