'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import { VaultManagerIcon } from '../Vaults/VaultManagerIcon';
import { VaultDepositWithdrawForm } from '../Vaults/VaultMainContent/VaultDepositWithdrawForm';
import { Typo } from '../Text/Typo';

const VaultDepositFormModal = () => {
	const set = useDriftStore((s) => s.set);

	const {
		uiVaultConfig,
		vaultAccountData,
		vaultDepositorAccountData,
		isVaultDepositorLoaded,
	} = useDriftStore((s) => s.modalsProps.showVaultDepositWithdrawFormModal);

	const hideVaultDepositFormModal = () => {
		set((s) => {
			s.modals.showVaultDepositWithdrawFormModal = false;
		});
	};

	if (!uiVaultConfig) {
		return null;
	}

	return (
		<Modal onClose={hideVaultDepositFormModal}>
			<Modal.Body className="flex flex-col h-full pb-0">
				<Modal.Header
					className="py-2"
					onClose={hideVaultDepositFormModal}
					showX
				>
					<Modal.Title>
						<div className="flex items-center w-full">
							<VaultManagerIcon uiVaultConfig={uiVaultConfig} />
							<Typo.T1 className="ml-4">{uiVaultConfig?.name}</Typo.T1>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Content className="flex-grow p-0">
					<VaultDepositWithdrawForm
						uiVaultConfig={uiVaultConfig}
						vaultAccountData={vaultAccountData}
						vaultDepositorAccountData={vaultDepositorAccountData}
						isVaultDepositorLoaded={isVaultDepositorLoaded}
					/>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default VaultDepositFormModal;
