import { MetricsEventSink } from './MetricsEventSink';

export class MetricsTracker {
	public metricsEventSink: MetricsEventSink;

	public constructor() {
		this.metricsEventSink = new MetricsEventSink();

		const observer = new PerformanceObserver((list) => {
			for (const entry of list.getEntries()) {
				// @ts-ignore
				if (entry.initiatorType === 'fetch') {
					this.metricsEventSink.push({
						type: 'network_request',
						ts: Date.now(),
						data: {
							// @ts-ignore
							transferSizeBytes: entry?.transferSize ?? 0,
						},
					});
				}
			}
		});

		observer.observe({
			entryTypes: ['resource'],
		});
	}
}
