'use client';

import { User } from '@drift-labs/sdk';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useHistoryFetcher from './useHistoryFetcher';
import { COMMON_UI_UTILS } from '@drift/common';

const useTradeHistory = () => {
	const getState = useDriftStore((s) => s.get);

	const setAccountsStore = useDriftAccountsStore((s) => s.set);

	// Get the initial trade history, and find out what the total size of the trade history is for the user - to help with pagination
	const getNewTradesFromHistoryServer = async (users: User[]) => {
		const isConnected =
			getState().wallet.current.adapter.connected &&
			getState().connection.current;
		if (!isConnected) return;

		const pubKeys = users.map((user) => user.getUserAccountPublicKey());
		const userKeys = users.map((user) =>
			COMMON_UI_UTILS.getUserKey(
				user.getUserAccount().subAccountId,
				user.getUserAccount().authority
			)
		);

		if (pubKeys.find((a) => !a)) return;

		const results = await ExchangeHistoryClient.getTradesForUsers(
			pubKeys,
			0,
			undefined,
			undefined
		);

		if (!results.success) return false;

		const { records, totalCounts } = results?.body?.data || {};

		const newTrades = records ?? [];

		if (totalCounts) {
			setAccountsStore((s) => {
				userKeys.forEach((userKey, index) => {
					const tradesForUser = newTrades[index];
					const tradeCountForUser = totalCounts?.[index] ?? 0;

					if (!s.accounts[userKey]) return; // accounts not loaded yet

					s.accounts[userKey].tradeHistory.loadedUserTradeHistory =
						tradesForUser;

					s.accounts[userKey].tradeHistory.userTradeHistoryTotalCount =
						tradeCountForUser;

					s.accounts[userKey].tradeHistory.initialHistoryLoaded = true;
				});
			});
		}

		return true;
	};

	useHistoryFetcher(getNewTradesFromHistoryServer, 'tradeHistory');
};

export default useTradeHistory;
