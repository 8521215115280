'use client';

import { useMemo } from 'react';
import { MarketId } from '@drift/common';
import useBorrowLendDataForMarket from './useBorrowLendDataForMarket';

const UNSTAKE_UTILIZATION_CUTOFF_PCT = 80;

const useUtilizationIsTooHighForIFUnstake = (market: MarketId) => {
	const selectedMarketBorrowInfo = useBorrowLendDataForMarket(market);

	const unstakingIsDisabled =
		selectedMarketBorrowInfo &&
		selectedMarketBorrowInfo.borrowUtilization.toNum() * 100 >=
			UNSTAKE_UTILIZATION_CUTOFF_PCT;

	const result = useMemo(
		() => ({
			unstakingIsDisabled,
			unstakingCutoffPct: UNSTAKE_UTILIZATION_CUTOFF_PCT.toFixed(2),
			currentUtilizationPercent: selectedMarketBorrowInfo
				? (selectedMarketBorrowInfo.borrowUtilization.toNum() * 100).toFixed(2)
				: undefined,
		}),
		[unstakingIsDisabled]
	);

	return result;
};

export default useUtilizationIsTooHighForIFUnstake;
