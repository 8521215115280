'use client';

import Button from 'src/components/Button';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import useDriftActions from 'src/hooks/useDriftActions';
import { memo, useState } from 'react';
import Modal from './Modal';
import { PublicKey } from '@drift-labs/sdk';
import InfoMessage from '../TradeForm/InfoMessage';

const EmulateWalletModal = () => {
	const actions = useDriftActions();
	const showModal = actions.showModal;

	const hideModal = () => showModal('showEmulationModal', false);

	const [emulateAuthority, setEmulateAuthority] = useState<string>('');
	const [emulationLoading, setEmulationLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');

	const handleEmulate = async () => {
		setErrorMessage('');
		setEmulationLoading(true);
		const success = await actions.emulateAccountForSigning({
			authority: new PublicKey(emulateAuthority),
		});
		if (success) {
			hideModal();
		} else {
			setErrorMessage('There was an error emulating the specified account.');
		}
	};

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Emulate account</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<div className="flex flex-col items-end">
						<LabelledInput label="Account authority">
							<TextField.Default
								type="text"
								value={emulateAuthority}
								onChange={setEmulateAuthority}
							/>
						</LabelledInput>
						<Utility.VERTSPACERXS />
					</div>
					{errorMessage && (
						<>
							<Utility.VERTSPACERM />
							<InfoMessage type="error" message={errorMessage} />
						</>
					)}
					<Modal.ButtonBar>
						<Button.Secondary
							className="w-[50%] flex-grow sm:grow-0"
							onClick={hideModal}
							size="MEDIUM"
						>
							Cancel
						</Button.Secondary>
						<Button.Secondary
							className="w-[50%] flex-grow sm:grow-0"
							disabled={!emulateAuthority || emulationLoading}
							onClick={handleEmulate}
							size="MEDIUM"
							highlight
						>
							Emulate
						</Button.Secondary>
					</Modal.ButtonBar>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default memo(EmulateWalletModal);
