'use client';

import type { ReactNode } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import UI_UTILS from 'src/utils/uiUtils';
import useDriftActions from '../../hooks/useDriftActions';
import useShowAccountValues from '../../hooks/useShowAccountValues';
import Button from '../Button';
import Text from '../Text/Text';
import MarketIcon from '../Utils/MarketIcon';
import Modal from './Modal';
import { COMMON_UI_UTILS } from '@drift/common';

const TradeDetailsRow = (props: { children: ReactNode }) => {
	return (
		<div className="flex flex-row items-center justify-between py-4">
			{props.children}
		</div>
	);
};

/**
 * Displays details of a trade that are specified with `selectedTradeDetails` in the driftStore.
 * Oonly used on mobile for now.
 */
const TradeDetailsModal = () => {
	const showAccountValues = useShowAccountValues();
	const setStoreState = useDriftStore((s) => s.set);
	const selectedTradeDetails = useDriftStore((s) => s.selectedTradeDetails);
	const actions = useDriftActions();

	if (!selectedTradeDetails) {
		return null;
	}

	const baseAssetSymbol = COMMON_UI_UTILS.getBaseAssetSymbol(
		selectedTradeDetails.marketName
	);

	const directionLabel = selectedTradeDetails.isLong
		? selectedTradeDetails.isSpot
			? 'buy'
			: 'long'
		: selectedTradeDetails.isSpot
		? 'sell'
		: 'short';

	const hideModal = () => {
		actions.showModal('showTradeDetailsModal', false);
		setStoreState((s) => (s.selectedTradeDetails = undefined));
	};

	return (
		<Modal onClose={hideModal} sizeConfig={{ xs: 12, sm: 10, md: 6, xl: 4 }}>
			<Modal.Body className="flex flex-col overflow-visible">
				<Modal.Header onClose={hideModal} showX>
					Trade Details
				</Modal.Header>
				<div className="flex flex-col justify-between h-full p-4 shrink">
					<div>
						<div className="flex flex-row items-center w-full text-text-default">
							<MarketIcon
								marketSymbol={selectedTradeDetails.marketName}
								sizeClass={'w-8 h-8'}
								customHeight={32}
								customWidth={32}
							/>
							<div className="flex flex-col items-start ml-4">
								<Text.H3>{selectedTradeDetails.marketName}</Text.H3>
								<Text.BODY2
									className={`${
										selectedTradeDetails.isLong
											? 'text-positive-green'
											: 'text-negative-red'
									} capitalize mt-1`}
								>
									{directionLabel}
								</Text.BODY2>
							</div>
						</div>
						<div className="pt-2 divide-y text-text-default divide-container-border">
							<TradeDetailsRow>
								<Text.BODY1 className="text-text-label">Size</Text.BODY1>
								<Text.BODY1>
									{showAccountValues
										? selectedTradeDetails.baseAssetAmountFilled.prettyPrint()
										: '∗∗∗∗'}{' '}
									{baseAssetSymbol}
								</Text.BODY1>
							</TradeDetailsRow>
							<TradeDetailsRow>
								<Text.BODY1 className="text-text-label">Notional</Text.BODY1>
								<Text.BODY1>
									{' '}
									{showAccountValues
										? UI_UTILS.isNotionalDust(
												selectedTradeDetails.quoteAssetAmountFilled
										  )
											? '< $0.01'
											: selectedTradeDetails.quoteAssetAmountFilled.toNotional()
										: '$ ∗∗∗∗∗∗'}
								</Text.BODY1>
							</TradeDetailsRow>
							<TradeDetailsRow>
								<Text.BODY1 className="text-text-label">Entry Price</Text.BODY1>
								<Text.BODY1>{selectedTradeDetails.avgEntryPrice}</Text.BODY1>
							</TradeDetailsRow>
							<TradeDetailsRow>
								<Text.BODY1 className="text-text-label">Fee</Text.BODY1>
								<Text.BODY1>
									{showAccountValues
										? selectedTradeDetails.fee?.print() ?? '0'
										: '∗∗∗∗'}
									{' USDC'}
								</Text.BODY1>
							</TradeDetailsRow>
							<TradeDetailsRow>
								<Text.BODY1 className="text-text-label">Date</Text.BODY1>
								<Text.BODY1>
									{new Date(selectedTradeDetails.ts.toNumber() * 1000)
										.toLocaleString()
										.replace(',', '')}
								</Text.BODY1>
							</TradeDetailsRow>
						</div>
					</div>
					<Button.Secondary size="LARGE" onClick={hideModal}>
						<Text.H2 className="capitalize">Close</Text.H2>
					</Button.Secondary>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TradeDetailsModal;
