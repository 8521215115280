'use client';

import { useEffect } from 'react';
import { dlog } from '../../dev';
import { EventLoopMetricsTracker } from '../../providers/metrics/EventLoopMetricsTracker';
import useDevSwitchIsOn from '../useDevSwitchIsOn';

const useLogEventLoopLag = () => {
	const devSwitchIsOn = useDevSwitchIsOn();

	useEffect(() => {
		if (!devSwitchIsOn) {
			return;
		}

		const tracker = new EventLoopMetricsTracker((lag) => {
			dlog(`event_loop_lag`, lag);
		});

		tracker.subscribe();

		return () => {
			tracker.unsubscribe();
		};
	}, [devSwitchIsOn]);
};

export default useLogEventLoopLag;
