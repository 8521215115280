'use client';

import { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useIdle } from 'react-use';
import { useImmer } from 'use-immer';

const IDLE_LEVELS_ENABLED = false;

export type IdleLevelsState = {
	idle1Min: boolean;
	idle10Min: boolean;
};

const DEFAULT_STATE: IdleLevelsState = {
	idle1Min: false,
	idle10Min: false,
};

const useIdleLevels = () => {
	const [idleState, setIdleState] = useImmer(DEFAULT_STATE);

	const idle1Min = useIdle(60e3);
	const idle10Min = useIdle(600e3);

	useEffect(() => {
		if (!IDLE_LEVELS_ENABLED) return;
		setIdleState((s) => {
			s.idle1Min = idle1Min;
		});
	}, [idle1Min]);

	useEffect(() => {
		if (!IDLE_LEVELS_ENABLED) return;
		setIdleState((s) => {
			s.idle10Min = idle10Min;
		});
	}, [idle10Min]);

	return idleState;
};

export default singletonHook(DEFAULT_STATE, useIdleLevels);
