'use client';

import { useState } from 'react';
import {
	DepositTable,
	PerpPositionDrawerTable,
	PnlTable,
} from 'src/components/LiquidationDetails';
import HistoryDrawer from './HistoryDrawer';
import Text from 'src/components/Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import { format } from 'timeago.js';
import { useDrawersStore } from 'src/stores/useDrawersStore';

type LiqTabOption = 'perp' | 'deposits' | 'borrows' | 'unsettled';

const LIQ_TABS = [
	{
		label: 'Perpetual',
		value: 'perp',
	},
	{
		label: 'Deposits',
		value: 'deposits',
	},
	{
		label: 'Borrows',
		value: 'borrows',
	},
	{
		label: 'Unsettled P&L',
		value: 'unsettled',
	},
];

const LiquidationDrawer = () => {
	const liquidation = useDrawersStore((s) => s.liquidations.liquidation);

	const tabs: { label: string; value: string }[] = [];
	if (liquidation.liqdPerps?.length > 0) {
		tabs.push(LIQ_TABS[0]);
	}
	if (liquidation.liqdDeposits?.length > 0) {
		tabs.push(LIQ_TABS[1]);
	}
	if (liquidation.liqdBorrows?.length > 0) {
		tabs.push(LIQ_TABS[2]);
	}
	if (liquidation.liqdPerpPnls?.length > 0) {
		tabs.push(LIQ_TABS[3]);
	}

	const defaultTab =
		liquidation.liqdPerps?.length > 0
			? 'perp'
			: liquidation.liqdBorrows?.length > 0
			? 'borrows'
			: 'deposits';
	const [selectedTab, setSelectedTab] = useState<LiqTabOption>(defaultTab);

	const renderTable = () => {
		switch (selectedTab) {
			case 'borrows':
				return <DepositTable data={liquidation.liqdBorrows} />;
			case 'deposits':
				return <DepositTable data={liquidation.liqdDeposits} />;
			case 'unsettled':
				return <PnlTable data={liquidation.liqdPerpPnls} />;
			default:
				return <PerpPositionDrawerTable data={liquidation} />;
		}
	};

	const summarySection = (
		<div className="flex items-center w-full space-x-6">
			<div className="block">
				<div>
					<Text.BODY3 className="text-text-label">Date/Time</Text.BODY3>
				</div>
				<div>
					<Text.BODY2 className="text-text-emphasis">
						{format(new Date(liquidation.ts * 1000))}
					</Text.BODY2>
				</div>
			</div>
			<div className="block mx-4">
				<div>
					<Text.BODY3 className="text-text-label">
						Notional Debt Transferred
					</Text.BODY3>
				</div>
				<div>
					<Text.BODY2 className="text-text-emphasis">
						{liquidation.notionalAmount.toNotional()}
					</Text.BODY2>
				</div>
			</div>
			<div className="block">
				<div>
					<Text.BODY3 className="text-text-label">
						Liquidation Summary
					</Text.BODY3>
				</div>
				<div>
					<Text.BODY2 className="text-text-emphasis">
						{UI_UTILS.getDescriptionForLiqRecord(liquidation)}
					</Text.BODY2>
				</div>
			</div>
		</div>
	);

	return (
		<HistoryDrawer
			title={'Liquidations'}
			summarySection={summarySection}
			contentSection={renderTable()}
			modalStoreKey={'liquidations'}
			tabs={tabs}
			tabsClassName="max-w-[25%]"
			defaultTab={defaultTab}
			tabSwitchCallback={(newTab: string) =>
				setSelectedTab(newTab as LiqTabOption)
			}
		/>
	);
};

export default LiquidationDrawer;
