'use client';

import {
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { Subject } from 'rxjs';
import { SlotContext } from '../currentSlotProvider';
import { MetricsTracker } from './MetricsTracker';
import { MetricsEventBusMessage } from './metricsTypes';
import { NetworkMetricsTracker } from './NetworkMetricsTracker';
import { SlotSubscriberMetricsTracker } from './SlotSubscriberMetricsTracker';
import { METRICS_SAMPLING_LOGGING_ENABLED } from './metrics';
import { EventLoopMetricsTracker } from './EventLoopMetricsTracker';
import { DriftWindow } from '../../window/driftWindow';

export type MetricsEventBus = Subject<MetricsEventBusMessage>;
const EVENT_BUS = new Subject<MetricsEventBusMessage>();

export const MetricsContext = createContext({
	eventBus: EVENT_BUS,
});

const MetricsProvider = (props: PropsWithChildren<any>) => {
	const returnValue = useMemo(() => {
		return { eventBus: EVENT_BUS };
	}, []);

	const slotContext = useContext(SlotContext);

	useEffect(() => {
		window.UNCONSUMED_GRAFANA_METRICS = {};
		DriftWindow.initialiseMetricsBus(EVENT_BUS);
	}, []);

	useEffect(() => {
		EVENT_BUS.subscribe((message) => {
			switch (message.type) {
				case 'event_loop_lag':
					window.UNCONSUMED_GRAFANA_METRICS.EVENT_LOOP_LAG = [
						...(window.UNCONSUMED_GRAFANA_METRICS.EVENT_LOOP_LAG ?? []),
						message.value,
					];
					break;
				default:
					break;
			}
		});
	}, []);

	/**
	 * This hook sets up the metrics which should only be enabled if the session has been selected as a sample session
	 */
	useEffect(() => {
		if (!METRICS_SAMPLING_LOGGING_ENABLED) {
			return;
		}

		const metricsTracker = new MetricsTracker();

		// # Network Metrics
		const networkMetricsAlarmTracker = new NetworkMetricsTracker(
			metricsTracker.metricsEventSink
		);
		networkMetricsAlarmTracker.subscribe();

		// # Slot Metrics
		const slotMetricsAlarmTracker = new SlotSubscriberMetricsTracker(
			slotContext.subject
		);
		slotMetricsAlarmTracker.subscribe();

		// # Event Loop Blocking Metrics
		const eventLoopMetricsTracker = new EventLoopMetricsTracker((lag) => {
			EVENT_BUS.next({
				type: 'event_loop_lag',
				value: lag,
			});
		});
		eventLoopMetricsTracker.subscribe();
	}, []);

	return <MetricsContext value={returnValue}>{props.children}</MetricsContext>;
};

export default MetricsProvider;
