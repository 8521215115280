'use client';

import { useConnect } from 'wagmi';
import Modal from './Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from '../Text/Text';
import { useEffect, useRef } from 'react';
import { getWalletIcon } from 'src/utils/evm';
import { notify } from 'src/utils/notifications';

const WalletButton = ({
	iconUrl,
	walletName,
	onClick,
}: {
	iconUrl?: string;
	walletName: string;
	onClick: () => void;
}) => {
	return (
		<div
			className="flex items-center w-full gap-2 p-2 border cursor-pointer border-container-border hover:bg-container-bg-hover"
			onClick={onClick}
		>
			{iconUrl && <img src={iconUrl} className="w-6 h-6" />}
			<Text.H3>{walletName}</Text.H3>
		</div>
	);
};

export default function EvmWalletsSelectorModal() {
	const setStore = useDriftStore((s) => s.set);
	const { connect, connectors, error, status } = useConnect({
		onSuccess: (data) => {
			notify({
				type: 'success',
				message: 'Wallet connected',
				description: `You've successfully connected your ${data.connector.name} wallet.`,
			});
		},
	});

	const prevStatusRef = useRef('');

	useEffect(() => {
		if (prevStatusRef.current !== 'success' && status === 'success') {
			onClose();
		}

		prevStatusRef.current = status;
	}, [status]);

	const onClose = () => {
		setStore((s) => {
			s.modals.showEvmWalletsSelectorModal = false;
		});
		return;
	};

	if (error) {
		console.error(error);
	}

	return (
		<Modal onClose={onClose}>
			<Modal.Body className="">
				<Modal.Header showX onClose={onClose} showBack={false}>
					<Text.H2>Connect Wallet</Text.H2>
				</Modal.Header>
				<div className="flex flex-col gap-3 p-4 text-text-default">
					{connectors.map((walletConnector) => (
						<WalletButton
							key={walletConnector.id}
							iconUrl={getWalletIcon(walletConnector.name)}
							walletName={walletConnector.name}
							onClick={() => connect({ connector: walletConnector })}
						/>
					))}
				</div>
			</Modal.Body>
		</Modal>
	);
}
