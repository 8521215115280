'use client';

import {
	BigNum,
	BN,
	calculateBorrowRate,
	calculateDepositRate,
	calculateUtilization,
	calculateWithdrawLimit,
	getTokenAmount,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotBalanceType,
	SpotMarketConfig,
	SPOT_MARKET_RATE_PRECISION_EXP,
	ZERO,
	SpotMarketAccount,
	// SPOT_MARKET_UTILIZATION_PRECISION_EXP,
} from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { useCallback } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from '../utils/uiUtils';
import { useAggregateAccountsSpotBalances } from './useAccountBankBalances';
import useDriftClientIsReady from './useDriftClientIsReady';
import useUiUpdateInterval from './useUiUpdateInterval';

export type BorrowMarketData = {
	bankConfig: SpotMarketConfig;
	bankIndex: number;
	symbol: string;
	totalDepositsBase: BigNum;
	totalDepositsQuote: BigNum;
	depositApr: BigNum;
	totalBorrowsBase: BigNum;
	totalBorrowsQuote: BigNum;
	borrowApr: BigNum;
	totalUserBorrowsBase: BigNum;
	totalUserBorrowsQuote: BigNum;
	totalUserDepositsBase: BigNum;
	totalUserDepositsQuote: BigNum;
	totalUserNetBalanceBase: BigNum;
	totalUserNetBalanceQuote: BigNum;
	borrowLimitBase: BigNum;
	borrowLimitQuote: BigNum;
	borrowUtilization: BigNum;
	userBorrowAccounts: number;
	userDepositAccounts: number;
	assetWeight: number;
	liabilityWeight: number;
	maintenanceAssetWeight: number;
	maintenanceLiabilityWeight: number;
	fuelBoostDeposits?: number;
	fuelBoostBorrows?: number;
	fuelBoostInsurance?: number;
};

/* Used for global balances + aggregated current user balances */
const useBorrowLendBalances = () => {
	const set = useDriftStore((s) => s.set);
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftStore((s) => s.driftClient.client);

	const userBankBalances = useAggregateAccountsSpotBalances(null);

	const updateBorrowData = useCallback(() => {
		if (!driftClientIsReady || !driftClient.isSubscribed) {
			set((s) => {
				s.borrowLendData = [];
			});
			return;
		}

		const borrowLendBalances = CurrentSpotMarkets.map((bank) => {
			let bankAccount: SpotMarketAccount;
			try {
				bankAccount = driftClient.getSpotMarketAccount(bank.marketIndex);
			} catch (e) {
				console.error(e);
				return;
			}

			const totalDepositsBase = BigNum.from(
				getTokenAmount(
					bankAccount.depositBalance,
					driftClient.getSpotMarketAccount(bank.marketIndex),
					SpotBalanceType.DEPOSIT
				),
				bank.precisionExp
			);

			const totalBorrowsBase = BigNum.from(
				getTokenAmount(
					bankAccount.borrowBalance,
					driftClient.getSpotMarketAccount(bank.marketIndex),
					SpotBalanceType.BORROW
				),
				bank.precisionExp
			);

			const oraclePrice = BigNum.from(
				driftClient.getOraclePriceDataAndSlot(bankAccount.oracle)?.data.price ??
					ZERO,
				PRICE_PRECISION_EXP
			).shiftTo(bank.precisionExp);

			const userBankBorrowBalances = userBankBalances?.filter(
				(bal) =>
					bal.asset.marketIndex === bank.marketIndex &&
					matchEnum(bal.balanceType, SpotBalanceType.BORROW)
			);

			const borrowAccountIds = userBankBorrowBalances
				.filter((borrowBal) => !borrowBal.balance.eqZero())
				.map((borrowBal) => borrowBal.accountId);

			const userBankDepositBalances = userBankBalances?.filter(
				(bal) =>
					bal.asset.marketIndex === bank.marketIndex &&
					matchEnum(bal.balanceType, SpotBalanceType.DEPOSIT)
			);

			const depositAccountIds = userBankDepositBalances
				.filter((depositBal) => !depositBal.balance.eqZero())
				.map((depositBal) => depositBal.accountId);

			const totalUserBorrowsBase = NumLib.sumBigNums(
				userBankBorrowBalances?.map((borrowBal) => borrowBal.balance),
				bank.precisionExp
			);

			const totalUserDepositsBase = NumLib.sumBigNums(
				userBankDepositBalances?.map((depositBal) => depositBal.balance),
				bank.precisionExp
			);

			const totalUserBorrowsQuote = totalUserBorrowsBase
				.shiftTo(QUOTE_PRECISION_EXP)
				.mul(oraclePrice);

			const totalUserDepositsQuote = totalUserDepositsBase
				.shiftTo(QUOTE_PRECISION_EXP)
				.mul(oraclePrice);

			const borrowLimitBase = BigNum.from(
				calculateWithdrawLimit(bankAccount, new BN(UI_UTILS.nowTs()))
					.borrowLimit,
				bank.precisionExp
			);

			const assetWeight = bankAccount.initialAssetWeight / 100;
			const liabilityWeight = bankAccount.initialLiabilityWeight / 100;

			const maintenanceAssetWeight = bankAccount.maintenanceAssetWeight / 100;
			const maintenanceLiabilityWeight =
				bankAccount.maintenanceLiabilityWeight / 100;

			const borrowMarketData: BorrowMarketData = {
				bankConfig: bank,
				bankIndex: bank.marketIndex,
				symbol: bank.symbol,
				totalDepositsBase,
				totalDepositsQuote: totalDepositsBase
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(oraclePrice)
					.shiftTo(QUOTE_PRECISION_EXP),
				depositApr: BigNum.from(
					calculateDepositRate(bankAccount),
					SPOT_MARKET_RATE_PRECISION_EXP
				),
				totalBorrowsBase,
				totalBorrowsQuote: totalBorrowsBase
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(oraclePrice)
					.shiftTo(QUOTE_PRECISION_EXP),
				borrowApr: BigNum.from(
					calculateBorrowRate(bankAccount),
					SPOT_MARKET_RATE_PRECISION_EXP
				),
				totalUserBorrowsBase,
				totalUserBorrowsQuote:
					totalUserBorrowsQuote.shiftTo(QUOTE_PRECISION_EXP),
				totalUserDepositsBase,
				totalUserDepositsQuote:
					totalUserDepositsQuote.shiftTo(QUOTE_PRECISION_EXP),
				totalUserNetBalanceBase:
					totalUserDepositsBase.sub(totalUserBorrowsBase),
				totalUserNetBalanceQuote: totalUserDepositsQuote.sub(
					totalUserBorrowsQuote
				),
				borrowLimitBase,
				borrowLimitQuote: borrowLimitBase
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(oraclePrice)
					.shiftTo(QUOTE_PRECISION_EXP),
				borrowUtilization: BigNum.from(
					calculateUtilization(bankAccount),
					6
					// SPOT_MARKET_UTILIZATION_PRECISION_EXP
				),
				userBorrowAccounts: borrowAccountIds.length,
				userDepositAccounts: depositAccountIds.length,
				assetWeight,
				liabilityWeight,
				maintenanceAssetWeight,
				maintenanceLiabilityWeight,
				fuelBoostDeposits: bankAccount.fuelBoostDeposits,
				fuelBoostBorrows: bankAccount.fuelBoostBorrows,
				fuelBoostInsurance: bankAccount.fuelBoostInsurance,
			};

			return borrowMarketData;
		}).filter((bank) => !!bank);

		set((s) => {
			s.borrowLendData = borrowLendBalances;
		});
	}, [driftClientIsReady, driftClient, userBankBalances]);

	useUiUpdateInterval(updateBorrowData, false, true);
};

export default useBorrowLendBalances;
