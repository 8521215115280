import { Info } from '@drift-labs/icons';
import Tooltip from './Tooltip';

const LoweredAssetWeightTooltip = ({
	marginLimitDisplayValue,
}: {
	marginLimitDisplayValue: string;
}) => {
	return (
		<Tooltip
			allowHover
			content={
				<div className="flex flex-col">
					<span>
						The initial asset weight has been lowered to limit the margin
						extended by this token collateral to
						{` ${marginLimitDisplayValue}`}. This does not impact existing
						positions or the maintenance asset weight.
					</span>

					<a
						className="underline text-text-interactive"
						href="https://docs.drift.trade/getting-started/cross-collateral-deposits"
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn more
					</a>
				</div>
			}
		>
			<Info className="ml-1" color="var(--text-label)" />
		</Tooltip>
	);
};

export default LoweredAssetWeightTooltip;
