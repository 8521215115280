'use client';

import { useEffect, useRef } from 'react';
import Text from 'src/components/Text/Text';
import Chevron from 'src/components/Icons/Chevron';
import { UserSettings } from 'src/environmentVariables/EnvironmentVariables';
import TextField from 'src/components/Inputs/TextField';
import { EnvironmentConstants } from '@drift/common';
import useIsMainnet from 'src/hooks/useIsMainnet';
import SemanticChip from 'src/components/Utils/SemanticChip';
import { useAllRpcLatencies } from '../../hooks/useRpcLatency';
import PriorityFeeSettingsInputs from './Parts/PriorityFeeSettingsInputs';
import Utility from '../Inputs/Utility';
import SettingsSwitch from './SettingsSwitch';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import { Updater } from 'use-immer';
import { SuccessFilled } from '@drift-labs/icons';

interface NetworkSettingsProps {
	localSettings: UserSettings;
	setLocalSettings: Updater<UserSettings>;
	toggleRpcDropdown: () => void;
	isRpcDropdownOpen: boolean;
}

const CUSTOM_RPC_LABEL = 'Custom';

const RpcLatencyIndicator = ({ latency }: { latency: number }) => (
	<SemanticChip
		className="mr-2"
		status={
			latency < 0
				? 'grey'
				: latency < 100
				? 'green'
				: latency < 250
				? 'amber'
				: 'red'
		}
	/>
);

const NetworkSettings = ({
	localSettings,
	setLocalSettings,
	toggleRpcDropdown,
	isRpcDropdownOpen,
}: NetworkSettingsProps) => {
	const isMainnet = useIsMainnet();
	const isDevMode = useDevSwitchIsOn();

	const rpcOptions = isMainnet
		? EnvironmentConstants.rpcs.mainnet
		: EnvironmentConstants.rpcs.dev;

	const { value: selectedValue, label: selectedLabel } =
		localSettings.rpcEndpoint;

	const latencies = useAllRpcLatencies();
	const selectedPing = latencies[selectedValue]?.avg || null;

	// const handleReset = () => {
	// 	setLocalSettings({
	// 		...localSettings,
	// 		...defaultTradeSettings,
	// 	});

	// 	// clear browser cache
	// 	caches.keys().then(function (names) {
	// 		for (const name of names) caches.delete(name);
	// 	});
	// };

	const rpcDropdown = useRef(null);

	useEffect(() => {
		const checkDropdownClick = (e) => {
			if (rpcDropdown?.current) {
				const isDescendantOfDropdown = rpcDropdown.current.contains(e.target);

				// If clicked on the dropdown then toggle dropdown display regardless
				if (isDescendantOfDropdown) {
					toggleRpcDropdown();
					return;
				}

				// If not a descendant of the dropdown but the dropdown is currently open then toggle it off
				if (isRpcDropdownOpen && !isDescendantOfDropdown) {
					toggleRpcDropdown();
					return;
				}
			}
		};
		window.addEventListener('click', checkDropdownClick);

		return () => {
			window.removeEventListener('click', checkDropdownClick);
		};
	}, [isRpcDropdownOpen, rpcDropdown.current]);

	const toggleEventSubscriberType = () => {
		setLocalSettings((state) => {
			if (state['eventSubscriberType'] !== 'events-server') {
				state['eventSubscriberType'] =
					localSettings.eventSubscriberType === 'websocket'
						? 'polling'
						: 'websocket';
			} else {
				state['eventSubscriberType'] = 'events-server';
			}
		});
	};

	const setEventsServerEventSubscriberType = () => {
		setLocalSettings((state) => {
			state['eventSubscriberType'] = 'events-server';
		});
	};

	const toggleAccountSubscriberType = () => {
		setLocalSettings((state) => {
			state['accountSubscriberType'] =
				localSettings.accountSubscriberType === 'websocket'
					? 'polling'
					: 'websocket';
		});
	};

	const updateSettingsValue = (update: Partial<UserSettings>) => {
		setLocalSettings((state) => {
			Object.entries(update).forEach((entry) => {
				state[entry[0]] = entry[1];
			});
		});
	};

	return (
		<div className="flex flex-col">
			<div className="flex flex-col pb-6">
				<div>
					<div className="flex items-center justify-between">
						<Text.BODY2 className="text-text-label">RPC Endpoint</Text.BODY2>
						<div
							className="relative flex justify-between p-2 rounded cursor-pointer max-w-[250px] space-x-3 bg-input-bg"
							ref={rpcDropdown}
						>
							<Text.BODY2 className="shrink-0">{selectedLabel}</Text.BODY2>
							<Text.BODY2 className="flex items-center shrink-0">
								{selectedLabel !== CUSTOM_RPC_LABEL && (
									<RpcLatencyIndicator latency={selectedPing} />
								)}
								{selectedPing}
								{selectedLabel !== CUSTOM_RPC_LABEL && ' ms'}
								<Chevron
									className="w-2 h-2 ml-3"
									direction={isRpcDropdownOpen ? 'up' : 'down'}
								/>
							</Text.BODY2>
							{isRpcDropdownOpen && (
								<div className="absolute right-0 mt-1 w-[240px] translate-y-full z-100 -bottom-1 bg-container-bg flex flex-col">
									{rpcOptions.map((rpc, idx) => {
										const avgLatency = latencies[rpc.value].avg;
										return (
											<div
												key={rpc.value}
												className={`flex space-x-2 justify-between p-3 border border-container-border hover:bg-container-bg-hover ${
													idx === 0 && 'rounded-t'
												}`}
												onClick={() => {
													updateSettingsValue({ rpcEndpoint: rpc });
												}}
											>
												<Text.BODY2 className="flex items-center shrink-0">
													<div className="flex items-center w-4 h-4 mr-2">
														{rpc.label === localSettings.rpcEndpoint.label && (
															<SuccessFilled
																size={20}
																className={`text-neutrals-10 w-full h-full p-0.5 `}
															/>
														)}
													</div>
													{rpc.label}
												</Text.BODY2>
												<Text.BODY2 className="flex items-center shrink-0">
													<RpcLatencyIndicator latency={avgLatency} />
													{avgLatency} ms
												</Text.BODY2>
											</div>
										);
									})}
									<div
										className="p-3 border rounded-b border-container-border hover:bg-container-bg-hover"
										onClick={() => {
											updateSettingsValue({
												rpcEndpoint: {
													label: CUSTOM_RPC_LABEL,
													value: '',
												},
											});
										}}
									>
										<Text.BODY2 className="flex items-center">
											<div className="w-4 h-4 mr-2"></div>
											{CUSTOM_RPC_LABEL}
										</Text.BODY2>
									</div>
								</div>
							)}
						</div>
					</div>
					{localSettings.rpcEndpoint.label === CUSTOM_RPC_LABEL && (
						<div className="mt-2">
							<TextField.Default
								type="string"
								onChange={(value) => {
									updateSettingsValue({
										rpcEndpoint: {
											label: CUSTOM_RPC_LABEL,
											value,
										},
									});
								}}
								placeholder="Enter custom RPC URL"
								value={localSettings.rpcEndpoint.value}
							/>
						</div>
					)}
				</div>
				<Utility.VERTSPACERXL />
				<PriorityFeeSettingsInputs
					localSettings={localSettings}
					setLocalSettings={setLocalSettings}
				/>
				{isDevMode && (
					<>
						<div className="mt-4" />
						<SettingsSwitch
							textClass="text-text-label"
							includeLineSpace={false}
							label="Use Websockets"
							tooltipContent={
								'Using websockets to subscribe to blockchain accounts and events can result in a more responsive UI that uses less bandwidth. However, the websockets connection may be less stable.'
							}
							checked={localSettings.accountSubscriberType === 'websocket'}
							onChange={toggleAccountSubscriberType}
						/>
						<div className="mt-4" />
						<SettingsSwitch
							textClass="text-text-label"
							includeLineSpace={false}
							label="Use events-server (overrides Websockets for Event Subscription)"
							tooltipContent={
								'Using events server as backend for EventSubscriber, should use less bandwidth than RPC-based.'
							}
							checked={localSettings.eventSubscriberType === 'events-server'}
							onChange={setEventsServerEventSubscriberType}
						/>
						<div className="mt-4" />
						<SettingsSwitch
							textClass="text-text-label"
							includeLineSpace={false}
							label="Use Websockets for Event Subscription"
							tooltipContent={
								'Using websockets to subscribe to blockchain events can result in a more responsive UI that uses less bandwidth. However, the websockets connection may be less stable.'
							}
							checked={localSettings.eventSubscriberType === 'websocket'}
							onChange={toggleEventSubscriberType}
						/>
					</>
				)}
			</div>
			{/* <Button.Ghost
				onClick={handleReset}
				className="self-end mt-2"
				size="SMALL"
			>
				<Text.BODY2 className="text-text-label">Restore defaults</Text.BODY2>
			</Button.Ghost> */}
		</div>
	);
};

export default NetworkSettings;
