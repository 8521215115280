'use client';

import Button from 'src/components/Button';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import { useState } from 'react';
import Modal from './Modal';
import useDriftActions from 'src/hooks/useDriftActions';
import Select from '../Inputs/Select';
import { SUPPORTED_SOURCE_DOMAINS } from 'src/constants/cctp';
import useSafePush from 'src/hooks/useSafePush';

const CctpTxHashModal = () => {
	const actions = useDriftActions();
	const safePush = useSafePush();

	const [txHash, setTxHash] = useState('');
	const [sourceDomain, setSourceDomain] = useState(0);

	const hideModal = () => actions.showModal('showCctpTxHashModal', false);

	const onConfirm = () => {
		safePush(`/bridge/cctp?txHash=${txHash}&sourceDomain=${sourceDomain}`);
		hideModal();
	};

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Check for Transfer</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<div className="flex flex-col items-end space-y-1">
						<LabelledInput label="Source Chain">
							<Select.Default
								id="source_chain_selector"
								selection={sourceDomain}
								onChange={(newDomain) => setSourceDomain(newDomain)}
								options={SUPPORTED_SOURCE_DOMAINS}
								labelClassName={'flex-row-reverse gap-2 items-center'}
								optionsClassName={
									'flex-row-reverse gap-2 items-center [&>img]:relative [&>img]:-top-[2px]'
								}
								preventPopupIfOneOptionOnly
							/>
						</LabelledInput>
						<Utility.VERTSPACERXS />
					</div>
					<div className="flex flex-col items-end">
						<LabelledInput label="Transaction Hash">
							<TextField.Default
								type="text"
								value={txHash}
								onChange={setTxHash}
							/>
						</LabelledInput>
						<Utility.VERTSPACERXS />
					</div>
					<Modal.ButtonBar>
						<Button.Secondary
							className="w-full"
							disabled={!txHash}
							onClick={onConfirm}
							size="MEDIUM"
						>
							Confirm
						</Button.Secondary>
					</Modal.ButtonBar>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default CctpTxHashModal;
