'use client';

import {
	BigNum,
	BN,
	QUOTE_PRECISION_EXP,
	PositionDirection,
	ZERO,
	AMM_RESERVE_PRECISION_EXP,
	FOUR,
	MarketType,
	BASE_PRECISION_EXP,
} from '@drift-labs/sdk';
import Env from 'src/environmentVariables/EnvironmentVariables';
import useAccountExists from 'src/hooks/useAccountExists';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddLpInput } from '../Inputs/CollateralInput';
import LabelledInput from '../Inputs/LabelledInput';
import Select from '../Inputs/Select';
import Utility from '../Inputs/Utility';
import Modal from '../Modals/Modal';
import Text from '../Text/Text';
import ValueDisplay from '../ValueDisplay';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useTargetAccountData from '../../hooks/useTargetAccountData';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { LiquidityPool } from 'src/hooks/useLiquidityPools';
import CheckboxInput from '../CheckboxInput';
import InfoMessage from '../TradeForm/InfoMessage';
import { MAX_NUMBER_OF_PERP_POSITIONS } from 'src/constants/constants';
import useUserWillExceedMaxPerpsPositions from 'src/hooks/useUserWillExceedMaxPerpsPositions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { COMMON_UI_UTILS } from '@drift/common';
import Tooltip from '../Tooltip/Tooltip';
import { Edit, Info, AlertTriangle } from '@drift-labs/icons';
import useMaxMarginRatioAndLeverage from 'src/hooks/useMaxMarginRatioAndLeverage';
import { twMerge } from 'tailwind-merge';

const AddLiquidityModalContent = (props: { onClose: () => void }) => {
	// # Constants
	const availableSubaccounts = useLazySubAccounts();
	const actions = useDriftActions();
	const accountExists = useAccountExists();
	const selectedTargetAccountKey = useDriftAccountStore(
		(s) => s.currentUserKey
	);
	const targetAccount = useTargetAccountData(selectedTargetAccountKey);
	const setAccountState = useDriftAccountStore((s) => s.set);
	const setState = useDriftStore((s) => s.set);
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const lpData = useDriftStore((s) => s.liquidityPoolInfo);
	const allLiqPools = lpData?.pools ?? [];
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const { captureEvent } = usePostHogCapture();
	const userWillExceedMaxPerpsPositions = useUserWillExceedMaxPerpsPositions(
		selectedMarket?.market.marketIndex
	);
	const maxLeverageOnUserAccount = useMaxMarginRatioAndLeverage()[1];
	const isMobile = useIsMobileScreenSize();
	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);

	// # State
	const currentLeverage = targetAccount?.marginInfo?.leverage ?? 0;

	const [acceptedTerms, setAcceptedTerms] = useState(false);

	const [targetAccountKey, setTargetAccountKey] = useState(
		selectedTargetAccountKey
	);

	const [selectedPool, setSelectedPool] = useState<LiquidityPool>(
		allLiqPools?.find(
			(pool) =>
				pool.marketConfig.marketIndex === selectedMarket?.market.marketIndex
		)
	);

	const [quoteAmountToAdd, setQuoteAmountToAdd] = useState('');

	const [submitting, setSubmitting] = useState(false);

	const [maxButtonTransition, setMaxButtonTransition] = useState(false);

	const [_maxSelected, setMaxSelected] = useState(false);

	const [quoteAmountToAddBigNum, setQuoteAmountToAddBigNum] = useState(
		BigNum.zero(QUOTE_PRECISION_EXP)
	);
	const [lpSharesAmountToAddBigNum, setLpSharesAmountToAddBigNum] = useState(
		BigNum.zero(AMM_RESERVE_PRECISION_EXP)
	);

	const [maxLeverageAllowed, setMaxLeverageAllowed] = useState(
		maxLeverageOnUserAccount
	);

	// # State-Dependent constants
	const currentPoolSharesBalance =
		selectedPool?.userLpShares ?? BigNum.zero(AMM_RESERVE_PRECISION_EXP);

	const maxMarginAvailable = BigNum.from(
		BN.max(
			BN.min(
				targetAccount?.client?.getMaxTradeSizeUSDCForPerp(
					selectedPool?.marketConfig.marketIndex ?? 0,
					PositionDirection.LONG,
					true
				).tradeSize ?? ZERO,
				targetAccount?.client?.getMaxTradeSizeUSDCForPerp(
					selectedPool?.marketConfig.marketIndex ?? 0,
					PositionDirection.SHORT,
					true
				).tradeSize ?? ZERO
			),
			ZERO
		),
		QUOTE_PRECISION_EXP
	);

	const exceededMax = quoteAmountToAddBigNum.gt(maxMarginAvailable);

	const allowConfirmation =
		selectedPool &&
		!userWillExceedMaxPerpsPositions &&
		!exceededMax &&
		accountExists &&
		!submitting &&
		quoteAmountToAdd &&
		quoteAmountToAddBigNum.gt(selectedPool?.minContributionQuoteValue, true) &&
		acceptedTerms &&
		!isGeoblocked;

	const bidAskString = useMemo(() => {
		if (!targetAccount.client || lpSharesAmountToAddBigNum.eqZero())
			return 'O LONG / 0 SHORT';

		const [baseBids, baseAsks] = targetAccount.client?.getLPBidAsks(
			selectedMarket?.marketIndex,
			lpSharesAmountToAddBigNum.val
		);

		return `${BigNum.from(baseBids, BASE_PRECISION_EXP)
			.abs()
			.printShort(true)} LONG / ${BigNum.from(baseAsks, BASE_PRECISION_EXP)
			.abs()
			.printShort(true)} SHORT`;
	}, [
		lpSharesAmountToAddBigNum.print(),
		targetAccount?.userKey,
		selectedMarket?.marketIndex,
	]);

	const liqRiskRating = useMemo(() => {
		// default to low if no shares added yet
		if (lpSharesAmountToAddBigNum.eqZero()) return 'Low';

		// no max lev set = high risk
		if (!maxLeverageOnUserAccount) return 'High';

		// 2x and under = low risk
		if (maxLeverageOnUserAccount <= 2) return 'Low';

		// 2-4 = moderate risk
		if (maxLeverageOnUserAccount > 2 && maxLeverageOnUserAccount <= 4)
			return 'Moderate';

		// > 4 = high risk
		if (maxLeverageOnUserAccount > 4) return 'High';
	}, [maxLeverageOnUserAccount, lpSharesAmountToAddBigNum.print()]);

	// # Effect Hooks
	useEffect(() => {
		captureEvent('opened_add_lp_modal');
	}, []);

	useEffect(() => {
		const newQuoteAmountToAddBigNum = BigNum.fromPrint(
			quoteAmountToAdd,
			QUOTE_PRECISION_EXP
		);
		setQuoteAmountToAddBigNum(newQuoteAmountToAddBigNum);

		if (driftClientIsReady && selectedPool) {
			const newLpSharesAmountToAddBigNum =
				COMMON_UI_UTILS.getLpSharesAmountForQuote(
					driftClient,
					selectedPool?.marketConfig.marketIndex,
					newQuoteAmountToAddBigNum.val
				);

			setLpSharesAmountToAddBigNum(newLpSharesAmountToAddBigNum);
		}
	}, [
		quoteAmountToAdd,
		driftClientIsReady,
		selectedPool?.marketConfig?.marketIndex,
	]);

	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setQuoteAmountToAdd('');
	}, [selectedPool?.marketConfig?.marketIndex]);

	useEffect(() => {
		if (!driftClientIsReady || !targetAccount?.client?.isSubscribed) {
			setMaxLeverageAllowed(Env.defaultMaxMarketLeverage);
			return;
		}

		try {
			const maxLevForMarket = BigNum.from(
				targetAccount.client.getMaxLeverageForPerp(
					selectedPool?.marketConfig?.marketIndex,
					undefined,
					currentPoolSharesBalance.eqZero()
				),
				FOUR
			).toNum();

			if (maxLeverageOnUserAccount == undefined) {
				setMaxLeverageAllowed(maxLevForMarket);
			} else {
				setMaxLeverageAllowed(
					Math.max(maxLevForMarket, maxLeverageOnUserAccount)
				);
			}
		} catch (e) {
			// little race condition here after wallet disconnect so wrap in try/catch
		}

		return;
	}, [
		selectedPool?.marketConfig?.marketIndex,
		targetAccount,
		maxMarginAvailable?.toString(),
		driftClientIsReady,
		maxLeverageOnUserAccount,
	]);

	useEffect(() => {
		if (exceededMax) {
			setQuoteAmountToAdd(maxMarginAvailable.print());
		}
	}, [exceededMax]);

	useEffect(() => {
		const resetLiqPool = allLiqPools?.find(
			(pool) =>
				pool.marketConfig.marketIndex === selectedMarket?.market.marketIndex
		);

		if (resetLiqPool) {
			setSelectedPool(resetLiqPool);
		}
	}, [lpData?.currentUserKey]);

	// # Methods
	const updateCurrentPool = (newPool: LiquidityPool) => {
		setSelectedPool(newPool);
		actions.switchMarket({
			marketIndex: newPool?.marketConfig.marketIndex,
			marketType: MarketType.PERP,
		});
	};

	const handleAddLiq = async () => {
		setSubmitting(true);
		actions.addLoadingItemToQueue({ key: 'modal' });

		const lpSharesAmount = COMMON_UI_UTILS.getLpSharesAmountForQuote(
			driftClient,
			selectedPool.marketConfig.marketIndex,
			quoteAmountToAddBigNum.val
		);

		captureEvent('submitted_add_lp', {
			lp_symbol: selectedPool.marketConfig.baseAssetSymbol,
		});

		const userAccountToAddLiq = targetAccount.client.getUserAccount();

		const _success = await actions.addPerpLpShares({
			userAccount: userAccountToAddLiq,
			amount: lpSharesAmount.val,
			marketIndex: selectedPool.marketConfig.marketIndex,
		});

		setSubmitting(false);
		actions.removeLoadingItemFromQueue({ key: 'modal' });
		props.onClose();
	};

	const handleValueChange = (newValue: string) => {
		setMaxSelected(false);
		setQuoteAmountToAdd(
			BigNum.max(
				BigNum.fromPrint(newValue, QUOTE_PRECISION_EXP),
				BigNum.zero(QUOTE_PRECISION_EXP)
			).printShort()
		);
	};

	const handleMaxSelected = () => {
		setQuoteAmountToAdd(maxMarginAvailable.printShort());
		setMaxSelected(true);
	};

	const handleTargetAccountChange = (newAccountKey: string) => {
		setTargetAccountKey(newAccountKey);
		setAccountState((s) => {
			s.currentUserKey = newAccountKey;
		});
	};

	const handleAcceptedTermsChange = useCallback(() => {
		setAcceptedTerms((prevState) => !prevState);
	}, []);

	const handleOpenMarginModal = useCallback(() => {
		setState((s) => {
			s.modals.showMarginModal = true;
		});
	}, []);

	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					<div className="items-center block">
						<div className="mb-1">
							<Text.P1 className="mr-1 text-text-emphasis">
								Enter the amount of collateral you would like to contribute as
								liquidity to the pool.
							</Text.P1>
						</div>
						<div>
							{isGeoblocked && (
								<Text.P1 className="text-negative-red">
									You are not allowed to use Drift from a restricted territory.
								</Text.P1>
							)}
						</div>
					</div>
					<Utility.VERTSPACERXL />

					{userWillExceedMaxPerpsPositions && (
						<>
							<InfoMessage
								type="error"
								message={`You have reached the maximum number of Perp Positions (${MAX_NUMBER_OF_PERP_POSITIONS}) for a single user account. You will need to remove a position from another market before you can add liquidity to this pool.`}
							/>
							<Utility.VERTSPACERXL />
						</>
					)}

					<>
						<LabelledInput label="Provide Liquidity From">
							<Select.Subaccount
								id="lpAccountSelection"
								onChange={handleTargetAccountChange}
								options={availableSubaccounts}
								initialSelection={targetAccountKey}
								includeDelegates
							/>
						</LabelledInput>
						<Utility.VERTSPACERXL />
					</>

					{selectedPool && (
						<>
							<div className={isMobile ? 'mb-4' : ''}>
								<AddLpInput
									direction="add"
									allLiqPools={allLiqPools}
									selectedPool={selectedPool}
									maxAmount={maxMarginAvailable}
									onChangePool={updateCurrentPool}
									label="Pool & Liquidity to Provide"
									value={quoteAmountToAdd}
									onChangeValue={handleValueChange}
									amountLabel={'Available'}
									onMax={handleMaxSelected}
									currentLeverage={currentLeverage}
									maxLeverage={maxLeverageAllowed}
									exceededMax={exceededMax}
									showMaxLabel
									isMobile={isMobile}
								/>
							</div>
							<Utility.VERTSPACERL />
						</>
					)}

					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />

					<ValueDisplay.Default
						label={
							<Tooltip
								content={
									<>
										The open bids/asks that will be placed initially for this
										size.
									</>
								}
								className="flex items-center text-text-default"
							>
								<Text.BODY3>Perp Orders</Text.BODY3>
								<Info className="ml-0.5" size={14} />
							</Tooltip>
						}
						value={bidAskString}
					/>
					<Utility.VERTSPACERXS />
					<ValueDisplay.Default
						label={
							<Tooltip
								content={
									<>
										BAL can open new orders/positions up to this leverage
										threshold, regardless of the initial leverage entered with.
										The lower this value is set, the lower liquidation risk will
										be. We strongly encourage utilizing this with BAL. Click the
										edit icon to adjust this.
									</>
								}
								className="flex items-center text-text-default"
							>
								<Text.BODY3>Max Account Leverage</Text.BODY3>
								<Info className="ml-0.5" size={14} />
							</Tooltip>
						}
						value={
							<div className="flex items-center text-text-default">
								<Text.BODY3>
									{maxLeverageOnUserAccount
										? `${maxLeverageOnUserAccount}x`
										: 'No Limit'}
								</Text.BODY3>
								<Edit
									className="ml-0.5 hover:cursor-pointer"
									size={14}
									onClick={handleOpenMarginModal}
								/>
							</div>
						}
					/>
					<Utility.VERTSPACERXS />
					<ValueDisplay.Default
						label={
							<Text.BODY3 className="text-text-default">
								Risk of Liquidation
							</Text.BODY3>
						}
						value={
							<Text.BODY3
								className={twMerge(
									'flex items-center text-default',
									liqRiskRating === 'Moderate' && 'text-warn-yellow',
									liqRiskRating === 'High' && 'text-negative-red'
								)}
							>
								{liqRiskRating}
								{liqRiskRating !== 'Low' && (
									<Tooltip
										content={
											<>{`BAL can open positions up to ${
												maxLeverageOnUserAccount
													? `${maxLeverageOnUserAccount}x`
													: `max`
											} leverage. Lower it with the edit icon above to decrease liquidation risk.`}</>
										}
									>
										<AlertTriangle className="w-4 h-4 ml-0.5" />
									</Tooltip>
								)}
							</Text.BODY3>
						}
					/>
					<Utility.VERTSPACERXS />

					<Utility.VERTSPACERL />
					<div className="mb-4">
						<CheckboxInput
							label={
								<Text.BODY3 className="text-text-default">
									{`I am aware that by providing the liquidity shown above, the protocol can open new positions/orders up to `}
									<span className="inline-flex font-bold">
										{maxLeverageOnUserAccount
											? `${maxLeverageOnUserAccount}x`
											: `the market max leverage`}
									</span>
									{` in `}
									<span className="inline-flex font-bold">
										{selectedMarket.symbol}
									</span>
									{` and I may be at risk of liquidation. `}
									<a
										href="https://docs.drift.trade/backstop-amm-liquidity/bal-provider-overview"
										target="_blank"
										rel="noreferrer"
										className="inline-flex text-interactive-link hover:cursor-pointer"
									>
										Docs
									</a>
								</Text.BODY3>
							}
							checked={acceptedTerms}
							onChange={handleAcceptedTermsChange}
							secondaryStyle
							alignStart
						/>
					</div>
					<Utility.VERTSPACERXS />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleAddLiq}
						customLabel={'Add Liquidity'}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default AddLiquidityModalContent;
