'use client';

import Button from 'src/components/Button';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import useDriftActions from 'src/hooks/useDriftActions';
import { useEffect, useState } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import Modal from './Modal';
import Text from '../Text/Text';
import { useDebounce } from 'use-debounce';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useDriftClient from 'src/hooks/useDriftClient';
import useReferrerNameAccounts from 'src/hooks/useReferrerNameAccounts';
import { referralLinkBannedCharactersRegex } from 'src/constants/constants';
import { regex as badwordsRegex } from 'badwords-list';
import UI_UTILS from 'src/utils/uiUtils';

const maxChars = 32;

const CreateReferralLinkModal = () => {
	const { fetchReferrerNameAccounts } = useReferrerNameAccounts();
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const actions = useDriftActions();
	const showModal = actions.showModal;
	const authority = useDriftAccountStore((s) => s.accounts[0]?.authority);
	const accountName = useDriftAccountStore((s) => s.accounts[0]?.name);

	const [submitting, setSubmitting] = useState(false);
	const [referrerNameAlreadyUsed, setReferrerNameAlreadyUsed] = useState(false);
	const [referrerName, setReferrerName] = useState<string>(
		accountName === 'Main Account'
			? authority?.toString()?.slice(0, maxChars)
			: accountName?.replace(referralLinkBannedCharactersRegex, '') || ''
	);

	const charsRemaining = maxChars - referrerName.length;
	const hasBannedCharacters =
		!!referrerName.match(referralLinkBannedCharactersRegex) ||
		UI_UTILS.decodeName(UI_UTILS.encodeName(referrerName)) !== referrerName;
	const hasBadWords = !!referrerName.match(badwordsRegex);
	const buttonDisabled =
		referrerName.length === 0 ||
		referrerName.length > maxChars ||
		referrerNameAlreadyUsed ||
		hasBannedCharacters ||
		hasBadWords ||
		submitting;

	const hideModal = () => showModal('showCreateReferralLinkModal', false);

	const handleEditReferrerName = async () => {
		setSubmitting(true);

		const setReferrerNameResult = await actions.createReferralLink(
			referrerName
		);

		if (setReferrerNameResult === true) {
			// This is not working without a timeout and I don't know why.
			// The await above isn't actually waiting or TX to approve maybe?
			// I don't really get why.
			setTimeout(() => {
				fetchReferrerNameAccounts();
				hideModal();
			}, 2000);
		} else {
			// wat do?
			setSubmitting(false);
		}
	};

	const handleValueChange = (value: string) => {
		let cleanValue = value
			.replace(referralLinkBannedCharactersRegex, '')
			.toLowerCase();

		if (cleanValue.length > maxChars) {
			cleanValue = cleanValue.slice(0, maxChars);
		}

		setReferrerName(cleanValue);
	};

	const checkIfNameIsInUse = async (nameTocheck: string) => {
		try {
			const existingAccount = await driftClient.fetchReferrerNameAccount(
				nameTocheck
			);
			if (existingAccount) {
				// In use?
				setReferrerNameAlreadyUsed(true);
			} else {
				// Not in use?
				setReferrerNameAlreadyUsed(false);
			}
		} catch (err) {
			// Not in use
			setReferrerNameAlreadyUsed(false);
		}
	};

	const [debouncedReferrerName] = useDebounce(referrerName, 1000);
	useEffect(() => {
		if (driftClientIsReady && !!debouncedReferrerName) {
			checkIfNameIsInUse(debouncedReferrerName);
		}
	}, [debouncedReferrerName, driftClientIsReady]);

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Customize Your Referral Link</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<div className="pb-4 text-text-default">
						<Text.BODY2>
							You can only have one referral link per Drift account.
						</Text.BODY2>
						<br />
						<Text.BODY2 className="font-bold">
							Your referral link cannot be changed. Also, note that once a user
							signs up using this referral code, the original subaccount for
							this wallet can no longer be deleted.
						</Text.BODY2>
					</div>
					<div className="flex flex-col items-start">
						<LabelledInput
							label={
								<div className="flex flex-row items-center justify-between w-full">
									<div>Your Referral Link</div>
									<Utility.FORMLABEL02
										label={`${charsRemaining} chars remaining`}
									/>
								</div>
							}
						>
							<TextField.Default
								type="text"
								value={referrerName}
								onChange={handleValueChange}
							/>
						</LabelledInput>
						<Utility.VERTSPACERL />

						<LabelledInput label="Link Preview">
							<div className="w-full p-2 overflow-x-auto text-text-input bg-input-bg whitespace-nowrap thin-scroll">
								{`app.drift.trade/ref/${referrerName}`}
							</div>
						</LabelledInput>

						<Utility.VERTSPACERL />

						{referrerNameAlreadyUsed && (
							<>
								<Utility.VERTSPACERM />
								<InfoMessage
									type="error"
									message="This custom name has already been taken. Please choose a different name."
								/>
							</>
						)}
						{hasBannedCharacters && (
							<>
								<Utility.VERTSPACERM />
								<InfoMessage
									type="error"
									messageTitle="Referral link has invalid characters"
								/>
							</>
						)}
						{hasBadWords && (
							<>
								<Utility.VERTSPACERM />
								<InfoMessage
									type="error"
									messageTitle="Referral link cannot include banned words"
								/>
							</>
						)}
					</div>
					<Modal.ButtonBar>
						<Button.Primary
							className="flex-grow"
							disabled={buttonDisabled}
							onClick={handleEditReferrerName}
							size="LARGE"
						>
							CREATE
						</Button.Primary>
					</Modal.ButtonBar>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default CreateReferralLinkModal;
