'use client';

import { Adapter, WalletReadyState } from '@solana/wallet-adapter-base';
import {
	ConnectionProvider,
	WalletProvider,
} from '@solana/wallet-adapter-react';
import axios from 'axios';
import { ThemeProvider } from 'next-themes';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import CurrentSlotProvider from 'src/providers/currentSlotProvider';
import DriftBlockchainEventSubscriberProvider from 'src/providers/driftBlockchainEventSubscriberProvider';
import OrderRecordMatcherProvider from 'src/providers/orderRecordMatcherProvider';
import PHProvider from 'src/providers/posthog/postHogProvider';
import { SWRConfig } from 'swr';
import { DRIFT_WALLET_PROVIDERS } from '../constants/wallets';
import Env, { DriftTheme } from '../environmentVariables/EnvironmentVariables';
import useInterval from '../hooks/useInterval';
import CandlesProvider from '../providers/candles/candleClientProvider';
import OrderSubscriberProvider from '../providers/orderSubscriberProvider';
import OrderbookDisplayDataProvider from '../providers/orderbookDisplayProvider';
import SettingsProvider from '../providers/settingsProvider';
import DevTopWrapper from './Dev/DevTopWrapper';
import FloatingUI from './FloatingUI';
import WagmiProvider from 'src/providers/wagmiProvider';
import WebsocketSubscriptionProvider from '../providers/websockets/websocketSubscriptionProvider';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import IfStakeSubscriberProvider from 'src/providers/ifStakeSubscriberProvider';
import MetricsProvider from '../providers/metrics/MetricsProvider';
import utc from 'dayjs/plugin/utc';
import SafePushProvider from '../providers/safePushProvider';
import { DriftBlockchainEventSubjectProvider } from '../providers/driftEvents/driftEventSubjectProvider';
import React from 'react';
import { MarketOrderToastStateHandlerProvider } from './MarketOrderToasts/MarketOrderToastStateHandlerProvider';
import { MarketOrderToastEventEmitterProvider } from './MarketOrderToasts/MarketOrderToastEventEmitterProvider';
import { TipLinkWalletAdapter } from '@tiplink/wallet-adapter';
import OptimisedSubscriptionsProvider from 'src/providers/optimisedDataSubscriptions/optimisedSubscriptionsProvider';
import UI_UTILS from 'src/utils/uiUtils';
import Web3ModalProvider from 'src/providers/web3ModalProvider';
import AsyncAppSetup from './AsyncAppSetup';
import { ThemeSetter } from './ThemeSetter';
import HighLeverageModeConfigSubscriberProvider from 'src/providers/HighLeverageModeConfigSubscriberProvider';

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const ProviderComposer_ = ({ contexts, children }) =>
	contexts.reduceRight(
		(kids, parent) => React.cloneElement(parent, { children: kids }),
		children
	);

const ProviderComposer = React.memo(ProviderComposer_);

/**
 * Note: previously this component was in charge of running async app setup hooks once the window.load event fired. After updating to React 18 the window.load event stopped firing - so that functionality has temporarily been disabled. That is why we still have a "liteStore" and "onLoad" event hook which aren't doing anything. Once SSR has been properly set up on the app which is the goal of updating to react 18 anyway, then the problem this component was trying to solve will be finished.
 * @param props
 * @returns
 */
const AppWrapper = (props: PropsWithChildren<any>) => {
	const [e2eWallet, setE2eWallet] = useState<Adapter>(undefined);
	const [theme, setTheme] = useState<DriftTheme>(undefined);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	useInterval(() => {
		const E2E_WALLET = Env.E2E_WALLET;

		if (UI_UTILS.isWindowDefined() && window.useE2eWallet === true) {
			setE2eWallet(E2E_WALLET);
		} else {
			if (E2E_WALLET.connected) {
				E2E_WALLET.disconnect();
			}
			setE2eWallet(undefined);
		}
	}, 1000);

	const wallets = useMemo(
		() => {
			const extraWallets = [
				e2eWallet ?? undefined,
				new TipLinkWalletAdapter({
					theme: theme === DriftTheme.dark ? 'dark' : 'light',
					title: 'Drift',
					clientId: 'b6bc6bdd-f7da-43ac-a8bf-472425ef3e21',
				}),
			].filter((wallet) => wallet);

			return [...DRIFT_WALLET_PROVIDERS, ...extraWallets];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[e2eWallet, theme]
	);

	const sortedWallets = useMemo(
		() =>
			[...wallets].sort((a, b) => {
				const aIsInstalled =
					a.readyState === WalletReadyState.Loadable ||
					a.readyState === WalletReadyState.Installed;

				const bIsInstalled =
					b.readyState === WalletReadyState.Loadable ||
					b.readyState === WalletReadyState.Installed;

				if (aIsInstalled && !bIsInstalled) return -1;
				if (aIsInstalled === bIsInstalled) return 0;
				return 1;
			}),
		[wallets]
	);

	const memoizedProviders = useMemo(() => {
		return [
			// @ts-ignore
			<ConnectionProvider
				key="ConnectionProvider"
				endpoint={Env.defaultRpcAddress}
			/>,
			<WebsocketSubscriptionProvider key="WebsocketSubscriptionProvider" />,
			// @ts-ignore
			<WalletProvider
				key="WalletProvider"
				wallets={sortedWallets}
				// We run our own custom autoconnect logic in the useKeepWalletsInSync hook
				autoConnect={false}
				localStorageKey="walletName"
			/>,
			// @ts-ignore
			<WagmiProvider key="wagmi" />,
			<Web3ModalProvider key="Web3ModalProvider" />,
			<SettingsProvider key="SettingsProvider" />,
			<IfStakeSubscriberProvider key="IfStakeSubscriberProvider" />,
			<HighLeverageModeConfigSubscriberProvider key="HighLeverageModeConfigSubscriberProvider" />,
			<OrderSubscriberProvider key="OrderSubscriberProvider" />,
			<DriftBlockchainEventSubscriberProvider key="DriftBlockchainEventSubscriberProvider" />,
			<DriftBlockchainEventSubjectProvider key="DriftBlockchainEventSubjectProvider" />,
			<CurrentSlotProvider key="CurrentSlotProvider" />,
			<OrderbookDisplayDataProvider key="OrderbookDisplayDataProvider" />,
			<CandlesProvider key="CandlesProvider" />,
			<OrderRecordMatcherProvider key="OrderRecordMatcherProvider" />,
			<MarketOrderToastEventEmitterProvider key="MarketOrderToastEventProvider" />,
			<MarketOrderToastStateHandlerProvider key="MarketOrderStateHandlerProvider" />,
			<MetricsProvider key="MetricsProvider" />,
			<OptimisedSubscriptionsProvider key="OptimisedSubscriptionsProvider" />,
		].filter((provider) => provider);
	}, [Env.defaultRpcAddress, sortedWallets]);

	return (
		<>
			<ThemeProvider
				attribute="class"
				themes={[DriftTheme.dark, DriftTheme.light]}
				enableSystem
			>
				{/* Need this component to make the theme available to logic outside of the Theme Provider */}
				<ThemeSetter setTheme={setTheme} />
				<SafePushProvider>
					<PHProvider>
						<SWRConfig
							value={{
								fetcher: (url: string) =>
									axios.get(url).then((res) => res.data),
							}}
						>
							<ProviderComposer contexts={memoizedProviders}>
								<>
									<DevTopWrapper />
									{isMounted && props.children}
									<FloatingUI />
								</>
								<AsyncAppSetup />
							</ProviderComposer>
						</SWRConfig>
					</PHProvider>
				</SafePushProvider>
			</ThemeProvider>
		</>
	);
};

export default AppWrapper;
