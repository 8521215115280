'use client';

import { useContext, useRef } from 'react';
import { SlotContext } from '../providers/currentSlotProvider';
import { useInterval } from 'react-use';

const useCurrentSlotRef = (updateFrequencyMs: number) => {
	const currentSlot = useContext(SlotContext).currentSlot;
	const currentSlotRef = useRef(currentSlot);

	useInterval(() => {
		currentSlotRef.current = currentSlot;
	}, updateFrequencyMs);

	return currentSlotRef;
};

export default useCurrentSlotRef;
