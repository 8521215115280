'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import { TradeForm } from '../TradeForm/TradeForm';
import MarketIcon from '../Utils/MarketIcon';
import Text from '../Text/Text';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';

const TradeFormModal = () => {
	const set = useDriftStore((s) => s.set);
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const side = useDriftStore((s) => s.tradeForm.side);
	const isPredictionMarket = selectedMarket.isPredictionMarket;
	const isPerp = selectedMarket.isPerp;

	const hideTradeFormModal = () => {
		set((s) => {
			s.modals.showTradeFormModal = false;
		});
	};

	return (
		<Modal className="absolute top-0 z-[150]" onClose={hideTradeFormModal}>
			<Modal.Body className="flex flex-col h-full pb-0">
				<Modal.Header className="py-2" onClose={hideTradeFormModal} showX>
					<Modal.Title>
						<div className="flex items-center w-full">
							<MarketIcon marketSymbol={selectedMarket?.symbol} />{' '}
							<Text.H1 className="ml-4">
								{isPredictionMarket
									? ''
									: isPerp
									? side === 'buy'
										? 'Long'
										: 'Short'
									: side.toLocaleUpperCase()}{' '}
								{isPredictionMarket
									? PredictionMarketConfigs.get(selectedMarket.marketIndex)
											.shortTitle
									: selectedMarket?.symbol}
							</Text.H1>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Content className="flex-grow p-2 pb-4">
					<TradeForm
						marketType={selectedMarket?.marketType}
						isPredictionMarket={isPredictionMarket}
					/>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default TradeFormModal;
