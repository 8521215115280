'use client';

import {
	PerpPosition,
	SpotBalanceType,
	positionIsAvailable,
} from '@drift-labs/sdk';
import { ACTION_HELPERS, COMMON_UI_UTILS, ENUM_UTILS } from '@drift/common';
import { useMemo, useState } from 'react';
import Button from 'src/components/Button';
import Text from 'src/components/Text/Text';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore, {
	AccountSpotBalance,
} from 'src/stores/useDriftAccountsStore';
import useTargetAccountData from '../../hooks/useTargetAccountData';
import Utility from '../Inputs/Utility';
import Modal from './Modal';
import useInterval from '../../hooks/useInterval';
import { PERP_MARKETS_LOOKUP } from '../../environmentVariables/EnvironmentVariables';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';

const NonZeroBalanceInfo = (accountBalance: AccountSpotBalance) => {
	return (
		<div
			className="flex justify-between w-full space-x-2"
			key={accountBalance.asset.symbol}
		>
			<Text.BODY1>{accountBalance.asset.symbol}</Text.BODY1>
			<Text.BODY1>
				{ENUM_UTILS.match(accountBalance.balanceType, SpotBalanceType.BORROW)
					? 'Non-Zero Borrow Balance'
					: 'Non-Zero Deposit Balance'}
			</Text.BODY1>
		</div>
	);
};

const PerpPositionInfo = (perpPosition: PerpPosition) => {
	return (
		<div
			className="flex justify-between w-full space-x-2"
			key={perpPosition.marketIndex}
		>
			<Text.BODY1>
				{PERP_MARKETS_LOOKUP[perpPosition.marketIndex].symbol}
			</Text.BODY1>
		</div>
	);
};

const DeleteSubaccountModal = () => {
	const [isDeleting, setIsDeleting] = useState(false);
	const getDriftStore = useDriftStore((s) => s.get);
	const set = useDriftStore((s) => s.set);
	const subaccountIdToDelete = useDriftStore(
		(s) => s.subAccountsInfo.accountIdToDelete
	);

	const currentAuthority = useDriftStore(
		(s) => s.wallet.current.adapter.publicKey
	);
	const accountsState = useDriftAccountStore((s) => s.get)();
	const actions = useDriftActions();
	const showModal = actions.showModal;
	const userKey = COMMON_UI_UTILS.getUserKey(
		subaccountIdToDelete,
		currentAuthority
	);

	const currentAccount = accountsState?.accounts?.[userKey];

	const [latestSlot, setLatestSlot] = useState(0);

	useInterval(() => {
		getDriftStore()
			.connection.current.getSlot()
			.then((slot) => {
				if (slot) {
					setLatestSlot(slot);
				}
			});
	}, 5000);

	const hideModal = () => showModal('showDeleteSubaccountModal', false);

	const handleDelete = async () => {
		setIsDeleting(true);
		const userAccountToDelete = currentAccount.client;
		await actions.deleteUser(userAccountToDelete);
		hideModal();
		setIsDeleting(false);
		set((s) => {
			s.modals.showDeleteSubaccountModal = false;
			s.subAccountsInfo.accountIdToDelete = null;
			return s;
		});
	};

	const spotBalances = useAccountSpotBalances(userKey);

	const nonZeroBalances = useMemo(() => {
		return spotBalances?.filter((b) => !b.balance.eqZero()) ?? [];
	}, [spotBalances]);

	const depositBalances = useMemo(() => {
		return nonZeroBalances?.filter((b) =>
			ENUM_UTILS.match(b.balanceType, SpotBalanceType.DEPOSIT)
		);
	}, [nonZeroBalances]);

	const borrowBalances = useMemo(() => {
		return nonZeroBalances?.filter((b) =>
			ENUM_UTILS.match(b.balanceType, SpotBalanceType.BORROW)
		);
	}, [nonZeroBalances]);

	const openPerpPositionsAndOrders = useMemo(() => {
		if (!currentAccount) {
			return [];
		}

		const userAccount = currentAccount?.client?.getUserAccount();

		if (!userAccount || !userAccount?.perpPositions) {
			return [];
		}

		const nonZeroPositionSet = new Map<number, PerpPosition>();

		userAccount.perpPositions.forEach((position) => {
			// position is available returns TRUE when the position is blank|empty
			if (!positionIsAvailable(position)) {
				nonZeroPositionSet.set(position.marketIndex, position);
			}
		});

		return Array.from(nonZeroPositionSet.values());
	}, [currentAccount]);

	const canBeDeletedStatus = useMemo(() => {
		if (!currentAccount) return 'no';

		const user = currentAccount.client;
		const userStatsAccount = getDriftStore().userStatsAccount;
		return ACTION_HELPERS.ACCOUNT_DELETION_HELPERS.getAccountCanBeDeletedInstantly(
			user,
			userStatsAccount,
			latestSlot
		);
	}, [currentAccount, latestSlot]);

	const canBeDeleted =
		canBeDeletedStatus === 'yes' ||
		canBeDeletedStatus === 'yes-after-making-idle';

	const canDeleteAfterWaiting = canBeDeletedStatus === 'no-wait-for-idle';

	const idleWaitTimeEstimateMinutes = useMemo(() => {
		if (!currentAccount || latestSlot === 0) return 0;

		if (canBeDeletedStatus === 'no-wait-for-idle') {
			return ACTION_HELPERS.ACCOUNT_DELETION_HELPERS.getIdleWaitTimeMinutes(
				currentAccount.client,
				latestSlot
			);
		}

		return 0; // Don't care otherwise
	}, [currentAccount, canBeDeletedStatus, latestSlot]);

	const selectedSubAccount = useTargetAccountData(userKey);

	if (!selectedSubAccount) return <></>;

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Delete {selectedSubAccount.name}</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<div className="mb-8 text-text-default">
						{canBeDeleted ? (
							<Text.BODY1>
								Deleting this account will refund some SOL to your wallet.
							</Text.BODY1>
						) : canDeleteAfterWaiting ? (
							<>
								<div className="flex flex-col">
									<Text.H3>Cool Down Period</Text.H3>
									<Utility.VERTSPACERS />
									<Text.BODY1>
										Drift is experiencing an influx of new accounts being
										created and has implemented a temporary cool down period for
										account deletions.
									</Text.BODY1>
									<Utility.VERTSPACERS />
									<Text.BODY1>
										Please note that any account activity, like depositing or
										trading, will reset the cool down period for the account.
									</Text.BODY1>
									<Utility.VERTSPACERM />
									<Utility.VERTDIVIDER />
									<Utility.VERTSPACERM />
									<Text.BODY1 className="text-warn-yellow">
										<div className="flex">
											<span>Estimated remaining cool down time : </span>
											<span className="ml-2">
												{idleWaitTimeEstimateMinutes === 0 ? (
													<SkeletonValuePlaceholder
														loading
														className="w-6 h-4"
													/>
												) : (
													`${idleWaitTimeEstimateMinutes} 
											minutes.`
												)}
											</span>
										</div>
									</Text.BODY1>
								</div>
							</>
						) : (
							<div className="flex flex-col">
								<Text.H2 className="block">Account cannot be deleted</Text.H2>
								{openPerpPositionsAndOrders.length > 0 && (
									<>
										<Utility.VERTSPACERM />
										<Utility.VERTDIVIDER />
										<Utility.VERTSPACERL />
										<Text.H3>
											You need to close all PERP positions and orders:
										</Text.H3>
										<Utility.VERTSPACERS />
										<div className="flex flex-col space-y-1">
											{openPerpPositionsAndOrders.map(PerpPositionInfo)}
										</div>
									</>
								)}
								{depositBalances?.length > 0 && (
									<>
										<Utility.VERTSPACERM />
										<Utility.VERTDIVIDER />
										<Utility.VERTSPACERL />
										<Text.H3>You need to withdraw all your deposits:</Text.H3>
										<Utility.VERTSPACERS />
										<div className="flex flex-col space-y-1">
											{depositBalances.map(NonZeroBalanceInfo)}
										</div>
									</>
								)}
								{borrowBalances?.length > 0 && (
									<>
										<Utility.VERTSPACERM />
										<Utility.VERTDIVIDER />
										<Utility.VERTSPACERL />
										<Text.H3>You need to close all your borrows:</Text.H3>
										<Utility.VERTSPACERS />
										<div className="flex flex-col space-y-1">
											{borrowBalances.map(NonZeroBalanceInfo)}
										</div>
									</>
								)}
							</div>
						)}
					</div>
					<Button.Secondary
						size="LARGE"
						textClass={canBeDeleted ? 'text-negative-red' : ''}
						onClick={canBeDeleted ? handleDelete : hideModal}
						className={'w-full'}
						disabled={isDeleting}
					>
						{isDeleting
							? 'Deleting Account'
							: canBeDeleted
							? `Delete`
							: 'Close'}
					</Button.Secondary>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default DeleteSubaccountModal;
