'use client';

import {
	BigNum,
	getTokenAmount,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { BankBalanceUI, COMMON_UI_UTILS, MarketId } from '@drift/common';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import useSubaccountCollateralBalances from './useSubaccountCollateralBalances';
import { useEffect } from 'react';
import useDriftAccountStore, {
	AccountSpotBalance,
} from 'src/stores/useDriftAccountsStore';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import useGetOraclePriceForMarket from './useGetOraclePriceForMarket';

export const useSyncAccountSpotBalances = () => {
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const setAccountsStore = useDriftAccountStore((s) => s.set);
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);

	const userBankBalances = useSubaccountCollateralBalances(
		currentUserKey,
		true
	);

	const getOraclePriceForMarket = useGetOraclePriceForMarket();

	const marketDataState = useMarketStateStore((s) => s.marketDataState);

	useEffect(() => {
		if (!driftClientIsReady || !driftClient) return;

		const formattedSpotBalances = formatSpotBalances(userBankBalances);
		setSpotBalancesInStore(formattedSpotBalances);
	}, [driftClientIsReady, driftClient, userBankBalances, marketDataState]);

	const formatSpotBalances = (userBankBalances: BankBalanceUI[]) => {
		return userBankBalances.map((bankBalance) => {
			const assetPrecision =
				OrderedSpotMarkets[bankBalance.marketIndex].precisionExp;

			const baseBalance = BigNum.from(
				getTokenAmount(
					bankBalance.scaledBalance,
					driftClient.getSpotMarketAccount(bankBalance.marketIndex),
					bankBalance.balanceType
				),
				assetPrecision
			).shiftTo(assetPrecision);

			const marketId = MarketId.createSpotMarket(bankBalance.marketIndex);

			const oraclePrice = getOraclePriceForMarket(marketId);

			const quoteValue = baseBalance
				.shiftTo(PRICE_PRECISION_EXP)
				.mul(oraclePrice)
				.shiftTo(QUOTE_PRECISION_EXP);

			return {
				asset: OrderedSpotMarkets[bankBalance.marketIndex],
				balance: baseBalance,
				balanceType: bankBalance.balanceType,
				quoteValue,
				accountId: bankBalance.accountId,
				accountName: bankBalance.accountName,
				accountKey: COMMON_UI_UTILS.getUserKey(
					bankBalance.accountId,
					bankBalance.accountAuthority
				),
			};
		});
	};

	const setSpotBalancesInStore = (spotBalances: AccountSpotBalance[]) => {
		const groupedAccountsBalances = spotBalances.reduce((acc, spotBalance) => {
			const { accountKey } = spotBalance;

			if (!acc[accountKey]) {
				acc[accountKey] = [];
			}

			acc[accountKey].push(spotBalance);

			return acc;
		}, {});

		setAccountsStore((s) => {
			const accountKeys = Object.keys(s.accounts);
			accountKeys.forEach((accountKey) => {
				s.accounts[accountKey].spotBalances =
					groupedAccountsBalances[accountKey] ?? [];
			});
		});
	};
};
