'use client';

import { ClockSubscriber } from '@drift-labs/sdk';
import { useEffect } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import {
	DEFAULT_COMMITMENT_LEVEL,
	TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
} from 'src/constants/constants';
import { DriftWindow } from '../window/driftWindow';

const useSyncDriftClock = () => {
	const connection = useDriftStore((s) => s.connection.current);

	useEffect(() => {
		if (!connection) return;

		const clockSubscriber = new ClockSubscriber(connection, {
			commitment: DEFAULT_COMMITMENT_LEVEL,
		});

		clockSubscriber.subscribe();

		const interval = setInterval(() => {
			DriftWindow.chainClock.update({
				commitment: clockSubscriber.commitment,
				slot: clockSubscriber.latestSlot,
				ts: clockSubscriber.currentTs,
			});
		}, 1000);

		return () => {
			clearInterval(interval);
			clockSubscriber.unsubscribe();
		};
	}, [connection]);

	useEffect(() => {
		if (!connection) return;

		const clockSubscriber = new ClockSubscriber(connection, {
			commitment: TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
		});

		clockSubscriber.subscribe();

		const interval = setInterval(() => {
			DriftWindow.chainClock.update({
				commitment: clockSubscriber.commitment,
				slot: clockSubscriber.latestSlot,
				ts: clockSubscriber.currentTs,
			});
		}, 1000);

		return () => {
			clearInterval(interval);
			clockSubscriber.unsubscribe();
		};
	}, [connection]);
};

export default useSyncDriftClock;
